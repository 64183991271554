import { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';

import { Dialog } from '../dialog';
import { Alert, Field } from '../forms';
import { fetch } from '../requests';
import { addToast } from '../store/toasts';

function _AddTeamMember({ teamId, teamName, addToast, callback }) {
  const field = useRef(null);
  const [email, setEmail] = useState('');
  const [error, setError] = useState(null);

  useEffect(() => {
    field.current.focus();
  }, []);

  function onConfirm() {
    return fetch(`/api/teams/${teamId}/invite`, {
      method: 'POST',
      body: { email, teamName },
    })
      .then(res => res.json())
      .then(result => {
        if (result.error) {
          return Promise.reject(result.error);
        }
        addToast({ text: 'Invitation sent to the provided email address' });
        if (callback) {
          callback(result);
        }
        return true;
      })
      .catch(error => {
        setError(error);
        return false;
      });
  }

  function onChange({ target }) {
    setEmail(target.value);
  }

  return (
    <Dialog
      heading="Add team member"
      confirmText="Send invitation"
      onConfirm={onConfirm}
      confirmProps={{ disabled: !email.includes('@') }}
      closeOnConfirm
      isOpen
    >
      {error && <Alert text={error} styles={{ marginBottom: 12 }} />}
      <Field
        label="Email address"
        placeholder="Enter an email address"
        name="email"
        type="email"
        innerRef={field}
        onChange={onChange}
      />
    </Dialog>
  );
}

export const AddTeamMember = connect(
  null,
  { addToast },
)(_AddTeamMember);
