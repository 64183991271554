import React, { Suspense } from 'react';
import { connect } from 'react-redux';

import { fetch } from '../requests';
import { Heading } from '../typography';
import * as colors from '../colors';

// Ensure that the charting library is loaded as a separate chunk, as the third
// party library is quite huge.
const Chart = React.lazy(() =>
  import(/* webpackChunkName: "chart" */ '../chart'),
);

const Separator = ({ styles = {} }) => (
  <hr
    css={{
      height: 1,
      width: '100%',
      border: 'none',
      backgroundColor: colors.hexToRgba(colors.grey3, 0.3),
      margin: 0,
      ...styles,
    }}
  />
);

const Total = ({ label, value, units }) => (
  <div
    css={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      textTransform: 'uppercase',
      letterSpacing: '0.05em',
      paddingLeft: '30px',
    }}
  >
    <div
      css={{
        color: colors.grey2,
        lineHeight: '23px',
      }}
    >
      {label}
    </div>
    <div css={{ fontSize: 28 }}>
      {value} {units}
    </div>
  </div>
);

function _Stats(props) {
  const noMetrics = {
    processingMinutes: {
      total: 0,
      series: [],
    },
    processingGigapixels: {
      total: 0,
      series: [],
    },
    storage: {
      transferInMaxUnit: [],
      averagePerDay: {
        value: 0,
        unit: 'bytes',
      },
    },
    transferred: {
      transferInMaxUnit: [],
      total: {
        value: 0,
        unit: 'bytes',
      },
    },
  };

  const [metrics, setMetrics] = React.useState({
    currentMonth: noMetrics,
    lastWeek: noMetrics,
  });

  const storageTooltips = [
    'Video currently available in PIXOP platform',
    'Video no longer available in PIXOP platform<br/>which can be recovered quickly if accidently deleted.<br/>Just reach out to us if you need help with recovery',
    'Video no longer available in PIXOP platform<br/>which has transitioned into long-term storage.<br/>After 90 days the files will be permanently deleted.<br/>Recovery is still possible, but it takes a few hours.<br/>Just reach out to us if you need help with recovery',
  ];

  React.useEffect(() => {
    fetch(`/api/stats/${props.teamId}`)
      .then(res => res.json())
      .then(results => {
        setMetrics(state => ({ ...state, ...results }));
      });
  }, []);
  return (
    <div css={{ backgroundColor: colors.white0 }}>
      <Suspense fallback={null}>
        <Heading
          as="h4"
          css={{ lineHeight: '29px', padding: '14px 20px 10px' }}
        >
          Processed minutes
        </Heading>
        <Separator />
        <div css={{ display: 'flex' }}>
          <div
            css={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              marginTop: 30,
            }}
          >
            <Total
              value={metrics.currentMonth.processingMinutes.total}
              label="Current month"
              units="Minutes"
            />
            <Chart
              data={metrics.currentMonth.processingMinutes.series}
              units="Minutes"
              width={500}
              margin={{
                top: 30,
                right: 20,
                left: 0,
                bottom: 10,
              }}
            />
          </div>
          <div
            css={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              marginTop: 30,
              marginLeft: -20,
            }}
          >
            <Total
              value={metrics.lastWeek.processingMinutes.total}
              label="Last week"
              units="Minutes"
            />
            <Chart
              data={metrics.lastWeek.processingMinutes.series}
              units="Minutes"
              width={500}
              margin={{
                top: 30,
                right: 60,
                left: 0,
                bottom: 10,
              }}
            />
          </div>
        </div>
        <Heading
          as="h4"
          css={{ lineHeight: '29px', padding: '14px 20px 10px' }}
        >
          Processed pixels
        </Heading>
        <Separator />
        <div css={{ display: 'flex' }}>
          <div
            css={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              marginTop: 30,
            }}
          >
            <Total
              value={metrics.currentMonth.processingGigapixels.total}
              label="Current month"
              units="GP"
            />
            <Chart
              data={metrics.currentMonth.processingGigapixels.series}
              units="Gigapixels (GP)"
              width={500}
              margin={{
                top: 30,
                right: 20,
                left: 0,
                bottom: 10,
              }}
            />
          </div>
          <div
            css={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              marginTop: 30,
              marginLeft: -20,
            }}
          >
            <Total
              value={metrics.lastWeek.processingGigapixels.total}
              label="Last week"
              units="GP"
            />
            <Chart
              data={metrics.lastWeek.processingGigapixels.series}
              units="Gigapixels (GP)"
              width={500}
              margin={{
                top: 30,
                right: 60,
                left: 0,
                bottom: 10,
              }}
            />
          </div>
        </div>
        <Heading
          as="h4"
          css={{ lineHeight: '29px', padding: '14px 20px 10px' }}
        >
          Stored data
        </Heading>
        <Separator />
        <div css={{ display: 'flex' }}>
          <div
            css={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              marginTop: 30,
              marginLeft: -20,
            }}
          >
            <Total
              value={metrics.currentMonth.storage.averagePerDay.value}
              label="Current month (total per day)"
              units={metrics.currentMonth.storage.averagePerDay.unit}
            />
            <Chart
              data={metrics.currentMonth.storage.transferInMaxUnit}
              units={`Active (${
                metrics.currentMonth.storage.averagePerDay.unit
              })`}
              width={500}
              margin={{
                top: 30,
                right: 20,
                left: 0,
                bottom: 10,
              }}
            />
          </div>
          <div
            css={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              marginTop: 30,
            }}
          >
            <Total
              value={metrics.lastWeek.storage.averagePerDay.value}
              label="Last week (total per day)"
              units={metrics.lastWeek.storage.averagePerDay.unit}
            />
            <Chart
              data={metrics.lastWeek.storage.transferInMaxUnit}
              units={`Active (${metrics.lastWeek.storage.averagePerDay.unit})`}
              width={500}
              margin={{
                top: 30,
                right: 60,
                left: 0,
                bottom: 10,
              }}
            />
          </div>
        </div>
        <Heading
          as="h4"
          css={{ lineHeight: '29px', padding: '14px 20px 10px' }}
        >
          Downloaded data
        </Heading>
        <Separator />
        <div css={{ display: 'flex' }}>
          <div
            css={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              marginTop: 30,
            }}
          >
            <Total
              value={metrics.currentMonth.transferred.total.value}
              label="Current month"
              units={metrics.currentMonth.transferred.total.unit}
            />
            <Chart
              data={metrics.currentMonth.transferred.transferInMaxUnit}
              units={`Data (${metrics.currentMonth.transferred.total.unit})`}
              width={500}
              margin={{
                top: 30,
                right: 20,
                left: 0,
                bottom: 10,
              }}
            />
          </div>
          <div
            css={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              marginTop: 30,
              marginLeft: -20,
            }}
          >
            <Total
              value={metrics.lastWeek.transferred.total.value}
              label="Last week"
              units={metrics.lastWeek.transferred.total.unit}
            />
            <Chart
              data={metrics.lastWeek.transferred.transferInMaxUnit}
              units={`Data (${metrics.lastWeek.transferred.total.unit})`}
              width={500}
              margin={{
                top: 30,
                right: 60,
                left: 0,
                bottom: 10,
              }}
            />
          </div>
        </div>
        <div css={{ marginTop: 30, paddingBottom: 20 }} />
      </Suspense>
    </div>
  );
}

export const Stats = connect(state => {
  const teamId = state.currentTeamId;
  return {
    teamId,
  };
})(_Stats);
