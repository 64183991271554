import * as colors from './colors';
import { Film } from './icons';

export const MultiThumbnail = ({ thumbnails, styles = {} }) => (
  <div
    css={{
      display: 'flex',
      flexDirection: 'row',
      width: 86,
      height: 56,
      backgroundColor: colors.grey0,
      borderRadius: 3,
      overflow: 'hidden',
      div: {
        flex: 1,
        display: 'flex',
        backgroundSize: 'cover',
      },
      ...styles,
    }}
  >
    {thumbnails.length === 0 && (
      <div
        css={{
          flex: 1,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Film width={20} height={20} color={colors.white0} />
      </div>
    )}
    {thumbnails.length < 3 ? (
      thumbnails.map(thumbnail => (
        <div
          key={thumbnail}
          css={{
            backgroundImage: `url(${thumbnail})`,
          }}
        />
      ))
    ) : (
      <>
        <div css={{ flexDirection: 'column' }}>
          <div
            css={{
              backgroundImage: `url(${thumbnails[0]})`,
            }}
          />
          <div
            css={{
              backgroundImage: `url(${thumbnails[1]})`,
            }}
          />
        </div>
        <div css={{ flexDirection: 'column' }}>
          <div
            css={{
              backgroundImage: `url(${thumbnails[2]})`,
            }}
          />
          {thumbnails[3] && (
            <div
              css={{
                backgroundImage: `url(${thumbnails[3]})`,
              }}
            />
          )}
        </div>
      </>
    )}
  </div>
);
