import React, { Component } from 'react';
import { connect } from 'react-redux';

import * as colors from '../colors';
import * as constants from '../constants';
import { rem } from '../typography';
import { Progress } from '../progress';
import { RoundedButton } from '../button';
import { Dropzone } from '../dropzone';
import { ContextMenu } from '../context-menu';
import { TeamMembers } from '../team-members';
import { Film, ExclamationCircle, BoltCircle } from '../icons';
import { aggregateVideos, getVideoState, videoStates } from '../video-status';
import { Filter } from '../filter';
import { formatMsToHourMinutesSeconds } from '../utils';
import { cancelUpload, resumeUpload } from '../store/uploads';
import { openDialog } from '../store/dialog';
import {
  currentProjectSelector,
  currentProjectVideosSelector,
} from '../store/selectors';
import { NotFound } from './not-found';
import { formatContainer } from '../modals/processing-tool/process-video-options';

const VideoTitleBar = ({
  video,
  size,
  confirmDeleteVideo,
  editMetadata,
  moveVideo,
  cancelUpload,
  resumeUpload,
  downloadVideo,
  processVideo,
  upload,
}) => (
  <div
    css={{
      position: 'relative',
      padding: '0 15px',
      borderBottomRightRadius: 7,
      borderBottomLeftRadius: 7,
      flexDirection: 'column',
      backgroundColor: colors.white0,
      height: 63,
    }}
  >
    <div
      css={{
        paddingTop: 9,
        fontSize: rem(14),
        color: colors.black1,
        height: 23,
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        display: 'block',
        marginRight: 35,
        verticalAlign: 'middle',
        lineHeight: '21px',
      }}
    >
      {video.name}
    </div>
    <div
      css={{
        display: 'flex',
        alignItems: 'center',
        fontSize: rem(14),
        color: colors.grey2,
        height: 23,
      }}
    >
      {(
        formatContainer(video) ||
        video.file_container_name ||
        'Pending ingestion'
      )
        .replace(/ *\([^)]*\) */g, '') // remove text between parentheses
        .replace(/(.{24})..+/, '$1…') // insert ellipsis after 24 chars
      }{' '}
      {size !== '0 bytes' && `- ${size}`}
      {video.metadata.durationInMillis &&
        ` - ${formatMsToHourMinutesSeconds(video.metadata.durationInMillis)}`}
    </div>
    <div
      css={{
        position: 'absolute',
        top: 10,
        right: 10,
        width: 35,
        height: 17,
      }}
    >
      <ContextMenu
        items={[
          {
            label: 'Cancel upload',
            onClick:
              upload &&
              upload.isActive &&
              (video.upload && video.upload.progress > 0) &&
              cancelUpload(video.id),
          },
          {
            label: 'Resume upload',
            onClick:
              upload &&
              !upload.isActive &&
              (video.upload && video.upload.progress > 0) &&
              resumeUpload(video.id),
          },
          {
            label: 'Download',
            onClick: video.web_video_url && downloadVideo,
          },
          { label: 'Edit metadata', onClick: editMetadata },
          { label: 'Move', onClick: moveVideo },
          { label: 'Process', onClick: processVideo },
          {
            label: 'Delete',
            onClick: confirmDeleteVideo,
            color: colors.red0,
          },
        ]}
      />
    </div>
  </div>
);

function VideoBackground({ video, styles = {} }) {
  const player = React.useRef();

  // use state as suggested in https://stackoverflow.com/questions/36803176/how-to-prevent-the-play-request-was-interrupted-by-a-call-to-pause-error
  // to prevent “The play() request was interrupted by a call to pause()” errors
  let playing = false;

  function play() {
    event.stopPropagation();
    if (player.current.paused && !playing) {
      player.current.play();
    }
  }
  function pause() {
    event.stopPropagation();
    if (!player.current.paused && playing) {
      player.current.pause();
    }
  }

  return (
    <div
      css={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: 200,
        overflow: 'hidden',
      }}
    >
      <video
        ref={player}
        loop
        muted
        onMouseEnter={play}
        onMouseLeave={pause}
        onPlaying={() => (playing = true)}
        onPause={() => (playing = false)}
        poster={video.thumbnail_urls && video.thumbnail_urls.large}
        css={{
          width: '100%',
          height: 200,
          backgroundColor: colors.black0,
          objectFit: 'cover',
          ...styles,
        }}
      >
        <source src={video.web_video_url} type="video/mp4" />
      </video>
    </div>
  );
}

export const ProcessingVideo = ({
  video,
  aggregatedVideoData,
  styles = {},
  statusStyles = {},
  contentStyles = {},
}) => {
  const [dotsAnimation, setDotsAnimation] = React.useState(false);

  function updateDots() {
    const numberOfDots = 1 + (Math.floor(new Date().getTime() / 400) % 3);
    setDotsAnimation('.'.repeat(numberOfDots));
  }

  React.useEffect(() => {
    const dotTimer = setInterval(() => updateDots(), 400);

    return () => {
      clearInterval(dotTimer);
    };
  }, []);

  return (
    <>
      <div
        css={{
          flex: 1,
          height: 165,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: colors.grey3,
          backgroundSize: 'cover',
          backgroundImage:
            video.thumbnail_urls && `url(${video.thumbnail_urls.large})`,
          filter: video.thumbnail_urls && 'grayscale(100%)',
          textShadow: video.thumbnail_urls && `0px 0px 3px ${colors.black0}`,
          color: colors.white0,
          ...styles,
        }}
      >
        <div
          css={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            ...contentStyles,
          }}
        >
          <Film />
          <span css={{ marginTop: 10 }}>
            <span css={{ opacity: 0 }}>
              {aggregatedVideoData.isActive ? dotsAnimation : ''}
            </span>
            <span>{aggregatedVideoData.label}</span>
            <span>{aggregatedVideoData.isActive ? dotsAnimation : ''}</span>
          </span>
        </div>
      </div>
      <div
        css={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: '#fff',
          height: 35,
          ...statusStyles,
        }}
      >
        <Progress
          value={aggregatedVideoData.value}
          color={aggregatedVideoData.color}
          isActive={aggregatedVideoData.isActive}
          styles={{ flex: 1, margin: '0 10px' }}
        />
      </div>
    </>
  );
};

const _VideoCard = ({
  video,
  aggregatedVideoData,
  deleteVideo,
  editMetadata,
  moveVideo,
  downloadVideo,
  cancelUpload,
  resumeUpload,
  processVideo,
  upload,
  navigate,
}) => {
  const hasProcessedVideos =
    aggregatedVideoData.numCompleted - aggregatedVideoData.numCompletedClips >
    1;
  const hasErrors = aggregatedVideoData.withError.length !== 0;

  let popupMessage;
  let headlineText = 'Problem';
  let backgroundColor = colors.red0;
  let symbol = 'exclamation';

  if (getVideoState(video) === videoStates.error) {
    popupMessage =
      'There is a problem with the original video; it might be corrupt. Please verify the integrity and/or repair the file by transcoding before reuploading it.';
  } else if (hasErrors) {
    popupMessage =
      "There is an issue with one or more of this video's derived videos; please open to review.";
  } else if (hasProcessedVideos) {
    backgroundColor = colors.green0;
    symbol = 'bolt';
  } else if (aggregatedVideoData.state === videoStates.low_quality) {
    headlineText = 'Warning';
    backgroundColor = colors.orange0;
    popupMessage = 'Low video quality detected; processing results may suffer.';
  } else {
    symbol = null;
  }

  const [errorLeftPos, setErrorLeftPos] = React.useState(-80);
  const ref = React.useRef();
  React.useLayoutEffect(() => {
    const { x } = ref.current.getBoundingClientRect();
    if (x <= 80) {
      setErrorLeftPos(0);
    }
  }, []);
  return (
    <div
      ref={ref}
      tabIndex="0"
      role="button"
      onKeyDown={event => {
        if (event.keyCode === 13) {
          navigate(`/projects/${video.project_id}/videos/${video.id}`);
        }
      }}
      onClick={() =>
        navigate(`/projects/${video.project_id}/videos/${video.id}`)
      }
      css={{
        position: 'relative',
        borderBottomRightRadius: 7,
        borderBottomLeftRadius: 7,
        boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.05)',
        cursor: 'pointer',
        transition: `all ${constants.transitionShort} ease`,
        ':hover, :focus': {
          boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.15)',
        },
        ':hover > div.popup > div': {
          visibility: 'visible',
        },
      }}
    >
      {symbol && (
        <div
          className="popup"
          css={{
            width: 30,
            height: 30,
            backgroundColor,
            position: 'absolute',
            left: 10,
            bottom:
              aggregatedVideoData.state !== videoStates.uploading &&
              aggregatedVideoData.state !== videoStates.ingesting &&
              aggregatedVideoData.state !== videoStates.processing
                ? 73
                : 108,
            zIndex: constants.zIndices.base + 1,
            borderRadius: '50%',
            justifyContent: 'center',
            alignItems: 'center',
            '> div': {
              visibility: 'hidden',
            },
          }}
        >
          {symbol === 'exclamation' && (
            <ExclamationCircle
              styles={{ position: 'absolute', top: 7, left: 7 }}
            />
          )}
          {symbol === 'bolt' && (
            <BoltCircle styles={{ position: 'absolute', top: 7, left: 11 }} />
          )}
          {popupMessage && (
            <div
              css={{
                position: 'absolute',
                backgroundColor: colors.white0,
                borderRadius: 3,
                boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.05)',
                padding: '10px 16px',
                bottom: 36,
                left: errorLeftPos,
                width: 159,
                border: `1px solid ${colors.grey3}`,
              }}
            >
              <div css={{ color: colors.black1 }}>{headlineText}</div>
              <div
                css={{
                  color: colors.grey2,
                  lineHeight: '20px',
                  fontWeight: 400,
                  marginTop: 6,
                }}
              >
                {popupMessage}
              </div>
            </div>
          )}
        </div>
      )}
      {aggregatedVideoData.state !== videoStates.uploading &&
      aggregatedVideoData.state !== videoStates.ingesting &&
      aggregatedVideoData.state !== videoStates.processing ? (
        <VideoBackground video={video} />
      ) : (
        <ProcessingVideo
          video={video}
          aggregatedVideoData={aggregatedVideoData}
        />
      )}
      <VideoTitleBar
        video={video}
        upload={upload}
        size={aggregatedVideoData.size}
        confirmDeleteVideo={deleteVideo}
        cancelUpload={cancelUpload}
        resumeUpload={resumeUpload}
        editMetadata={editMetadata}
        moveVideo={moveVideo}
        downloadVideo={downloadVideo}
        processVideo={processVideo}
      />
    </div>
  );
};

const VideoCard = connect(
  (state, props) => {
    return {
      upload: state.uploads.find(upload => upload.video.id === props.video.id),
    };
  },
  (dispatch, ownProps) => {
    const createHandler = (type, opts = {}) => () =>
      dispatch({
        type: 'openDialog',
        payload: { type, videoId: ownProps.video.id, ...opts },
      });

    const isIngested =
      ownProps.video.ingestion_state.ingestionStatus === 'DONE';

    return {
      cancelUpload: id => () => dispatch(cancelUpload({ id })),
      resumeUpload: id => () => dispatch(resumeUpload({ id })),
      deleteVideo: createHandler('deleteVideo'),
      editMetadata: createHandler('editVideo'),
      moveVideo: createHandler('moveVideo'),
      downloadVideo: createHandler('downloadVideo'),
      processVideo: isIngested && createHandler('processVideo'),
    };
  },
)(_VideoCard);

class _Project extends Component {
  render() {
    if (!this.props.project) {
      return <NotFound {...this.props} />;
    }

    const filter = new URLSearchParams(this.props.location.search).get(
      'filter',
    );

    const parentVideosById = {};
    const childVideos = [];
    this.props.videos.forEach(video => {
      if (video.parent_id) {
        childVideos.push(video);
      } else {
        parentVideosById[video.id] = video;
        parentVideosById[video.id].children = [];
      }
    });

    childVideos.forEach(video => {
      const parentVideo = parentVideosById[video.parent_id];
      if (parentVideo) {
        parentVideo.children.push(video);
      }
    });

    let videos = Object.values(parentVideosById).map(
      ({ children, ...video }) => {
        video.aggregatedVideoData = aggregateVideos([video, ...children]);
        return video;
      },
    );

    if (filter === 'newest') {
      videos = videos.sort(
        (videoA, videoB) =>
          new Date(videoB.created_at) - new Date(videoA.created_at),
      );
    } else if (filter === 'oldest') {
      videos = videos.sort(
        (videoA, videoB) =>
          new Date(videoA.created_at) - new Date(videoB.created_at),
      );
    } else if (filter === 'active') {
      videos = videos.filter(
        video =>
          video.aggregatedVideoData.state !== videoStates.none &&
          video.aggregatedVideoData.state !== videoStates.finished,
      );
    } else if (filter === 'inactive') {
      videos = videos.filter(
        video =>
          video.aggregatedVideoData.state === videoStates.none ||
          video.aggregatedVideoData.state === videoStates.finished,
      );
    } else if (filter === 'error') {
      videos = videos.filter(
        video => video.aggregatedVideoData.state === videoStates.error,
      );
    } else if (filter === 'a-z') {
      videos = [...videos].sort((videoA, videoB) => {
        const a = videoA.name.toLowerCase();
        const b = videoB.name.toLowerCase();
        return a < b ? -1 : a > b ? 1 : 0;
      });
    } else if (filter === 'z-a') {
      videos = [...videos].sort((videoA, videoB) => {
        const a = videoA.name.toLowerCase();
        const b = videoB.name.toLowerCase();
        return b < a ? -1 : b > a ? 1 : 0;
      });
    }

    return (
      <>
        <div
          css={{
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: 20,
          }}
        >
          <RoundedButton
            onClick={() =>
              this.props.openDialog({
                type: 'downloadVideo',
                projectId: this.props.project.id,
              })
            }
          >
            Download videos
          </RoundedButton>
          <TeamMembers />
          <Filter
            basePath={this.props.location.pathname}
            currentFilter={filter}
          />
        </div>
        <Dropzone
          projectId={this.props.project.id}
          styles={{ marginBottom: 20 }}
        />
        <div
          css={{
            display: 'grid',
            justifyContent: 'center',
            gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))',
            gridGap: 20,
          }}
        >
          {videos.map(video => (
            <VideoCard
              key={video.id}
              video={video}
              aggregatedVideoData={video.aggregatedVideoData}
              navigate={this.props.history.push}
            />
          ))}
        </div>
      </>
    );
  }
}

export const Project = connect(
  (state, ownProps) => ({
    project: currentProjectSelector(state, ownProps),
    videos: currentProjectVideosSelector(state, ownProps),
  }),
  { openDialog },
)(_Project);
