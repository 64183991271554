import * as colors from '../colors';

export const Dev = () =>
  Object.entries(colors).map(([name, color]) => (
    <div key={name} css={{ display: 'flex' }}>
      <div
        css={{
          width: 40,
          height: 40,
          backgroundColor: color,
          border: '2px dotted #000',
        }}
      />
      <pre css={{ marginLeft: 12 }}>
        {color} / {name}
      </pre>
    </div>
  ));
