import { connect } from 'react-redux';

import { Dropzone } from '../dropzone';
import { Dialog } from '../dialog';
import { closeDialog } from '../store/dialog';

function _Upload({ closeDialog, projectId, noConfirm }) {
  return (
    <Dialog isOpen heading="Upload videos">
      <Dropzone
        onDropFiles={closeDialog}
        projectId={projectId}
        noConfirm={noConfirm}
      />
    </Dialog>
  );
}

export const Upload = connect(
  null,
  { closeDialog },
)(_Upload);
