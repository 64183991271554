import { Component } from 'react';

import * as colors from './colors';
import { Modal } from './modal';
import { rem } from './typography';
import { RoundedIconButton, Close } from './icons';
import { TermsPolicy } from './terms-policy';
import { CookiePolicy } from './cookie-policy';

export class PolicyModal extends Component {
  state = { isOpen: false };

  showModal = isOpen => () => this.setState({ isOpen });

  open = this.showModal(true);

  close = this.showModal(false);

  render() {
    return (
      <>
        {this.props.children(this.open)}
        {this.state.isOpen && (
          <Modal close={this.close}>
            <div
              css={{
                position: 'relative',
                boxSizing: 'border-box',
                backgroundColor: colors.white0,
                width: 'calc(100% - 40px)',
                maxWidth: rem(720),
                overflow: 'auto',
                maxHeight: 'calc(100% - 40px)',
                borderRadius: 3,
                h1: {
                  fontSize: rem(36),
                },
                h2: {
                  marginTop: rem(18),
                },
                h3: {
                  marginTop: rem(12),
                },
                'span + span': {
                  marginTop: rem(18),
                },
                a: {
                  color: colors.orange0,
                },
              }}
            >
              <RoundedIconButton
                tabIndex="0"
                icon={Close}
                iconProps={{ color: colors.grey7 }}
                onClick={this.close}
                onKeyDown={event => event.keyCode === 13 && this.close()}
                styles={{
                  position: 'sticky',
                  float: 'right',
                  top: 18,
                  right: 18,
                }}
              />
              <div
                css={{
                  padding: `${rem(30)} ${rem(40)} ${rem(40)}`,
                }}
              >
                {this.props.policy === 'cookies' ? (
                  <CookiePolicy />
                ) : (
                  <TermsPolicy />
                )}
              </div>
            </div>
          </Modal>
        )}
      </>
    );
  }
}
