import React from 'react';

import { Dialog } from '../dialog';
import * as colors from '../colors';

export function UploadInitiated() {
  return (
    <Dialog
      heading="So what happens next?"
      closeOnOutsideContainerClick
      isOpen
      containerStyles={{
        width: 540,
        fontSize: '15px',
        color: colors.grey7,
        lineHeight: 1.4,
      }}
    >
      <p>
        Your file is now being uploaded. You will receive an email once
        ingestion is complete.
      </p>

      <p>
        By default, a 10-second clip from the middle of the uploaded file is
        also extracted. We{' '}
        <span css={{ color: colors.black3, fontSize: 16 }}>highly</span>{' '}
        recommend that you experiment with processing settings on this clip{' '}
        <span css={{ color: colors.black3, fontSize: 16 }}>before</span>{' '}
        processing the complete master file. Create additional clips if you wish
        to further verify the settings.
      </p>

      <p>You can close this window now.</p>
    </Dialog>
  );
}
