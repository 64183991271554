import styled from '@emotion/styled';

import * as colors from './colors';
import { rem } from './typography';

export const Badge = styled('span')({
  fontSize: 12,
  lineHeight: rem(20),
  textTransform: 'uppercase',
  letterSpacing: '0.03em',
  color: colors.white0,
  backgroundColor: colors.orange1,
  padding: `${rem(3)} ${rem(15)} ${rem(2)}`,
  borderRadius: 25,
});
