import { getClientId } from './store/sockets-middleware';

/**
 * Fetch wrapper. Mostly useful to automatically provide specific headers, such
 * as the content type and the WebSocket connection's client id, as well as
 * stringifying payloads.
 */
export function fetch(path, options = {}) {
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    ...(options.headers || {}),
  };
  const clientId = getClientId();
  if (clientId) {
    headers['X-WS-Client-Id'] = getClientId();
  }
  return window.fetch(path, {
    headers,
    method: options.method || 'GET',
    body: options.body ? JSON.stringify(options.body) : null,
    credentials: 'same-origin',
  });
}

export function nativeFetch(...args) {
  return window.fetch(...args);
}

const pause = (cb, delay) =>
  new Promise(resolve => {
    setTimeout(() => {
      resolve(cb());
    }, delay);
  });

export const fetchRetry = (url, options, n, delay) =>
  fetch(url, options)
    .then(result => {
      // trigger retry of any type of possibly transient server-side errors
      if (result.status >= 500) {
        throw new Error('Error fetching, status: ' + result.status);
      }

      return result;
    })
    .catch(function(error) {
      if (n === 1) {
        throw error;
      }

      // retry with exponential backoff
      return pause(() => fetchRetry(url, options, n - 1, delay * 2), delay);
    });
