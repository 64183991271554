import { connect } from 'react-redux';
import useReactRouter from 'use-react-router';

import { teamsSelector, currentTeamSelector } from '../store/selectors';
import { closeDialog } from '../store/dialog';
import { setCurrentTeam } from '../store/teams';
import { Dialog } from '../dialog';
import * as colors from '../colors';

const Team = ({ isCurrent, children, onClick }) => (
  <div
    role="button"
    tabIndex="0"
    onClick={onClick}
    css={{
      display: 'flex',
      alignItems: 'center',
      color: colors.orange1,
      textDecoration: 'none',
      height: 40,
      padding: '0 10px',
      borderRadius: 3,
      border: '1px solid transparent',
      userSelect: 'none',
      ...(isCurrent
        ? {
            color: colors.grey3,
          }
        : {
            ':hover': {
              cursor: 'pointer',
              backgroundColor: colors.white3,
              border: `1px solid ${colors.grey4}`,
            },
          }),
      span: {
        marginTop: 5,
      },
    }}
  >
    {children}
    {isCurrent && (
      <div css={{ marginLeft: 6, color: colors.grey3 }}>(current)</div>
    )}
  </div>
);

function _Teams({ currentTeam, otherTeams, selectTeam }) {
  const { history } = useReactRouter();
  const onSelect = () => {
    if (window.location.pathname !== '/') {
      history.push('/');
    }
  };

  return (
    <Dialog isOpen heading="Teams">
      <Team id={currentTeam.id} isCurrent>
        {currentTeam.name}
      </Team>
      {otherTeams.map(team => (
        <Team key={team.id} onClick={selectTeam(team.id, onSelect)}>
          {team.name}
        </Team>
      ))}
    </Dialog>
  );
}

export const Teams = connect(
  state => {
    const teams = teamsSelector(state);
    const currentTeam = currentTeamSelector(state);
    const otherTeams = teams.filter(team => team.id !== state.currentTeamId);
    return { currentTeam, otherTeams };
  },
  dispatch => ({
    selectTeam: (id, cb) => () => {
      dispatch(closeDialog());
      dispatch(setCurrentTeam({ id }));
      cb();
    },
  }),
)(_Teams);
