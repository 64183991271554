// Adapted version of https://www.falldowngoboone.com/blog/talk-to-your-react-components-with-custom-events/

function eventOn(eventType, listener) {
  document.addEventListener(eventType, listener);
}

function eventOff(eventType, listener) {
  document.removeEventListener(eventType, listener);
}

function eventOnce(eventType, listener) {
  eventOn(eventType, handleEventOnce);

  function handleEventOnce(event) {
    listener(event);
    eventOff(eventType, handleEventOnce);
  }
}

function triggerEvent(eventType, data) {
  const event = new CustomEvent(eventType, { detail: data });
  document.dispatchEvent(event);
}

export { eventOn, eventOnce, eventOff, triggerEvent };
