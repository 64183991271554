import React from 'react';
import styled from '@emotion/styled';

import * as colors from '../../colors';

const innerWidth = 877;
export function ClipSelection({
  start,
  end,
  onChangeClipDuration,
  frames,
  width,
  duration,
  maxDuration,
  styles = {},
}) {
  const sideScrubWidth = 20;
  const minScrubWidth = 15;
  const totalScrubWidth = sideScrubWidth * 2 + minScrubWidth;

  function clipInit(duration) {
    const clipPosStart = start || 0;
    const clipPosEnd = maxDuration
      ? Math.min(start + maxDuration, end)
      : end || duration;
    return {
      duration,
      target: null,
      pos: 0,
      clipPos: {
        start: clipPosStart,
        end: clipPosEnd,
      },
      containerDimensions: {
        x: 0,
        width,
      },
      isDragging: false,
      scrubWidth: width - sideScrubWidth * 2,
      offset: 0,
      ...getPosFromClipDuration(
        { duration },
        { start: clipPosStart, end: clipPosEnd },
      ),
    };
  }

  function updateClipPos(duration, { start, end }) {
    // Ensure that start/end are not the same value.
    if (start === end) {
      if (end < duration) {
        end += 1;
      } else if ((start === end) === 0) {
        end = 1;
      }
    }

    // Ensure that start/end fall within the appropriate bounds.
    return {
      start: Math.max(0, start),
      end: Math.min(duration, end),
    };
  }

  // Calculate where the scrub should be placed based on the
  // provided clip duration.
  function getPosFromClipDuration(state, { start, end }) {
    const widthRatio = (end - start) / state.duration;
    const nextPos = Math.min(
      Math.round(
        (start / state.duration) * width, // map start position to UI component coordinates
      ),
      width - totalScrubWidth,
    );
    const maxPotentialWidth = width - nextPos - 2 * sideScrubWidth;
    const nextWidth = Math.max(
      minScrubWidth,
      Math.min(
        Math.round(widthRatio * width) - 2 * sideScrubWidth,
        maxPotentialWidth,
      ),
    );

    return {
      pos: nextPos,
      width: nextWidth,
    };
  }

  function clipReducer(state, action) {
    switch (action.type) {
      case 'setDimensions': {
        const { x, width } = action.payload;
        return {
          ...state,
          containerDimensions: {
            x,
            width,
          },
        };
      }
      case 'onMouseDown': {
        const { target, clientX } = action.payload;

        return {
          ...state,
          target,
          isDragging: true,
          offset: clientX - state.containerDimensions.x - state.pos,
        };
      }
      case 'onMouseUp': {
        return {
          ...state,
          isDragging: false,
        };
      }
      case 'onManualChange': {
        if (maxDuration) {
          let { start, end } = action.payload.clipPos;

          // The payload has only one value, either start or end. Based on
          // whether the old start/end value is still within the maxDuration range
          // (if there is a maxDuration), we need to set the value that was not
          // provided. Example: maxDuration is 10 seconds. Start/end were 5,15.
          // New end is 20. Now start will have to be updated to 10, so 10,20.
          if (start != null) {
            // Ensure start can never exceed end - adjust if necessary.
            if (start > state.clipPos.end) {
              start = state.clipPos.end - 1;
            }

            end =
              state.clipPos.end - start > maxDuration
                ? start + maxDuration
                : state.clipPos.end;
          } else {
            // Ensure end can never preceed start - adjust if necessary.
            if (end < state.clipPos.start) {
              end = state.clipPos.start + 1;
            }

            start =
              state.clipPos.start - start > maxDuration
                ? end - maxDuration
                : state.clipPos.start;

            if (end - start > maxDuration) {
              start = Math.max(0, end - maxDuration);
            }
          }

          return {
            ...state,
            ...getPosFromClipDuration(state, { start, end }),
            clipPos: updateClipPos(state.duration, { start, end }),
          };
        }

        // New value only has one of either start or end, not both.
        let { start, end } = { ...state.clipPos, ...action.payload.clipPos };

        // Ensure start can never exceed end and end can never preceed start - adjust if necessary.
        if (start > end) {
          start = end - 1;

          // Start can become < 0, truncate positions to 0 and 1
          if (start < 0) {
            start = 0;
            end = 1;
          }
        } else if (end < start) {
          end = start + 1;
        }

        return {
          ...state,
          ...getPosFromClipDuration(state, { start, end }),
          clipPos: updateClipPos(state.duration, { start, end }),
        };
      }
      case 'onMouseMove': {
        const { clientX } = action.payload;

        if (state.target === 'scrub') {
          // No change if already maxed out.
          if (state.width === width) return state;

          // No scrubbing if the scrub is maxed out (i.e., from beginning to end).
          if (state.scrubWidth === state.width) return state;

          const _nextPos = clientX - state.containerDimensions.x - state.offset;
          const totalWidth = state.width + sideScrubWidth * 2;
          const nextPos = Math.min(
            Math.max(0, _nextPos),
            state.containerDimensions.width - totalWidth,
          );

          const nextStart = Math.min(
            state.duration - (state.clipPos.end - state.clipPos.start),
            Math.round(
              state.duration * (nextPos / state.containerDimensions.width),
            ),
          );

          const nextEnd = Math.min(
            nextStart + (state.clipPos.end - state.clipPos.start),
            duration,
          );

          return {
            ...state,
            pos: nextPos,
            clipPos: {
              start: nextStart,
              end: nextEnd,
            },
          };
        } else if (state.target === 'left') {
          if (state.clipPos.end - state.clipPos.start === maxDuration)
            return state;

          const pos = Math.max(
            0,
            Math.min(
              state.containerDimensions.width - totalScrubWidth,
              clientX - state.containerDimensions.x - state.offset,
            ),
          );

          const width = Math.max(
            minScrubWidth,
            state.width - (pos - state.pos),
          );

          const updatedStart = Math.round(
            state.duration * (pos / state.containerDimensions.width),
          );

          if (updatedStart >= state.clipPos.end) {
            return state;
          }

          const clipPos = updateClipPos(state.duration, {
            start: updatedStart,
            end: state.clipPos.end,
          });
          return { ...state, pos, width, clipPos };
        } else if (state.target === 'right') {
          if (state.clipPos.end - state.clipPos.start === maxDuration)
            return state;

          // Note, we currently cannot handle the case where it expands initially further than intended.
          const maxWidth =
            state.containerDimensions.width - state.pos - sideScrubWidth * 2;
          const nextWidth = Math.min(
            clientX -
              state.containerDimensions.x -
              state.pos -
              sideScrubWidth * 2,
            maxWidth,
          );

          const nextEnd = Math.max(
            state.clipPos.start,
            Math.round(
              state.duration *
                ((state.pos + nextWidth + sideScrubWidth * 2) /
                  state.containerDimensions.width),
            ),
          );

          return {
            ...state,
            width: Math.max(minScrubWidth, nextWidth),
            clipPos: updateClipPos(state.duration, {
              start: state.clipPos.start,
              end: Math.max(nextEnd, state.clipPos.start + 1),
            }),
          };
        }

        return state;
      }
      case 'onDoubleClick': {
        // If there's a max duration, resize it towards being the max duration, as possible.
        if (maxDuration) {
          if (duration <= maxDuration) {
            const clipPos = {
              start: 0,
              end: duration,
            };
            return {
              ...state,
              ...getPosFromClipDuration({ duration }, clipPos),
              clipPos,
            };
          }

          const clipPos = {
            start,
            end: end - start < maxDuration ? start + maxDuration : end,
          };
          return {
            ...state,
            ...getPosFromClipDuration({ duration }, clipPos),
            clipPos,
          };
        }

        return {
          ...state,
          pos: 0,
          clipPos: {
            start: 0,
            end: state.duration,
          },
          width: width - sideScrubWidth * 2,
        };
      }
      default: {
        throw new Error('Invalid clip reducer state update');
      }
    }
  }

  const [state, dispatch] = React.useReducer(clipReducer, duration, clipInit);

  function onMouseDown(event) {
    dispatch({
      type: 'onMouseDown',
      payload: {
        target:
          maxDuration && duration > maxDuration
            ? 'scrub'
            : event.target.dataset.direction,
        clientX: event.clientX,
      },
    });
  }

  function onMouseMove(event) {
    if (state.isDragging) {
      dispatch({ type: 'onMouseMove', payload: { clientX: event.clientX } });
    }
  }

  function onMouseUp() {
    dispatch({ type: 'onMouseUp' });
  }

  function onManualChange(clipPos) {
    dispatch({ type: 'onManualChange', payload: { clipPos } });
  }

  function onDoubleClick() {
    dispatch({ type: 'onDoubleClick' });
  }

  // Register a global click handler due to the fact that the onMouseUp does not fire if outside the element.
  React.useEffect(() => {
    document.addEventListener('mouseup', onMouseUp);
    return () => document.removeEventListener('mouseup', onMouseUp);
  }, []);

  React.useEffect(
    () => {
      if (onChangeClipDuration) {
        onChangeClipDuration(state.clipPos);
      }
    },
    [state.clipPos],
  );

  const container = React.useRef();
  React.useEffect(() => {
    const boundingBox = container.current.getBoundingClientRect();
    dispatch({
      type: 'setDimensions',
      payload: {
        x: boundingBox.x,
        width: boundingBox.width,
      },
    });
  }, []);

  return (
    <div css={{ borderTop: '1px solid rgba(165,177,194,0.2)', height: 120 }}>
      <div
        css={{
          width: 'calc(100% - 40px)',
          padding: '0 20px',
          margin: '0 auto',
          marginTop: 8,
        }}
      >
        <NumberSelection
          onChange={onManualChange}
          duration={duration}
          start={state.clipPos.start}
          end={state.clipPos.end}
        />
        <div
          ref={container}
          onMouseMove={onMouseMove}
          onDoubleClick={onDoubleClick}
          css={{
            ...styles,
            width: innerWidth,
            backgroundColor: '#fff',
            border: '1px solid #ececec',
            borderRadius: 6,
            position: 'relative',
          }}
        >
          <div
            css={{
              display: 'flex',
              height: 60,
              borderRadius: 5,
              overflow: 'hidden',
            }}
          >
            {Array.from({ length: 10 }, (_, i) => (
              <img
                key={i}
                onDragStart={event => event.preventDefault()}
                onError={event => {
                  event.target.style.opacity = 0;
                }}
                css={{ flex: 1, width: '10%', userSelect: 'none' }}
                src={frames[Math.floor(i * 2)]}
              />
            ))}
          </div>
          <div
            onMouseDown={onMouseDown}
            css={{
              position: 'absolute',
              top: 0,
              left: 0,
              height: 60,
              transform: `translateX(${state.pos}px)`,
              display: 'inline-flex',
              maxWidth: 880,
            }}
          >
            <div
              data-direction="left"
              css={{
                height: 60,
                width: sideScrubWidth,
                backgroundColor: colors.orange0,
                borderTopLeftRadius: 5,
                borderBottomLeftRadius: 5,
                cursor: 'ew-resize',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <div
                data-direction="left"
                css={{
                  backgroundColor: 'rgba(255,255,255,0.5)',
                  height: 12,
                  width: 2,
                  marginRight: 2,
                }}
              />
              <div
                data-direction="left"
                css={{
                  backgroundColor: 'rgba(255,255,255,0.5)',
                  height: 12,
                  width: 2,
                }}
              />
            </div>
            <div
              data-direction="scrub"
              css={{
                width: state.width,
                height: 52,
                backgroundColor: 'transparent',
                cursor: 'grab',
                borderTop: `4px solid ${colors.orange0}`,
                borderBottom: `4px solid ${colors.orange0}`,
              }}
            />
            <div
              data-direction="right"
              css={{
                height: 60,
                width: sideScrubWidth,
                backgroundColor: colors.orange0,
                borderTopRightRadius: 5,
                borderBottomRightRadius: 5,
                cursor: 'ew-resize',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <div
                data-direction="right"
                css={{
                  backgroundColor: 'rgba(255,255,255,0.5)',
                  height: 12,
                  width: 2,
                  marginRight: 2,
                }}
              />
              <div
                data-direction="right"
                css={{
                  backgroundColor: 'rgba(255,255,255,0.5)',
                  height: 12,
                  width: 2,
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const selectStyles = {
  border: 'none',
  WebkitAppearance: 'none',
  MozAppearance: 'none',
  appearance: 'none',
  MsAppearance: 'none',
  backgroundColor: '#fafafa',
  width: 26,
  textAlignLast: 'center',
};

function timeToHourMinutesSeconds(time) {
  const hours = Math.floor(time / 3600);
  time %= 3600;
  const minutes = Math.floor(time / 60);
  const seconds = Math.floor(time % 60);
  return { hours, minutes, seconds };
}

function hourMinutesSecondsToTime({ hours, minutes, seconds } = {}) {
  return (
    parseInt(hours, 10) * 60 * 60 +
    parseInt(minutes, 10) * 60 +
    parseInt(seconds, 10)
  );
}

const Label = styled('label')({
  display: 'block',
  color: colors.black0,
  fontSize: 12,
  lineHeight: '20px',
  select: {
    fontSize: 14,
  },
});

export function NumberSelection({
  duration,
  start,
  end,
  onChange,
  styles = {},
}) {
  const clipDuration = timeToHourMinutesSeconds(end - start);

  function onChangeStart(value) {
    onChange({ start: value });
  }

  function onChangeEnd(value) {
    onChange({ end: Math.min(value, duration) });
  }

  return (
    <div
      css={{
        ...styles,
        display: 'flex',
        alignItems: 'center',
        marginBottom: 8,
      }}
    >
      <div css={{ display: 'flex', marginRight: 6 }}>
        <div
          css={{
            display: 'flex',
            alignItems: 'center',
            height: 30,
            marginRight: 8,
          }}
        >
          <Label
            css={{
              display: 'inline',
              marginRight: 6,
              marginBottom: '0px !important',
            }}
          >
            Start position
          </Label>
          <TimeSelection
            disabled={duration === 0}
            min={0}
            max={end - 1}
            duration={duration}
            value={start}
            onChange={onChangeStart}
            styles={{ width: 80, height: 16, display: 'inline-flex' }}
          />
        </div>
        <div
          css={{
            display: 'flex',
            alignItems: 'center',
            height: 30,
          }}
        >
          <Label css={{ marginRight: 6, marginBottom: '0px !important' }}>
            End position
          </Label>
          <TimeSelection
            disabled={duration === 0}
            min={start + 1}
            max={duration}
            duration={duration}
            value={Math.min(duration, end)}
            onChange={onChangeEnd}
            styles={{ width: 80, height: 16, display: 'inline-flex' }}
          />
        </div>
      </div>
      <div css={{ flex: 1, display: 'flex', justifyContent: 'flex-end' }}>
        <div
          css={{ display: 'inline-block', fontSize: 12, color: colors.black0 }}
        >
          Clip duration:{' '}
          <span css={{ color: colors.grey3 }}>
            {clipDuration.hours.toString().padStart(2, '0')}:
            {clipDuration.minutes.toString().padStart(2, '0')}:
            {clipDuration.seconds.toString().padStart(2, '0')}
          </span>
        </div>
      </div>
    </div>
  );
}

// Inclusive range, min one result.
function range(min, max, fn) {
  return Array.from({ length: 1 + max - min }, (_, i) => fn(i + min));
}

export function TimeSelection({
  value,
  disabled,
  min,
  max,
  duration,
  onChange,
  styles = {},
}) {
  const initial = timeToHourMinutesSeconds(value);
  const minVals = timeToHourMinutesSeconds(disabled ? 0 : min);
  const maxVals = timeToHourMinutesSeconds(disabled ? 0 : max);

  // Correct maxVals values to depend on the other TimeSelection element's
  // value.
  if (Math.floor(max / 60) > Math.floor(value / 60)) {
    maxVals.seconds = 59;
  }
  if (Math.floor(max / 60 / 60) > Math.floor(value / 60 / 60)) {
    maxVals.minutes = 59;
  }

  // Correct minVals values to depend on the other TimeSelection element's
  // value.
  if (Math.floor(min / 60) < Math.floor(value / 60)) {
    minVals.seconds = 0;
  }
  if (Math.floor(min / 60 / 60) < Math.floor(value / 60 / 60)) {
    minVals.minutes = 0;
  }

  const hours = initial.hours.toString().padStart(2, '0');
  const minutes = initial.minutes.toString().padStart(2, '0');
  const seconds = initial.seconds.toString().padStart(2, '0');

  const _onChange = type => event => {
    const val = event.target.value;

    let nextDuration;

    if (type === 'hours') {
      nextDuration =
        parseInt(seconds, 10) +
        parseInt(minutes, 10) * 60 +
        parseInt(val, 10) * 60 * 60;
    }

    if (type === 'minutes') {
      nextDuration =
        parseInt(seconds, 10) +
        parseInt(val, 10) * 60 +
        parseInt(hours, 10) * 60 * 60;
    }

    if (type === 'seconds') {
      nextDuration =
        parseInt(val, 10) +
        parseInt(minutes, 10) * 60 +
        parseInt(hours, 10) * 60 * 60;
    }

    onChange(nextDuration);
  };

  return (
    <div
      css={{
        ...styles,
        backgroundColor: '#fafafa',
        border: '1px solid #e5e5e5',
        borderRadius: 3,
        padding: '4px',
        marginTop: 2,
        display: 'inline-flex',
        alignItems: 'center',
      }}
    >
      <select
        css={{ ...selectStyles }}
        onChange={_onChange('hours')}
        onMouseDown={_onChange('hours')}
        disabled={duration < 60 * 60}
        value={hours}
      >
        {range(minVals.hours, maxVals.hours, n => (
          <option key={n}>{n.toString().padStart(2, '0')}</option>
        ))}
      </select>
      <span css={{ margin: '0 2px', color: colors.black1 }}>:</span>
      <select
        css={selectStyles}
        onChange={_onChange('minutes')}
        onMouseDown={_onChange('minutes')}
        value={minutes}
        disabled={duration < 60}
      >
        {range(minVals.minutes, maxVals.minutes, n => (
          <option key={n}>{n.toString().padStart(2, '0')}</option>
        ))}
      </select>
      <span css={{ margin: '0 2px', color: colors.black1 }}>:</span>
      <select
        css={selectStyles}
        onChange={_onChange('seconds')}
        onMouseDown={_onChange('seconds')}
        disabled={duration === 0}
        value={seconds}
      >
        {range(minVals.seconds, maxVals.seconds, n => (
          <option key={n}>{n.toString().padStart(2, '0')}</option>
        ))}
      </select>
    </div>
  );
}
