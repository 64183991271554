import React from 'react';
import {
  BrowserRouter,
  Switch,
  Route,
  Redirect,
  withRouter,
} from 'react-router-dom';
import { connect } from 'react-redux';

import { ModalManager } from './modals/modal-manager';
import { MainLayout } from './main-layout';
import { MainNav } from './navigation';
import { userSelector } from './store/selectors';
import { Projects } from './pages/projects';
import { Authenticate } from './pages/login';
import { Register } from './pages/register';
import { ResetPassword } from './pages/reset-password';
import { ChangePassword } from './pages/change-password';
import { Project } from './pages/project';
import { Video } from './pages/video';
import { Account } from './pages/account';
import { Billing } from './pages/billing';
import { Dev } from './pages/dev';
import { NotFound } from './pages/not-found';
import { Stats } from './pages/stats';
import {
  VideoComparison,
  VideoComparisonModal,
} from './pages/video-comparison';
import { logOutRequest } from './profile';
import { Anchor, Text, rem } from './typography';
import { PolicyModal } from './policy-modal';
import { SquareButton } from './button';
import * as constants from './constants';
import * as colors from './colors';

function _LogOutAndRegister({ history, dispatch }) {
  React.useEffect(() => {
    logOutRequest().then(() => {
      dispatch({ type: 'reset' });
      history.push('/');
    });
  }, []);

  return null;
}
const LogOutAndRegister = connect()(_LogOutAndRegister);

function NotFoundWithNav(props) {
  return (
    <>
      <MainNav />
      <NotFound {...props} />
    </>
  );
}

function cleanGoogleReCaptcha() {
  delete window.grecaptcha;

  // Also remove script tag as per https://github.com/t49tran/react-google-recaptcha-v3/issues/182#issuecomment-1760091418
  const mainSelector =
    "script[src^='https://www.recaptcha.net/recaptcha/enterprise.js?render=']";

  const mainScript = document.querySelector(mainSelector);

  if (mainScript) {
    mainScript.remove();
  }

  // Clean the recaptcha_[language] script injected by recaptcha.js
  setTimeout(() => {
    const languageSelector =
      "script[src^='https://www.gstatic.com/recaptcha/releases']";

    const languageScript = document.querySelector(languageSelector);

    if (languageScript) {
      languageScript.remove();
    }
  }, 1000);
}

const LayoutContainer = withRouter(({ children, location: { pathname } }) => {
  React.useEffect(
    () => {
      // Ensure we always scroll to the top of the page on all route transitions.
      window.scrollTo(0, 0);

      // Remove Google reCaptcha component.
      if (location.pathname !== '/register') {
        cleanGoogleReCaptcha();
      }
    },
    [pathname],
  );

  return children;
});

const getCookie = name => {
  return document.cookie.split('; ').reduce((r, v) => {
    const parts = v.split('=');
    return parts[0] === name ? decodeURIComponent(parts[1]) : r;
  }, '');
};

const ACCEPT_COOKIES = 'accept-cookies';

const isDntEnabled = () => {
  return (
    typeof window === 'undefined' ||
    (typeof window.navigator !== 'undefined' &&
      window.navigator.doNotTrack === '1')
  );
};

function injectGoogleAnalytics(trackingId) {
  if (!trackingId) {
    return;
  }

  const mainScript = document.createElement('script');
  mainScript.src = `https://www.googletagmanager.com/gtag/js?id=${trackingId}`;
  document.body.appendChild(mainScript);

  const configScript = document.createElement('script');
  configScript.type = 'text/javascript';
  configScript.text = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', '${trackingId}', { 'anonymize_ip': true, 'cookie_flags': 'max-age=7200;secure;samesite=none', 'site_speed_sample_rate': 100 });
    `.trim();
  document.body.appendChild(configScript);
}

function injectGoogleTagManager(googleTagManagerId) {
  if (!googleTagManagerId) {
    return;
  }

  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.text = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f); })(window,document,'script','dataLayer','${googleTagManagerId}');`;
  document.body.appendChild(script);
}

function injectPlausible(plausibleDataDomain) {
  if (!plausibleDataDomain) {
    return;
  }

  const plausibleConfig = document.createElement('script');
  plausibleConfig.src = 'https://plausible.pixop.com/js/script.exclusions.js';
  plausibleConfig.defer = true;
  plausibleConfig.setAttribute('data-domain', plausibleDataDomain);
  plausibleConfig.setAttribute('data-exclude', '/projects/**');
  document.body.appendChild(plausibleConfig);

  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.text = `window.plausible = window.plausible || function() { (window.plausible.q = window.plausible.q || []).push(arguments) }`;
  document.body.appendChild(script);
}

const Analytics = withRouter(({ env, location: { pathname } }) => {
  function injectFacebookPixel(pixelId) {
    if (!pixelId) {
      return;
    }

    const configScript = document.createElement('script');
    configScript.type = 'text/javascript';
    configScript.text = `
      if(typeof(fbq) === 'undefined') {
        !function(f,b,e,v,n,t,s)
        {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
        n.callMethod.apply(n,arguments):n.queue.push(arguments)};
        if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
        n.queue=[];t=b.createElement(e);t.async=!0;
        t.src=v;s=b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t,s)}(window, document,'script',
        'https://connect.facebook.net/en_US/fbevents.js');
        fbq('init', '${pixelId}');
        fbq('track', 'PageView');
      }
    `.replace(/\s/g, '');
    document.body.appendChild(configScript);
  }

  function injectLinkedinInsightTag(linkedinPartnerId) {
    if (!linkedinPartnerId) {
      return;
    }

    const configScript = document.createElement('script');
    configScript.type = 'text/javascript';
    configScript.text = `
    _linkedin_partner_id = "${linkedinPartnerId}"; window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || []; window._linkedin_data_partner_ids.push(_linkedin_partner_id); (function(){var s = document.getElementsByTagName("script")[0]; var b = document.createElement("script"); b.type = "text/javascript";b.async = true; b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js"; s.parentNode.insertBefore(b, s);})();
    `.trim();
    document.body.appendChild(configScript);
  }

  function injectRewardful(rewardfulApiKey) {
    if (!rewardfulApiKey) {
      return;
    }

    const configScript = document.createElement('script');
    configScript.type = 'text/javascript';
    configScript.text =
      "(function(w,r){w._rwq=r;w[r]=w[r]||function(){(w[r].q=w[r].q||[]).push(arguments)}})(window,'rewardful');";
    document.body.appendChild(configScript);

    const rewardfulLaunch = document.createElement('script');
    rewardfulLaunch.src = 'https://rewardful.pixop.com/rw.js';
    rewardfulLaunch.setAttribute('data-rewardful', rewardfulApiKey);
    document.body.appendChild(rewardfulLaunch);
  }

  React.useEffect(
    () => {
      if (pathname === '/register' || pathname.startsWith('/login')) {
        const cookiesAccepted = getCookie(ACCEPT_COOKIES);

        if (cookiesAccepted && !isDntEnabled()) {
          injectFacebookPixel(env.FACEBOOK_PIXEL_ID);
          injectLinkedinInsightTag(env.LINKEDIN_PARTNER_ID);
        }
      }
    },
    [pathname],
  );

  React.useEffect(() => {
    const cookiesAccepted = getCookie(ACCEPT_COOKIES);

    if (cookiesAccepted && !isDntEnabled()) {
      injectGoogleAnalytics(env.GOOGLE_ANALYTICS_TRACKING_ID);
      injectGoogleTagManager(env.GOOGLE_TAG_MANAGER_ID);
    }

    // Plausible is a privacy first analytics tool and DNT allows "data analysis techniques that protect the anonymity of the users"
    injectPlausible(env.PLAUSIBLE_DATA_DOMAIN);

    // always inject Rewardful snippet for the benefit of our affiliates
    injectRewardful(env.REWARDFUL_API_KEY);
  }, []);

  return null;
});

const LargeText = ({ children, styles = {} }) => (
  <Text css={{ fontSize: rem(16), ...styles }}>{children}</Text>
);

const CookieBanner = ({ accept }) => (
  <div
    css={{
      position: 'fixed',
      bottom: 0,
      left: 0,
      right: 0,
      zIndex: constants.zIndices.nav,
      minHeight: 60,
      backgroundColor: colors.black4,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: `${rem(12)} ${rem(24)}`,
    }}
  >
    <LargeText styles={{ color: colors.white0 }}>
      By closing this banner, you accept that we use cookies to improve your
      website experience.{' '}
      <PolicyModal policy="cookies">
        {openPolicyModal => (
          <Anchor
            css={{ color: colors.orange0, cursor: 'pointer' }}
            onClick={openPolicyModal}
          >
            Learn more.
          </Anchor>
        )}
      </PolicyModal>
    </LargeText>
    <SquareButton css={{ marginRight: 80 }} onClick={accept}>
      Accept
    </SquareButton>
  </div>
);

export const _Router = ({ env, acceptCookies, user }) => {
  const [hasAcceptedCookies, setHasAcceptedCookies] = React.useState(
    acceptCookies,
  );

  function setCookie(name, value, days) {
    let expires = '';
    if (days) {
      let date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      expires = '; expires=' + date.toUTCString();
    }

    // include all subdomains on pixop
    const cookieDomain =
      window.location.hostname.split('.')[1] === 'pixop'
        ? '.pixop.com'
        : window.location.hostname;

    document.cookie =
      name +
      '=' +
      (value || '') +
      expires +
      '; Secure; SameSite=Strict; path=/; domain=' +
      cookieDomain;
  }

  return (
    <BrowserRouter>
      <ModalManager />
      {hasAcceptedCookies || (
        <CookieBanner
          accept={() => {
            setCookie(ACCEPT_COOKIES, 'true', 365);

            // if no DNT signal, inject analytics snippets
            if (!isDntEnabled()) {
              injectGoogleAnalytics(env.GOOGLE_ANALYTICS_TRACKING_ID);
              injectGoogleTagManager(env.GOOGLE_TAG_MANAGER_ID);
            }

            setHasAcceptedCookies(true);
          }}
        />
      )}
      <Analytics env={env} />
      <LayoutContainer>
        {user ? (
          <MainLayout>
            <Switch>
              <Route path="/compare" component={VideoComparisonModal} />
              <Redirect from="/login" to="/" />
              <Redirect from="/authenticate" to="/login" />
              <Route exact path="/" component={Projects} />
              <Route exact path="/projects/:projectId" component={Project} />
              <Route
                path="/projects/:projectId/videos/:videoId"
                component={Video}
              />
              <Route path="/account" component={Account} />
              <Route path="/billing" component={Billing} />
              <Route exact path="/stats" component={Stats} />
              <Route exact path="/dev" component={Dev} />
              <Route exact path="/register" component={LogOutAndRegister} />

              <Route component={NotFound} />
            </Switch>
          </MainLayout>
        ) : (
          <Switch>
            <Route exact path="/" component={Authenticate} />
            <Route exact path="/compare" component={VideoComparison} />
            <Route exact path="/login" component={Authenticate} />
            <Redirect from="/authenticate" to="/login" />
            <Route exact path="/register" component={Register} />
            <Route exact path="/reset-password" component={ResetPassword} />
            <Route exact path="/change-password" component={ChangePassword} />

            <Route component={NotFoundWithNav} />
          </Switch>
        )}
      </LayoutContainer>
    </BrowserRouter>
  );
};

export const Router = connect(state => ({
  env: state.env,
  user: userSelector(state),
  acceptCookies: getCookie(ACCEPT_COOKIES),
}))(_Router);
