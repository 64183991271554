import React from 'react';

import { PixopLogo } from './icons';
import * as colors from './colors';

export function UnexpectedError() {
  return (
    <div
      css={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        margin: '0 auto',
        width: 480,
        height: '100%',
        'h1,p': { margin: 0 },
      }}
    >
      <div css={{ color: colors.grey3 }}>
        <PixopLogo styles={{ marginBottom: 24 }} />
        <h1 css={{ color: colors.black1, marginBottom: '12px !important' }}>
          Unexpected Error
        </h1>
        <p css={{ lineHeight: '1.6' }}>
          There&#39;s been an unexpected error. We&#39;ve been made aware of the
          issue and will look into it shortly. We&#39;re sorry for the
          inconveniece. Feel free to{' '}
          <a
            href="mailto:info@pixop.com"
            css={{
              color: colors.orange1,
              textDecoration: 'none',
              ':hover': { textDecoration: 'underline' },
            }}
          >
            get in touch
          </a>{' '}
          with us at any time to help resolve the issue.
        </p>
        <a
          href="/"
          css={{
            display: 'block',
            color: colors.orange1,
            marginTop: 12,
            textDecoration: 'none',
            ':hover': { textDecoration: 'underline' },
          }}
        >
          Take me back home
        </a>
      </div>
    </div>
  );
}
