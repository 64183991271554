import { useEffect, useReducer } from 'react';

import { Input } from './forms';
import * as colors from './colors';

// Used to set an upper bound.
const wrapNum = num => Math.max(0, Math.min(999, num));

function createReducer({ sourceFrameRate, onChange }) {
  return function reducer(state, action) {
    const value = wrapNum(parseFloat(action.value));

    const formatNumber = value => {
      try {
        return parseFloat(value.toFixed(3));
      } catch (error) {
        return null;
      }
    };

    // Handle case where one of the inputs has been cleared completely.
    if (isNaN(value)) {
      return {
        fps:
          action.type === 'fps'
            ? formatNumber(action.value)
            : formatNumber(sourceFrameRate),
      };
    }

    if (action.type === 'reset') {
      return {
        fps: formatNumber(action.value),
      };
    }

    if (action.type === 'fps') {
      const returnValue = {
        fps: formatNumber(value),
      };
      onChange(returnValue);
      return returnValue;
    }

    return state;
  };
}

export function CustomFrameRate({
  sourceFrameRate,
  customFrameRate,
  onChange,
}) {
  const reducer = createReducer({ sourceFrameRate, onChange });
  const fps = customFrameRate ? customFrameRate.fps : sourceFrameRate;
  const [state, dispatch] = useReducer(reducer, {
    fps,
  });

  useEffect(
    () => {
      dispatch({
        type: 'reset',
        value: fps,
      });
    },
    [customFrameRate],
  );

  return (
    <div>
      <Input
        type="number"
        step="1"
        value={state.fps}
        onChange={event => dispatch({ type: 'fps', value: event.target.value })}
      />
      <span
        css={{
          display: 'block',
          color: colors.grey2,
          fontSize: 12,
          margin: '12px 0',
        }}
      >
        {!!state.fps && <>{state.fps} frames per second (FPS)</>}
      </span>
    </div>
  );
}
