/**
 * Basic wrapper to contain a WebSocket instance.
 * Handles reconnecting automatically.
 */
export class WebSocketManager {
  constructor() {
    const { protocol, hostname, port } = window.location;
    this.protocol = protocol === 'https:' ? 'wss://' : 'ws://';
    this.hostname = hostname;
    this.port = port ? `:${port}` : '';
    this.defaultReconnectDelayMs = this.reconnectDelayMs = 240;
  }

  open({ onOpen, onMessage, isReconnecting } = {}) {
    this.onOpen = onOpen;
    this.onMessage = onMessage;
    this.isReconnecting = isReconnecting;

    this.websocket = new WebSocket(
      `${this.protocol}${this.hostname}${this.port}`,
    );
    this.websocket.onopen = this._onOpen.bind(this);
    this.websocket.onmessage = this._onMessage.bind(this);
    this.websocket.onclose = this._onClose.bind(this);
    return this;
  }

  close() {
    if (this.websocket) {
      this.websocket.close();
      this.websocket = null;
    }
  }

  _onOpen() {
    // Reset reopen interval (subsequent retries should not start at the
    // current interval).
    this.reconnectDelayMs = this.defaultReconnectDelayMs;
    clearTimeout(this.connectTimeout);
    if (this.onOpen) {
      this.onOpen(this.isReconnecting);
    }
  }

  _onMessage(event) {
    if (this.onMessage) {
      this.onMessage(event);
    }
  }

  _onClose(event) {
    if (!event.wasClean) {
      this.connectTimeout = setTimeout(() => {
        this.open({
          onOpen: this.onOpen,
          onMessage: this.onMessage,
          isReconnecting: true,
        });
      }, this.reconnectDelayMs);
      this.reconnectDelayMs = Math.min(30000, this.reconnectDelayMs * 2);
    }
  }

  get state() {
    return this.websocket ? this.websocket.readyState : -1;
  }

  get isConnecting() {
    return this.state === 0;
  }

  get isOpen() {
    return this.state === 1;
  }

  get isClosing() {
    return this.state === 2;
  }

  get isClosed() {
    return this.state === 3;
  }
}
