import { subject } from '@casl/ability';

import { FileUpload } from '../file-upload';
import { startUpload, completeUpload } from '../analytics';
import { createAction, createReducer } from './utils';
import { throttle } from '../utils';
import { fetch } from '../requests';
import { addIfNotPresent, updateVideo, removeVideo } from './videos';
import { openDialog, closeDialog } from './dialog';
import { addProject } from './projects';
import { addToast } from './toasts';
import { removeProject } from './projects';
import { updateTeam } from './teams';
import { ability } from '../ability';

export const initUpload = createAction('initUpload');
export const createUpload = createAction('createUpload');
export const uploadChunks = createAction('uploadChunks');
export const removeUpload = createAction('removeUpload');
export const cancelUpload = createAction('cancelUpload');
export const resumeUpload = createAction('resumeUpload');

const dateFormatter = Intl.DateTimeFormat('en-US', {
  year: 'numeric',
  day: 'numeric',
  month: 'short',
  hour: 'numeric',
  minute: 'numeric',
});

const attributes = ['fullPath', 'name', 'lastModified', 'size'];
function isSameFile(fileOne, fileTwo) {
  for (const attr of attributes) {
    if (fileOne[attr] !== fileTwo[attr]) {
      return false;
    }
  }
  return true;
}

function isSameVideo(file, video) {
  const videoSize = parseInt(video.file_size_bytes, 10);
  const videoTime = new Date(video.file_last_modified_at).getTime();
  if (videoSize === file.size && videoTime === file.lastModified) {
    return true;
  }
  return false;
}

import dragDrop from 'drag-drop';

export const uploadsMiddleware = store => {
  dragDrop(document.body, files => {
    const state = store.getState();
    if (state.user === null) return;

    const toUpload = files.filter(file => file.size > 1000);

    if (files.length > toUpload.length) {
      store.dispatch(
        addToast({
          text: 'One or more files not uploaded due to small size',
        }),
      );
    }

    if (toUpload.length === 0) return;

    // Determine if we're inside a project. If the current page is a project
    // page, we upload into that project, otherwise we always upload into a new
    // project (by omitting the projectId).
    let projectId;
    const match = window.location.pathname.match(
      /^\/projects\/([a-f0-9]{8}.*)/,
    );
    if (match) {
      projectId = match[1].slice(0, 36);
    }

    // Close the upload dialog, if it is open.
    if (state.dialog && state.dialog.type === 'upload') {
      store.dispatch(closeDialog());
    }

    store.dispatch(
      initUpload({
        files: toUpload,
        projectId,
      }),
    );
  });

  // Check for inactive uploads in the queue and start them.
  // Be careful to not trigger multiple uploads at once here!
  setInterval(() => {
    const { uploads } = store.getState();
    if (uploads.length === 0) return;

    const noActiveUploads = !uploads.some(upload => upload.isActive);
    if (noActiveUploads) {
      const [nextUpload] = uploads.filter(u => !u.isCancelled);
      // Start uploading chunks for the given file, and mark the
      // upload as active.
      if (nextUpload) {
        store.dispatch(uploadChunks({ id: nextUpload.uploadId }));
      }
    }
  }, 1000);

  function createProject(teamId, projectName) {
    return fetch('/api/projects', {
      method: 'POST',
      body: { teamId, name: projectName || dateFormatter.format(new Date()) },
    })
      .then(res => res.json())
      .then(result => {
        if (result && result.project) {
          store.dispatch(
            addProject({
              ...result.project,
              created_at: Date.now(),
              updated_at: Date.now(),
            }),
          );
          return result.project;
        }

        throw new Error('Failed to create new project: ' + result.error);
      });
  }

  // Check for finished, yet not completed uploads, on page load, and
  // manually complete them. For example, someone might start to upload a
  // video, and actually finish all the chunk uploads, but then leave the page.
  // The video will be left in a 100% state on the next page load, but still
  // "uploading". This is because finishing a multipart upload requires a
  // separate request, which the user aborted by leaving before it was made or
  // finished. So, in this case, we simply look for these uploads on page load,
  // and if there are any, finish them manually.
  const { videos, teams } = store.getState();
  const incompleteUploads = videos.filter(video => {
    return (
      video.upload_state.uploadStatus === 'UPLOADING' &&
      video.upload_state.progress === 1
    );
  });
  const reqObjects = incompleteUploads.map(upload => {
    const team = teams.find(team => team.id === upload.team_id);
    return {
      videoId: upload.id,
      bucketName: team.cloudfront_s3_context.s3BucketName,
    };
  });
  Promise.all(
    reqObjects.map(obj => {
      return fetch(`/api/uploads/${obj.videoId}/uploadId`)
        .then(res => res.json())
        .then(result => {
          return { ...obj, uploadId: result.uploadId };
        });
    }),
  ).then(results => {
    results.forEach(payload => {
      fetch('/api/uploads/complete-upload', {
        method: 'POST',
        body: payload,
      })
        .then(res => res.json())
        .then(() => {
          // Achtung, this is inaccurate when the team has been switched, but good enough for analytics
          completeUpload(
            teams.find(team => team.id === store.getState().currentTeamId),
          );
        });
    });
  });

  return next => action => {
    if (action.type === initUpload.type) {
      let { files, projectId } = action.payload;
      const { uploads, videos, teams } = store.getState();

      const maybeCreateProject = (teamId, projectName) =>
        files.length > 0 && !projectId
          ? createProject(teamId, projectName).then(project => {
              projectId = project.id;
            })
          : Promise.resolve();

      const onReupload = ({ reason, url, projectId }) => {
        store.dispatch(
          openDialog({
            reason,
            type: 'confirmReupload',
            url,
            callback: () => {
              store.dispatch({
                type: action.type,
                payload: { ...action.payload, projectId, noConfirm: true },
              });
            },
          }),
        );
      };

      const teamId = store.getState().currentTeamId;
      const team = teams.find(team => team.id === teamId);
      let currentUploads = team.total_uploads;

      const maybeCreateProjectAndUploadFiles = projectName =>
        maybeCreateProject(teamId, projectName)
          .then(() => {
            for (let file of files) {
              // Create the multipart upload in the database.
              // Note that this does not actually upload any content; just create a
              // container for the upcoming chunks, as well as a videos record to
              // display in the UI and to contain the content that will be uploaded.
              const upload = new FileUpload({
                file,
                team,
              });
              upload
                .createMultipartUpload({ projectId })
                .then(() => {
                  // Add the created video and the potentially created project
                  // to the store.
                  store.dispatch(addIfNotPresent(upload.video));
                  store.dispatch(addProject(upload.project));

                  // Enqueue the upload. It will be picked up by the interval
                  // set up by this middleware once it is the first inactive
                  // upload in the uploads array.
                  store.dispatch(createUpload(upload));

                  // Increment number of total uploads
                  store.dispatch(
                    updateTeam({
                      id: teamId,
                      total_uploads: ++currentUploads,
                    }),
                  );

                  if (currentUploads === 1) {
                    store.dispatch(
                      openDialog({
                        type: 'uploadInitiated',
                      }),
                    );
                  }
                })
                .catch(error => {
                  if (
                    error.message === 'Unsupported media type' &&
                    error.file.name !== '.DS_Store'
                  ) {
                    store.dispatch(
                      openDialog({
                        type: 'unsupportedMedia',
                        fileName: error.file.name,
                      }),
                    );
                  } else if (error.message === 'Invalid filename') {
                    store.dispatch(addToast({ text: 'Invalid filename' }));
                    return;
                  } else if (typeof error === 'string') {
                    store.dispatch(addToast({ text: error, timeout: false }));
                    return;
                  } else {
                    store.dispatch(
                      addToast({
                        text:
                          'Unknown error uploading video; please contact support',
                        timeout: false,
                      }),
                    );
                  }

                  throw error;
                });
            }
          })
          .catch(error =>
            store.dispatch(
              addToast({
                text:
                  'You cannot create a new project for upload until you have added your payment information on the [billing settings] page.',
              }),
            ),
          );

      if (!action.payload.noConfirm) {
        for (let file of files) {
          for (let upload of uploads) {
            if (upload.teamId === teamId && isSameFile(file, upload.file)) {
              if (
                upload.video &&
                upload.video.upload_state.uploadStatus !== 'DONE'
              ) {
                store.dispatch(
                  addToast({
                    text: 'File is already being uploaded',
                  }),
                );
                return;
              }

              const { video } = upload;
              const url = `/projects/${video.project_id}/videos/${video.id}`;
              onReupload({
                reason: 'uploading',
                url,
                videoId: video.id,
                projectId: video.project_id,
              });
              return;
            }
          }

          for (let video of videos) {
            if (video.team_id === teamId && isSameVideo(file, video)) {
              const url = `/projects/${video.project_id}/videos/${video.id}`;
              onReupload({
                reason: 'uploaded',
                url,
                videoId: video.id,
                projectId: video.project_id,
              });
              return;
            }
          }
        }
      }

      if (
        ability.cannot(
          'upload',
          subject('Video', {
            currentUploadCount: team.total_uploads,
          }),
        )
      ) {
        store.dispatch(
          addToast({
            text:
              'You cannot upload videos until you have added your payment information on the [billing settings] page.',
          }),
        );
      } else {
        startUpload(team);

        // begin/resume upload
        maybeCreateProjectAndUploadFiles();
      }

      return;
    }

    if (action.type === uploadChunks.type) {
      const upload = store
        .getState()
        .uploads.find(upload => upload.uploadId === action.payload.id);

      upload.onProgress = throttle(() => {
        store.dispatch(
          updateVideo({
            id: upload.video.id,
            upload,
            upload_state: {
              uploadStatus: 'UPLOADING',
              progress: upload.progress,
            },
          }),
        );
      }, 300);

      // Fetch signed upload URLs and start uploading chunks.
      upload
        .getSignedUploadUrls()
        .then(() => upload.upload())
        .then(() => {
          // Manually mark the upload as 100% completed.
          store.dispatch(
            updateVideo({
              id: upload.video.id,
              upload,
              upload_state: {
                uploadStatus: 'UPLOADING',
                progress: 1,
              },
            }),
          );
          return upload.completeUpload();
        })
        .then(() => {
          // The upload is complete; remove the active upload from the queue to
          // make way for other uploads.
          store.dispatch(removeUpload({ id: upload.uploadId }));
        })
        .catch(error => {
          clearInterval(upload.speedCheckInterval);

          store.dispatch(
            addToast({
              text:
                'Error uploading video. Please refresh your browser window and try again later. If the problem persists reach out to Pixop support.',
              timeout: false,
            }),
          );

          // eslint-disable-next-line no-console
          throw error;
        });
    }

    if (action.type === cancelUpload.type) {
      const { id, projectId } = action.payload;
      const { uploads, videos } = store.getState();
      uploads.forEach(upload => {
        if (id && upload.video.id === id) {
          const verifiedProgress = upload.abort();
          const video = videos.find(v => v.id === id);
          store.dispatch(
            updateVideo({
              id,
              upload_state: {
                ...video.upload_state,
                progress: verifiedProgress,
              },
            }),
          );
        } else if (projectId && upload.project.id === projectId) {
          upload.abort();
        }
      });
    }

    if (action.type === removeVideo.type) {
      const { uploads } = store.getState();
      uploads.forEach(upload => {
        if (upload.video.id === action.payload.id) {
          upload.abort();
          store.dispatch(removeUpload({ id: upload.uploadId }));
        }
      });
    }

    if (action.type === removeProject.type) {
      const { uploads, videos } = store.getState();
      // remove uploads related to the project being removed
      uploads.forEach(upload => {
        if (upload.project.id === action.payload.id) {
          store.dispatch(removeVideo({ id: upload.video.id }));
          store.dispatch(removeUpload({ id: upload.uploadId }));
        }
      });
      // remove video related to the project being removed
      videos.forEach(video => {
        if (video.project_id === action.payload.id) {
          store.dispatch(removeVideo({ id: video.id }));
        }
      });
    }

    return next(action);
  };
};

export const uploadsReducer = createReducer([], {
  // This action is purposefully left as a no-op in the reducer, it triggers
  // side effects in the middleware instead.
  [initUpload]: state => state,
  [createUpload]: (state, action) => {
    const hasUpload = state.find(
      upload => upload.uploadId === action.payload.uploadId,
    );
    if (hasUpload) {
      // As we allow reuploads, the upload may have been started but cancelled.
      // In this case, replace the previous upload.
      return state.map(upload => {
        if (upload.uploadId === action.payload.uploadId) {
          return action.payload;
        }
        return upload;
      });
    }
    return [...state, action.payload];
  },
  [uploadChunks]: (state, action) => {
    return state.map(upload => {
      if (upload.uploadId !== action.payload.id) return upload;
      return { ...upload, isActive: true };
    });
  },
  [cancelUpload]: (state, action) => {
    return state.map(upload => {
      if (upload.video.id !== action.payload.id) return upload;
      return { ...upload, isActive: false, isCancelled: true };
    });
  },
  [resumeUpload]: (state, action) => {
    return state.map(upload => {
      if (upload.video.id !== action.payload.id) return upload;
      return { ...upload, isCancelled: false };
    });
  },
  [removeUpload]: (state, action) =>
    state.filter(upload => upload.uploadId !== action.payload.id),
});
