import { Component, StrictMode } from 'react';
import { Global } from '@emotion/react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import { ability } from './ability';
import { AbilityContext } from './can';
import { Router } from './router';
import { configureStore } from './store';
import { ToastsManager } from './toasts-manager';
import { GlobalUploadState } from './global-upload-state';
import { UnexpectedError } from './unexpected-error';
import * as colors from './colors';
import * as constants from './constants';

export class App extends Component {
  state = {
    error: null,
    errorInfo: null,
  };

  componentDidCatch(error, errorInfo) {
    this.setState({ error, errorInfo });

    const { SENTRY_WEB_DSN } = this.props.initialState.env;

    if (process.env.NODE_ENV === 'production') {
      import(/* webpackChunkName: "sentry" */ '@sentry/browser').then(
        Sentry => {
          Sentry.init({
            dsn: SENTRY_WEB_DSN,
            integrations: integrations =>
              integrations.filter(int => int.name !== 'GlobalHandlers'),
          });

          Sentry.withScope(scope => {
            Object.keys(errorInfo).forEach(key => {
              scope.setExtra(key, errorInfo[key]);
            });
            Sentry.captureException(error);
          });
        },
      );
    }
  }

  render() {
    return (
      <StrictMode>
        <Global
          styles={{
            'html, body': {
              fontSize: constants.baseFontSize,
              fontFamily: 'Hind',
              backgroundColor: colors.white1,
              color: colors.black1,
              height: '100%',
              minHeight: '100vh',
            },

            '#root': {
              height: '100%',
            },

            '*:focus': {
              outline: 'none',
            },

            // Remove pesky dotted outline in Firefox.
            '*::-moz-focus-inner': {
              border: 0,
            },

            // Add a custom outline around focused elements via keyboard.
            '*:focus-visible:not(:active)': {
              boxShadow: `0 0 0 2px ${colors.hexToRgba(colors.orange1, 0.6)}`,
            },
          }}
        />
        {this.state.error ? (
          process.env.NODE_ENV === 'development' ? (
            <>
              <h1>Error</h1>
              <h3>{this.state.error.name}</h3>
              <pre>{this.state.errorInfo.componentStack}</pre>
            </>
          ) : (
            <UnexpectedError
              name={this.state.error.name}
              componentStack={this.state.errorInfo.componentStack}
            />
          )
        ) : (
          <Provider store={configureStore(this.props.initialState)}>
            <AbilityContext.Provider value={ability}>
              <>
                <Router />
                <BrowserRouter>
                  <GlobalUploadState />
                  <ToastsManager />
                </BrowserRouter>
              </>
            </AbilityContext.Provider>
          </Provider>
        )}
      </StrictMode>
    );
  }
}
