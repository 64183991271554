import { fetch, nativeFetch, fetchRetry } from './requests';
import { bytesInClosestUnit } from './utils';
import { completeUpload } from './analytics';

export class FileUpload {
  constructor({ file, team, processingPresetId, onProgress }) {
    this.file = file;
    this.team = team;
    this.teamId = team.id;
    this.processingPresetId = processingPresetId;
    this.onProgress = onProgress;
    this.requests = [];
    this.chunks = this._splitIntoChunks(file);
    this.verifiedProgress = 0;
    this.onUploadStart();
  }

  onUnload = event => {
    event.preventDefault();
    event.returnValue = '';
  };

  onUploadStart = () => {
    window.addEventListener('beforeunload', this.onUnload);
  };

  onUploadEnd = () => {
    window.removeEventListener('beforeunload', this.onUnload);
  };

  createMultipartUpload = ({ projectId }) => {
    this.onUploadStart();
    return this._getContentType()
      .catch(error => {
        // Pass on the filename so that we may report it.
        error.file = this.file;
        this.onUploadEnd();
        throw error;
      })
      .then(result => {
        return fetch('/api/uploads', {
          method: 'POST',
          body: {
            projectId,
            teamId: this.teamId,
            fileName: this.file.name,
            fileSize: this.file.size,
            fileType: result.contentType,
            fileContainerName: result.formatName,
            fileLastModifiedAt: this.file.lastModified,
            processingPresetId: this.processingPresetId,
          },
        });
      })
      .then(res => res.json())
      .then(response => {
        if (response.error) throw response.error;

        // The upload Id from S3; either a new or existing upload.
        this.uploadId = response.uploadId;
        this.video = response.video;
        this.key = response.key;
        this.bucketName = response.bucketName;
        this.project = response.project;

        // Prepare the upload.
        this.progress = 0;
        this.previouslyUploadedSize = 0;
        this.currentUploadSize = 0;

        let prevSize = 0;
        this.currentUploadSpeed = bytesInClosestUnit(0);
        const intervalMs = 250;
        this.speedCheckInterval = setInterval(() => {
          this.currentUploadSpeed = bytesInClosestUnit(
            (this.currentUploadSize - prevSize) * (1000 / intervalMs),
          );
          prevSize = this.currentUploadSize;
        }, intervalMs);

        // An upload already existed, i.e., the upload Id is for an existing
        // upload. The parts we received are the parts that have already been
        // uploaded successfully.
        if (response.parts) {
          const uploadedChunkPartNumbers = Object.keys(response.parts).map(
            part => parseInt(part, 10),
          );

          // Update chunks to the remaining chunks only.
          this.chunks = this.chunks.filter(
            chunk => !uploadedChunkPartNumbers.includes(chunk.partNumber),
          );

          // Update the progress accordingly.
          this.previouslyUploadedSize =
            this.file.size -
            this.chunks.reduce((acc, { chunk }) => acc + chunk.size, 0);
          this.progress = this.previouslyUploadedSize / this.file.size;
        }
      });
  };

  getSignedUploadUrls = () => {
    return fetch('/api/uploads/signed-upload-urls', {
      method: 'POST',
      body: {
        uploadId: this.uploadId,
        key: this.key,
        videoId: this.video.id,
        numParts: this.numChunks, // Not equivalent to this.chunks.length.
        partNumbers: this.chunks.map(({ partNumber }) => partNumber),
        bucketName: this.bucketName,
      },
    })
      .then(res => res.json())
      .then(results => {
        this.presignedUrls = results.presignedUrls;
      });
  };

  // Completed chunks must be reported to the server, in order to maintain
  // state about uploads and to enable resumable uploads.
  completeChunk = ({ eTag, partNumber, currentProgress }) => {
    return fetchRetry(
      '/api/uploads/chunk',
      {
        method: 'POST',
        body: {
          eTag,
          partNumber,
          currentProgress,
          videoId: this.video.id,
          teamId: this.teamId,
        },
      },
      8,
      1000,
    ).then(result => {
      if (result.status >= 400) {
        throw new Error('Error completing chunk');
      }

      // Collect "verified" progress, i.e., chunks that are actually completed.
      // This is the true progress in the upload, useful to show if the upload
      // is cancelled, for example.
      const completedChunk = this.chunks.find(
        chunk => chunk.partNumber === partNumber,
      ).chunk;
      this.verifiedProgress += completedChunk.size / this.file.size;

      return result.json();
    });
  };

  upload = () => {
    // Most browsers support 6 concurrent requests. Since OPTIONS requests are
    // counted towards this limit, that means we can make 3 requests at any
    // single time.
    // Note that with the current implementation, it is possible that one chain
    // of requests finishes before the other(s), reducing the overall number of
    // concurrent uploads.
    const concurrency = 3;
    this.startTime = Date.now();
    return Promise.all(
      Array.from({ length: concurrency }, (_, i) => {
        return this.uploadChunks(i, concurrency);
      }),
    );
  };

  uploadChunks = (index, concurrency) => {
    // An upload for a nonexistent chunk may be triggered due to an
    // inconsistency between the concurrency configuration and the number of
    // chunks. This is fine.
    if (index >= this.chunks.length) {
      return Promise.resolve();
    }

    const { partNumber, chunk } = this.chunks[index];
    const putUrl = this.presignedUrls[index];
    return this._uploadChunk({
      presignedUrl: putUrl,
      chunk,
      index,
    })
      .then(eTag => {
        return this.completeChunk({
          eTag,
          partNumber,
          size: chunk.size,
          currentProgress: this.progress,
        });
      })
      .then(() => {
        return this.uploadChunks(index + concurrency, concurrency);
      });
  };

  completeUpload = () => {
    return fetch('/api/uploads/complete-upload', {
      method: 'POST',
      body: {
        teamId: this.teamId,
        uploadId: this.uploadId,
        videoId: this.video.id,
        bucketName: this.bucketName,
      },
    })
      .then(res => {
        if (res.status >= 400) {
          throw new Error('Error completing upload');
        }

        return res.json();
      })
      .then(() => {
        clearInterval(this.speedCheckInterval);
        this.duration = (Date.now() - this.startTime) / 1000;
      })
      .finally(() => {
        completeUpload(this.team);
        this.onUploadEnd();
      });
  };

  abort = () => {
    this.requests.forEach(request => request.abort());
    this.onUploadEnd();
    this.averageSpeed = '0 Kb/s';
    return this.verifiedProgress;
  };

  _getContentType = () => {
    const data = new FormData();
    data.append('head', this.file.slice(0, 1024));
    // Use the built-in Fetch function directly as opposed to the imported one.
    return nativeFetch('/api/uploads/content-type', {
      method: 'POST',
      body: data,
      credentials: 'same-origin',
    }).then(res =>
      res.ok ? res.json() : Promise.reject(new Error('Unsupported media type')),
    );
  };

  _calculateAverageSpeed = size => {
    const duration = (Date.now() - this.startTime) / 1000;
    return bytesInClosestUnit(size / duration);
  };

  _splitIntoChunks = file => {
    // note: S3 supports up to 5 TB objects uploaded in up to 10,000 parts
    // compute chunk size - 10 MB for objects < 100 GB, otherwise derived from the object size
    const chunkSize = Math.ceil(Math.max(10 * 1000 * 1000, file.size / 9999));
    const numChunks = Math.floor(file.size / chunkSize) + 1;
    this.numChunks = numChunks;
    return Array.from({ length: numChunks }, (_, i) => i).map(index => ({
      partNumber: index + 1,
      chunk: file.slice(
        index * chunkSize,
        index < numChunks - 1 ? (index + 1) * chunkSize : undefined,
      ),
      progress: 0,
    }));
  };

  _uploadChunk = ({ presignedUrl, chunk, index }) => {
    const fileType = this.file.type;
    const chunkEntry = this.chunks[index];
    const _onProgress = this._onProgress;
    const requests = this.requests;

    return new Promise((resolve, reject) => {
      function requestWithRetry(n, delay) {
        const xhr = new XMLHttpRequest();
        xhr.open('PUT', presignedUrl, true);
        xhr.setRequestHeader('Content-Type', fileType);
        xhr.onload = () => resolve(xhr.getResponseHeader('ETag'));
        xhr.upload.onprogress = event => {
          chunkEntry.progress = event.loaded;
          _onProgress();
        };
        xhr.onerror = error => {
          if (n == 1) {
            reject(error);
          }

          setTimeout(() => requestWithRetry(n - 1, delay * 2), delay);
        };
        xhr.send(chunk);
        requests.push(xhr);
      }

      requestWithRetry(8, 1000);
    });
  };

  // Calculate the progress and call the provided onProgress function.
  _onProgress = () => {
    this.currentUploadSize = this.chunks.reduce(
      (acc, { progress }) => acc + progress,
      0,
    );
    this.progress =
      (this.previouslyUploadedSize + this.currentUploadSize) / this.file.size;
    this.averageSpeed = this._calculateAverageSpeed(this.currentUploadSize);
    this.onProgress();
  };
}
