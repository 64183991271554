import React from 'react';
import { connect } from 'react-redux';
import { Switch, Route } from 'react-router-dom';

import * as colors from '../colors';
import { Link } from '../link';
import { Heading, Text, rem } from '../typography';
import { fetch } from '../requests';
import { updateUser } from '../store/user';
import { updateTeam } from '../store/teams';
import { addToast } from '../store/toasts';
import { closeDialog, openDialog } from '../store/dialog';
import { removeMember } from '../store/members';
import { currentTeamSelector } from '../store/selectors';
import { NotFound } from './not-found';

const baseStyles = {
  lineHeight: '25px',
  paddingLeft: 20,
  margin: '2px 0',
  a: {
    color: colors.orange1,
  },
};

const activeStyles = {
  borderLeft: `3px solid ${colors.orange1}`,
  lineHeight: '25px',
  paddingLeft: 17,
  a: {
    color: colors.black1,
  },
  'a:hover': {
    textDecoration: 'none',
    cursor: 'default',
  },
};

const getItemStyles = active => ({
  ...baseStyles,
  ...(active ? activeStyles : {}),
});

const Separator = ({ styles = {} }) => (
  <hr
    css={{
      height: 1,
      width: '100%',
      border: 'none',
      backgroundColor: colors.hexToRgba(colors.grey3, 0.3),
      ...styles,
    }}
  />
);

function LargeInput({ label, initialValue, inputProps, save }) {
  const ref = React.useRef();
  const [value, setValue] = React.useState(initialValue);

  function onClick() {
    if (!inputProps.disabled) {
      ref.current.focus();
    }
  }

  return (
    <div
      tabIndex="0"
      onClick={onClick}
      css={{
        padding: '17px 20px',
        borderRadius: 5,
        border: '1px solid rgba(0,0,0,0)',
        cursor: 'text',
        position: 'relative',
        label: {
          cursor: 'text',
        },
        '.input-edit': {
          display: 'none',
        },
        ':focus-within, :hover': {
          '.input-edit': {
            display: 'block',
          },
          backgroundColor: colors.white4,
          border: `1px solid ${colors.hexToRgba(colors.grey3, 0.43)}`,
          input: {
            backgroundColor: colors.white4,
          },
        },
      }}
    >
      {value === initialValue ? (
        <span
          className="input-edit"
          css={{
            position: 'absolute',
            top: 17,
            right: 20,
            color: colors.orange1,
            cursor: 'pointer',
          }}
          onClick={onClick}
        >
          Edit
        </span>
      ) : (
        <span
          className="input-edit"
          css={{
            position: 'absolute',
            top: 17,
            right: 20,
            color: colors.orange1,
            cursor: 'pointer',
          }}
          onClick={() => save(value, setValue)}
        >
          Save
        </span>
      )}
      <label>
        <span
          css={{
            display: 'block',
            color: colors.black1,
            marginLeft: 1,
            lineHeight: '23px',
          }}
        >
          {label}
        </span>
        <input
          {...inputProps}
          value={value}
          ref={ref}
          onChange={event => setValue(event.target.value)}
          onKeyDown={event => event.keyCode === 13 && save(value, setValue)}
          css={{
            marginTop: 7,
            border: 'none',
            color: colors.grey3,
            lineHeight: '23px',
            width: '100%',
            '::placeholder': {
              color: colors.grey1,
            },
          }}
        />
      </label>
    </div>
  );
}

function _UserSettings({ user, updateUser, addToast, openDialog }) {
  function patchUser(body) {
    return fetch('/api/user', {
      method: 'PATCH',
      body,
    });
  }

  function saveName(name) {
    return patchUser({ name })
      .then(res => res.json())
      .then(response => {
        if (response.error) {
          addToast({ text: response.error });
          return;
        }

        if (name !== user.name) {
          updateUser({ name });
        }
        addToast({ text: 'User updated' });
      })
      .catch(error => {
        addToast({ text: 'Could not update user' });
        throw error;
      });
  }

  function saveEmail(email) {
    return fetch('/api/user/email', { method: 'POST', body: { email } })
      .then(res => res.json())
      .then(response => {
        if (response.error) {
          addToast({ text: response.error });
          return;
        }

        addToast({
          text: 'Confirmation email sent; please confirm your new email',
        });
      })
      .catch(error => {
        addToast({ text: 'Could not change email' });
        throw error;
      });
  }

  function savePassword(newPassword, setValue) {
    // Show a thing asking for the current password
    // Capture the value or confirm it before submitting here
    // If submittingk, submit both, return as appropriate
    openDialog({
      type: 'confirmPassword',
      callback: isConfirmed => {
        if (!isConfirmed) return;

        return fetch('/api/user/password', {
          method: 'POST',
          body: { password: newPassword },
        })
          .then(res => res.json())
          .then(response => {
            if (response.error) {
              addToast({ text: response.error });
              return;
            }

            setValue('');
            addToast({ text: 'Password changed' });
          })
          .catch(error => {
            addToast({ text: 'Could not change password' });
            throw error;
          });
      },
    });
  }

  const automationOptions = [
    {
      id: 'makeClipOnUpload',
      description:
        'Make 10 seconds clip from the middle of ingested video uploads',
    },
  ];

  const [automationPreferences, setAutomationPreferences] = React.useState(
    null,
  );

  React.useEffect(() => {
    fetch(`/api/user/automation`)
      .then(res => res.json())
      .then(response => {
        if (response.error) {
          addToast({ text: 'Failed to get automation preferences' });
        } else {
          setAutomationPreferences(response.automationPreferences);
        }
      })
      .catch(error => {
        addToast({ text: 'Failed to get automation preferences' });
        throw error;
      });
  }, []);

  function toggleAutomationOptions({ id }) {
    // toggle automation options
    let targetObj = automationPreferences[id];
    targetObj = !targetObj;

    return fetch(`/api/user/automation`, {
      method: 'POST',
      body: {
        id,
        value: targetObj,
      },
    })
      .then(res => res.json())
      .then(response => {
        if (response.error) {
          addToast({ text: response.error });
          return;
        }

        setAutomationPreferences({
          ...automationPreferences,
          [id]: targetObj,
        });

        addToast({ text: 'Automation preferences updated' });
      })
      .catch(error => {
        addToast({ text: 'Could not update automation preferences' });
        throw error;
      });
  }

  return (
    <>
      <SectionHeading heading="User" value={user.id} />
      <LargeInput
        label="Name"
        initialValue={user.name}
        save={saveName}
        inputProps={{
          placeholder: 'Enter a new name',
          type: 'name',
          name: 'name',
        }}
      />
      <LargeInput
        label="Email"
        initialValue={user.email}
        save={saveEmail}
        inputProps={{
          placeholder: 'Enter a new email',
          type: 'email',
          name: 'email',
        }}
      />
      <LargeInput
        label="Change password"
        save={savePassword}
        initialValue=""
        inputProps={{
          placeholder: 'Enter a new password',
          type: 'password',
          name: 'password',
        }}
      />

      <SectionHeading heading="Automation" />
      <div css={{ marginLeft: 20, marginRight: 20 }}>
        <table
          css={{
            width: '100%',
            color: colors.grey3,
            borderSpacing: '0 3px',
            td: { color: colors.black1 },
            'td + td': { textAlign: 'center' },
            tr: {
              height: 30,
            },
            'tr:nth-of-type(even)': {
              background: colors.hexToRgba(colors.grey4, 0.6),
            },
            'tr>td:first-of-type': {
              paddingLeft: 6,
            },
          }}
        >
          <tbody>
            {automationOptions.map(automationOption => (
              <tr key={automationOption.id}>
                <td>{automationOption.description}</td>
                <td>
                  {' '}
                  <input
                    type="checkbox"
                    checked={
                      automationPreferences &&
                      automationPreferences[automationOption.id]
                    }
                    disabled={!automationPreferences}
                    onChange={() =>
                      toggleAutomationOptions({
                        id: automationOption.id,
                      })
                    }
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div css={getItemStyles(false)}>
        <Text css={{ color: colors.grey3 }}>
          No processing credits are charged for automatically made clips from
          uploads; the cost of storage and download is according to{' '}
          <a
            target="_blank"
            rel="nofollow"
            href="https://www.pixop.com/pricing#other-pricing"
          >
            standard Pixop pricing
          </a>
          .
        </Text>
      </div>
    </>
  );
}

const UserSettings = connect(
  state => ({ user: state.user }),
  { updateUser, addToast, openDialog },
)(_UserSettings);

function _Notifications({ user, addToast }) {
  const ingestionEmailNotifications = [
    {
      id: 'uploadIngestionDone',
      description: 'Uploads',
    },
    {
      id: 'processingIngestionDone',
      description: 'Processing jobs',
    },
    {
      id: 'clipIngestionDone',
      description: 'Clips',
    },
  ];

  const toastNotifications = [
    {
      id: 'processingEnqueued',
      description: 'Processing job triggered',
    },
    {
      id: 'clipProcessingEnqueued',
      description: 'Clip transcoding triggered',
    },
    {
      id: 'clipIngestionDone',
      description: 'Clip ingested',
    },
  ];

  const [messagingPreferences, setMessagingPreferences] = React.useState(null);

  React.useEffect(() => {
    fetch(`/api/user/messaging`)
      .then(res => res.json())
      .then(response => {
        if (response.error) {
          addToast({ text: 'Failed to get messaging preferences' });
        } else {
          setMessagingPreferences(response.messagingPreferences);
        }
      })
      .catch(error => {
        addToast({ text: 'Failed to get messaging preferences' });
        throw error;
      });
  }, []);

  function toggleNotifications({ id, target, channel }) {
    // toggle notifications
    const targetObj = messagingPreferences[id];
    targetObj[target][channel] = !targetObj[target][channel];

    return fetch(`/api/user/messaging`, {
      method: 'POST',
      body: {
        id,
        target,
        channel,
        value: targetObj[target][channel],
      },
    })
      .then(res => res.json())
      .then(response => {
        if (response.error) {
          addToast({ text: response.error });
          return;
        }

        setMessagingPreferences({
          ...messagingPreferences,
          [id]: targetObj,
        });

        user.messaging_preferences[id][target][channel] =
          targetObj[target][channel];

        addToast({ text: 'Messaging preferences updated' });
      })
      .catch(error => {
        addToast({ text: 'Could not update messaging preferences' });
        throw error;
      });
  }

  function ChannelNotifications({ notifications, channel }) {
    return (
      <div css={{ marginLeft: 20, marginRight: 20 }}>
        <table
          css={{
            width: '100%',
            color: colors.grey3,
            borderSpacing: '0 3px',
            td: { color: colors.black1 },
            'td + td': { textAlign: 'center', width: 70 },
            'td + td + td': { textAlign: 'center', width: 70 },
            'thead td': {
              color: colors.grey3,
              fontWeight: 'bold',
            },
            tr: {
              height: 30,
            },
            'tr:nth-of-type(even)': {
              background: colors.hexToRgba(colors.grey4, 0.6),
            },
            'tr>td:first-of-type': {
              paddingLeft: 6,
            },
          }}
        >
          <thead>
            <tr>
              <td />
              <td>Mine</td>
              <td>Other's</td>
            </tr>
          </thead>
          <tbody>
            {notifications.map(notification => (
              <tr key={notification.id}>
                <td>{notification.description}</td>
                <td>
                  {' '}
                  <input
                    type="checkbox"
                    checked={
                      messagingPreferences &&
                      messagingPreferences[notification.id]['mine'][channel]
                    }
                    disabled={!messagingPreferences}
                    onChange={() =>
                      toggleNotifications({
                        id: notification.id,
                        target: 'mine',
                        channel,
                      })
                    }
                  />
                </td>
                <td>
                  {' '}
                  <input
                    type="checkbox"
                    checked={
                      messagingPreferences &&
                      messagingPreferences[notification.id]['others'][channel]
                    }
                    disabled={!messagingPreferences}
                    onChange={() =>
                      toggleNotifications({
                        id: notification.id,
                        target: 'others',
                        channel,
                      })
                    }
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }

  return (
    <>
      <div>
        <SectionHeading heading="Ingestion emails" />
        <ChannelNotifications
          notifications={ingestionEmailNotifications}
          channel="email"
        />
        <div>
          <Text css={{ color: colors.grey3 }}>
            You can optionally be notified via email when a video has been fully
            ingested and is ready for your next action.
          </Text>
        </div>
      </div>
      <div css={{ paddingTop: 40 }}>
        <SectionHeading heading="Toasts" />
        <ChannelNotifications
          notifications={toastNotifications}
          channel="toast"
        />
      </div>
    </>
  );
}

const Notifications = connect(
  state => ({
    user: state.user,
  }),
  {
    addToast,
  },
)(_Notifications);

function _TeamSettings({
  stripePublicKey,
  team,
  updateTeam,
  addToast,
  openDialog,
  closeDialog,
}) {
  function patchTeam(body) {
    return fetch(`/api/teams/${team.id}`, {
      method: 'PATCH',
      body,
    });
  }

  function saveName(name) {
    if (!name) {
      addToast({ text: 'Name required; could not update team' });
    } else {
      return patchTeam({ name })
        .then(res => res.json())
        .then(response => {
          if (response.error) {
            addToast({ text: response.error });
            return;
          }

          if (name !== team.name) {
            updateTeam({ id: team.id, name });
          }
          addToast({ text: 'Team updated' });
        })
        .catch(error => {
          addToast({ text: 'Could not update team' });
          throw error;
        });
    }
  }

  function closeAccount() {
    const description = (
      <>
        When you delete this team account, you will also delete video data and
        possibly your user account if this is the only team you are currently
        member of. Any remaining processing credits will be lost and cannot be
        refunded. This action can <b>NOT</b> be undone!
      </>
    );

    openDialog({
      type: 'settleUtilities',
      heading: 'Confirm account closure',
      description,
      stripePublicKey,
      team,
      addToast,
      closeDialog,
      postConfirm: isConfirmed => {
        if (isConfirmed) {
          return fetch(`/api/teams/${team.id}`, {
            method: 'DELETE',
          })
            .then(res => res.json())
            .then(response => {
              if (response.error) {
                addToast({ text: response.error });
                return;
              }

              location.href = '/';
            })
            .catch(error => {
              addToast({ text: error });
            });
        }
      },
    });
  }

  return (
    <>
      <SectionHeading heading="Team" value={team.id} />
      <LargeInput
        label="Name"
        initialValue={team.name}
        save={saveName}
        inputProps={{
          placeholder: 'Enter a new name',
          type: 'name',
          name: 'name',
        }}
      />
      <SectionHeading heading="Close account" />
      <div css={{ padding: 0 }}>
        <Text css={{ color: colors.grey3, marginBottom: 2 }}>
          Permanently{' '}
          <span
            onClick={closeAccount}
            css={{
              color: colors.orange1,
              cursor: 'pointer',
              ':hover': { textDecoration: 'underline' },
            }}
          >
            delete this team account
          </span>{' '}
          including all video data.
        </Text>
      </div>
    </>
  );
}

const TeamSettings = connect(
  state => {
    const { env } = state;
    return {
      team: currentTeamSelector(state),
      stripePublicKey: env.STRIPE_PUBLIC_KEY,
    };
  },
  {
    updateTeam,
    addToast,
    openDialog,
    closeDialog,
  },
)(_TeamSettings);

function _Members({ team, teamMembers, openDialog, addToast, removeMember }) {
  const [invitations, setInvitations] = React.useState([]);
  const [isRemovingTeamMember, setIsRemovingTeamMember] = React.useState(false);

  React.useEffect(() => {
    fetch(`/api/teams/${team.id}/invitations`)
      .then(res => res.json())
      .then(response => {
        if (response.error) {
          addToast({ text: 'Failed to get invitations' });
        } else {
          setInvitations(response.invitations);
        }
      });
  }, []);

  function addTeamMember() {
    const callback = response => {
      setInvitations(state => [...state, response.invitation]);
    };

    openDialog({
      type: 'addTeamMember',
      teamId: team.id,
      teamName: team.name,
      callback,
    });
  }

  function removeTeamMember(userId) {
    setIsRemovingTeamMember(true);

    fetch(`/api/teams/${team.id}/users/${userId}`, {
      method: 'DELETE',
    })
      .then(res => res.json())
      .then(response => {
        if (response.error) {
          addToast({ text: 'Could not remove team member' });
          return;
        }

        removeMember({ id: userId });
        addToast({ text: 'Team member removed' });
      })
      .finally(() => setIsRemovingTeamMember(false));
  }

  function revokeInvitation(invitationId) {
    fetch(`/api/teams/${team.id}/invitations/${invitationId}`, {
      method: 'DELETE',
    })
      .then(res => res.json())
      .then(response => {
        if (response.error) {
          addToast({ text: 'Could not revoke invitation' });
          return;
        }

        setInvitations(state => {
          return state.filter(invitation => invitation.id !== invitationId);
        });
        addToast({ text: 'Invitation revoked' });
      });
  }

  return (
    <>
      <SectionHeading heading="Invite member" />
      <div css={{ padding: 20 }}>
        <Text css={{ color: colors.black1, marginBottom: 7 }}>
          Administrator
        </Text>
        <Text css={{ color: colors.grey3, marginBottom: 2 }}>
          Administrators have full access to upload, process and manage
          material, configure billing and account settings, and invite other
          users. This is currently the only user type available.
        </Text>
        <Text
          css={{
            color: colors.orange1,
            cursor: 'pointer',
            ':hover': { textDecoration: 'underline' },
          }}
          tabIndex="0"
          onClick={addTeamMember}
        >
          Invite user
        </Text>
      </div>
      <SectionHeading heading="Members" />
      {teamMembers.length === 0 && (
        <>
          <Text css={{ color: colors.grey3 }}>
            No other team members listed.
          </Text>
          <Text css={{ color: colors.grey3 }}>
            Go ahead and{' '}
            <span
              onClick={addTeamMember}
              css={{
                color: colors.orange1,
                cursor: 'pointer',
                ':hover': { textDecoration: 'underline' },
              }}
            >
              invite someone to join
            </span>{' '}
            your team!
          </Text>
        </>
      )}
      {teamMembers.map(member => (
        <div css={{ position: 'relative', padding: 20 }} key={member.id}>
          {!isRemovingTeamMember && (
            <Text
              tabIndex="0"
              onClick={() => removeTeamMember(member.id)}
              css={{
                position: 'absolute',
                top: 20,
                right: 20,
                color: colors.orange1,
                cursor: 'pointer',
                ':hover': { textDecoration: 'underline' },
              }}
            >
              Revoke
            </Text>
          )}
          <Text css={{ color: colors.black1, marginBottom: 7 }}>
            {member.name}
          </Text>
          <Text css={{ color: colors.grey3 }}>Administrator</Text>
        </div>
      ))}
      {invitations && invitations.length > 0 && (
        <>
          <SectionHeading heading="Invited members" />
          {invitations.map(invitation => (
            <div
              key={invitation.email}
              css={{
                position: 'relative',
                padding: 20,
              }}
            >
              <Text
                tabIndex="0"
                onClick={() => revokeInvitation(invitation.id)}
                css={{
                  position: 'absolute',
                  top: 20,
                  right: 20,
                  color: colors.orange1,
                  cursor: 'pointer',
                  ':hover': { textDecoration: 'underline' },
                }}
              >
                Revoke
              </Text>
              <Text css={{ color: colors.black1, marginBottom: 7 }}>
                {invitation.email}
              </Text>
              <Text css={{ color: colors.grey3 }}>Administrator</Text>
            </div>
          ))}
        </>
      )}
    </>
  );
}

const Members = connect(
  state => {
    const team = currentTeamSelector(state);
    return {
      team,
      teamMembers: state.members.filter(member => member.team_id === team.id),
    };
  },
  { openDialog, addToast, removeMember },
)(_Members);

function SectionHeading({ heading, value }) {
  return (
    <>
      <Heading as="h4">{heading}</Heading>
      {value && (
        <span>
          <font
            css={{
              color: colors.grey3,
            }}
          >
            {value}
          </font>
        </span>
      )}
      <Separator styles={{ marginBottom: 20 }} />
    </>
  );
}

export function Account({ location }) {
  const { pathname } = location;
  return (
    <div css={{ display: 'flex', minHeight: 'calc(100vh - 170px)' }}>
      <ul
        css={{
          width: 215,
          backgroundColor: colors.white4,
          margin: 0,
          padding: '12px 0',
          fontSize: rem(13),
          lineHeight: '21px',
          listStyleType: 'none',
          borderRight: `1px solid ${colors.hexToRgba(colors.grey3, 0.15)}`,
        }}
      >
        <li css={getItemStyles(pathname === '/account')}>
          <Link to="/account">User</Link>
        </li>
        <li css={getItemStyles(pathname === '/account/notifications')}>
          <Link to="/account/notifications">Notifications</Link>
        </li>
        <li css={getItemStyles(pathname === '/account/team')}>
          <Link to="/account/team">Team</Link>
        </li>
        <li css={getItemStyles(pathname === '/account/manage-team-members')}>
          <Link to="/account/manage-team-members">Manage team members</Link>
        </li>
      </ul>
      <div
        css={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: colors.white0,
          padding: '14px 20px 20px',
          '>*:not(:last-child)': {
            marginBottom: 12,
          },
        }}
      >
        <Switch>
          <Route exact path="/account" component={UserSettings} />
          <Route
            exact
            path="/account/notifications"
            component={Notifications}
          />
          <Route exact path="/account/team" component={TeamSettings} />
          <Route
            exact
            path="/account/manage-team-members"
            component={Members}
          />

          <Route component={NotFound} />
        </Switch>
      </div>
    </div>
  );
}
