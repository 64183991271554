import { Heading, Text, Anchor } from './typography';
// <Heading as="h1">Privacy policy</Heading>
// <Text></Text>

export const TermsPolicy = () => (
  <>
    <Heading as="h1">Terms and conditions</Heading>
    <Text>
      These terms and conditions (&quot;Terms&quot;, &quot;Agreement&quot;) are
      an agreement between Pixop ApS (&quot;Pixop ApS&quot;, &quot;us&quot;,
      &quot;we&quot; or &quot;our&quot;) and you (&quot;User&quot;,
      &quot;you&quot; or &quot;your&quot;). This Agreement sets forth the
      general terms and conditions of your use of the{' '}
      <a target="_blank" rel="nofollow" href="http://www.pixop.com">
        pixop.com
      </a>{' '}
      website and any of its products or services (collectively,
      &quot;Website&quot; or &quot;Services&quot;).
    </Text>
    <Heading as="h2">Accounts and membership</Heading>
    <Text>
      If you create an account on the Website, you are responsible for
      maintaining the security of your account and you are fully responsible for
      all activities that occur under the account and any other actions taken in
      connection with it. We may, but have no obligation to, monitor and review
      new accounts before you may sign in and use our Services. Providing false
      contact information of any kind may result in the termination of your
      account. You must immediately notify us of any unauthorized uses of your
      account or any other breaches of security. We will not be liable for any
      acts or omissions by you, including any damages of any kind incurred as a
      result of such acts or omissions. We may suspend, disable, or delete your
      account (or any part thereof) if we determine that you have violated any
      provision of this Agreement or that your conduct or content would tend to
      damage our reputation and goodwill. If we delete your account for the
      foregoing reasons, you may not re-register for our Services. We may block
      your email address and Internet protocol address to prevent further
      registration.
    </Text>
    <Heading as="h2">Billing and payments</Heading>
    <Text>
      You shall pay all fees or charges to your account in accordance with the
      fees, charges, and billing terms in effect at the time a fee or charge is
      due and payable. Where Services are offered on a free trial basis, payment
      may be required after the free trial period ends, and not when you enter
      your billing details (which may be required prior to the commencement of
      the free trial period). If auto-renewal is enabled for the Services you
      have subscribed for, you will be charged automatically in accordance with
      the term you selected. If, in our judgment, your purchase constitutes a
      high-risk transaction, we will require you to provide us with a copy of
      your valid government-issued photo identification, and possibly a copy of
      a recent bank statement for the credit or debit card used for the
      purchase. We reserve the right to change products and product pricing at
      any time.
    </Text>
    <Heading as="h2">Refund policy</Heading>
    <Text>
      Our policy is valid for a period of 14 calendar days from the date of
      purchase. If you are not satisfied with the service for any reason, you
      can ask for a refund. A pro-rated refund will be issued for the unused
      portion of the services (excluding any bonuses, offers, or discounted
      processing credits). If the period of 14 days has lapsed since the
      purchase, we can't, unfortunately, offer you a refund.
    </Text>

    <Heading as="h4">Refund requirements</Heading>
    <Text>
      At least one of the following criteria must be met in order to qualify for
      a refund:
      <ol>
        <li>Service must not be used </li>
        <li>Service malfunctions or does not work as described</li>
      </ol>
      If the conditions listed above are not met, we reserve the right not to
      issue a refund. It's important to keep in mind that there is often a
      difference between a service that doesn't work and a situation where you
      are receiving an error message. Error messages could be related to an
      incorrect setup, configuration, or software and as a result, the service
      is not working.
    </Text>
    <Heading as="h2">Accuracy of information</Heading>
    <Text>
      Occasionally there may be information on the Website that contains
      typographical errors, inaccuracies or omissions that may relate to
      promotions and offers. We reserve the right to correct any errors,
      inaccuracies or omissions, and to change or update information or cancel
      orders if any information on the Website or on any related Service is
      inaccurate at any time without prior notice (including after you have
      submitted your order). We undertake no obligation to update, amend or
      clarify information on the Website including, without limitation, pricing
      information, except as required by law. No specified update or refresh
      date applied on the Website should be taken to indicate that all
      information on the Website or on any related Service has been modified or
      updated.
    </Text>
    <Heading as="h2">Links to other websites</Heading>
    <Text>
      Although this Website may link to other websites, we are not, directly or
      indirectly, implying any approval, association, sponsorship, endorsement,
      or affiliation with any linked website, unless specifically stated herein.
      We are not responsible for examining or evaluating, and we do not warrant
      the offerings of, any businesses or individuals or the content of their
      websites. We do not assume any responsibility or liability for the
      actions, products, services, and content of any other third-parties. You
      should carefully review the legal statements and other conditions of use
      of any website which you access through a link from this Website. Your
      linking to any other off-site websites is at your own risk.
    </Text>
    <Heading as="h2">Prohibited uses</Heading>
    <Text>
      In addition to other terms as set forth in the Agreement, you are
      prohibited from using the Website or its Content: (a) for any unlawful
      purpose; (b) to solicit others to perform or participate in any unlawful
      acts; (c) to violate any international, federal, provincial or state
      regulations, rules, laws, or local ordinances; (d) to infringe upon or
      violate our intellectual property rights or the intellectual property
      rights of others; (e) to harass, abuse, insult, harm, defame, slander,
      disparage, intimidate, or discriminate based on gender, sexual
      orientation, religion, ethnicity, race, age, national origin, or
      disability; (f) to submit false or misleading information; (g) to upload
      or transmit viruses or any other type of malicious code that will or may
      be used in any way that will affect the functionality or operation of the
      Service or of any related website, other websites, or the Internet; (h) to
      collect or track the personal information of others; (i) to spam, phish,
      pharm, pretext, spider, crawl, or scrape; (j) for any obscene or immoral
      purpose; or (k) to interfere with or circumvent the security features of
      the Service or any related website, other websites, or the Internet. We
      reserve the right to terminate your use of the Service or any related
      website for violating any of the prohibited uses.
    </Text>
    <Heading as="h2">Disclaimer of warranty</Heading>
    <Text>
      You agree that your use of our Website or Services is solely at your own
      risk. You agree that such Service is provided on an &quot;as is&quot; and
      &quot;as available&quot; basis. We expressly disclaim all warranties of
      any kind, whether express or implied, including but not limited to the
      implied warranties of merchantability, fitness for a particular purpose
      and non-infringement. We make no warranty that the Services will meet your
      requirements, or that the Service will be uninterrupted, timely, secure,
      or error-free; nor do we make any warranty as to the results that may be
      obtained from the use of the Service or as to the accuracy or reliability
      of any information obtained through the Service or that defects in the
      Service will be corrected. You understand and agree that any material
      and/or data downloaded or otherwise obtained through the use of Service is
      done at your own discretion and risk and that you will be solely
      responsible for any damage to your computer system or loss of data that
      results from the download of such material and/or data. We make no
      warranty regarding any goods or services purchased or obtained through the
      Service or any transactions entered into through the Service. No advice or
      information, whether oral or written, obtained by you from us or through
      the Service shall create any warranty not expressly made herein.
    </Text>
    <Heading as="h2">Limitation of liability</Heading>
    <Text>
      To the fullest extent permitted by applicable law, in no event will Pixop
      ApS, its affiliates, officers, directors, employees, agents, suppliers or
      licensors be liable to any person for (a): any indirect, incidental,
      special, punitive, cover or consequential damages (including, without
      limitation, damages for lost profits, revenue, sales, goodwill, use of
      content, impact on business, business interruption, loss of anticipated
      savings, loss of business opportunity) however caused, under any theory of
      liability, including, without limitation, contract, tort, warranty, breach
      of statutory duty, negligence or otherwise, even if Pixop ApS has been
      advised as to the possibility of such damages or could have foreseen such
      damages. To the maximum extent permitted by applicable law, the aggregate
      liability of Pixop ApS and its affiliates, officers, employees, agents,
      suppliers and licensors, relating to the services will be limited to an
      amount greater of one dollar or any amounts actually paid in cash by you
      to Pixop ApS for the prior one month period prior to the first event or
      occurrence giving rise to such liability. The limitations and exclusions
      also apply if this remedy does not fully compensate you for any losses or
      fails of its essential purpose.
    </Text>
    <Heading as="h2">Indemnification</Heading>
    <Text>
      You agree to indemnify and hold Pixop ApS and its affiliates, directors,
      officers, employees, and agents harmless from and against any liabilities,
      losses, damages or costs, including reasonable attorneys' fees, incurred
      in connection with or arising from any third-party allegations, claims,
      actions, disputes, or demands asserted against any of them as a result of
      or relating to your Content, your use of the Website or Services or any
      willful misconduct on your part.
    </Text>
    <Heading as="h2">Severability</Heading>
    <Text>
      All rights and restrictions contained in this Agreement may be exercised
      and shall be applicable and binding only to the extent that they do not
      violate any applicable laws and are intended to be limited to the extent
      necessary so that they will not render this Agreement illegal, invalid or
      unenforceable. If any provision or portion of any provision of this
      Agreement shall be held to be illegal, invalid or unenforceable by a court
      of competent jurisdiction, it is the intention of the parties that the
      remaining provisions or portions thereof shall constitute their agreement
      with respect to the subject matter hereof, and all such remaining
      provisions or portions thereof shall remain in full force and effect.
    </Text>
    <Heading as="h2">Dispute resolution</Heading>
    <Text>
      The formation, interpretation, and performance of this Agreement and any
      disputes arising out of it shall be governed by the substantive and
      procedural laws of Denmark without regard to its rules on conflicts or
      choice of law and, to the extent applicable, the laws of Denmark. The
      exclusive jurisdiction and venue for actions related to the subject matter
      hereof shall be the courts located in Denmark, and you hereby submit to
      the personal jurisdiction of such courts. You hereby waive any right to a
      jury trial in any proceeding arising out of or related to this Agreement.
      The United Nations Convention on Contracts for the International Sale of
      Goods does not apply to this Agreement.
    </Text>
    <Heading as="h2">Changes and amendments</Heading>
    <Text>
      We reserve the right to modify this Agreement or its policies relating to
      the Website or Services at any time, effective upon posting of an updated
      version of this Agreement on the Website. When we do, we will send you an
      email to notify you. Continued use of the Website after any such changes
      shall constitute your consent to such changes.
    </Text>
    <Heading as="h2">Acceptance of these terms</Heading>
    <Text>
      You acknowledge that you have read this Agreement and agree to all its
      terms and conditions. By using the Website or its Services you agree to be
      bound by this Agreement. If you do not agree to abide by the terms of this
      Agreement, you are not authorized to use or access the Website and its
      Services.
    </Text>
    <Heading as="h2">Contacting us</Heading>
    <Text>
      If you would like to contact us to understand more about this Agreement or
      wish to contact us concerning any matter relating to it, you may send an
      email to &#115;&#117;&#112;&#112;&#111;r&#116;&#64;&#112;ixop&#46;c&#111;m
      or write a letter to Vestergade 55, 1st floor, 5000 Odense, Denmark
    </Text>
    <Text>
      This document was last updated on <b>June 21, 2024</b>
    </Text>
  </>
);
