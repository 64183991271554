import { useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import * as colors from '../colors';
import { Text, rem } from '../typography';
import { Dialog } from '../dialog';
import { Alert } from '../forms';
import { fetch } from '../requests';
import { removeVideo } from '../store/videos';
import { addToast } from '../store/toasts';
import { cancelUpload } from '../store/uploads';
import { removeProject } from '../store/projects';

function _DeleteUpload({
  videoId,
  navigateTo,
  removeVideo,
  addToast,
  cancelUpload,
  removeProject,
  projectId,
  teamId,
  isOnlyProjectVideo,
  history,
}) {
  const [error, setError] = useState(null);

  function onConfirm(event) {
    event.preventDefault();
    event.stopPropagation();

    const requests = [];

    cancelUpload({ id: videoId });
    requests.push(fetch(`/api/uploads/${videoId}`, { method: 'DELETE' }));

    // Also remove the project.
    if (isOnlyProjectVideo) {
      history.push('/');

      requests.push(
        fetch(`/api/projects/${projectId}`, {
          method: 'DELETE',
          body: { teamId },
        }),
      );
    }

    return Promise.all(requests).then(results => {
      if (results.every(res => res.ok)) {
        if (navigateTo) {
          history.push(navigateTo);
        }

        setTimeout(() => {
          // Ensure that we navigate away before the video is deleted from the
          // store (including any derived videos).
          removeVideo({ id: videoId });
          if (isOnlyProjectVideo) {
            removeProject({ id: projectId });
          }
        });

        if (isOnlyProjectVideo) {
          history.push('/');
        }

        addToast({ text: 'Video successfully deleted' });
        return true;
      } else {
        const failedResult = results.find(res => !res.ok);
        return failedResult.json().then(response => {
          setError(response.error);
          return false;
        });
      }
    });
  }

  return (
    <Dialog
      heading="Cancel upload"
      confirmText="Yes, cancel upload"
      cancelText="Continue"
      onConfirm={onConfirm}
      isOpen
      closeOnConfirm
      danger
    >
      {error && <Alert text={error} styles={{ marginBottom: 12 }} />}
      <Text css={{ fontSize: rem(14), color: colors.grey2 }}>
        Are you sure you wish to cancel this upload?
      </Text>
    </Dialog>
  );
}

export const DeleteUpload = withRouter(
  connect(
    (state, ownProps) => {
      const projectVideos = state.videos.filter(
        v => v.project_id === ownProps.projectId,
      );
      const isOnlyProjectVideo =
        projectVideos.length === 1 && projectVideos[0].id === ownProps.videoId;

      const teamId = state.currentTeamId;
      return { isOnlyProjectVideo, teamId };
    },
    { removeVideo, addToast, cancelUpload, removeProject },
  )(_DeleteUpload),
);
