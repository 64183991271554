export const black0 = '#000000';
export const black1 = '#0f0f0f';
export const black2 = '#2e3131';
export const black3 = '#333333';
export const black4 = '#2e2e2e';
export const blue0 = '#4c75fc';
export const blue1 = '#2742a8';
export const blue2 = '#635bff';
export const green0 = '#2ecc71';
export const grey0 = '#dedede';
export const grey1 = '#bcbcbc';
export const grey10 = '#6f829b';
export const grey11 = '#a0a3a7';
export const grey2 = '#a2a2a2';
export const grey3 = '#a5b1c2';
export const grey4 = '#e5e5e5';
export const grey5 = '#a9aec1';
export const grey6 = '#a7a7a7';
export const grey7 = '#909090';
export const grey8 = '#bac4d1';
export const grey9 = '#d0d0d0';
export const orange0 = '#ffa502';
export const orange1 = '#f5ab35';
export const orange2 = '#c57b05';
export const red0 = '#fc5c65';
export const white0 = '#ffffff';
export const white1 = '#f1f2f6';
export const white2 = '#f5f5f5';
export const white3 = '#fafafa';
export const white4 = '#fbfbfc';
export const yellow0 = '#ffcb05';

// Assumes a long hex notation. Useful to change the opacity of colors.
export function hexToRgba(hex, alpha = 1) {
  const red = parseInt(hex.slice(1, 3), 16);
  const green = parseInt(hex.slice(3, 5), 16);
  const blue = parseInt(hex.slice(5, 7), 16);
  return `rgba(${red},${green},${blue},${alpha})`;
}
