import styled from '@emotion/styled';

import { rem } from './typography';
import * as colors from './colors';
import * as constants from './constants';
import { Select } from './select';
import { replaceTemplateTagsWithHtml } from './template-tags';

export const FormTitle = styled('span')({
  color: colors.grey3,
  fontSize: rem(20),
  marginTop: 6,
});

export const FormCard = styled('form')({
  boxSizing: 'border-box',
  display: 'flex',
  flexDirection: 'column',
  width: 350,
  padding: '20px 30px',
  backgroundColor: colors.white0,
  borderRadius: 5,
  boxShadow: '0 0 10px rgba(0, 0, 0, 0.05)',
  '> *:not(:last-child)': {
    marginBottom: 20,
  },
});

export const FieldSet = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  label: {
    color: colors.grey3,
    fontSize: rem(12),
    marginBottom: 5,
  },
  span: {
    color: colors.red0,
    fontSize: rem(12),
    marginTop: 6,
  },
  '> *': {
    display: 'block',
  },
});

export const Field = ({
  label,
  fieldName,
  validationMessage,
  innerRef,
  ...inputAttrs
}) => (
  <FieldSet>
    <label htmlFor={fieldName}>{label}</label>
    <Input
      id={fieldName}
      name={fieldName}
      invalid={!!validationMessage}
      ref={innerRef}
      {...inputAttrs}
    />
    {validationMessage && <span>{validationMessage}</span>}
  </FieldSet>
);

export const DropDown = ({
  label,
  fieldName,
  options,
  validationMessage,
  innerRef,
  ...inputAttrs
}) => (
  <FieldSet>
    <label htmlFor={fieldName}>{label}</label>
    <Select
      id={fieldName}
      name={fieldName}
      invalid={!!validationMessage}
      ref={innerRef}
      options={options}
      {...inputAttrs}
    />
    {validationMessage && <span>{validationMessage}</span>}
  </FieldSet>
);

export const Input = styled('input')(
  {
    padding: '8px 12px 7px',
    lineHeight: '23px',
    fontSize: rem(14),
    color: colors.black1,
    backgroundColor: colors.white3,
    borderRadius: 3,
    minWidth: 0,
    outline: 'none',
    transition: `
      box-shadow ${constants.transitionShort} ease,
      border ${constants.transitionShort} ease;
    `,
    border: `1px solid ${colors.grey4}`,
    '::placeholder': {
      color: colors.grey3,
    },
    ':disabled': {
      color: colors.grey2,
    },
    ':focus': {
      border: `1px solid ${colors.hexToRgba(colors.orange1, 0.6)}`,
      boxShadow: `0 0 0 1px ${colors.hexToRgba(colors.orange1, 0.6)}`,
    },
  },
  props =>
    props.invalid && {
      border: `1px solid ${colors.red0}`,
      ':focus': {
        border: `1px solid ${colors.hexToRgba(colors.red0, 0.6)}`,
        boxShadow: `0 0 0 1px ${colors.hexToRgba(colors.red0, 0.6)}`,
      },
    },
);

export const Alert = ({ text, styles = {} }) => (
  <div
    css={{
      padding: 12,
      border: `1px solid ${colors.hexToRgba(colors.red0, 0.7)}`,
      borderRadius: 3,
      color: colors.red0,
      a: {
        color: styles.color || colors.red0,
        filter: 'brightness(65%)',
      },
      fontSize: rem(14),
      ...styles,
    }}
  >
    <p css={{ margin: '2px 0 0' }}>
      {' '}
      <div
        dangerouslySetInnerHTML={{
          __html: replaceTemplateTagsWithHtml(text),
        }}
      />
    </p>
  </div>
);
