import { connect } from 'react-redux';

import { ability } from '../ability';
import { Dialog } from '../dialog';
import { Alert } from '../forms';
import { Film, Clapperboard } from '../icons';
import { bytesInClosestUnit } from '../utils';
import { Text } from '../typography';
import * as colors from '../colors';
import {
  currentVideoSelector,
  currentProjectVideosSelector,
  derivedVideosSelector,
} from '../store/selectors';

const Download = ({ video, label, sizeBytes }) => (
  <a
    href={
      video.parent_id
        ? `/api/uploads/${video.parent_id}/download/${video.id}`
        : `/api/uploads/${video.id}/download`
    }
    css={{
      display: 'flex',
      alignItems: 'center',
      color: colors.orange1,
      textDecoration: 'none',
      height: 40,
      padding: '0 10px',
      borderRadius: 3,
      border: '1px solid transparent',
      cursor: 'pointer',
      ':hover': {
        backgroundColor: colors.white3,
        border: `1px solid ${colors.grey4}`,
      },
    }}
  >
    {!video.clip_id && (
      <Film color={colors.black1} styles={{ marginRight: 10 }} />
    )}
    {video.clip_id && (
      <Clapperboard color={colors.black1} styles={{ marginRight: 10 }} />
    )}
    <span css={{ flex: 1 }}>
      {label.name}{' '}
      <span css={{ flex: 1, color: colors.grey3 }}>({label.info})</span>
    </span>
    {sizeBytes && (
      <span css={{ color: colors.grey3 }}>{bytesInClosestUnit(sizeBytes)}</span>
    )}
  </a>
);

const getDisplayRes = ({ frameWidth, frameHeight }) => {
  const canned = {
    '1920': '2K',
    '3840': '4K',
    '7680': '8K',
  };
  if (canned[frameWidth]) {
    return canned[frameWidth];
  }
  return `${frameWidth}x${frameHeight}`;
};

function getLabel({ video }) {
  return {
    name: video.name,
    info: video.parent_id === null ? 'original' : getDisplayRes(video.metadata),
  };
}

function _DownloadVideo({ videos }) {
  return (
    <Dialog
      isOpen
      heading={videos.length > 1 ? 'Download videos' : 'Download video'}
    >
      {videos.length === 0 && (
        <Text css={{ color: colors.grey3 }}>
          There are no videos available to download.
        </Text>
      )}
      {videos.length > 0 && ability.cannot('download', 'Video') && (
        <Alert
          text={
            'You cannot download videos until you have added your payment information on the [billing settings] page.'
          }
          styles={{ marginBottom: 12 }}
        />
      )}
      {ability.can('download', 'Video') &&
        videos.map(video => (
          <Download
            key={video.id}
            video={video}
            label={getLabel({ video })}
            sizeBytes={video.metadata.size || video.file_size_bytes}
          />
        ))}
    </Dialog>
  );
}

const ingestedVideos = video =>
  video.ingestion_state.ingestionStatus === 'DONE';

export const DownloadVideo = connect((state, ownProps) => {
  let videos = [];

  if (ownProps.projectId) {
    videos = currentProjectVideosSelector(state, ownProps);
    videos = videos.filter(ingestedVideos);
  } else {
    const video = currentVideoSelector(state, ownProps);

    if (ownProps.onlyThis) {
      videos = [video];
    } else {
      const derivedVideos = derivedVideosSelector(state, ownProps);
      const ingestedDerivedVideos = derivedVideos
        .filter(ingestedVideos)
        .filter(v => v.clip_id === video.clip_id);
      videos = [video, ...ingestedDerivedVideos];
    }
  }

  return { videos };
})(_DownloadVideo);
