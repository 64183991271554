import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import Cookie from 'js-cookie';

import { ability } from '../ability';
import { rootReducer } from './reducers';
import { socketsMiddleware } from './sockets-middleware';
import { clipboardMiddleware } from './clipboard-middleware';
import { videosMiddleware } from './videos';
import { teamsMiddleware } from './teams';
import { uploadsMiddleware } from './uploads';
import { intercomMiddleware } from './intercom-middleware';

let devMiddleware = [];
if (process.env.NODE_ENV !== 'production') {
  const { loggingMiddleware } = require('./logging-middleware');
  const { globalStoreMiddleware } = require('./global-store-middleware');
  devMiddleware = [loggingMiddleware, globalStoreMiddleware];
}

function injectCookieState(state) {
  function setCurrentTeamAndAcls(team) {
    state.currentTeamId = team.id;
    ability.update(team.acls);
    Cookie.set('currentTeamId', team.id);
  }

  // ensures that the team stays selected between browser refreshes
  if (state.teams && state.teams.length > 0) {
    const currentTeamId = Cookie.get('currentTeamId');

    if (currentTeamId) {
      // attempt to set initial team and ACLs based on the stored value
      const currentTeam = state.teams.find(team => team.id === currentTeamId);

      if (currentTeam) {
        setCurrentTeamAndAcls(currentTeam);
        return state;
      }
    }

    // team doesn't exist anymore or no id was found in cookie, set a new one
    setCurrentTeamAndAcls(state.teams[0]);
  }

  return state;
}

export function configureStore(preloadedState) {
  const store = createStore(
    rootReducer,
    injectCookieState(preloadedState),
    applyMiddleware(
      ...devMiddleware,
      thunk,
      intercomMiddleware,
      uploadsMiddleware,
      videosMiddleware,
      teamsMiddleware,
      clipboardMiddleware,
      socketsMiddleware,
    ),
  );

  store.subscribe(() => {
    try {
      const state = store.getState();

      // ensures that the team id is persisted and ACLs updated when logging in and changing team
      if (state.teams) {
        const newTeamId = state.currentTeamId;
        const newTeam = state.teams.find(team => team.id === newTeamId);

        if (newTeam) {
          // update ACLs to those of the selected team
          ability.update(newTeam.acls);
        }

        Cookie.set('currentTeamId', newTeam.id);
      }
    } catch (error) {
      // ignore
    }
  });

  if (process.env.NODE_ENV !== 'production' && module.hot) {
    module.hot.accept('./reducers', () => store.replaceReducer(rootReducer));
  }

  return store;
}
