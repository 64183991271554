import styled from '@emotion/styled';

import * as colors from './colors';
import * as constants from './constants';

export const rem = px => `${px / constants.baseFontSize}rem`;

export const typographyScale = {
  huge: {
    fontSize: rem(56),
    lineHeight: 80 / 56,
  },
  large: {
    fontSize: rem(26),
    lineHeight: 36 / 26,
  },
  medium: {
    fontSize: rem(18),
    lineHeight: 29 / 18,
  },
  small: {
    fontSize: rem(16),
    lineHeight: 26 / 16,
  },
  tiny: {
    fontSize: rem(14),
    lineHeight: 23 / 16,
  },
};

export const Heading = styled('span')(
  {
    display: 'block',
    margin: 0,
    fontWeight: 400,
    color: colors.black1,
  },
  props =>
    props.as === 'h1'
      ? typographyScale.huge
      : props.as === 'h2'
      ? typographyScale.large
      : props.as === 'h4'
      ? typographyScale.medium
      : typographyScale.small,
);

export const Text = styled('span')({
  ...typographyScale.tiny,
  display: 'block',
  margin: 0,
  color: colors.grey2,
});

export const Anchor = styled('a')({
  ...typographyScale.small,
  color: colors.grey2,
  textDecoration: 'none',
  ':hover': {
    textDecoration: 'underline',
  },
});
