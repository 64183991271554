import { useRef, useState } from 'react';
import { connect } from 'react-redux';
import useReactRouter from 'use-react-router';

import { rem } from './typography';
import * as colors from './colors';
import * as constants from './constants';
import { useOnClickOutside } from './search-box';
import { fetch } from './requests';
import { openDialog } from './store/dialog';

function hashCode(str) {
  let h = 0;
  for (let i = 0; i < str.length; i++)
    h = (Math.imul(31, h) + str.charCodeAt(i)) | 0;
  return h;
}

function stringToHslColor(str, saturation, lightness) {
  const h = hashCode(str) % 360;
  return 'hsl(' + h + ', ' + saturation + '%, ' + lightness + '%)';
}

export function logOutRequest() {
  return fetch('/api/log-out', { method: 'DELETE' }).then(res => res.json());
}

function MenuItem({ label, action, closeMenu }) {
  function _action(event) {
    if (action) action(event);
    if (closeMenu) closeMenu(event);
  }

  return (
    <div
      role="button"
      tabIndex="0"
      onClick={_action}
      onKeyDown={event => {
        if (event.keyCode === 13) {
          _action();
        }
      }}
    >
      {label}
    </div>
  );
}

const Info = ({ user, styles = {} }) => (
  <div
    css={{
      backgroundColor: colors.white0,
      border: `1px solid ${colors.grey3}`,
      borderRadius: 2,
      padding: '6px 8px',
      opacity: 1,
      transition: 'opacity .18s ease-in',
      minWidth: 60,
      maxWidth: 120,
      boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)',
      ':after': {
        content: "''",
        position: 'absolute',
        top: '100%',
        left: 0,
        right: 0,
        margin: '0 auto',
        width: 0,
        height: 0,
        borderTop: `solid 5px ${colors.grey3}`,
        borderLeft: 'solid 5px transparent',
        borderRight: 'solid 5px transparent',
      },
      ...styles,
    }}
  >
    <div
      css={{
        color: colors.black1,
        lineHeight: '20px',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
      }}
    >
      {user.name}
    </div>
    <div css={{ color: colors.grey3, lineHeight: '20px' }}>Admin</div>
  </div>
);

export function Thumbnail({ user, color, round, withInfo, styles = {} }) {
  const [isHovering, setHovering] = useState(false);
  const initials = user.name
    .split(' ')
    .map(name => name[0])
    .join('')
    .slice(0, 3);
  const backgroundColor = color || stringToHslColor(initials, 30, 80);
  return (
    <div
      css={{
        backgroundColor,
        width: 40,
        height: 40,
        borderRadius: 5,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        userSelect: 'none',
        position: 'relative',
        ...(round
          ? { borderRadius: '50%', width: 30, height: 30, fontSize: 12 }
          : {}),
        ...styles,
      }}
      onMouseEnter={() => setHovering(true)}
      onMouseLeave={() => setHovering(false)}
    >
      {withInfo && (
        <Info
          user={user}
          styles={{
            top: -65,
            position: 'absolute',
            zIndex: constants.zIndices.subModal + 1,
            backgroundColor: '#fff',
            visibility: isHovering ? 'visible' : 'hidden',
            opacity: isHovering ? 1 : 0,
          }}
        />
      )}
      <span>{initials}</span>
    </div>
  );
}

const itemHeight = 23;
const paddingTopBot = 10;
const marginBelow = 4;
function _Profile({ user, color, round, logOut, openTeams, styles = {} }) {
  const { history } = useReactRouter();
  const [isOpen, setOpen] = useState(false);
  const ref = useRef();
  const closeMenu = () => setOpen(false);
  useOnClickOutside(ref, closeMenu);

  return (
    <div
      ref={ref}
      role="button"
      tabIndex="0"
      onMouseEnter={() => setOpen(true)}
      onClick={() => setOpen(!isOpen)}
      onKeyDown={event => {
        if (event.keyCode === 13) setOpen(!isOpen);
      }}
      css={{
        height: 40,
        width: 40,
        position: 'relative',
        cursor: 'pointer',
        ...styles,
      }}
    >
      <Thumbnail user={user} color={color} round={round} />
      {isOpen && (
        <div
          css={{
            position: 'absolute',
            top: 50,
            right: 0,
            minWidth: 140,
            paddingTop: rem(paddingTopBot),
            paddingBottom: rem(paddingTopBot),
            lineHeight: rem(itemHeight),
            backgroundColor: colors.white0,
            borderRadius: 5,
            boxShadow: `0 0 20px ${colors.hexToRgba(colors.black0, 0.16)}`,
            color: colors.grey3,
            cursor: 'default',
            ':hover': {
              cursor: 'pointer',
            },
            '> div': {
              paddingLeft: rem(15),
              paddingRight: rem(15),
            },
            '> div:hover, > div:active, > div:focus': {
              color: colors.black1,
            },
            '> div:not(:last-child)': {
              marginBottom: rem(marginBelow),
            },
          }}
        >
          <MenuItem
            label="Account"
            action={() => history.push('/account')}
            closeMenu={closeMenu}
          />
          <MenuItem
            label="Billing"
            action={() => history.push('/billing')}
            closeMenu={closeMenu}
          />
          <MenuItem
            label="Switch team"
            action={openTeams}
            closeMenu={closeMenu}
          />
          <MenuItem
            label="Log out"
            action={() => logOut().then(() => history.push('/login'))}
            closeMenu={closeMenu}
          />
        </div>
      )}
    </div>
  );
}

export const Profile = connect(
  state => ({ user: state.user }),
  dispatch => {
    return {
      openTeams: () => dispatch(openDialog({ type: 'teams' })),
      logOut: () =>
        logOutRequest().then(() => {
          dispatch({ type: 'reset' });
        }),
    };
  },
)(_Profile);
