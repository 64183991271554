import React from 'react';

import { Dialog } from '../dialog';
import { Alert, Field } from '../forms';
import { fetch } from '../requests';

export function ConfirmPassword({ callback }) {
  const field = React.useRef(null);
  const [password, setPassword] = React.useState('');
  const [error, setError] = React.useState(null);

  React.useEffect(() => {
    field.current.focus();
  }, []);

  function onConfirm() {
    return fetch('/api/user/confirm-password', {
      method: 'POST',
      body: { password },
    })
      .then(res => res.json())
      .then(result => {
        if (result.error) {
          callback(false);
          return Promise.reject(result.error);
        }
        callback(true);
        return true;
      })
      .catch(error => {
        setError(error);
        return false;
      });
  }

  return (
    <Dialog
      heading="Confirm password"
      confirmText="Confirm"
      onConfirm={onConfirm}
      closeOnConfirm
      isOpen
    >
      {error && <Alert text={error} styles={{ marginBottom: 12 }} />}
      <Field
        label="Current password"
        placeholder="Enter your current password"
        name="password"
        type="password"
        innerRef={field}
        onChange={event => setPassword(event.target.value)}
      />
    </Dialog>
  );
}
