import React from 'react';
import { connect } from 'react-redux';

import * as colors from '../colors';
import { FieldSet } from '../forms';
import { Select } from '../select';
import { Heading, Text, rem } from '../typography';
import { fetch } from '../requests';
import { currentTeamSelector } from '../store/selectors';
import { openDialog } from '../store/dialog';
import { PaginationPanel } from '../pagination-panel';

const PAGE_SIZE = 20;

const Separator = ({ styles = {} }) => (
  <hr
    css={{
      height: 1,
      width: '100%',
      border: 'none',
      backgroundColor: colors.hexToRgba(colors.grey3, 0.3),
      ...styles,
    }}
  />
);

function SectionHeading({ heading, value }) {
  return (
    <>
      <Heading as="h4">{heading}</Heading>
      {value && (
        <span>
          <font
            css={{
              color: colors.grey3,
            }}
          >
            {value}
          </font>
        </span>
      )}
      <Separator styles={{ marginBottom: 20 }} />
    </>
  );
}

function TransactionsSection({ transactions, openDialog }) {
  const [activePage, setActivePage] = React.useState(1);

  const transactionsByDateArray = Object.keys(transactions).map(_tx =>
    transactions[_tx].events.map(_event => {
      return {
        date: new Date(_event.loggedAt).toLocaleString(),
        description: _event.description,
        metadata: _event.eventMetadata,
        amount:
          Math.abs(_event.amount) > 1e-2
            ? _event.amount.toFixed(2)
            : _event.amount.toFixed(3),
        balance: _event.balance.toFixed(2),
      };
    }),
  );

  const allTransactions = Array.prototype.concat
    .apply([], transactionsByDateArray)
    .reverse();

  function hasBillableItems(metadata) {
    return metadata && metadata.billableItems;
  }

  function metadataHandler(metadata) {
    if (hasBillableItems(metadata)) {
      openDialog({
        type: 'viewJobDetails',
        sourceVideoId: metadata.sourceVideoId,
        targetVideoId: metadata.targetVideoId,
        billableItems: metadata.billableItems,
        discountPercentage: metadata.discountPercentage,
      });
    }
  }

  return (
    <>
      {allTransactions.length === 0 && (
        <>
          <span css={{ color: colors.grey3 }}>No transactions made.</span>
        </>
      )}
      {allTransactions.length > 0 && (
        <>
          <table
            css={{
              width: '100%',
              color: colors.grey3,
              borderSpacing: '0 3px',
              'td + td + td': { textAlign: 'right' },
              'tbody td + td + td': { color: colors.black1 },
              tr: {
                height: 26,
              },
              'thead td': {
                color: colors.grey3,
                fontWeight: 'bold',
              },
              td: { padding: 0 },
              'tr:nth-of-type(even)': {
                background: colors.hexToRgba(colors.grey4, 0.6),
              },
              'tr>td:first-of-type': {
                paddingLeft: 6,
              },
              'tr>td:last-of-type': {
                paddingRight: 6,
              },
            }}
          >
            <thead>
              <tr>
                <td />
                <td />
                <td />
                <td>Balance</td>
              </tr>
            </thead>
            <tbody>
              {allTransactions
                .slice((activePage - 1) * PAGE_SIZE, activePage * PAGE_SIZE)
                .map(_row => {
                  return (
                    <tr>
                      <td>{_row.date}</td>
                      <td
                        onClick={() => metadataHandler(_row.metadata)}
                        css={
                          hasBillableItems(_row.metadata)
                            ? {
                                color: colors.orange0,
                                textDecoration: 'none',
                                ':hover': { textDecoration: 'underline' },
                                cursor: 'pointer',
                              }
                            : {}
                        }
                      >
                        {_row.description}
                      </td>
                      <td>${_row.amount}</td>
                      <td>${_row.balance}</td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
          <PaginationPanel
            activePage={activePage}
            itemsCountPerPage={PAGE_SIZE}
            totalItemsCount={allTransactions.length}
            onChange={pageNumber => setActivePage(pageNumber)}
          />
        </>
      )}
    </>
  );
}

export const Processing = connect(
  state => ({
    currentTeam: currentTeamSelector(state),
  }),
  { openDialog },
)(_Processing);

function _Processing({ currentTeam, openDialog }) {
  const [transactions, setTransactions] = React.useState();

  React.useEffect(() => {
    fetch(`/api/transactions/${currentTeam.id}/processing`)
      .then(res => res.json())
      .then(transactions => setTransactions(transactions));
  }, []);

  return (
    <>
      <>
        <p>
          <SectionHeading heading={'Processing transactions'} />
          {transactions && (
            <TransactionsSection
              transactions={transactions.processing}
              openDialog={openDialog}
            />
          )}
          {!transactions && (
            <Text>Please wait while fetching transactions...</Text>
          )}
        </p>
      </>
      {}
    </>
  );
}

export const Utilities = connect(state => ({
  currentTeam: currentTeamSelector(state),
}))(_Utilities);

function _Utilities({ currentTeam }) {
  const [transactions, setTransactions] = React.useState();
  const [billingPeriod, setBillingPeriod] = React.useState();
  const [billingPeriodOptions, setBillingPeriodOptions] = React.useState();

  function BillingPeriodSelector() {
    const changeHandler = value => {
      setBillingPeriod(value);
      updateTransactions(value);
    };

    return (
      <div css={{ width: '45%' }}>
        <FieldSet>
          <label htmlFor="billing-period">Billing period</label>
          <Select
            options={billingPeriodOptions}
            selectedValue={billingPeriod}
            onSelect={changeHandler}
          />
        </FieldSet>
      </div>
    );
  }

  function updateTransactions(billingPeriodID) {
    fetch(`/api/transactions/${currentTeam.id}/utilities/${billingPeriodID}`)
      .then(res => res.json())
      .then(transactions => setTransactions(transactions));
  }

  React.useEffect(() => {
    fetch(`/api/billing-periods?teamId=${currentTeam.id}`)
      .then(res => res.json())
      .then(({ billingPeriods }) => {
        setBillingPeriodOptions([
          ...billingPeriods.map(billingPeriod => [
            billingPeriod.id,
            new Date(billingPeriod.created_at).toLocaleString() +
              ' - ' +
              (billingPeriod.ended_at
                ? new Date(billingPeriod.ended_at).toLocaleString()
                : ''),
          ]),
        ]);

        if (billingPeriods.length > 0) {
          const currentBillingPeriodID = billingPeriods.slice(-1)[0].id;
          setBillingPeriod(currentBillingPeriodID);
          updateTransactions(currentBillingPeriodID);
        }
      });
  }, []);

  return (
    <>
      <p>
        <SectionHeading heading={'Utilities transactions'} />
      </p>
      {billingPeriod && <BillingPeriodSelector />}
      <p>
        <SectionHeading heading={'Storage'} />
        {transactions && (
          <TransactionsSection transactions={transactions.storage} />
        )}
        {billingPeriod && !transactions && (
          <Text>Please wait while fetching transactions...</Text>
        )}
        {!billingPeriod && (
          <span css={{ color: colors.grey3 }}>No transactions made.</span>
        )}
      </p>
      {transactions && (
        <>
          {((transactions.downloadCF && !transactions.downloadS3) ||
            (!transactions.downloadCF && transactions.downloadS3) ||
            (!transactions.downloadCF && !transactions.downloadS3)) && (
            <>
              <p>
                <SectionHeading heading={'Download'} />
                {(transactions.downloadCF || !transactions.downloadS3) && (
                  <TransactionsSection transactions={transactions.downloadCF} />
                )}
                {transactions.downloadS3 && (
                  <TransactionsSection transactions={transactions.downloadS3} />
                )}
              </p>
            </>
          )}
          {transactions.downloadCF && transactions.downloadS3 && (
            <>
              <p>
                <SectionHeading heading={'CloudFront download'} />
                <TransactionsSection transactions={transactions.downloadCF} />
              </p>
              <p>
                <SectionHeading heading={'S3 download'} />
                <TransactionsSection transactions={transactions.downloadS3} />
              </p>
            </>
          )}
        </>
      )}
      {!transactions && (
        <p>
          <SectionHeading heading={'Download'} />
          {billingPeriod && (
            <Text>Please wait while fetching transactions...</Text>
          )}
          {!billingPeriod && (
            <span css={{ color: colors.grey3 }}>No transactions made.</span>
          )}
        </p>
      )}
    </>
  );
}
