import { Component, createRef, cloneElement } from 'react';
import { createPortal } from 'react-dom';
import { Global, keyframes } from '@emotion/react';

import * as constants from './constants';

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

export class Modal extends Component {
  container = document.createElement('div');
  rootEl = createRef();

  componentDidMount() {
    document.body.appendChild(this.container);
    setTimeout(() => {
      this.rootEl.current.focus();
    });
  }

  componentWillUnmount() {
    document.body.removeChild(this.container);
  }

  render() {
    return createPortal(
      <>
        <Global styles={{ body: { overflow: 'hidden' } }} />
        <div
          ref={this.rootEl}
          tabIndex="0"
          onKeyDown={event =>
            event.keyCode === 27 && this.props.close && this.props.close()
          }
          onClick={event => {
            if (
              event.target === this.rootEl.current &&
              this.props.close &&
              this.props.closeOnOutsideContainerClick
            ) {
              this.props.close();
            }
          }}
          css={{
            position: 'fixed',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            zIndex: constants.zIndices.modal,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            animation: `${fadeIn} ${constants.transitionShort} ease`,
          }}
        >
          {cloneElement(this.props.children)}
        </div>
      </>,
      this.container,
    );
  }
}
