import { connect } from 'react-redux';

import {
  MainNav,
  Breadcrumbs,
  mainNavHeight,
  totalNavHeight,
} from './navigation';
import {
  userSelector,
  currentTeamSelector,
  messagesSelector,
} from './store/selectors';
import { openDialog, closeDialog } from './store/dialog';

// Expected height of content on page.
const contentHeight = 647;
export const AuthLayout = ({ children }) => (
  <>
    <MainNav />
    <div
      css={{
        position: 'absolute',
        top: mainNavHeight,
        right: 0,
        bottom: 0,
        left: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundImage: 'url(https://static.pixop.com/images/beach.jpg)',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        minHeight: 'calc(100vh - 150px)',
        overflow: 'auto',
        [`@media (max-height: ${contentHeight + mainNavHeight + 40}px)`]: {
          alignItems: 'flex-start',
          padding: '20px 0',
        },
      }}
    >
      {children}
    </div>
  </>
);

const _Welcome = ({ messages, team, user, openDialog, closeDialog }) => {
  const numberOfWelcomeMessages = messages.filter(
    message =>
      message.message_id === 'welcome' && message.message_type === 'post',
  ).length;

  if (team && team.plan_type === 'visitor' && numberOfWelcomeMessages === 0) {
    setTimeout(() => {
      openDialog({ type: 'welcome', user, closeDialog });
      // mark the welcome post as delivered
      fetch('/api/messages/welcome/post', { method: 'POST' });
    }, 2000);
  }

  return <></>;
};

const Welcome = connect(
  state => ({
    messages: messagesSelector(state),
    team: currentTeamSelector(state),
    user: userSelector(state),
  }),
  {
    openDialog,
    closeDialog,
  },
)(_Welcome);

const _MobileDevice = ({ openDialog }) => {
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  if (isMobile) {
    openDialog({ type: 'mobileDeviceDetected' });
  }

  return <></>;
};

const MobileDevice = connect(
  null,
  {
    openDialog,
  },
)(_MobileDevice);

export const MainLayout = ({ children }) => (
  <>
    <MainNav />
    <Breadcrumbs />
    <main
      css={{
        maxWidth: 960,
        margin: '0 auto',
        padding: `${totalNavHeight + 20}px 20px 20px`,
        '> div': {
          // Prevent outline around the wrapper (as it is focused) on page load.
          boxShadow: 'none !important',
        },
      }}
    >
      {children}
    </main>
    <MobileDevice />
    <Welcome />
  </>
);
