import React from 'react';

import { Heading, rem } from './typography';
import { RoundedButton } from './button';
import {
  RoundedIconButton,
  Chevron,
  Close,
  ArrowLeft,
  ThumbLike,
  ThumbDislike,
  VideoPlayer,
  ImageDiff,
  CreditCard,
  Upload,
} from './icons';
import * as colors from './colors';
import * as constants from './constants';
import { bytesInClosestUnit, bitsInClosestUnit } from './utils';
import {
  formatContainer,
  formatCodec,
  formatDetailedFrameRate,
  calculateBitrate,
  formatPixelFormatNameAndBitDepth,
  formatColorInfo,
} from './modals/processing-tool/process-video-options';
import { computeAspectRatios } from './modals/processing-tool/parse-video';
import { openSampleComparison, openOwnComparison } from './analytics';
import { fetch } from './requests';

function computeAspectRatio(targetVideo, sourceVideo) {
  const { displayWidth, displayHeight } = computeAspectRatios(
    targetVideo.metadata,
  );

  // compute aspect ratio of the right video (the target)
  return displayWidth / displayHeight;
}

function calcTopOffsetWithForcedAR(state, aspectRatio) {
  return state.height * aspectRatio < state.width
    ? 0
    : (state.height - state.width / aspectRatio) / 2;
}

function calcLeftOffsetWithForcedAR(state, aspectRatio) {
  return state.height * aspectRatio < state.width
    ? (state.width - state.height * aspectRatio) / 2
    : 0;
}

function calcWidthWithForcedAR(state, aspectRatio) {
  return state.height * aspectRatio < state.width
    ? state.height * aspectRatio
    : state.width;
}

function calcHeightWithForcedAR(state, aspectRatio) {
  return state.height * aspectRatio < state.width
    ? state.height
    : state.width / aspectRatio;
}

function ImageComparison({ imageOne, imageTwo, aspectRatio }) {
  function getSizes(event = {}) {
    const width = window.innerWidth - 40;
    const height = window.innerHeight - 100;

    let { clientX = width / 2 } = event;
    // Handle touch drag events on mobile.
    if (event.touches) {
      clientX = event.touches[0].clientX;
    }

    const margin = 30;
    const offsetLeft = 20;
    return {
      width,
      height,
      // Determine the position where the source video and indicator start.
      // Prevent sliding over Pixop / Source indicators.
      offsetLeft: Math.min(
        Math.max(margin, Math.min(width, clientX - offsetLeft)),
        width - margin - offsetLeft,
      ),
    };
  }

  const [state, setState] = React.useState({
    ...getSizes(),
  });

  const onMouseMove = React.useCallback(function(event) {
    event.persist();
    setState(state => ({ ...state, ...getSizes(event) }));
  });

  const onTouchMove = React.useCallback(function(event) {
    event.persist();
    setState(state => ({ ...state, ...getSizes(event) }));
  });

  return (
    <div
      css={{
        position: 'relative',
        display: 'flex',
        flex: 1,
        backgroundColor: colors.black0,
        overflow: 'hidden',
        height: '100%',
        cursor: 'ew-resize',
      }}
      onMouseMove={onMouseMove}
      onTouchMove={onTouchMove}
    >
      <div
        css={{
          position: 'absolute',
          width: 1,
          height: '100%',
          backgroundColor: colors.white0,
          zIndex: constants.zIndices.modal + 3,
          boxShadow: `3px 0 20px ${colors.black0}`,
        }}
        style={{
          left: state.offsetLeft,
        }}
      />
      <div
        css={{
          position: 'relative',
          display: 'flex',
          flex: 1,
          overflow: 'hidden',
        }}
        style={{
          maxWidth: state.offsetLeft,
        }}
      >
        <img
          src={imageTwo}
          css={{
            position: 'absolute',
            top: calcTopOffsetWithForcedAR(state, aspectRatio),
            right: 0,
            bottom: 0,
            left: calcLeftOffsetWithForcedAR(state, aspectRatio),
            flex: 1,
            zIndex: constants.zIndices.modal + 2,
            objectFit: 'fill',
            backgroundColor: colors.black0,
            width: calcWidthWithForcedAR(state, aspectRatio),
            height: calcHeightWithForcedAR(state, aspectRatio),
          }}
        />
      </div>
      <img
        src={imageOne}
        css={{
          position: 'absolute',
          top: calcTopOffsetWithForcedAR(state, aspectRatio),
          right: 0,
          bottom: 0,
          left: calcLeftOffsetWithForcedAR(state, aspectRatio),
          flex: 1,
          zIndex: constants.zIndices.modal + 1,
          objectFit: 'fill',
          backgroundColor: colors.black0,
          width: calcWidthWithForcedAR(state, aspectRatio),
          height: calcHeightWithForcedAR(state, aspectRatio),
        }}
      />
      <SlideIndicator
        styles={{
          position: 'relative',
          left: -20,
          top: '50%',
          zIndex: constants.zIndices.modal + 3,
        }}
      />
    </div>
  );
}

function useInterval(callback, delay) {
  const savedCallback = React.useRef();

  React.useEffect(
    () => {
      savedCallback.current = callback;
    },
    [callback],
  );

  // Set up the interval.
  React.useEffect(
    () => {
      function tick() {
        savedCallback.current();
      }
      if (delay !== null) {
        let id = setInterval(tick, delay);
        return () => clearInterval(id);
      }
    },
    [delay],
  );
}

function getSizes(event = {}) {
  const width = window.innerWidth - 40;
  const height = window.innerHeight - 100;

  let { clientX = width / 2 } = event;
  // Handle touch drag events on mobile.
  if (event.touches) {
    clientX = event.touches[0].clientX;
  }

  const margin = 30;
  const offsetLeft = 20;
  return {
    width,
    height,
    // Determine the position where the source video and indicator start.
    // Prevent sliding over Pixop / Source indicators.
    offsetLeft: Math.min(
      Math.max(margin, Math.min(width, clientX - offsetLeft)),
      width - margin - offsetLeft,
    ),
  };
}

export function VideoComparison({
  imageModeTitle,
  videoModeTitle,
  leftTitle,
  rightTitle,
  comparisonVideos,
  team,
  close,
  billing,
  frames,
  styles = {},
}) {
  const leftSideVideo = React.useRef();
  const rightSideVideo = React.useRef();

  const [state, setState] = React.useState({
    offsetLeft: 0,
    width: 0,
    height: 0,
    resyncCount: 0,
    ...getSizes(),
  });

  const onMouseMove = React.useCallback(function(event) {
    event.persist();
    setState(state => ({ ...state, ...getSizes(event) }));
  });

  const onTouchMove = React.useCallback(function(event) {
    event.persist();
    setState(state => ({ ...state, ...getSizes(event) }));
  });

  const onKeyUp = React.useCallback(function(event) {
    event.persist();

    if (leftSideVideo.current) {
      let delta;

      switch (event.key) {
        case ' ':
          onClick();
          return;
        case 'ArrowRight':
          delta = 1;
          break;
        case 'ArrowLeft':
          delta = -1;
          break;
        case 'ArrowUp':
          delta = 15;
          break;
        case 'ArrowDown':
          delta = -15;
          break;
        case 'PageUp':
          delta = 60 * 10;
          break;
        case 'PageDown':
          delta = -60 * 10;
          break;
        default:
          return;
      }

      const changePosition = delta => {
        const newTime =
          Math.min(
            leftSideVideo.current.currentTime,
            rightSideVideo.current.currentTime,
          ) + delta;

        leftSideVideo.current.currentTime = newTime;
        rightSideVideo.current.currentTime = newTime;
      };

      if (!leftSideVideo.current.paused) {
        return pauseVideos()
          .then(() => {
            changePosition(delta);

            setTimeout(() => playVideos(), 500);
          })
          .catch(() => console.error('Error pausing video in onKeyUp'));
      } else {
        changePosition(delta);
      }
    }
  });

  const [canPlayCount, setCanPlayCount] = React.useState(0);

  const onCanPlay = React.useCallback(() => {
    setCanPlayCount(canPlayCount + 1);
  });

  React.useEffect(
    () => {
      if (canPlayCount === comparisonVideos.length) {
        playVideos();
      }
    },
    [canPlayCount],
  );

  const onEnded = React.useCallback(() => {
    if (leftSideVideo.current) {
      return pauseVideos().then(() => {
        leftSideVideo.current.currentTime = -1000;
        rightSideVideo.current.currentTime = -1000;

        return setTimeout(() => playVideos(), 500);
      });
    }
  });

  const seekToSlowest = () => {
    const minTime = Math.min(
      leftSideVideo.current.currentTime,
      rightSideVideo.current.currentTime,
    );

    leftSideVideo.current.currentTime = minTime;
    rightSideVideo.current.currentTime = minTime;
  };

  const onClick = React.useCallback(() => {
    if (!leftSideVideo.current) return;

    if (leftSideVideo.current.paused) {
      playVideos();
    } else {
      pauseVideos().then(() => seekToSlowest());
    }
  });

  function loadVideos() {
    if (leftSideVideo.current && rightSideVideo.current) {
      setCanPlayCount(0);

      leftSideVideo.current.load();
      rightSideVideo.current.load();
    }
  }

  const [pausePlayPromises, setPausePlayPromises] = React.useState([]);

  const playVideos = React.useCallback(() => {
    return Promise.all(pausePlayPromises).finally(() => {
      let leftPromise = Promise.resolve();
      let rightPromise = Promise.resolve();

      if (leftSideVideo.current && rightSideVideo.current) {
        leftPromise = leftSideVideo.current.play();
        rightPromise = rightSideVideo.current.play();
      }

      const promises = [leftPromise, rightPromise];

      setPausePlayPromises(promises);

      return Promise.all(promises);
    });
  });

  const pauseVideos = React.useCallback(() => {
    return Promise.all(pausePlayPromises).finally(() => {
      const leftPromise = leftSideVideo.current.pause();
      const rightPromise = rightSideVideo.current.pause();

      const promises = [leftPromise, rightPromise];

      setPausePlayPromises(promises);

      return Promise.all(promises);
    });
  });

  let videos = comparisonVideos;
  let leftSideSrc = comparisonVideos[0];
  let rightSideSrc = comparisonVideos[1];

  if (videos.length < 2) {
    return (
      <>
        <div
          onMouseMove={onMouseMove}
          onTouchMove={onTouchMove}
          css={{
            display: 'flex',
            flexDirection: 'column',
            margin: 20,
            flex: 1,
            height: 'calc(100% - 40px)',
            background: colors.grey4,
            borderTopLeftRadius: 5,
            borderTopRightRadius: 5,
            borderBottomLeftRadius: comparison === 'video' ? 0 : 5,
            borderBottomRightRadius: comparison === 'video' ? 0 : 5,
            overflow: 'hidden',
            ...(styles || {}),
          }}
        >
          <div
            css={{
              position: 'relative',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              height: 60,
              marginLeft: 18,
              marginRight: 18,
            }}
          >
            <span />
            <Heading
              as="h4"
              css={{
                color: colors.black3,
                padding: '0 40px 0 12px',
                textAlign: 'center',
                '@media (max-width: 720px)': {
                  fontSize: rem(16),
                },
              }}
            >
              Unable to perform video comparison
            </Heading>
            <div
              css={{
                width: 122,
                display: 'flex',
                flexDirection: 'row-reverse',
              }}
            >
              <RoundedIconButton
                tabIndex="0"
                icon={Close}
                iconProps={{ color: colors.grey7 }}
                onClick={close}
                onKeyDown={event => event.keyCode === 13 && close()}
                styles={{ ':hover': { backgroundColor: colors.grey1 } }}
              />
            </div>
          </div>
          <div
            css={{
              background: colors.black0,
              height: 'calc(100% - 40px)',
              overflow: 'hidden',
              position: 'relative',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              ...(styles || {}),
            }}
          >
            <div
              css={{
                fontSize: 32,
                margin: 20,
                color: colors.orange0,
              }}
            >
              We apologize for the inconvenience, but unfortunately, we were
              unable to find one or both videos.
            </div>
          </div>
        </div>
      </>
    );
  }

  if (leftSideSrc.is_sample && rightSideSrc.is_sample) {
    // analytics
    openSampleComparison(team);
  } else {
    openOwnComparison(team);
  }

  if (typeof comparisonVideos[0] !== 'string') {
    videos = [...comparisonVideos].sort(v =>
      v.parent_id === null || v.clip_id === v.id ? -1 : 1,
    );
    leftSideSrc = videos[0].web_video_url;
    rightSideSrc = videos[1].web_video_url;
  }

  const hasVideoFile = typeof videos[0] !== 'string';

  // Check whether the videos are synchronized and attempt to synchronize
  // them on a regular interval, or until they're within an acceptable delta.
  const maxDeltaSeconds = 0.05;
  const [lastCheckMs, setLastCheckMs] = React.useState(new Date().getTime());
  const [checkIntervalMs, setCheckIntervalMs] = React.useState(500);
  const [isSyncing, setIsSyncing] = React.useState(false);

  function scaleCheckInterval(factor) {
    setCheckIntervalMs(
      Math.max(Math.min(checkIntervalMs * factor, 60000), 500),
    );
  }

  useInterval(() => {
    const currentCheckMs = new Date().getTime();

    // readyState=3: Data for the current playback position as well as for at least a little bit of time into the future is available (in other words, at least two frames of video, for example)
    // https://developer.mozilla.org/en-US/docs/Web/API/HTMLMediaElement/readyState
    if (
      !leftSideVideo.current ||
      !rightSideVideo.current ||
      leftSideVideo.current.paused ||
      rightSideVideo.current.paused ||
      leftSideVideo.current.ended ||
      rightSideVideo.current.ended ||
      leftSideVideo.current.readyState < 3 ||
      rightSideVideo.current.readyState < 3 ||
      currentCheckMs < lastCheckMs + checkIntervalMs ||
      isSyncing
    ) {
      return;
    }

    const delta =
      leftSideVideo.current.currentTime - rightSideVideo.current.currentTime;

    if (Math.abs(delta) > maxDeltaSeconds) {
      setIsSyncing(true);
      setState(state => ({
        ...state,
        resyncCount: state.resyncCount + 1,
      }));

      pauseVideos().then(() => {
        seekToSlowest();

        setTimeout(
          () =>
            playVideos().finally(() => {
              scaleCheckInterval(4);
              setLastCheckMs(new Date().getTime());
              setIsSyncing(false);
            }),
          250,
        );
      });
    } else {
      setState(state => ({
        ...state,
        resyncCount: Math.max(state.resyncCount - 1, 0),
      }));

      scaleCheckInterval(0.95);
      setLastCheckMs(currentCheckMs);
    }
  }, 250);

  const [comparison, setComparison] = React.useState('image');
  function toggleComparison() {
    setComparison(value => (value === 'video' ? 'image' : 'video'));
  }

  const [showRight, setShowRight] = React.useState(false);

  const [imageComparisonIndex, setImageComparisonIndex] = React.useState(0);

  const [leftVideoCurrentTime, setLeftVideoCurrentTime] = React.useState('');
  const [rightVideoCurrentTime, setRightVideoCurrentTime] = React.useState('');

  React.useEffect(
    () => {
      if (comparison === 'video') {
        setState(state => ({
          ...state,
          resyncCount: 0,
        }));
        setCheckIntervalMs(500);
        setLastCheckMs(new Date().getTime());
        setIsSyncing(false);

        loadVideos();

        const interval = setInterval(() => {
          const toTimeString = secs => {
            const sign = Math.sign(secs) < 0 ? '-' : '';
            const absSecs = Math.abs(secs);

            const hours = Math.floor(absSecs / 3600);
            const minutes = Math.floor(absSecs / 60) % 60;
            const seconds = Math.floor(absSecs % 60);
            const millis = Math.round((absSecs % 1) * 1000);

            return (
              sign +
              [hours, minutes, seconds]
                .map(v => v.toString().padStart(2, '0'))
                .join(':') +
              '.' +
              millis.toString().padStart(3, '0')
            );
          };

          if (leftSideVideo.current) {
            setLeftVideoCurrentTime(
              toTimeString(leftSideVideo.current.currentTime),
            );
          }
          if (rightSideVideo.current) {
            setRightVideoCurrentTime(
              toTimeString(rightSideVideo.current.currentTime),
            );
          }
        }, 100);
        return () => {
          clearInterval(interval);
        };
      }
    },
    [comparison],
  );

  const [comparisonImages, setComparisonImages] = React.useState(frames);

  React.useEffect(() => {
    function fetchVideoFrames({ sourceId, targetId }) {
      return fetch(
        `/api/uploads/frame-urls?sourceId=${sourceId}&targetId=${targetId}`,
      ).then(res => res.json());
    }

    if (!comparisonImages) {
      fetchVideoFrames({
        sourceId: videos[0].id,
        targetId: videos[1].id,
      }).then(results => setComparisonImages(results));
    }
  }, []);

  const [isLiked, setIsLiked] = React.useState(false);
  const [isDisliked, setIsDisliked] = React.useState(false);

  function rateVideo(ratePathSegment, rating) {
    setIsLiked(rating > 5);
    setIsDisliked(rating < 5);

    return fetch(`/api/${ratePathSegment}`, {
      method: 'POST',
      body: { rating },
    })
      .then(res => res.json())
      .then(response => {
        if (response.error) {
          console.error(response.error);
          return;
        }
      })
      .catch(error => {
        console.error(response.error);
        throw error;
      });
  }

  const [isHidden, setIsHidden] = React.useState(false);

  const aspectRatio = computeAspectRatio(videos[1], videos[0]);

  return (
    <div
      onMouseMove={onMouseMove}
      onTouchMove={onTouchMove}
      onKeyUp={onKeyUp}
      tabIndex={0}
      css={{
        display: 'flex',
        flexDirection: 'column',
        margin: 20,
        flex: 1,
        height: 'calc(100% - 40px)',
        background: colors.grey4,
        borderTopLeftRadius: 5,
        borderTopRightRadius: 5,
        borderBottomLeftRadius: comparison === 'video' ? 0 : 5,
        borderBottomRightRadius: comparison === 'video' ? 0 : 5,
        overflow: 'hidden',
        ...(styles || {}),
      }}
    >
      <div
        css={{
          position: 'relative',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          height: 60,
          marginLeft: 18,
          marginRight: 18,
        }}
      >
        <span
          css={{
            width: team.plan_type === 'visitor' ? 500 : 200,
            alignItems: 'center',
            whiteSpace: 'nowrap',
          }}
        >
          <RoundedButton
            css={{ position: 'relative' }}
            onClick={toggleComparison}
          >
            {comparison === 'video' ? (
              <ImageDiff
                color={colors.orange0}
                width={18}
                height={18}
                style={{
                  position: 'absolute',
                  top: 5,
                  left: 13,
                }}
              />
            ) : (
              <VideoPlayer
                color={colors.orange0}
                width={20}
                height={19}
                style={{
                  position: 'absolute',
                  top: 4,
                  left: 13,
                }}
              />
            )}

            <span
              css={{
                marginLeft: 23,
                color: colors.black0,
              }}
            >
              {comparison === 'video' ? 'Image comparison' : 'Video comparison'}
            </span>
          </RoundedButton>
        </span>
        <Heading
          as="h4"
          css={{
            color: colors.black3,
            padding: '0 40px 0 12px',
            whiteSpace: 'nowrap',
            textAlign: 'center',
            '@media (max-width: 720px)': {
              fontSize: rem(16),
            },
          }}
        >
          {comparison === 'video' ? videoModeTitle : imageModeTitle}
        </Heading>
        <span
          css={{
            display: 'flex',
            flexDirection: 'row-reverse',
            width: team.plan_type === 'visitor' ? 500 : 200,
          }}
        >
          {team.plan_type === 'visitor' &&
            videos[1].master_processing_cost_usd && (
              <div
                css={{
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                }}
              >
                <RoundedButton
                  css={{ position: 'relative' }}
                  onClick={() => {
                    rateVideo(videos[1].rate_path_segment, 10);
                  }}
                >
                  <div
                    css={{
                      '.info': {
                        visibility: 'hidden',
                      },
                      ':hover .info': {
                        visibility: 'visible',
                      },
                    }}
                  >
                    <ThumbLike
                      color={isLiked ? colors.green0 : colors.orange1}
                      style={{
                        position: 'absolute',
                        top: 4,
                        left: 7,
                      }}
                    />
                    <span
                      css={{
                        marginLeft: 1,
                      }}
                    />
                    <div
                      className="info"
                      css={{
                        display: 'block',
                        position: 'absolute',
                        backgroundColor: colors.white0,
                        borderRadius: 3,
                        boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.05)',
                        padding: '8px 8px',
                        bottom: -40,
                        left: -37,
                        width: 90,
                        border: `1px solid ${colors.grey3}`,
                        zIndex: 100000,
                        color: colors.black0,
                      }}
                    >
                      I <b>like</b> the result
                    </div>
                  </div>
                </RoundedButton>

                <RoundedButton
                  css={{
                    marginLeft: 2,
                    position: 'relative',
                  }}
                  onClick={() => {
                    rateVideo(videos[1].rate_path_segment, 1);
                  }}
                >
                  <div
                    css={{
                      '.info': {
                        visibility: 'hidden',
                      },
                      ':hover .info': {
                        visibility: 'visible',
                      },
                    }}
                  >
                    <ThumbDislike
                      color={isDisliked ? colors.red0 : colors.orange1}
                      style={{
                        position: 'absolute',
                        top: 4,
                        left: 7,
                      }}
                    />
                    <span
                      css={{
                        marginLeft: 1,
                      }}
                    />
                    <div
                      className="info"
                      css={{
                        display: 'block',
                        position: 'absolute',
                        backgroundColor: colors.white0,
                        borderRadius: 3,
                        boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.05)',
                        padding: '8px 8px',
                        bottom: -40,
                        left: -47,
                        width: 110,
                        border: `1px solid ${colors.grey3}`,
                        zIndex: 100000,
                        color: colors.black0,
                      }}
                    >
                      I <b>dislike</b> the result
                    </div>
                  </div>
                </RoundedButton>

                {hasVideoFile && (
                  <>
                    {' '}
                    <RoundedButton
                      css={{
                        marginLeft: 5,
                        position: 'relative',
                        color: colors.black0,
                      }}
                      onClick={() => billing()}
                    >
                      <CreditCard
                        color={colors.orange0}
                        width={18}
                        height={18}
                        style={{
                          position: 'absolute',
                          top: 5,
                          left: 13,
                        }}
                      />

                      <span
                        css={{
                          marginLeft: 23,
                          color: colors.black0,
                        }}
                      >
                        Buy full master for{' '}
                        <b>${videos[1].master_processing_cost_usd}</b>
                      </span>
                    </RoundedButton>
                    <RoundedButton
                      css={{
                        marginLeft: 5,
                        position: 'relative',
                        color: colors.black0,
                      }}
                      onClick={() => billing()}
                    >
                      <Upload
                        color={colors.orange0}
                        width={18}
                        height={18}
                        style={{
                          position: 'absolute',
                          top: 5,
                          left: 13,
                        }}
                      />

                      <span
                        css={{
                          marginLeft: 23,
                          color: colors.black0,
                        }}
                      >
                        Upload new video
                      </span>
                    </RoundedButton>
                  </>
                )}
              </div>
            )}
          {(team.plan_type !== 'visitor' ||
            !videos[1].master_processing_cost_usd) && (
            <div>
              <RoundedIconButton
                tabIndex="0"
                icon={Close}
                iconProps={{ color: colors.grey7 }}
                onClick={close}
                onKeyDown={event => event.keyCode === 13 && close()}
                styles={{ ':hover': { backgroundColor: colors.grey1 } }}
              />
            </div>
          )}
        </span>
      </div>
      {comparison === 'video' ? (
        <div
          onClick={onClick}
          css={{
            position: 'relative',
            display: 'flex',
            flex: 1,
            backgroundColor: colors.black0,
            overflow: 'hidden',
            cursor: 'ew-resize',
          }}
        >
          <div
            css={{
              position: 'absolute',
              width: 1,
              height: '100%',
              backgroundColor: colors.white0,
              zIndex: constants.zIndices.modal + 3,
              boxShadow: `3px 0 20px ${colors.black0}`,
            }}
            style={{
              left: state.offsetLeft,
            }}
          />
          <div
            css={{
              position: 'absolute',
              zIndex: constants.zIndices.modal + 3,
              marginTop: 25,
              marginLeft: 30,
            }}
          >
            <RoundedButton
              css={{
                color: colors.orange0,
                backgroundColor: colors.black0,
                borderColor: colors.black0,
                letterSpacing: '0.08em',
                ':hover': {
                  borderColor: colors.black0,
                },
              }}
            >
              {leftTitle || (hasVideoFile ? videos[0].name : 'Video #1')}
            </RoundedButton>
            {hasVideoFile && (
              <div
                css={{
                  color: colors.orange1,
                  backgroundColor: colors.black0,
                  minHeight: 20,
                  padding: '5px 15px',
                  marginTop: 6,
                  display: 'flex',
                  alignItems: 'center',
                  borderRadius: 15,
                  maxWidth: '40vw',
                }}
              >
                {displayVideoInfo(videos[0])}
              </div>
            )}
          </div>
          <div
            css={{
              position: 'absolute',
              zIndex: constants.zIndices.modal + 3,
              marginLeft: 40,
              bottom: state.resyncCount >= 2 ? 92 : 40,
            }}
          >
            <div
              css={{
                color: colors.white1,
                backgroundColor: colors.hexToRgba(colors.black0, 0.5),
                padding: '5px 5px',
                marginTop: 6,
                display: 'flex',
                alignItems: 'center',
                borderRadius: 15,
                fontSize: 11,
                fontFamily: 'monospace',
              }}
            >
              {leftVideoCurrentTime}
            </div>
          </div>

          <div
            css={{
              position: 'relative',
              display: 'flex',
              flex: 1,
              overflow: 'hidden',
            }}
            style={{
              maxWidth: state.offsetLeft,
            }}
          >
            <Video
              src={leftSideSrc}
              innerRef={leftSideVideo}
              styles={{
                position: 'absolute',
                top: calcTopOffsetWithForcedAR(state, aspectRatio),
                right: 0,
                bottom: 0,
                left: calcLeftOffsetWithForcedAR(state, aspectRatio),
                flex: 1,
                zIndex: constants.zIndices.modal + 2,
                objectFit: 'fill',
                backgroundColor: colors.black0,
              }}
              style={{
                width: calcWidthWithForcedAR(state, aspectRatio),
                height: calcHeightWithForcedAR(state, aspectRatio),
              }}
              onCanPlay={onCanPlay}
              onEnded={onEnded}
              muted
            />
          </div>
          <Video
            src={rightSideSrc}
            innerRef={rightSideVideo}
            styles={{
              position: 'absolute',
              top: calcTopOffsetWithForcedAR(state, aspectRatio),
              right: 0,
              bottom: 0,
              left: calcLeftOffsetWithForcedAR(state, aspectRatio),
              flex: 1,
              zIndex: constants.zIndices.modal + 1,
              objectFit: 'fill',
              backgroundColor: colors.black0,
            }}
            style={{
              width: calcWidthWithForcedAR(state, aspectRatio),
              height: calcHeightWithForcedAR(state, aspectRatio),
            }}
            onCanPlay={onCanPlay}
            onEnded={onEnded}
            muted
          />
          <SlideIndicator
            styles={{
              position: 'relative',
              left: -20,
              top: '50%',
              zIndex: constants.zIndices.modal + 3,
            }}
          />
          <div
            css={{
              position: 'absolute',
              right: 0,
              zIndex: constants.zIndices.modal + 3,
              marginTop: 25,
              marginRight: 25,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-end',
            }}
          >
            <RoundedButton
              css={{
                display: 'inline-block',
                alignSelf: 'flex-end',
                color: colors.orange0,
                backgroundColor: colors.black0,
                borderColor: colors.black0,
                letterSpacing: '0.08em',
                ':hover': {
                  borderColor: colors.black0,
                },
              }}
            >
              {rightTitle || (hasVideoFile ? videos[1].name : 'Video #2')}
            </RoundedButton>
            {hasVideoFile && (
              <div
                css={{
                  display: 'inline-block',
                  alignSelf: 'flex-end',
                  color: colors.orange1,
                  backgroundColor: colors.black0,
                  minHeight: 20,
                  padding: '5px 15px',
                  marginTop: 6,
                  display: 'flex',
                  alignItems: 'center',
                  borderRadius: 15,
                  maxWidth: '40vw',
                }}
              >
                {displayVideoInfo(videos[1])}
              </div>
            )}
          </div>
          <div
            css={{
              position: 'absolute',
              right: 0,
              zIndex: constants.zIndices.modal + 3,
              marginRight: 40,
              bottom: state.resyncCount >= 2 ? 92 : 40,
            }}
          >
            <div
              css={{
                color: colors.white1,
                backgroundColor: colors.hexToRgba(colors.black0, 0.5),
                padding: '5px 5px',
                marginTop: 6,
                display: 'flex',
                alignItems: 'center',
                borderRadius: 15,
                fontSize: 11,
                fontFamily: 'monospace',
              }}
            >
              {rightVideoCurrentTime}
            </div>
          </div>
          <div
            css={{
              position: 'absolute',
              bottom: 0,
              left: 0,
              right: 0,
              height: 3,
              zIndex: constants.zIndices.modal + 4,
              backgroundColor: colors.black0,
            }}
          >
            <div
              css={{
                backgroundColor: colors.orange0,
                height: 3,
                transition: 'width .50s ease',
              }}
              style={{
                width: `${state.progress * 100}%`,
              }}
            />
          </div>
          {state.resyncCount >= 2 && (
            <div
              css={{
                zIndex: constants.zIndices.modal + 4,
                position: 'absolute',
                bottom: 3,
                left: 0,
                right: 0,
                minHeight: 60,
                backgroundColor: colors.black0,
                color: colors.white0,
                opacity: 0.75,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                fontSize: rem(16),
                padding: 12,
              }}
            >
              It appears that there may be a problem synchronizing the videos.
              Please try a faster connection for the best results, or wait until
              both are fully buffered. Alternatively, compare the two paused
              frames of the videos.
            </div>
          )}
        </div>
      ) : (
        <div
          css={{
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
            backgroundColor: colors.grey11,
          }}
        >
          <div
            css={{
              flex: 1,
              display: 'flex',
              backgroundColor: '#000',
              position: 'relative',
            }}
          >
            <RoundedButton
              css={{
                position: 'absolute',
                top: 25,
                left: 30,
                zIndex: 100000,
                color: colors.orange0,
                backgroundColor: colors.black0,
                borderColor: colors.black0,
                letterSpacing: '0.08em',
                ':hover': {
                  borderColor: colors.black0,
                },
              }}
            >
              {leftTitle || (hasVideoFile ? videos[0].name : 'Video #1')}
            </RoundedButton>
            {hasVideoFile && (
              <div
                css={{
                  position: 'absolute',
                  top: 55,
                  left: 30,
                  zIndex: 100000,
                  color: colors.orange1,
                  backgroundColor: colors.black0,
                  minHeight: 20,
                  padding: '5px 15px',
                  marginTop: 6,
                  display: 'flex',
                  alignItems: 'center',
                  borderRadius: 15,
                  maxWidth: '40vw',
                }}
              >
                {displayVideoInfo(videos[0])}
              </div>
            )}
            <RoundedButton
              css={{
                position: 'absolute',
                top: 25,
                right: 25,
                zIndex: 100000,
                color: colors.orange0,
                backgroundColor: colors.black0,
                borderColor: colors.black0,
                letterSpacing: '0.08em',
                ':hover': {
                  borderColor: colors.black0,
                },
              }}
            >
              {rightTitle || (hasVideoFile ? videos[1].name : 'Video #2')}
            </RoundedButton>
            {hasVideoFile && (
              <div
                css={{
                  position: 'absolute',
                  top: 55,
                  right: 25,
                  zIndex: 100000,
                  color: colors.orange1,
                  backgroundColor: colors.black0,
                  minHeight: 20,
                  padding: '5px 15px',
                  marginTop: 6,
                  display: 'flex',
                  alignItems: 'center',
                  borderRadius: 15,
                  maxWidth: '40vw',
                }}
              >
                {displayVideoInfo(videos[1])}
              </div>
            )}
            {comparisonImages && (
              <ImageComparison
                imageOne={comparisonImages.target[imageComparisonIndex]}
                imageTwo={comparisonImages.source[imageComparisonIndex]}
                aspectRatio={computeAspectRatio(videos[1], videos[0])}
              />
            )}
          </div>
          <div
            css={{
              position: 'absolute',
              zIndex: 10000,
              bottom: 20,
              left: 20,
              right: 20,
              height: isHidden ? 50 : 148,
              backgroundColor: 'rgba(0,0,0,.5)',
              maxWidth: 'calc(100vw - 40px)',
              overflow: 'hidden',
              borderBottomLeftRadius: 5,
              borderBottomRightRadius: 5,
            }}
          >
            <div css={{ display: 'flex', justifyContent: 'space-between' }}>
              <div css={{ color: '#fff', marginTop: 16, marginLeft: 20 }}>
                Image comparison
              </div>
              <div css={{ display: 'flex', marginRight: 20, marginTop: 10 }}>
                <span
                  css={{
                    color: colors.orange0,
                    marginRight: 20,
                    marginTop: 8,
                    cursor: 'pointer',
                  }}
                  onClick={() => setIsHidden(!isHidden)}
                >
                  {isHidden ? 'Show' : 'Hide'}
                </span>
                <RoundedIconButton
                  icon={ArrowLeft}
                  onClick={() => setShowRight(false)}
                  iconProps={{
                    color: showRight ? colors.orange0 : 'rgba(245,171,53,0.3)',
                    disabled: showRight,
                  }}
                  styles={{
                    cursor: showRight ? 'pointer' : 'default',
                    backgroundColor: '#fff',
                    marginRight: 8,
                    width: 30,
                    height: 30,
                    ':hover': {
                      backgroundColor: '#fff',
                    },
                  }}
                />
                <RoundedIconButton
                  icon={ArrowLeft}
                  onClick={() => setShowRight(true)}
                  iconProps={{
                    color: showRight ? 'rgba(245,171,53,0.3)' : colors.orange0,
                    disabled: !showRight,
                    styles: {
                      transform: 'rotate(180deg)',
                    },
                  }}
                  styles={{
                    cursor: showRight ? 'default' : 'pointer',
                    backgroundColor: '#fff',
                    width: 30,
                    height: 30,
                    ':hover': {
                      backgroundColor: '#fff',
                    },
                  }}
                />
              </div>
            </div>
            {!isHidden && (
              <div
                css={{
                  marginTop: 14,
                  marginLeft: 20,
                  width: 3000,
                  transition: 'transform .36s ease',
                  transform: showRight
                    ? 'translateX(-1320px)'
                    : 'translateX(0)',
                }}
              >
                {comparisonImages &&
                  Array.isArray(comparisonImages.thumbs) &&
                  comparisonImages.thumbs.map((frame, index) => (
                    <img
                      key={index.toString()}
                      onClick={() => setImageComparisonIndex(index)}
                      src={frame}
                      css={{
                        boxSizing: 'border-box',
                        height: 75,
                        width: 112,
                        border: 'none',
                        marginRight: 20,
                        borderRadius: 3,
                        border:
                          index === imageComparisonIndex
                            ? `2px solid ${colors.orange0}`
                            : 'none',
                        cursor: 'pointer',
                        transition: 'filter .36s ease, border .18s ease',
                        filter: 'brightness(90%)',
                        ':hover': {
                          filter: 'brightness(110%)',
                        },
                      }}
                    />
                  ))}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export function Video({ src, innerRef, styles = {}, ...rest }) {
  return (
    <video
      ref={innerRef}
      css={{
        objectFit: 'fill',
        ...styles,
      }}
      playsInline
      {...rest}
    >
      <source src={src} type="video/mp4" />
    </video>
  );
}

export function displayVideoInfo(video) {
  const resolution = `${video.metadata.frameWidth}x${
    video.metadata.frameHeight
  }`;
  const frameRate = formatDetailedFrameRate(video);
  const container = formatContainer(video);
  const codec = formatCodec(video);
  const chromaSubsampling = formatPixelFormatNameAndBitDepth(
    video.metadata.pixelFormatName,
  );
  const colorInfo = video.metadata.colorSpaceName
    ? formatColorInfo(video)
    : null;
  const bitrate = `${bitsInClosestUnit(calculateBitrate(video))}ps`;
  const size = bytesInClosestUnit(video.metadata.size);

  return [
    resolution,
    frameRate,
    container,
    codec,
    chromaSubsampling,
    colorInfo,
    bitrate,
    size,
  ]
    .filter(v => v)
    .join(', ');
}

function SlideIndicator({ styles = {} }) {
  return (
    <div
      css={{
        width: 41,
        height: 41,
        backgroundColor: colors.white0,
        boxShadow: '0 0 20px rgba(0, 0, 0, 0.25)',
        borderRadius: '50%',
        ...styles,
      }}
    >
      <Chevron
        color={colors.orange0}
        css={{
          height: 30,
          position: 'absolute',
          top: 6,
          left: 0,
          transform: 'rotate(90deg)',
          transformOrigin: 'center',
        }}
      />
      <Chevron
        color={colors.orange0}
        css={{
          height: 30,
          position: 'absolute',
          top: 6,
          right: 0,
          transform: 'rotate(-90deg)',
          transformOrigin: 'center',
        }}
      />
    </div>
  );
}
