import useReactRouter from 'use-react-router';

import { Dialog } from '../dialog';
import * as colors from '../colors';
import { SquareButton } from '../button';

export function Welcome({ user, closeDialog }) {
  const { history } = useReactRouter();

  return (
    <Dialog
      heading="Welcome"
      closeOnOutsideContainerClick
      isOpen
      containerStyles={{
        width: 540,
        fontSize: '15px',
        color: colors.grey7,
        lineHeight: 1.4,
      }}
    >
      <p>
        Hi <b>{user.name}</b>,
      </p>

      <p>Welcome to Pixop! 🤝</p>

      <p>
        Take a look around for up to 14 days to get familiar with our interface.
      </p>

      <p>
        To give you an idea of what our filters are capable of, we've prepared a
        number of sample videos showcasing before and after results.
      </p>

      <p>
        Once you are ready to begin uploading/downloading videos, creating clips
        and processing, add your billing information and purchase processing
        credits.
      </p>

      <p>
        <b>
          Remember: We're offering a signup bonus of 25% off all purchases for
          your first month. Just remember to add your payment information and
          buy processing credits within 7 days to redeem it!
        </b>
      </p>

      <p
        style={{
          textAlign: 'center',
          marginTop: 20,
          marginBottom: 30,
        }}
      >
        <SquareButton
          css={{ fontSize: '16px' }}
          onClick={() => {
            history.push('/billing');
            closeDialog();
          }}
        >
          Redeem Bonus
        </SquareButton>
      </p>

      <p>Any questions? Happy to help!</p>
    </Dialog>
  );
}
