import { Progress } from './progress';
import { bytesInClosestUnit } from './utils';
import { rem } from './typography';
import * as colors from './colors';

// Ordered by precedence, i.e., if a video is in an error state it takes
// precedence over any of the other states when aggregating states across
// multiple videos. If a video is being ingested, it only takes precedence
export const videoStates = {
  error: 0,
  uploading: 1,
  ingesting: 2,
  processing: 3,
  low_quality: 4,
  finished: 5,
  none: 6,
};

export function getVideoState({
  parent_id,
  upload_state,
  ingestion_state,
  quality_assessment,
  processing_state,
}) {
  const uploadStatus = upload_state && upload_state.uploadStatus;
  const ingestionStatus = ingestion_state && ingestion_state.ingestionStatus;
  const processingStatus =
    processing_state && processing_state.processingStatus;
  if (
    processingStatus === 'ERROR' ||
    ingestionStatus === 'ERROR' ||
    uploadStatus === 'ERROR'
  ) {
    return videoStates.error;
  }
  if (processingStatus && processingStatus !== 'DONE') {
    return videoStates.processing;
  }
  if (ingestionStatus === 'DONE') {
    // check whether overall quality analysis score on an original video is too low
    const isLowQuality =
      !parent_id &&
      quality_assessment.overallSubjectiveScore &&
      quality_assessment.overallSubjectiveScore < 3.5;

    return !isLowQuality ? videoStates.finished : videoStates.low_quality;
  }
  if (
    ingestionStatus ||
    uploadStatus === 'DONE' ||
    processingStatus === 'DONE'
  ) {
    return videoStates.ingesting;
  }
  if (uploadStatus) {
    return videoStates.uploading;
  }
  return videoStates.none;
}

// This function is currently used both to compute the derived "status" for
// individual videos, based on the various upload, ingestion and processing
// attributes, and to aggregate multiple results into one. Instead, it would
// be most sensible to split this into two different functions.
export function aggregateVideos(videos) {
  let size = 0;
  let state = videoStates.none;
  const uploading = [];
  const ingesting = [];
  const processing = [];
  const thumbnails = [];
  const completed = [];
  const completedClips = [];
  let isUploading = false;

  const withError = [];

  videos.forEach(video => {
    size += parseInt(video.file_size_bytes || video.metadata.size || 0, 10);
    let videoState = getVideoState(video);

    // override accumulated "low quality" state if the original has one or more completed processed videos (ignoring clip parents)
    if (videoState === videoStates.low_quality) {
      const hasCompletedDerivedVideo = videos.find(childVideo => {
        return (
          childVideo.parent_id === video.id &&
          childVideo.clip_id !== childVideo.id &&
          getVideoState(childVideo) === videoStates.finished
        );
      });

      if (hasCompletedDerivedVideo) {
        videoState = videoStates.finished;
      }
    }

    if (videoState < state) {
      state = videoState;
    }
    if (videoState === videoStates.error) {
      withError.push(video.id);
    }

    if (videoState === videoStates.uploading) {
      if (video.upload) {
        // Mark the video as being uploaded by this user. This object is only
        // set on videos when an upload is initiated.
        isUploading = true;
      }
      uploading.push(video);
    } else if (videoState === videoStates.ingesting) {
      ingesting.push(video);
    } else if (videoState === videoStates.processing) {
      processing.push(video);
    } else {
      completed.push(video);

      if (video.clip_id && video.clip_id === video.id) {
        completedClips.push(video);
      }
    }
  });

  let progress = 0;
  let statusText;

  if (state === videoStates.uploading) {
    progress =
      uploading.reduce((sum, v) => sum + (v.upload_state.progress || 0), 0) /
      uploading.length;
  }

  if (state === videoStates.ingesting) {
    let activeIngesting = 0;

    ingesting.forEach(ingestingVideo => {
      if (ingestingVideo.thumbnail_urls && ingestingVideo.parent_id == null) {
        thumbnails.push(ingestingVideo.thumbnail_urls);
      }

      const ingestionState = ingestingVideo.ingestion_state;
      const stage = ingestionState.ingestionStatus;

      if (!stage) {
        statusText = 'Initializing…';
      } else if (stage === 'INITIAL') {
        statusText = 'Enqueuing…';
      } else if (stage === 'ENQUEUED') {
        statusText = 'In queue';
      } else if (stage === 'PROBING') {
        statusText = 'Probing…';
      } else if (stage === 'PROCESSING') {
        activeIngesting++;

        if (ingestionState.processingProgressPercent != null) {
          progress += ingestionState.processingProgressPercent / 100;
        }
      }
    });

    if (activeIngesting) {
      statusText = null;
      progress /= activeIngesting;
    }
  }

  if (state === videoStates.processing) {
    let activeProcessing = 0;

    processing.forEach(processingVideo => {
      const processingState = processingVideo.processing_state;
      const stage = processingState.processingStatus;

      if (!stage) {
        statusText = 'Initializing…';
      } else if (stage === 'INITIAL') {
        statusText = 'Enqueuing…';
      } else if (stage === 'ENQUEUED') {
        statusText = 'In queue';
      } else if (stage === 'PROBING') {
        statusText = 'Probing…';
      } else if (stage === 'PROCESSING') {
        activeProcessing++;

        if (processingState.processingProgressPercent != null) {
          progress += processingState.processingProgressPercent / 100;
        }
      }
    });

    if (activeProcessing) {
      statusText = null;
      progress /= activeProcessing;
    }
  }

  completed.forEach(video => {
    if (video.thumbnail_urls && video.parent_id == null) {
      thumbnails.push(video.thumbnail_urls);
    }
  });

  let label;
  let value;
  let color;
  let isActive = false;

  if (state === videoStates.finished) {
    value = 'Finished';
    color = colors.green0;
    isActive = false;
  } else if (state === videoStates.low_quality) {
    label = 'View log';
    value = 'Low quality';
    color = colors.orange1;
    isActive = false;
  } else if (state === videoStates.error) {
    label = 'View log';
    value = 'Failed';
    color = colors.red0;
    isActive = false;
  } else if (state === videoStates.processing) {
    label = 'Processing';
    value = statusText || progress;
    isActive = true;
  } else if (state === videoStates.uploading) {
    if (isUploading) {
      label = 'Uploading';
      isActive = true;
    } else {
      label = 'Paused';
      color = colors.grey1;
      isActive = false;
    }
    value = statusText || progress;
  } else if (state === videoStates.ingesting) {
    label = 'Ingesting';
    value = statusText || progress;
    isActive = true;
  }

  const numIngesting = ingesting.length;
  const numUploading = uploading.length;
  const numProcessing = processing.length;
  // total number of videos with activity
  const numTotal = isActive ? numIngesting + numUploading + numProcessing : 0;
  const numCompleted = completed.length;
  const numCompletedClips = completedClips.length;

  return {
    isUploading,
    state,
    progress,
    statusText,
    numIngesting,
    numUploading,
    numProcessing,
    numTotal,
    numCompleted,
    numCompletedClips,
    label,
    value,
    color,
    isActive,
    thumbnails,
    withError,
    size: bytesInClosestUnit(size),
  };
}

const ProgressWithLabel = ({
  label,
  value,
  color,
  count,
  numTotal,
  isActive,
}) => (
  <div
    css={{
      flex: 1,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      margin: '0 10px',
    }}
  >
    <span
      css={{
        flex: 1,
        fontSize: rem(12),
        color: colors.black2,
        marginRight: 10,
        letterSpacing: '0.03em',
      }}
    >
      {numTotal > 1 ? `${label} (${count} of ${numTotal} videos)` : label}
    </span>
    <Progress value={value} color={color} isActive={isActive} />
  </div>
);

export const VideoStatus = ({ aggregatedVideos, video, videos }) => {
  const {
    state,
    numUploading,
    numIngesting,
    numProcessing,
    numTotal,
    value,
    label,
    color,
    isActive,
  } = aggregatedVideos || aggregateVideos(videos || [video]);
  const count =
    state === videoStates.uploading
      ? numUploading
      : state === videoStates.ingesting
      ? numIngesting
      : state === videoStates.processing
      ? numProcessing
      : 0;
  return state === videoStates.none ? null : (
    <ProgressWithLabel
      value={value}
      label={label}
      color={color}
      isActive={isActive}
      count={count}
      numTotal={numTotal}
    />
  );
};
