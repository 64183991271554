import * as colors from './colors';
import * as constants from './constants';

export function ResolutionInfo({ sourceResolution, currentResolution }) {
  const widthFactor = parseFloat(
    (currentResolution.width / sourceResolution.width).toFixed(2),
  );
  const heightFactor = parseFloat(
    (currentResolution.height / sourceResolution.height).toFixed(2),
  );

  let width = 290;
  let height =
    heightFactor > 1
      ? width / (currentResolution.width / currentResolution.height)
      : width / (sourceResolution.width / sourceResolution.height);

  if (height > 290) {
    width /= height / 290;
    height = 290;
  }

  return (
    <div
      css={{
        position: 'absolute',
        top: -height - 40,
        backgroundColor: colors.white0,
        borderRadius: 3,
        boxShadow: '0 3px 20px rgba(0,0,0,.20)',
        padding: 12,
        color: colors.grey3,
        fontSize: '1rem',
        zIndex: constants.zIndices.modal + 1,
      }}
    >
      <div
        css={{
          position: 'relative',
          marginTop: 6,
          width,
          height,
          backgroundColor: heightFactor > 1 ? colors.orange0 : colors.grey3,
          fontSize: 12,
        }}
      >
        {heightFactor > 1 ? (
          <>
            <span css={{ color: colors.white0, paddingTop: 6, paddingLeft: 6 }}>
              Scaled ({widthFactor} x {heightFactor})
            </span>
            <div
              css={{
                position: 'absolute',
                left: 0,
                bottom: 0,
                backgroundColor: colors.grey3,
                width: Math.min(width, width / widthFactor),
                height: Math.min(height, height / heightFactor),
              }}
            >
              <span
                css={{ color: colors.white0, paddingTop: 6, paddingLeft: 6 }}
              >
                Original
              </span>
            </div>
          </>
        ) : (
          <>
            <span css={{ color: colors.white0, paddingTop: 6, paddingLeft: 6 }}>
              Original
            </span>
            <div
              css={{
                position: 'absolute',
                left: 0,
                bottom: 0,
                backgroundColor: colors.orange0,
                width: Math.min(width, width * widthFactor),
                height: Math.min(height, height * heightFactor),
              }}
            >
              <span
                css={{ color: colors.white0, paddingTop: 6, paddingLeft: 6 }}
              >
                Scaled ({widthFactor} x {heightFactor})
              </span>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
