import { idleCallback } from '../utils';

export function intercomMiddleware(store) {
  const { env } = store.getState();

  if (env.INTERCOM_APP_ID) {
    // Install Intercom when the page is idle
    idleCallback(() => {
      const snippet = getSnippet(env.INTERCOM_APP_ID);
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.innerHTML = snippet;
      document.body.appendChild(script);
      window.Intercom('boot', { app_id: env.INTERCOM_APP_ID });
    });
  }

  return next => action => {
    if (env.INTERCOM_APP_ID && action.type === 'reset') {
      const currentState = store.getState();
      next(action);
      const nextState = store.getState();

      if (!currentState.user && nextState.user) {
        // User logged in, update Intercom
        const { user } = nextState;

        window.Intercom('update', {
          user_id: user.id,
          user_hash: user.intercomHash,
        });
      } else if (currentState.user && !nextState.user) {
        setTimeout(() => {
          // User logged out, create a new session
          window.Intercom('shutdown');
          window.Intercom('boot', { app_id: env.INTERCOM_APP_ID });
        }, 100);
      }
      return nextState;
    }
    return next(action);
  };
}

function getSnippet(appId) {
  return `(function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/${appId}';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};if(document.readyState==="complete"){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();`;
}
