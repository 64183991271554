import { Component, createRef } from 'react';
import { connect } from 'react-redux';

import * as colors from '../colors';
import { Dropzone } from '../dropzone';
import { Check, Close, Edit } from '../icons';
import { rem } from '../typography';
import { RoundedButton } from '../button';
import { Input } from '../forms';
import { ContextMenu } from '../context-menu';
import { fetch } from '../requests';
import { VideoStatus, aggregateVideos, videoStates } from '../video-status';
import { Filter } from '../filter';
import { Badge } from '../badge';
import { MultiThumbnail } from '../multi-thumbnail';
import { TeamMembers } from '../team-members';
import { teamProjectsSelector, teamVideosSelector } from '../store/selectors';
import { openDialog } from '../store/dialog';
import { updateProject } from '../store/projects';
import { cancelUpload } from '../store/uploads';
import { addToast } from '../store/toasts';

const dateFormatter = new Intl.DateTimeFormat('en-US', {
  day: 'numeric',
  year: 'numeric',
  month: 'long',
});
const formatDate = date => dateFormatter.format(date);

class _EditableField extends Component {
  input = createRef();

  state = {
    value: this.props.value,
    persistedValue: this.props.value,
    isEditable: false,
    isHovering: false,
  };

  onChange = event => {
    event.stopPropagation();
    this.setState({ value: event.target.value });
  };

  onClick = event => {
    event.stopPropagation();
    this.setState({ isEditable: true });
  };

  onClose = event => {
    event.stopPropagation();
    this.setState({ isEditable: false, value: this.state.persistedValue });
  };

  onSave = event => {
    event.stopPropagation();
    if (this.state.value === this.state.persistedValue) return null;

    fetch(`/api/projects/${this.props.projectId}`, {
      method: 'PATCH',
      body: { teamId: this.props.teamId, name: this.state.value.slice(0, 48) },
    })
      .then(res => res.json())
      .then(result => {
        if (result.error) {
          this.props.addToast({
            text: result.error,
          });
          return;
        }

        if (result && result.project) {
          this.props.updateProject(result.project);
          this.setState({
            isEditable: false,
            persistedValue: this.state.value,
          });
          this.props.addToast({ text: 'Project name updated' });
        }
      });
  };

  onMouseEnter = () => {
    this.setState({ isHovering: true });
  };

  onMouseLeave = () => {
    this.setState({ isHovering: false });
  };

  onClickEdit = () => {
    this.setState({ isEditable: true });
    setTimeout(() => {
      this.input.current.focus();
    });
  };

  render() {
    return (
      <div
        css={{
          flex: 1,
          fontSize: rem(18),
          color: colors.black1,
          position: 'relative',
          cursor: 'default',
          minWidth: 140,
          paddingTop: 1,
          marginRight: 120,
        }}
        onClick={this.onClick}
        onMouseEnter={this.onMouseEnter}
        onMouseLeave={this.onMouseLeave}
      >
        {this.state.isHovering || this.state.isEditable ? (
          <>
            <div
              css={{
                width: 1,
                height: 27,
                backgroundColor: colors.grey4,
                position: 'absolute',
                top: 2,
                right: 28,
              }}
            />
            <Input
              ref={this.input}
              disabled={!this.state.isEditable}
              type="text"
              value={this.state.value}
              onChange={this.onChange}
              onClick={event => event.stopPropagation()}
              onKeyDown={event => event.keyCode === 13 && this.onSave(event)}
              css={{
                width: '100%',
                marginRight: 120,
                lineHeight: '27px',
                marginTop: 0,
                marginLeft: -8,
                paddingTop: 0,
                paddingLeft: 7,
                paddingBottom: 0,
                paddingRight: 0,
                fontSize: rem(18),
              }}
            />
            {this.state.value !== this.state.persistedValue ? (
              <>
                <div
                  css={{
                    width: 1,
                    height: 27,
                    backgroundColor: colors.grey4,
                    position: 'absolute',
                    top: 2,
                    right: 57,
                  }}
                />
                <Check
                  width={16}
                  height={16}
                  color={colors.black1}
                  styles={{
                    position: 'absolute',
                    top: 7,
                    right: 35,
                    cursor: 'pointer',
                  }}
                  onClick={this.onSave}
                />
                <Close
                  width={16}
                  height={16}
                  color={colors.black1}
                  styles={{
                    position: 'absolute',
                    top: 7,
                    right: 8,
                    cursor: 'pointer',
                  }}
                  onClick={this.onClose}
                />
              </>
            ) : (
              <Edit
                width={12}
                height={12}
                color={colors.black1}
                onClick={this.onClickEdit}
                styles={{
                  position: 'absolute',
                  top: 9,
                  right: 8,
                  cursor: 'pointer',
                }}
              />
            )}
          </>
        ) : (
          <div css={{ marginTop: 5 }}>{this.props.value}</div>
        )}
      </div>
    );
  }
}

const EditableField = connect(
  state => ({ teamId: state.currentTeamId }),
  { updateProject, addToast },
)(_EditableField);

const _ProjectCard = ({
  id,
  name,
  aggregatedVideos,
  numVideos,
  removeProject,
  cancelUpload,
  numUploads,
  navigate,
  is_sample: isSample,
  created_at: createdAt,
  updated_at: updatedAt,
}) => {
  return (
    <div
      role="button"
      onClick={() => navigate(`/projects/${id}`)}
      css={{
        position: 'relative',
        borderRadius: 7,
        backgroundColor: colors.white0,
        transition: 'box-shadow .36s ease',
        boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.05)',
        ':hover': {
          cursor: 'pointer',
          boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.15)',
        },
      }}
    >
      <ContextMenu
        items={[
          {
            label: 'Cancel upload' + (numUploads > 1 ? 's' : ''),
            onClick:
              numUploads > 0 ? cancelUpload({ projectId: id }) : undefined,
          },
          { label: 'Delete', onClick: removeProject, color: colors.red0 },
        ]}
        styles={{
          position: 'absolute',
          top: 10,
          right: 10,
        }}
      />
      {isSample && (
        <Badge css={{ position: 'absolute', top: 40, right: 10 }}>Sample</Badge>
      )}
      <div css={{ display: 'flex', padding: 10 }}>
        <MultiThumbnail
          thumbnails={aggregatedVideos.thumbnails
            .slice(0, 4)
            .map(thumb => thumb.small)}
          styles={{ marginRight: 15 }}
        />
        <div
          css={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            flex: 1,
          }}
        >
          <div
            css={{
              display: 'flex',
              height: 27,
              paddinbTop: 3,
            }}
          >
            <EditableField value={name} projectId={id} />
          </div>
          <div
            css={{
              lineHeight: '23px',
              fontSize: rem(14),
              color: colors.grey2,
            }}
          >
            {numVideos} video{numVideos === 1 ? '' : 's'}
            {numVideos > 0 ? ` • ${aggregatedVideos.size}` : ''}
          </div>
        </div>
      </div>
      <div
        css={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          paddingLeft: 10,
          height: 34,
          fontSize: rem(12),
          backgroundColor: colors.white2,
          borderBottomLeftRadius: 7,
          borderBottomRightRadius: 7,
        }}
      >
        <div
          css={{
            display: 'flex',
            alignItems: 'center',
            letterSpacing: '0.03em',
            '>*': {
              marginRight: 27,
            },
          }}
        >
          <div>
            <span css={{ color: colors.black2 }}>Created</span>
            <span css={{ color: colors.grey2 }}>
              {' '}
              {formatDate(new Date(createdAt))}
            </span>
          </div>
          <div>
            <span css={{ color: colors.black2 }}>Updated</span>
            <span css={{ color: colors.grey2 }}>
              {' '}
              {formatDate(new Date(updatedAt))}
            </span>
          </div>
        </div>
        <div
          css={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <VideoStatus aggregatedVideos={aggregatedVideos} />
        </div>
      </div>
    </div>
  );
};

const ProjectCard = connect(
  null,
  (dispatch, ownProps) => {
    return {
      cancelUpload: payload => () => dispatch(cancelUpload(payload)),
      removeProject: () =>
        dispatch(openDialog({ type: 'deleteProject', projectId: ownProps.id })),
    };
  },
)(_ProjectCard);

class _Projects extends Component {
  render() {
    const filter = new URLSearchParams(this.props.location.search).get(
      'filter',
    );

    let projects = this.props.projects.map(project => {
      const projectVideos = this.props.videos.filter(
        video => video.project_id === project.id,
      );
      return {
        ...project,
        numVideos: projectVideos.filter(v => v.parent_id === null).length,
        aggregatedVideos: aggregateVideos(projectVideos),
        numUploads: projectVideos.reduce(
          (sum, pv) => sum + (pv.upload ? 1 : 0),
          0,
        ),
      };
    });

    if (filter === 'newest') {
      projects = projects.sort(
        (projectA, projectB) =>
          new Date(projectB.created_at) - new Date(projectA.created_at),
      );
    } else if (filter === 'oldest') {
      projects = projects.sort(
        (projectA, projectB) =>
          new Date(projectA.created_at) - new Date(projectB.created_at),
      );
    } else if (filter === 'active') {
      projects = projects.filter(
        project =>
          project.aggregatedVideos.state !== videoStates.none &&
          project.aggregatedVideos.state !== videoStates.finished,
      );
    } else if (filter === 'inactive') {
      projects = projects.filter(
        project =>
          project.aggregatedVideos.state === videoStates.none ||
          project.aggregatedVideos.state === videoStates.finished,
      );
    } else if (filter === 'error') {
      projects = projects.filter(
        project => project.aggregatedVideos.state === videoStates.error,
      );
    } else if (filter === 'a-z') {
      projects = [...projects].sort((projectA, projectB) => {
        const a = projectA.name.toLowerCase();
        const b = projectB.name.toLowerCase();
        return a < b ? -1 : a > b ? 1 : 0;
      });
    } else if (filter === 'z-a') {
      projects = [...projects].sort((projectA, projectB) => {
        const a = projectA.name.toLowerCase();
        const b = projectB.name.toLowerCase();
        return b < a ? -1 : b > a ? 1 : 0;
      });
    }

    return (
      <div
        css={{ marginBottom: 40, '>*:not(:first-of-type)': { marginTop: 20 } }}
      >
        <div css={{ display: 'flex', justifyContent: 'space-between' }}>
          <RoundedButton onClick={this.props.createProject}>
            New project
          </RoundedButton>
          <TeamMembers />
          <Filter basePath="/" currentFilter={filter} />
        </div>
        <Dropzone />
        {projects.map(project => (
          <ProjectCard
            key={project.id}
            navigate={this.props.history.push}
            {...project}
          />
        ))}
      </div>
    );
  }
}

export const Projects = connect(
  state => ({
    videos: teamVideosSelector(state),
    projects: teamProjectsSelector(state),
  }),
  dispatch => {
    const createHandler = type => projectId => () =>
      dispatch(openDialog({ type, projectId }));
    return {
      createProject: createHandler('createProject')(),
      deleteProject: createHandler('deleteProject'),
    };
  },
)(_Projects);
