import { useEffect, useReducer } from 'react';

import { Input } from './forms';
import { bitsInClosestUnit } from './utils';
import * as colors from './colors';

// Used to set an upper bound.
const wrapNum = num => Math.min(1e10, num);

function createReducer({ sourceBitrate, onChange }) {
  return function reducer(state, action) {
    const value = wrapNum(parseFloat(action.value));

    // Handle case where one of the inputs has been cleared completely.
    if (isNaN(value)) {
      return {
        bitrate: action.type === 'bitrate' ? action.value : sourceBitrate,
      };
    }

    if (action.type === 'reset') {
      return action.value;
    }

    if (action.type === 'bitrate') {
      const returnValue = {
        bitrate: value,
      };
      onChange(returnValue);
      return returnValue;
    }

    return state;
  };
}

export function CustomBitrate({ sourceBitrate, onChange }) {
  const reducer = createReducer({ sourceBitrate, onChange });
  const [state, dispatch] = useReducer(reducer, {
    bitrate: sourceBitrate,
  });

  useEffect(
    () => {
      dispatch({
        type: 'reset',
        value: { bitrate: sourceBitrate },
      });
    },
    [sourceBitrate],
  );

  return (
    <div>
      <Input
        type="number"
        step="100000"
        value={state.bitrate}
        onChange={event =>
          dispatch({ type: 'bitrate', value: event.target.value })
        }
      />
      <span
        css={{
          display: 'block',
          color: colors.grey2,
          fontSize: 12,
          margin: '12px 0',
        }}
      >
        {bitsInClosestUnit(state.bitrate)}ps
      </span>
    </div>
  );
}
