import React from 'react';
import useReactRouter from 'use-react-router';

import { Modal } from '../modal';
import { VideoComparison as VideoComparisonComponent } from '../video-comparison';
import { fetch } from '../requests';

export function VideoComparison({ location, close, billing, ...rest }) {
  const [hasLoaded, setLoaded] = React.useState(false);
  const [comparisonVideos, setComparisonVideos] = React.useState([]);
  const [team, setTeam] = React.useState();

  React.useEffect(() => {
    const params = new URLSearchParams(location.search);
    const signature = params.get('s');
    const videoIdOne = params.get('v1');
    const videoIdTwo = params.get('v2');
    fetch(
      `/api/compare?v1=${videoIdOne}&v2=${videoIdTwo}&s=${encodeURIComponent(
        signature,
      )}`,
    )
      .then(res => res.json())
      .then(response => {
        setComparisonVideos(response.videos);
        setTeam(response.team);
        setLoaded(true);
      });
  }, []);

  return hasLoaded ? (
    <div
      css={{
        width: '100vw',
        height: '100vh',
        backgroundColor: '#7f7f7f',
        position: 'absolute',
      }}
    >
      <VideoComparisonComponent
        imageModeTitle="Drag slider to compare"
        videoModeTitle="Drag slider to compare; click to pause and unpause"
        comparisonVideos={comparisonVideos}
        team={team}
        styles={{
          boxShadow: '0 0 8px 3px rgba(0, 0, 0, 0.25)',
        }}
        close={close || (() => (window.location.href = '/'))}
        billing={billing || (() => (window.location.href = '/billing'))}
        {...rest}
      />
    </div>
  ) : null;
}

export function VideoComparisonModal(props) {
  const { history } = useReactRouter();
  return (
    <Modal isOpen>
      <VideoComparison
        {...props}
        close={() => history.push('/')}
        billing={() => history.push('/billing')}
      />
    </Modal>
  );
}
