/**
 * This module constructs various arrays to provide as options to the
 * processing video dialog. The goal here is to avoid typos by only writing
 * values once. Everything here could be static JSON, and, in fact is imported
 * as JSON on runtime, as babel-preval is used to import this.
 *
 * The dropdowns in the dialog either take an array of arrays, or an object of
 * arrays of arrays. When provided with an array of arrays, a dropdown with a
 * flat list of values and labels (as defined in the inner arrays) is rendered.
 * When provided with an object of arrays of arrays, the same is done, except
 * that the object is used to define otpgroups (i.e., suboptions).
 *
 * *** *** ***
 * If you prefer to define these as JSON objects instead of using the
 * utility functions as defined here, simply import this module with
 * babel-plugin-preval somewhere, copy the results and replace this whole
 * module with it:
 * const thisModuleAsJson = preval.require('./this-module');
 * *** *** *** */

const defaults = {};

// Resolutions
const presetResolutions = [
  [{ width: 1280, height: 720, tag: '720p' }, 'Standard HD'],
  [{ width: 1920, height: 1080, tag: 'hd' }, 'Full HD'],
  [{ width: 3840, height: 2160, tag: '4k' }, '4K UHD'],
  [{ width: 7680, height: 4320, tag: '8k' }, '8K UHD'],
];
const fixedOutputResolutions = [
  ['none', 'None'],
  ...presetResolutions.map(([value, label]) => [
    value.tag,
    `${label} - ${value.width}×${value.height}`,
  ]),
];
defaults.fixedOutputResolution = fixedOutputResolutions[0][0];

// Containers
const mov = 'QuickTime';
const mxf = 'Material Exchange Format';
const mp4 = 'MPEG-4';
const mpegts = 'MPEG-2 Transport Stream';
const containers = { mov, mxf, mp4, mpegts };
defaults.container = 'mov';

// Codecs
const h264 = 'H.264 / AVC';
const prores = 'Apple ProRes';
const dnxhd = 'Avid DNxHD/HR';
const mpeg2 = 'MPEG-2 video';
const hevc = 'H.265 / HEVC';
const xdcam = 'Sony XDCAM HD422';
const ffv1 = 'FF video codec 1';
const codecs = { h264, prores, dnxhd, mpeg2, hevc, xdcam, ffv1 };
defaults.codec = 'h264';

// Mapping of containers to supported codecs for the given container.
// Used to construct a dropdown with optgroups around the options. The
// optgroups are the containers (the outer keys in the following object) and
// the value is a combination of the container and the codec, as json, along
// with a label.
const containerCodecs = {
  [`${mov} (.mov)`]: selectValuesFor({
    container: 'mov',
    codecs: { h264, prores, dnxhd, hevc, mpeg2, ffv1 },
  }),
  [`${mxf} (.mxf)`]: selectValuesFor({
    container: 'mxf',
    codecs: { h264, xdcam, mpeg2, dnxhd },
  }),
  [`${mp4} (.mp4)`]: selectValuesFor({
    container: 'mp4',
    codecs: { h264, hevc },
  }),
  [`${mpegts} (.mts)`]: selectValuesFor({
    container: 'mpegts',
    codecs: { h264, hevc, mpeg2 },
  }),
};

// Used exclusively for the containerCodecs object above.
// Uses the provided codec variables as an object to pass in their "id" and
// representation.
// In:
//   { container: 'mov', codecs: { prores, h2g4, ... } }
// Out:
//   [
//     '{"container": "mov", "codec": "prores"}', 'Apple ProRes',
//     '{"container": "mov", "codec": "h264"}', 'AVC / H.264',
//   ];
// Note that the labels "Apple ProRes", etc where not passed in except through
// the object shorthand syntax in the input.
function selectValuesFor({ container, codecs }) {
  return Object.entries(codecs).map(([codec, name]) => [
    JSON.stringify({ container, codec }),
    name,
  ]);
}

const yuv410p = '8 bit';
const yuv411p = '8 bit';
const yuv420p = '8 bit';
const yuv420p10le = '10 bit';
const yuv420p12le = '12 bit';
const yuv420p14le = '14 bit';
const yuv420p16le = '16 bit';
const yuv422p = '8 bit';
const yuv422p10le = '10 bit';
const yuv422p12le = '12 bit';
const yuv422p14le = '14 bit';
const yuv422p16le = '16 bit';
const yuv440p = '8 bit';
const yuv440p10le = '10 bit';
const yuv440p12le = '12 bit';
const yuv444p = '8 bit';
const yuv444p10le = '10 bit';
const yuv444p12le = '12 bit';
const yuv444p14le = '14 bit';
const yuv444p16le = '16 bit';

function toArrValueLabels(obj) {
  return Object.entries(obj).map(([name, label]) => [name, label]);
}

const chromaSubsamplingOptions = {
  'YUV 4:1:0': toArrValueLabels({ yuv410p }),
  'YUV 4:1:1': toArrValueLabels({ yuv411p }),
  'YUV 4:2:0': toArrValueLabels({
    yuv420p,
    yuv420p10le,
    yuv420p12le,
    yuv420p14le,
    yuv420p16le,
  }),
  'YUV 4:2:2': toArrValueLabels({
    yuv422p,
    yuv422p10le,
    yuv422p12le,
    yuv422p14le,
    yuv422p16le,
  }),
  'YUV 4:4:0': toArrValueLabels({
    yuv440p,
    yuv440p10le,
    yuv440p12le,
  }),
  'YUV 4:4:4': toArrValueLabels({
    yuv444p,
    yuv444p10le,
    yuv444p12le,
    yuv444p14le,
    yuv444p16le,
  }),
};
defaults.chromaSubsampling = 'yuv422p';

const scanningTypes = [
  ['auto', 'Auto'],
  ['progressive', 'Progressive'],
  ['interlaced_tff', 'Interlaced - Top Field First'],
  ['interlaced_bff', 'Interlaced - Bottom Field First'],
];
defaults.scanningType = scanningTypes[0][0];

const deinterlacers = [
  ['none', 'None'],
  ['deint', 'Pixop Deinterlacer'],
  ['yadif', 'YADIF'],
  ['bwdif', 'Bob Weaver'],
  ['weston3f', 'Weston 3-Field'],
];
defaults.deinterlacer = deinterlacers[1][0];

// reshape will become a Swiss-army knife tool which includes options to crop, resize and pad in the future
const reshapers = [['none', 'None'], ['reshape', 'Frame Cropper']];
defaults.reshaper = reshapers[1][0];

// frame geometry adjustment filters applied by the 'reshape' tool
const frameGeometryAdjusters = [['crop', 'Crop']];

const interlacedFieldOrderModes = [
  ['auto_quick', 'Auto - Metadata'],
  ['auto_analysis', 'Auto - Segment Analysis'],
  ['tff', 'Top Field First'],
  ['bff', 'Bottom Field First'],
];
defaults.interlacedFieldOrderMode = interlacedFieldOrderModes[0][0];

const denoisers = [
  ['none', 'None'],
  ['denoise', 'Pixop Denoiser'],
  ['hqdn3d', '3D Denoiser'],
];
defaults.denoiser = denoisers[1][0];

const stabilizers = [['none', 'None'], ['dejit', 'Pixop Dejitterer']];
defaults.stabilizer = stabilizers[1][0];

const augmenters = [['none', 'None'], ['faceforward', 'Pixop Face Forward']];
defaults.augmenter = augmenters[1][0];

defaults.autoWhiteBalance = true;
defaults.colorBoost = 10;
defaults.relightIntensity = 100;
defaults.backgroundBlur = 5;

const scalers = [
  ['none', 'None'],
  ['dvres2', 'Pixop Deep Restoration 2'],
  ['dvres', 'Pixop Deep Restoration'],
  ['pabsr1', 'Pixop Super Resolution'],
  ['scale', 'Bicubic Interpolation'],
];
defaults.scaler = scalers[0][0];

const clarityBoostOptions = [
  ['0', 'None'],
  ['1', 'Marginal'],
  ['2', 'Very low'],
  ['3', 'Low'],
  ['4', 'Medium'],
  ['5', 'High'],
  ['6', 'Very high'],
];
defaults.clarityBoost = clarityBoostOptions[3][0];

const grainSizeOptions = [
  ['0', 'Super-fine'],
  ['1', 'Fine'],
  ['2', 'Medium'],
  ['3', 'Coarse'],
  ['4', 'Very coarse'],
];
defaults.grainSize = grainSizeOptions[1][0];

// grain strength (percent)
defaults.grainStrength = 15;

const dvres2Variants = [
  ['generic', 'Generic'],
  ['finetune', 'Fine-Tuning'],
  ['selfiestyle', 'Selfie-Style'],
];
defaults.dvres2Variant = dvres2Variants[0][0];

const frameRateConverters = [
  ['none', 'None'],
  ['vfi', 'Pixop Frame Rate Conversion'],
  ['fps', 'Constant FPS'],
  ['fblend', 'Frame Blending'],
  ['mcinterpolate', 'Motion Compensation'],
];
defaults.frameRateConverter = frameRateConverters[1][0];

const frameRateOptions = [
  ['23.976', 'Film with NTSC compatibility - 23.976 FPS'],
  ['24', 'Film - 24 FPS'],
  ['25', 'PAL video - 25 FPS'],
  ['29.97', 'NTSC video - 29.97 FPS'],
  ['30', 'HD video - 30 FPS'],
  ['50', 'PAL video - 50 FPS'],
  ['59.94', 'HD video with NTSC compatibility - 59.94 FPS'],
  ['60', 'HD video - 60 FPS'],
  ['100', 'PAL UHD video - 100 FPS'],
  ['119.88', 'UHD video with NTSC compatibility - 119.88 FPS'],
  ['120', 'UHD video - 120 FPS'],
];
defaults.frameRate = frameRateOptions[6][0];

const debanders = [['none', 'None'], ['gradfun', 'Gradient Debander']];
defaults.debander = debanders[1][0];

const postProcessors = [['none', 'None'], ['filmgrain', 'Pixop Film Grain']];
defaults.postProcessor = postProcessors[1][0];

function formatCodecName(codecName) {
  return codecs[codecName];
}

function formatCodec(video) {
  let codecName =
    formatCodecName(video.metadata.codecName) || video.metadata.longCodecName;

  // FFmpeg uses the same decoder for both MPEG-1 and MPEG-2 in MPEG containers (and its friends like e.g. VOB) - override the name
  // to avoid confusion like in VLC Media Information
  const mpeg12ContainerNames = ['mpeg'];
  const mpeg12CodecNames = ['mpeg1video', 'mpeg2video'];

  if (
    mpeg12ContainerNames.indexOf(video.metadata.containerName) >= 0 &&
    mpeg12CodecNames.indexOf(video.metadata.codecName) >= 0
  ) {
    codecName = 'MPEG-1/2 video';
  }

  return codecName;
}

function formatContainerName(containerName) {
  return containers[containerName];
}

function formatContainer(video) {
  return video.metadata.containerName
    ? formatContainerName(video.metadata.containerName.split(',')[0]) ||
        video.metadata.longContainerName
    : null;
}

function formatFrameRateWithScanning(scanning, frameRate) {
  if (scanning) {
    const scanningType = scanning.scanningType;

    if (scanningType.toUpperCase() === 'INTERLACED') {
      return `${2 * frameRate}i`;
    } else {
      return `${frameRate}p`;
    }
  } else {
    return `${frameRate}`;
  }
}

function frameRateToFixedString(frameRateFloat) {
  return parseFloat(frameRateFloat.toFixed(3));
}

function formatFrameRateWithScanningAndFieldOrder(scanning, frameRate) {
  if (scanning) {
    const scanningType = scanning.scanningType;

    if (scanningType.toUpperCase() === 'INTERLACED') {
      const fieldOrder =
        scanning.interlacedFieldOrder.toUpperCase() === 'TOP_FIRST'
          ? 'TFF'
          : 'BFF';

      return `${frameRateToFixedString(2 * frameRate)}i ${fieldOrder}`;
    } else {
      return `${frameRateToFixedString(frameRate)}p`;
    }
  } else {
    return `${frameRateToFixedString(frameRate)}`;
  }
}

function formatDetailedFrameRate(video) {
  const frameRate = frameRateToFixedString(video.metadata.averageFramerate);

  const scanning = video.metadata.scanning;

  if (scanning) {
    const formattedMetadata = formatFrameRateWithScanningAndFieldOrder(
      scanning.metadata,
      frameRate,
    );

    if (scanning.heuristics) {
      const formattedHeuristics = formatFrameRateWithScanningAndFieldOrder(
        scanning.heuristics,
        frameRate,
      );

      if (formattedMetadata !== formattedHeuristics) {
        return `${frameRate} FPS (${formattedMetadata} or ${formattedHeuristics})`;
      }
    }

    return `${frameRate} FPS (${formattedMetadata})`;
  } else {
    return `${frameRate} FPS`;
  }
}

function calculateBitrate(video) {
  return Math.round(
    ((video.metadata.videoStreamSize * 8) / video.metadata.durationInMillis) *
      1000,
  );
}

const unknownColorFormat = 'Unknown color format';

function formatPixelFormatName(pixelFormatName) {
  if (RegExp('yuv\\d\\d\\dp').test(pixelFormatName)) {
    return (
      'YUV ' +
      pixelFormatName
        .slice(3, 6)
        .split('')
        .join(':')
    );
  } else if (
    RegExp('yuvj\\d\\d\\dp').test(pixelFormatName) ||
    RegExp('uyvy\\d\\d\\d').test(pixelFormatName) ||
    RegExp('yuyv\\d\\d\\d').test(pixelFormatName)
  ) {
    return (
      pixelFormatName.slice(0, 4).toUpperCase() +
      ' ' +
      pixelFormatName
        .slice(4, 7)
        .split('')
        .join(':')
    );
  } else if (RegExp('uyyvyy\\d\\d\\d').test(pixelFormatName)) {
    return (
      'UYYVYY ' +
      pixelFormatName
        .slice(6, 9)
        .split('')
        .join(':')
    );
  } else if (RegExp('yuva\\d\\d\\dp').test(pixelFormatName)) {
    return (
      'YUVA ' +
      (pixelFormatName.slice(4, 7) + pixelFormatName.slice(4, 5)) // alpha channel bits = luminance channel bits
        .split('')
        .join(':')
    );
  } else if (
    pixelFormatName.includes('rgb') ||
    pixelFormatName.includes('bgr') ||
    pixelFormatName.startsWith('nv')
  ) {
    const letterMatches = pixelFormatName.match(/[a-z]+/g);
    return letterMatches[0].toUpperCase();
  } else if (RegExp('gbra?p').test(pixelFormatName)) {
    const letterMatches = pixelFormatName.match(/gbra?/g);
    return letterMatches[0].toUpperCase();
  }

  return unknownColorFormat;
}

function formatPixelFormatNameAndBitDepth(pixelFormatName) {
  let result = formatPixelFormatName(pixelFormatName);

  if (RegExp('gbra?p').test(pixelFormatName)) {
    const digitMatches = pixelFormatName.match(/\d+/g);
    const bit = digitMatches ? digitMatches[0] : 8;

    result = result + ' (' + bit + '-bit channels)';
  } else if (
    pixelFormatName.includes('rgb') ||
    pixelFormatName.includes('bgr')
  ) {
    if (pixelFormatName.includes('565')) {
      result = result + ' (16 bits / pixel)';
    } else {
      const bit = pixelFormatName.includes('555')
        ? 5
        : pixelFormatName.includes('48') || pixelFormatName.includes('64')
        ? 16
        : 8;

      result = result + ' (' + bit + '-bit channels)';
    }
  } else if (pixelFormatName.startsWith('nv')) {
    result = result + ' ' + pixelFormatName.match(/\d+/g);
  } else if (result !== unknownColorFormat) {
    const digitMatches = pixelFormatName.match(/\d+/g);
    const bit = digitMatches.length > 1 ? digitMatches[1] : 8;

    result = result + ' (' + bit + '-bit)';
  }

  return result;
}

function formatColorInfo(video) {
  const {
    hdrStandardName,
    colorSpaceName,
    colorTransferName,
    colorPrimariesName,
  } = video.metadata;

  if (colorSpaceName) {
    let result = '';

    // coalesce if all are identical (e.g. BT.709)
    if (
      (colorSpaceName === colorTransferName &&
        colorSpaceName === colorPrimariesName) ||
      !colorTransferName ||
      !colorPrimariesName
    ) {
      result = result + colorSpaceName;

      if (colorPrimariesName === 'BT.709') {
        result = result + ', HDTV';
      }
    } else {
      result =
        result +
        `${colorSpaceName}, ${colorPrimariesName}, ${colorTransferName}`;

      // is HDR?
      if (['PQ', 'HLG'].includes(colorTransferName) || hdrStandardName) {
        result = result + (hdrStandardName ? `, ${hdrStandardName}` : ', HDR');
      } else if (colorPrimariesName === 'BT.2020') {
        result = result + ', UHDTV';
      }
    }

    return result;
  } else {
    return '-';
  }
}

function formatBoolean(value) {
  return value === true || value === 'true' ? 'On' : 'Off';
}

function generateWarnings({ sourceVideo, targetVideo }) {
  const possibleInterlacedSource =
    sourceVideo.metadata.scanning &&
    (sourceVideo.metadata.scanning.metadata.scanningType.toUpperCase() ===
      'INTERLACED' ||
      sourceVideo.metadata.scanning.heuristics.scanningType.toUpperCase() ===
        'INTERLACED');
  const hasDeinterlacer = !!targetVideo.videoFilters.find(
    videoFilter =>
      !!deinterlacers.find(
        ([filterType, _]) => filterType === videoFilter.name,
      ),
  );
  const hasH264Encoder = targetVideo.codec.name === 'h264';
  const hasNoDeinterlacerAndOtherFilters =
    !hasDeinterlacer && targetVideo.videoFilters.length > 0;
  const hasLowNoiseScore = sourceVideo.quality_assessment.metrics.find(
    metric => metric.name === 'noise' && metric.subjectiveScore < 2.5,
  );
  const hasLowDetailsScore = sourceVideo.quality_assessment.metrics.find(
    metric => metric.name === 'details' && metric.subjectiveScore < 2.5,
  );
  const hasDenoiser = !!targetVideo.videoFilters.find(
    videoFilter =>
      !!denoisers.find(([filterType, _]) => filterType === videoFilter.name),
  );
  const hasPixopSuperResolutionScaler = !!targetVideo.videoFilters.find(
    videoFilter => videoFilter.name === 'pabsr1',
  );
  const hasStandardOutputFrameRate = !!frameRateOptions.find(
    ([frameRate, _]) =>
      Math.abs(parseFloat(frameRate) - targetVideo.averageFramerate) < 0.01,
  );

  return [
    hasH264Encoder &&
      targetVideo.pixelFormat.name !== 'yuv420p' &&
      'H.264 files only play back correctly in some video players when chroma subsampling is set to YUV 4:2:0 (8-bit); change the chroma subsampling or transcode the result if player compatibility is important',
    possibleInterlacedSource &&
      hasNoDeinterlacerAndOtherFilters &&
      'No deinterlacer was selected on a likely interlaced source; consider adding a deinterlacer',
    !possibleInterlacedSource &&
      hasDeinterlacer &&
      'A deinterlacer was selected on a progressive source; the processing result will likely improve by removing it',
    hasLowNoiseScore &&
      !hasDenoiser &&
      'From our quality assessment the source seems to be degraded by noise; consider adding a denoiser for better results',
    hasLowDetailsScore &&
      hasPixopSuperResolutionScaler &&
      'From our quality assessment the source seems to be low on details; consider using one of our Deep Restoration filters instead of Pixop Super Resolution if injecting details and/or deblurring is important',
    !hasStandardOutputFrameRate &&
      'The output frame rate is non-standard which can be a problem for certain video players; use frame rate conversion if player compatibility is important',
  ].filter(w => w);
}

// babel-plugin-preval does not appear to support EcmaScript module exports.
module.exports = {
  defaults,
  presetResolutions,
  fixedOutputResolutions,
  codecs,
  containers,
  containerCodecs,
  chromaSubsamplingOptions,
  scanningTypes,
  deinterlacers,
  interlacedFieldOrderModes,
  reshapers,
  frameGeometryAdjusters,
  denoisers,
  augmenters,
  stabilizers,
  scalers,
  clarityBoostOptions,
  grainSizeOptions,
  dvres2Variants,
  frameRateConverters,
  frameRateOptions,
  debanders,
  postProcessors,
  formatCodecName,
  formatCodec,
  formatContainerName,
  formatContainer,
  formatFrameRateWithScanning,
  formatFrameRateWithScanningAndFieldOrder,
  formatDetailedFrameRate,
  formatPixelFormatName,
  formatPixelFormatNameAndBitDepth,
  formatColorInfo,
  formatBoolean,
  calculateBitrate,
  generateWarnings,
};
