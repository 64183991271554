import { useRef, useState, useEffect } from 'react';
import { connect } from 'react-redux';

import { Dialog } from '../dialog';
import { Alert, Field } from '../forms';
import { fetch } from '../requests';
import { updateVideo } from '../store/videos';
import { addToast } from '../store/toasts';

function _EditVideo({ video, updateVideo, addToast, attr }) {
  const firstInput = useRef();
  const [title, setTitle] = useState(video.name || '');
  const [description, setDescription] = useState(video.description || '');
  const [validationError, setValidationError] = useState(null);

  // Hack around autofocus not working
  useEffect(() => {
    setTimeout(() => {
      firstInput.current.focus();
    });
  }, []);

  const fields =
    video.parent_id === null
      ? [
          'director',
          'producer',
          'writer',
          'cast',
          'genres',
          'language',
          'country',
          'notes',
        ]
      : ['notes'];

  const defaultValues = fields.reduce((acc, key) => {
    acc[key] = video.attributes[key] || '';
    return acc;
  }, {});
  const [attributes, setAdditionalField] = useState(defaultValues);

  const setField = (name, value) => {
    setAdditionalField(state => ({
      ...state,
      [name]: value,
    }));
  };

  function validate() {
    if (!title) {
      setValidationError('Video title required');
      return false;
    }

    if (title.length >= 128) {
      setValidationError('Video title too long');
      return false;
    }

    if (description && description.length >= 512) {
      setValidationError('Video description too long');
      return false;
    }

    setValidationError(null);
    return true;
  }

  function onConfirm() {
    if (!validate()) return Promise.reject();

    return fetch(`/api/uploads/${video.id}`, {
      method: 'PUT',
      body: { title, description, attributes, teamId: video.team_id },
    })
      .then(res => res.json())
      .then(response => {
        if (response.error) {
          return Promise.reject(response.error);
        }

        updateVideo(response.video);
        addToast({ text: 'Video successfully updated' });
        return true;
      })
      .catch(error => {
        setValidationError(error);
        return false;
      });
  }

  useEffect(
    () => {
      if (attr) {
        const el = document.getElementById(`edit-${attr.toLowerCase()}`);
        if (el) {
          setTimeout(() => {
            el.focus();
            el.scrollIntoView();
          });
        }
      }
    },
    [attr],
  );

  return (
    <Dialog
      isOpen
      heading="Edit video metadata"
      confirmText="Save changes"
      onConfirm={onConfirm}
      closeOnConfirm
      containerStyles={{
        width: 660,
        '@media (max-width: 610px)': {
          width: '100%',
        },
      }}
    >
      <div css={{ '>div': { marginBottom: 20 } }}>
        {validationError && <Alert text={validationError} />}
        <Field
          innerRef={firstInput}
          fieldName="title"
          label="Title"
          placeholder="Title"
          value={title}
          id="edit-title"
          onChange={event => setTitle(event.target.value)}
        />
        <Field
          fieldName="description"
          label="Description"
          placeholder="Description"
          value={description}
          id="edit-description"
          onChange={event => setDescription(event.target.value)}
        />
        {fields.map(field => {
          const capitalized = field[0].toUpperCase() + field.slice(1);
          return (
            <Field
              key={field}
              id={`edit-${field}`}
              fieldName={field}
              label={capitalized}
              placeholder={capitalized}
              value={attributes[field]}
              onChange={event => setField(field, event.target.value)}
            />
          );
        })}
      </div>
    </Dialog>
  );
}

export const EditVideo = connect(
  (state, ownProps) => ({
    video: state.videos.find(video => video.id === ownProps.videoId),
  }),
  { updateVideo, addToast },
)(_EditVideo);
