import { combineReducers } from 'redux';

import { dialogReducer } from './dialog';
import { videosReducer } from './videos';
import { userReducer } from './user';
import { teamsReducer, currentTeamIdReducer } from './teams';
import { projectsReducer } from './projects';
import { toastsReducer } from './toasts';
import { membersReducer } from './members';
import { notificationsReducer } from './notifications';
import { uploadsReducer } from './uploads';
import { presetsReducer } from './presets';
import { messagesReducer } from './messages';

const passThrough = (state = null) => state;

const appReducer = combineReducers({
  dialog: dialogReducer,
  videos: videosReducer,
  user: userReducer,
  teams: teamsReducer,
  currentTeamId: currentTeamIdReducer,
  projects: projectsReducer,
  toasts: toastsReducer,
  notifications: notificationsReducer,
  members: membersReducer,
  uploads: uploadsReducer,
  presets: presetsReducer,
  messages: messagesReducer,

  // These state slices do not (currently) have custom reducers to update
  // their values. This state is effectively read only at the moment, but we
  // must add it here to have access to it in connected components.
  teamIds: passThrough,
  env: passThrough,
  lang: passThrough,
  isSafari: passThrough,
  polyfills: passThrough,
});

export const rootReducer = (state, action) => {
  // Custom action to reset state values to the provided payload.
  // Useful for logging in, etc.
  if (action.type === 'reset') {
    return {
      env: state.env,
      toasts: state.toasts,
      notifications: state.notifications,
      uploads: [],
      videos: [],
      dialog: [],
      ...(action.payload || {}),
    };
  }

  // Custom action to override part of the state.
  if (action.type === 'resetPartial') {
    return {
      ...state,
      ...(action.payload || {}),
    };
  }

  // Otherwise delegate to the combined reducers.
  return appReducer(state, action);
};
