import { Text, rem } from '../typography';
import * as colors from '../colors';
import { Dialog } from '../dialog';

export function MobileDeviceDetected() {
  return (
    <Dialog heading="Mobile device detected" isOpen>
      <Text
        css={{
          fontSize: rem(14),
          color: colors.grey2,
          marginBottom: 6,
        }}
      >
        Warning: This application is not intended for mobile devices. Please
        switch to a desktop machine for the best experience.
      </Text>
    </Dialog>
  );
}
