import { createAction, createReducer } from './utils';

export const openDialog = createAction('openDialog');
export const closeDialog = createAction('closeDialog');

export const dialogReducer = createReducer([], {
  [openDialog]: (state, action) => {
    return [...state, action.payload];
  },
  [closeDialog]: state => state.slice(0, -1),
});
