import { WebSocketManager } from '../websockets';
import * as projectActions from './projects';
import * as videoActions from './videos';
import * as memberActions from './members';
import * as toastActions from './toasts';
import * as teamActions from './teams';
import * as presetActions from './presets';
import * as messsageActions from './messages';

let clientId = null;
export function getClientId() {
  return clientId;
}

let webSocketManager = new WebSocketManager();

const RECONNECT = 'RECONNECT';
export const socketsMiddleware = store => {
  const state = store.getState();

  let connection;
  function connect() {
    return webSocketManager.open({
      onOpen: onOpenHandler,
      onMessage: createOnMessageHandler(store),
      isReconnecting: false,
    });
  }

  if (state.user) {
    connection = connect();
  }

  return next => action => {
    const previousState = store.getState();
    let returnValue = next(action);
    const currentState = store.getState();

    if (action.type === RECONNECT) {
      connection.close();
      connection = connect();
      return returnValue;
    }

    if (previousState.user && !currentState.user) {
      connection.close();
      connection = null;
    } else if (!previousState.user && currentState.user) {
      connection = connect();
    }

    return returnValue;
  };
};

function onOpenHandler(isReconnecting) {
  if (isReconnecting) {
    // perform full refresh when reconnecting as events might have been lost
    window.location.reload();
  }
}

function createOnMessageHandler(store) {
  // reconnect if no messages is received within ping interval * 2
  const watchdogTimeout = 2 * 30 * 1000;

  let watchdogTimer;

  return message => {
    if (process.env.NODE_ENV !== 'production') {
      /* eslint-disable-next-line no-console */
      console.log('WS message:', message.data);
    }

    const eventType = message.data[0];

    if (watchdogTimer) {
      clearTimeout(watchdogTimer);
    }

    watchdogTimer = setTimeout(() => {
      console.log(
        `No websocket messages received in ${watchdogTimeout} ms, refreshing state...`,
      );
      window.location.reload();
    }, watchdogTimeout);

    if (eventType === 'p') {
      // Just a ping; no-op.
      return;
    }

    const data = JSON.parse(message.data.slice(2, message.data.length));

    if (eventType === 'w') {
      clientId = data.clientId;
      return;
    }

    const resources = {
      project: 'projects',
      video: 'videos',
      member: 'members',
      preset: 'presets',
      message: 'messages',
      team: 'teams',
    };

    Object.entries(data).forEach(([key, value]) => {
      const resourceName = resources[key] || key;

      if (resourceName === 'members') {
        if (eventType === 'c') {
          // A new team member was added.
          store.dispatch(memberActions.addMember(value));
        } else if (eventType === 'd') {
          const { user } = store.getState();
          if (value.id === user.id) {
            const { currentTeamId, teams } = store.getState();
            if (currentTeamId === value.teamId) {
              // The currently logged in user was removed from their current
              // team.
              // TODO - It would be better to do this without refreshing the
              // browser. Everything below this won't execute unless we do it
              // with history.push.
              window.location = '/';

              store.dispatch(
                toastActions.addToast({
                  text: 'You were removed from the team',
                }),
              );

              const otherTeams = teams.filter(
                team => team.id !== currentTeamId,
              );
              const nextTeam = otherTeams[0];
              store.dispatch(teamActions.setCurrentTeam({ id: nextTeam.id }));
              store.dispatch(teamActions.removeTeam({ id: currentTeamId }));
              store.dispatch({ type: RECONNECT });
            } else {
              // The currently logged in user was removed from one of their
              // teams.

              store.dispatch(
                toastActions.addToast({
                  text: 'You were removed from a team',
                }),
              );
              store.dispatch(teamActions.removeTeam({ id: value.teamId }));
            }
          } else {
            // Another user was removed from a team.
            store.dispatch(memberActions.removeMember(value));
          }
        }
      } else if (resourceName === 'projects') {
        if (eventType === 'c') {
          store.dispatch(projectActions.addProject(value));
        } else if (eventType === 'u') {
          store.dispatch(projectActions.updateProject(value));
        } else if (eventType === 'd') {
          store.dispatch(projectActions.removeProject(value));
        } else {
          if (process.env.NODE_ENV !== 'production') {
            /* eslint-disable-next-line no-console */
            console.log('Unknown projects message received; left unhandled');
          }
        }
      } else if (resourceName === 'videos') {
        if (eventType === 'c') {
          store.dispatch(videoActions.addIfNotPresent(value));
        } else if (eventType === 'u') {
          store.dispatch(videoActions.updateVideo(value));
        } else if (eventType === 'd') {
          store.dispatch(videoActions.removeVideo(value));
        } else {
          if (process.env.NODE_ENV !== 'production') {
            /* eslint-disable-next-line no-console */
            console.log('Unknown videos message received; left unhandled');
          }
        }
      } else if (resourceName === 'presets') {
        if (eventType === 'c') {
          store.dispatch(presetActions.addPreset(value));
        } else if (eventType === 'u') {
          store.dispatch(presetActions.updatePreset(value));
        } else if (eventType === 'd') {
          store.dispatch(presetActions.removePreset(value));
        } else {
          if (process.env.NODE_ENV !== 'production') {
            /* eslint-disable-next-line no-console */
            console.log('Unknown presets message received; left unhandled');
          }
        }
      } else if (resourceName === 'messages') {
        if (eventType === 'c') {
          store.dispatch(messsageActions.addMessage(value));
        } else if (eventType === 'u') {
          store.dispatch(messsageActions.updateMessage(value));
        } else if (eventType === 'd') {
          store.dispatch(messsageActions.removeMessage(value));
        } else {
          if (process.env.NODE_ENV !== 'production') {
            /* eslint-disable-next-line no-console */
            console.log('Unknown messages message received; left unhandled');
          }
        }
      } else if (resourceName === 'teams') {
        if (eventType === 'u') {
          store.dispatch(teamActions.updateTeam(value));
        } else {
          if (process.env.NODE_ENV !== 'production') {
            /* eslint-disable-next-line no-console */
            console.log('Unknown teams message received; left unhandled');
          }
        }
      } else {
        if (process.env.NODE_ENV !== 'production') {
          /* eslint-disable-next-line no-console */
          console.log('Unknown resource message received; left unhandled');
        }
      }
    });
  };
}
