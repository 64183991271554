import { connect } from 'react-redux';

import { MobileDeviceDetected } from './mobile-device-detected';
import { Welcome } from './welcome';
import { UploadInitiated } from './upload-initiated';

import { AddTeamMember } from './add-team-member';
import { CreateProject } from './create-project';
import { DeleteProject } from './delete-project';
import { DeleteVideo } from './delete-video';
import { DownloadVideo } from './download-video';
import { EditVideo } from './edit-video';
import { MoveVideo } from './move-video';
import { Teams } from './teams';
import { UnsupportedMedia } from './unsupported-media';
import { ConfirmPassword } from './confirm-password';
import { ConfirmReupload } from './confirm-reupload';
import { Upload } from './upload';
import { ProcessingCredits } from './processing-credits';
import { ConfirmPurchaseCredits } from './confirm-purchase-credits';
import { ViewInfo } from './view-info';
import { ViewJobDetails } from './view-job-details';
import { DeleteUpload } from './delete-upload';
import { ProcessingTool } from './processing-tool';
import { TrimTool } from './trim-tool';
import { CreatePreset } from './processing-tool/create-preset-modal';
import { DeletePreset } from './processing-tool/delete-preset';
import { EditPreset } from './processing-tool/edit-preset';
import { EditImageRectangle } from './processing-tool/edit-image-rectangle';
import { ConfirmAppraisal } from './processing-tool/confirm-appraisal';
import { SettleUtilities } from './settle-utilities';
import { VideoPlayerModal } from './video-player';

const componentForType = type =>
  ({
    mobileDeviceDetected: MobileDeviceDetected,
    welcome: Welcome,
    uploadInitiated: UploadInitiated,
    addTeamMember: AddTeamMember,
    createProject: CreateProject,
    deleteProject: DeleteProject,
    deleteVideo: DeleteVideo,
    downloadVideo: DownloadVideo,
    editVideo: EditVideo,
    moveVideo: MoveVideo,
    processVideo: ProcessingTool,
    makeClip: TrimTool,
    teams: Teams,
    unsupportedMedia: UnsupportedMedia,
    confirmPassword: ConfirmPassword,
    upload: Upload,
    processingCredits: ProcessingCredits,
    confirmPurchaseCredits: ConfirmPurchaseCredits,
    settleUtilities: SettleUtilities,
    confirmReupload: ConfirmReupload,
    viewInfo: ViewInfo,
    viewJobDetails: ViewJobDetails,
    deleteUpload: DeleteUpload,
    createPreset: CreatePreset,
    deletePreset: DeletePreset,
    editPreset: EditPreset,
    editImageRectangle: EditImageRectangle,
    confirmAppraisal: ConfirmAppraisal,
    videoPlayer: VideoPlayerModal,
  }[type] || null);

function _ModalManager({ dialog }) {
  if (!dialog.length === 0) return null;
  return dialog.map((d, index) => {
    const { type, ...props } = d;
    const Component = componentForType(type);
    return <Component key={index} isOpen {...props} />;
  });
}

export const ModalManager = connect(state => ({ dialog: state.dialog }))(
  _ModalManager,
);
