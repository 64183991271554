import React from 'react';
import { keyframes } from '@emotion/react';

import * as colors from './colors';
import { rem } from './typography';
import { Bell } from './icons';
import { useOnClickOutside } from './search-box';
import { Notifications } from './notifications';

const scaleIn = keyframes`
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
`;

const Number = ({ value }) => (
  <div
    css={{
      position: 'absolute',
      right: 0,
      color: colors.white0,
      backgroundColor: colors.red0,
      borderRadius: '50%',
      border: `1px solid ${colors.white0}`,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontSize: rem(13),
      width: 18,
      height: 18,
      animation: `${scaleIn} .72s ease`,
    }}
  >
    <div css={{ marginTop: 2 }}>{value}</div>
  </div>
);

export function NotificationsBadge({ number, styles }) {
  const [isOpen, setOpen] = React.useState(false);
  const ref = React.useRef();

  useOnClickOutside(ref, () => setOpen(false));

  function onClick() {
    setOpen(!isOpen);
  }

  return (
    <div
      css={{ position: 'relative', ...styles }}
      tabIndex="0"
      onClick={onClick}
    >
      <Bell css={{ position: 'absolute', top: 8, right: 10 }} />
      {number > 0 && <Number value={number} />}
      {isOpen && (
        <Notifications
          innerRef={ref}
          styles={{
            position: 'absolute',
            top: 40,
            right: 15,
          }}
        />
      )}
    </div>
  );
}
