import React from 'react';
import { connect } from 'react-redux';
import useReactRouter from 'use-react-router';

import {
  currentVideoSelector,
  currentProjectSelector,
  teamNotificationsSelector,
} from './store/selectors';
import * as colors from './colors';
import { Thumbnail } from './search-box';
import { removeNotification } from './store/notifications';

const dateFormatter = Intl.DateTimeFormat('en-US', {
  year: 'numeric',
  day: 'numeric',
  month: 'short',
  hour: 'numeric',
  minute: 'numeric',
});

const Separator = () => (
  <hr
    css={{
      width: '100%',
      height: 1,
      border: 'none',
      marginTop: 12,
      marginBottom: 15,
      backgroundColor: colors.hexToRgba(colors.grey3, 0.25),
    }}
  />
);

const Notification = ({
  video,
  project,
  created_at,
  isLast,
  openNotification,
}) => (
  <div>
    <div
      css={{ display: 'flex', cursor: 'pointer', tabIndex: '0' }}
      onClick={openNotification}
    >
      <Thumbnail
        src={video.thumbnail_urls && video.thumbnail_urls.small}
        styles={{ width: 62, height: 40, marginRight: 10 }}
      />
      <div
        css={{
          display: 'flex',
          alignItems: 'center',
          color: colors.orange1,
          lineHeight: '20px',
        }}
      >
        New video uploaded in {project.name}
      </div>
    </div>
    <div css={{ marginTop: 7, lineHeight: '23px', color: colors.grey2 }}>
      {dateFormatter.format(new Date(created_at))}
    </div>
    {!isLast && <Separator />}
  </div>
);

function _Notifications({
  notifications,
  innerRef,
  createOpenNotification,
  styles = {},
}) {
  const { history } = useReactRouter();
  const [opacity, setOpacity] = React.useState(0);
  React.useEffect(() => {
    setOpacity(1);
  }, []);
  return (
    <div
      ref={innerRef}
      css={{
        opacity,
        width: 324,
        boxSizing: 'border-box',
        borderRadius: 5,
        boxShadow: `0 0 20px ${colors.hexToRgba(colors.black0, 0.16)}`,
        padding: '15px 20px',
        backgroundColor: colors.white0,
        overflow: 'auto',
        maxHeight: '70vh',
        transition: 'opacity .18s ease-in',
        ...styles,
      }}
    >
      <div>Notifications</div>
      <hr
        css={{
          width: '100%',
          height: 1,
          border: 'none',
          marginTop: 12,
          marginBottom: 15,
          backgroundColor: colors.hexToRgba(colors.grey3, 0.25),
        }}
      />
      {notifications.length === 0 ? (
        <div css={{ color: colors.grey3 }}>
          There aren’t any new notifications.
        </div>
      ) : (
        notifications.map((notification, index) => (
          <Notification
            key={notification.id}
            {...notification}
            openNotification={createOpenNotification({
              id: notification.id,
              projectId: notification.project_id,
              videoId: notification.video_id,
              navigate: history.push,
            })}
            isLast={index === notifications.length - 1}
          />
        ))
      )}
    </div>
  );
}

export const Notifications = connect(
  state => {
    const notifications = teamNotificationsSelector(state)
      .map(notification => {
        return {
          ...notification,
          video: currentVideoSelector(state, {
            videoId: notification.video_id,
          }),
          project: currentProjectSelector(state, {
            projectId: notification.project_id,
          }),
        };
      })
      .filter(notification => notification.video && notification.project);
    return { notifications };
  },
  dispatch => {
    const createOpenNotification = ({
      id,
      projectId,
      videoId,
      navigate,
    }) => () => {
      navigate(`/projects/${projectId}/videos/${videoId}`);
      dispatch(removeNotification({ id }));
    };
    return { createOpenNotification };
  },
)(_Notifications);
