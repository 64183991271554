import { Component } from 'react';

import { SquareButton } from '../button';
import { FormCard, FormTitle, Field, Alert } from '../forms';
import { fetch } from '../requests';
import { AuthLayout } from '../main-layout';

export class ChangePassword extends Component {
  state = {
    error: false,
    isButtonDisabled: false,
    password: '',
    passwordConfirmation: '',
    passwordValidation: false,
    passwordConfirmationValidation: false,
  };

  onChange = ({ target }) => {
    this.setState({ [target.name]: target.value });
  };

  validateForm = () => {
    const { password, passwordConfirmation } = this.state;

    this.setState({
      error: false,
      passwordValidation: false,
      passwordConfirmationValidation: false,
    });

    if (password === '') {
      this.setState({ passwordValidation: 'New password required' });
      return false;
    }

    if (password.length < 10) {
      this.setState({
        passwordValidation: 'New password must be 10 characters or more',
      });
      return false;
    }

    if (passwordConfirmation === '') {
      this.setState({
        passwordConfirmationValidation: 'New password required',
      });
      return false;
    }

    if (password !== passwordConfirmation) {
      this.setState({
        passwordValidation: 'New passwords must match',
        passwordConfirmationValidation: 'New passwords must match',
      });
      return false;
    }

    return true;
  };

  onSubmit = event => {
    event.preventDefault();

    if (!this.validateForm()) {
      return;
    }

    this.setState({ isButtonDisabled: true });
    fetch('/api/change-password', {
      method: 'POST',
      body: {
        password: this.state.password,
        token: new URLSearchParams(window.location.search).get('token'),
      },
    })
      .then(res => res.json())
      .then(({ error }) => {
        this.setState({ isButtonDisabled: false });

        if (error) {
          this.setState({ error });
          return;
        }

        this.props.history.push('/login?c=t');
      })
      .catch(error => {
        this.setState({
          isButtonDisabled: false,
          error: 'Unknown error; please try again',
        });
        throw error;
      });
  };

  render() {
    return (
      <AuthLayout>
        <FormCard css={{ margin: '0 auto' }}>
          <FormTitle css={{ alignSelf: 'center' }}>Change password</FormTitle>
          {this.state.error && <Alert text={this.state.error} />}
          <Field
            type="password"
            label="New password *"
            fieldName="password"
            placeholder="Your new password"
            autoComplete="off"
            value={this.state.password}
            onChange={this.onChange}
            validationMessage={this.state.passwordValidation}
            autoFocus
            required
          />
          <Field
            type="password"
            label="Repeat new password *"
            fieldName="passwordConfirmation"
            placeholder="Repeat your new password"
            autoComplete="off"
            value={this.state.passwordConfirmation}
            onChange={this.onChange}
            validationMessage={this.state.passwordConfirmationValidation}
            required
          />
          <SquareButton
            css={{ alignSelf: 'center', marginTop: 10 }}
            onClick={this.onSubmit}
            type="submit"
            disabled={this.state.isButtonDisabled}
            isLoading={this.state.isButtonDisabled}
          >
            Change password
          </SquareButton>
        </FormCard>
      </AuthLayout>
    );
  }
}
