export function debounce(func, wait, immediate) {
  let timeout;
  return function(...args) {
    const context = this;
    const later = function() {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };

    const callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);

    if (callNow) {
      func.apply(context, args);
    }
  };
}

export function throttle(func, wait) {
  let lastTimestamp = null;
  let limit = wait;
  return function(...args) {
    const context = this;
    const now = Date.now();
    if (!lastTimestamp || now - lastTimestamp >= limit) {
      lastTimestamp = now;
      return func.apply(context, args);
    }
  };
}

export function idleCallback(callback) {
  const ric = window.location.requestIdleCallback
    ? window.location.requestIdleCallback
    : cb => setTimeout(cb, 1);
  ric(callback);
}

// Return milliseconds in an hour:minutes:seconds format.
// Only returns hours if the video is longer than sixty minutes.
export function formatMsToHourMinutesSeconds(ms, full = false) {
  let seconds = ms > 1000 ? Math.round(ms / 1000) : Math.ceil(ms / 1000);
  let minutes = Math.floor(seconds / 60);
  seconds %= 60;
  let hours = Math.floor(minutes / 60);
  minutes %= 60;
  const maybeHours = hours === 0 && !full ? '' : `${hours}:`;
  minutes = minutes.toString().padStart(2, '0');
  seconds = seconds.toString().padStart(2, '0');
  return `${maybeHours}${minutes}:${seconds}`;
}

function roundDecimal(value, places = 2) {
  return (
    Math.round(value * Math.pow(10, places)) / Math.pow(10, places)
  ).toFixed(places);
}

export function inClosestUnit(type, places = 2, addPlaceThreshold = Infinity) {
  const denominations =
    type === 'bits'
      ? ['b', 'Kb', 'Mb', 'Gb', 'Tb']
      : ['bytes', 'kB', 'MB', 'GB', 'TB'];
  const multiplier = type === 'bits' ? 1000 : 1024;
  return value => {
    if (value === 0) {
      return type === 'bits' ? '0 b' : '0 bytes';
    }

    for (let i = 0; i < denominations.length; i++) {
      if (
        Math.abs(value) < Math.pow(multiplier, i + 1) ||
        i === denominations.length - 1
      ) {
        const valueInUnit = roundDecimal(
          value / Math.pow(multiplier, i),
          value < addPlaceThreshold ? places : places + 1,
        );
        return `${valueInUnit} ${denominations[i]}`;
      }
    }
  };
}

export const bitsInClosestUnit = inClosestUnit('bits', 0, 1000000);
export const bytesInClosestUnit = inClosestUnit('bytes');

// The safer way to set "dangerous" HTML in a React context
// taken from https://stackoverflow.com/questions/34424845/adding-script-tag-to-react-jsx
// Does not cause errors on e.g. the project page like document.body.appendChild()
export function setDangerousHtml(html, el) {
  if (el === null) return;
  const range = document.createRange();
  range.selectNodeContents(el);
  range.deleteContents();
  el.appendChild(range.createContextualFragment(html));
}
