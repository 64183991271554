import { Anchor, Text, rem } from '../typography';
import * as colors from '../colors';
import { Dialog } from '../dialog';

const supportedMediaTypes = [
  'AVI',
  'Active Streaming Format',
  'Flash',
  'MPEG-1',
  'MPEG-2 (Transport Stream)',
  'MPEG-4',
  'Material Exchange Format',
  'Matroska',
  'Ogg',
  'Quicktime',
  'WebM',
];

export function UnsupportedMedia({ fileName }) {
  return (
    <Dialog heading="Invalid file type" confirmText="OK" closeOnConfirm isOpen>
      <Text
        css={{
          fontSize: rem(14),
          color: colors.grey2,
          marginBottom: 6,
        }}
      >
        A file you attempted to upload, “{fileName}”, is of a file type that is
        currently not supported. We currently support the following file types:
      </Text>
      <ul
        css={{
          margin: 0,
          marginBottom: 12,
          color: colors.grey2,
          li: { lineHeight: rem(18) },
        }}
      >
        {supportedMediaTypes.map(type => (
          <li key={type}>{type}</li>
        ))}
      </ul>
      <Text css={{ fontSize: rem(14), color: colors.grey2, marginBottom: 18 }}>
        Please{' '}
        <Anchor
          href="mailto:info@pixop.com"
          target="_blank"
          rel="noopener noreferrer"
          css={{ fontSize: rem(14), color: colors.orange0 }}
        >
          get in touch with us
        </Anchor>{' '}
        if you need support for additional file types.
      </Text>
    </Dialog>
  );
}
