import React from 'react';
import styled from '@emotion/styled';
import { connect } from 'react-redux';

import { fetch } from '../../requests';
import * as colors from '../../colors';
import { Text } from '../../typography';
import { Input } from '../../forms';
import { Dialog } from '../../dialog';
import {
  presetResolutions,
  codecs,
  containers,
  deinterlacers,
  reshapers,
  denoisers,
  stabilizers,
  augmenters,
  scalers,
  frameRateConverters,
  debanders,
  postProcessors,
  scanningTypes,
  interlacedFieldOrderModes,
  clarityBoostOptions,
  grainSizeOptions,
  dvres2Variants,
  formatPixelFormatName,
  frameRateOptions,
  formatBoolean,
} from './process-video-options';
import { addToast } from '../../store/toasts';
import { currentTeamSelector } from './../../store/selectors';
import { addPreset } from '../../store/presets';
import { closeDialog } from '../../store/dialog';
import { bitsInClosestUnit } from '../../utils';

const Label = styled('label')({
  display: 'block',
  color: colors.grey10,
  fontSize: 12,
  lineHeight: '20px',
  select: {
    fontSize: 14,
  },
});

/**
 * Create Preset modal.
 *
 * === How it works ===
 *
 * This modal is used to display a list of processing options used, and allows
 * the user to create a preset to process future videos with the same options.
 * The modal is displayed after processing a video without an existing preset.
 *
 * Given selected processing options (config) and available processing options
 * (options, i.e. those options that can be selected), a list of options is
 * rendered. The values listed depend on the particular configuration, and may
 * not match the exact config object exactly, rather, it's manually constructed
 * (see formatSelection below) to list labels and their formatted values in
 * a particular order, and only those values that are relevant. For example,
 * bitrate should not be displayed if a custom bitrate is chosen (which is shown
 * instead).
 *
 * Besides a name, this dialog also offers to set an automatic (or fixed custom) bitrate
 * and resolution value. Automatic values are stored as `{ tag: someTag }`,
 * where the tag matches that of the selected value in the dropdown (this
 * option is not provided for custom bitrates or resolutions, as these are not
 * options in a dropdown). For example, if the recommended bitrate was chosen
 * and the user chooses to store the automatic value in the preset, the saved
 * value will be `{ tag: 'recommended' }` at the time of writing (or equivalent). When
 * this preset is chosen for another video, the same tag is chosen – i.e., the
 * recommended bitrate.
 *
 * === How to update it ===
 *
 * Adding a new config value and label is a matter of updating formatSelection
 * to also include this new config value and label. If there are dependencies
 * on other values, i.e., if this config value is only relevant if another
 * value is also set, then this must also be checked before pushing the label
 * and value pair.
 */

// Given preset options, remove invalid or uneccessary options.
function getPresetOptions(opts) {
  return Object.entries(opts).reduce((acc, item) => {
    const [key, val] = item;

    console.log(key + ' ' + val);

    // Ignore codec-specific options.
    if (key === 'dnxhdProfile' && opts.codec !== 'dnxhd') {
      return acc;
    }
    if (key === 'proresProfile' && opts.codec !== 'prores') {
      return acc;
    }

    // Ignore filter-specific options.
    if (key === 'interlacedFieldOrderMode' && opts.deinterlacer === 'none') {
      return acc;
    }
    if (key === 'normalizedCropCoordinates' && opts.reshaper !== 'reshape') {
      return acc;
    }
    if (
      [
        'autoWhiteBalance',
        'colorBoost',
        'relightIntensity',
        'backgroundBlur',
      ].includes(key) &&
      opts.augmenter !== 'faceforward'
    ) {
      return acc;
    }
    if (key === 'clarityBoost' && opts.scaler !== 'pabsr1') {
      return acc;
    }
    if (key === 'dvres2Variant' && opts.scaler !== 'dvres2') {
      return acc;
    }
    if (key === 'frameRate' && opts.frameRateConverter === 'none') {
      return acc;
    }
    if (
      ['grainSize', 'grainStrength'].includes(key) &&
      opts.postProcessor !== 'filmgrain'
    ) {
      return acc;
    }

    // Ignore empty values, but keep all other provided options.
    if (val != null && val != 'none') {
      acc[key] = val;
    }

    return acc;
  }, {});
}

function _CreatePreset({
  team,
  presets,
  dispatch,
  config,
  videoId,
  bitrates,
  resolutions,
  outputAspectRatios,
}) {
  const inputFocusRef = useFocus();

  const presetSelection = React.useMemo(
    () => formatSelection(config, bitrates, resolutions, outputAspectRatios),
    [config, bitrates, resolutions, outputAspectRatios],
  );

  const numCustomPresets = presets.length;
  const [name, setName] = React.useState(
    `My new preset #${numCustomPresets + 1}`,
  );

  function createPreset() {
    // Config values to include in the preset.
    const configKeys = [
      'container',
      'codec',
      'dnxhdProfile',
      'proresProfile',
      'chromaSubsampling',
      'scanningType',
      'bitrate',
      'customBitrate',
      'resolution',
      'customResolution',
      'deinterlacer',
      'interlacedFieldOrderMode',
      'reshaper',
      'normalizedCropCoordinates',
      'denoiser',
      'stabilizer',
      'augmenter',
      'scaler',
      'outputAspectRatio',
      'clarityBoost',
      'autoWhiteBalance',
      'colorBoost',
      'relightIntensity',
      'backgroundBlur',
      'grainSize',
      'grainStrength',
      'dvres2Variant',
      'frameRateConverter',
      'frameRate',
      'customFrameRate',
      'debander',
      'postProcessor',
      'fixedOutputResolution',
    ];

    const bitrate = getBitrate({
      config,
      bitrates,
    });

    const resolution = getResolution({
      config,
    });

    const outputAspectRatio = getOutputAspectRatio({
      config,
    });

    return fetch('/api/presets', {
      method: 'POST',
      body: {
        name,
        videoId,
        teamId: team.id,
        options: getPresetOptions({
          ...pick(config, configKeys),
          bitrate,
          resolution,
          outputAspectRatio,
        }),
      },
      headers: { 'Content-Type': 'application/json' },
    })
      .then(res => res.json())
      .then(response => {
        dispatch(addPreset(response));
        dispatch(closeDialog());
        dispatch(addToast({ text: 'New preset created.' }));
      });
  }

  return (
    <Dialog
      heading="Create a new preset?"
      onConfirm={createPreset}
      confirmText="Create preset"
      containerStyles={{ position: 'relative' }}
      isOpen
      closeOnConfirm
    >
      <Text css={{ marginBottom: 12 }}>
        You can create a preset from the configuration used to process the
        video, which can be useful when processing multiple videos with the
        exact same settings.
      </Text>
      <Label css={{ marginBottom: 12 }}>
        Processing settings saved
        <ul css={{ color: colors.black1, marginTop: 6 }}>
          {presetSelection.map(({ label, value }) => (
            <li key={label}>
              {label}: {value}
            </li>
          ))}
        </ul>
      </Label>
      <div
        css={{
          display: 'flex',
          justifyContent: 'space-between',
          margin: '0 24px',
        }}
      />
      <Label css={{ marginTop: 6, marginBottom: 12 }}>
        Preset name
        <Input
          ref={inputFocusRef}
          type="text"
          placeholder="Preset name"
          value={name}
          onChange={event => setName(event.target.value)}
          css={{ display: 'block', marginTop: 12, width: 'calc(100% - 20px)' }}
        />
      </Label>
    </Dialog>
  );
}

export const CreatePreset = connect(state => ({
  team: currentTeamSelector(state),
  presets: state.presets.filter(
    p => !p.builtin && p.team_id === state.currentTeamId,
  ),
}))(_CreatePreset);

// Given a config object, return a list of label / value pairs
// with the relevant options.
function formatSelection(config, bitrates, resolutions, outputAspectRatios) {
  // A workaround for the fact that some options are stored as
  // tuples of identifying name and its lavel. E.g.:
  //   ['scale', 'Bicubic Interpolation'];
  // so
  //   getLabel(scalers, 'scale') -> 'Bicubic Interpolation'.
  const getLabelFromValueLabelPair = (pair, value) =>
    pair.find(([k]) => k === value)[1];

  // Push a label & value pair if the value is set.
  const selection = [];
  const createPair = (label, value) =>
    typeof value != null &&
    value.toLowerCase() !== 'none' &&
    selection.push({ label, value });

  createPair('Codec', codecs[config.codec]);

  // prores and dnxhd codecs have their own profiles, which are otherwise omitted.
  if (config.codec === 'prores') {
    createPair('ProRes profile', JSON.parse(config.proresProfile).name);
  } else if (config.codec === 'dnxhd') {
    createPair('DNxHD/HR profile', JSON.parse(config.dnxhdProfile).family);
  }

  createPair('Container', containers[config.container]);
  createPair(
    'Chroma subsampling',
    formatPixelFormatName(config.chromaSubsampling),
  );
  createPair(
    'Scanning',
    getLabelFromValueLabelPair(scanningTypes, config.scanningType),
  );
  createPair(
    'Deinterlacer',
    getLabelFromValueLabelPair(deinterlacers, config.deinterlacer),
  );
  createPair(
    'Reshaper',
    getLabelFromValueLabelPair(reshapers, config.reshaper),
  );
  createPair(
    'Denoiser',
    getLabelFromValueLabelPair(denoisers, config.denoiser),
  );
  createPair(
    'Stabilizer',
    getLabelFromValueLabelPair(stabilizers, config.stabilizer),
  );
  createPair(
    'Augmenter',
    getLabelFromValueLabelPair(augmenters, config.augmenter),
  );
  createPair('Scaler', getLabelFromValueLabelPair(scalers, config.scaler));
  createPair(
    'Frame rate converter',
    getLabelFromValueLabelPair(frameRateConverters, config.frameRateConverter),
  );
  createPair(
    'Debander',
    getLabelFromValueLabelPair(debanders, config.debander),
  );
  createPair(
    'Post processor',
    getLabelFromValueLabelPair(postProcessors, config.postProcessor),
  );

  // Add a custom field when using a deinterlacer.
  if (config.deinterlacer !== 'none') {
    createPair(
      'Interlaced Field Order',
      getLabelFromValueLabelPair(
        interlacedFieldOrderModes,
        config.interlacedFieldOrderMode,
      ),
    );
  }

  // Add a custom field when using the reshaper tool.
  if (config.reshaper !== 'none') {
    const [nx1, ny1, nx2, ny2] = config.normalizedCropCoordinates.map(v =>
      parseFloat(v.toFixed(4)),
    );

    createPair('Cropped area', `(${nx1},${ny1})-(${nx2},${ny2}) (norm.)`);
  }

  if (config.scaler === 'pabsr1') {
    // Add a custom field when using Pixop Super Resolution.
    createPair(
      'Clarity boost',
      getLabelFromValueLabelPair(clarityBoostOptions, config.clarityBoost),
    );
  } else if (config.scaler === 'dvres2') {
    // Add a custom field when using Pixop Deep Restoration 2.
    createPair(
      'Pixop Deep Restoration 2 variant',
      getLabelFromValueLabelPair(dvres2Variants, config.dvres2Variant),
    );
  }

  if (config.scaler !== 'none') {
    // If a custom resolution is set, it means it should be used over the provided
    // resolution (if any).
    if (config.customResolution) {
      const { width, height } = config.customResolution;
      createPair('Resolution', `${width}×${height} (custom)`);
    } else {
      const outputAspectRatioTag = getOutputAspectRatio({
        config,
      }).tag;
      const resolutionTag = getResolution({
        config,
      }).tag;

      const resolution = resolutions[outputAspectRatioTag].find(
        r => r.resolution.tag === resolutionTag,
      );
      createPair('Resolution', `${resolution.genericLabel}`);

      const outputAspectRatio = outputAspectRatios.find(
        ar => ar.tag === outputAspectRatioTag,
      );
      createPair('Aspect Ratio', `${outputAspectRatio.genericLabel}`);
    }
  }

  // Add a custom field when using a frame rate converter.
  if (config.frameRateConverter !== 'none') {
    if (config.customFrameRate) {
      createPair(
        'Target frame rate',
        `${parseFloat(config.customFrameRate.fps.toFixed(3))} FPS (custom)`,
      );
    } else {
      createPair(
        'Target frame rate',
        getLabelFromValueLabelPair(
          frameRateOptions,
          JSON.stringify(parseFloat(config.frameRate)),
        ),
      );
    }
  }

  if (config.augmenter === 'faceforward') {
    // Add a custom fields when using Pixop Face Forward.
    createPair('Face relighting intensity', `${config.relightIntensity}%`);
    createPair(
      'Auto white balance',
      `${formatBoolean(config.autoWhiteBalance)}`,
    );
    createPair('Color boost', `${config.colorBoost}%`);
    createPair('Background blur', `${config.backgroundBlur}`);
  }

  if (config.postProcessor === 'filmgrain') {
    // Add a custom fields when using Pixop Film Grain.
    createPair(
      'Grain size',
      getLabelFromValueLabelPair(grainSizeOptions, config.grainSize),
    );
    createPair('Grain strength', `${config.grainStrength}`);
  }

  // If a custom bitrate is set, it means it should be used over the provided
  // bitrate (if any).
  if (config.customBitrate) {
    createPair(
      'Bitrate',
      `${bitsInClosestUnit(config.customBitrate)}ps (custom)`,
    );
  } else {
    const { tag, singularOption } = getBitrate({ config, bitrates });

    if (!singularOption) {
      // only for dynamic profiles - i.e. not ProRes, DNxHD/HR, etc.
      const bitrate = bitrates.find(b => b.tags.includes(tag));

      createPair('Bitrate', `${bitrate.genericLabel}`);
    } else {
      createPair('Bitrate', 'Recommended');
    }
  }

  if (config.fixedOutputResolution !== 'none') {
    const resolutionLabel = presetResolutions
      .filter(([value, label]) => value.tag === config.fixedOutputResolution)
      .map(([value, label]) => label);

    createPair('Fixed output resolution', `${resolutionLabel}`);
  }

  return selection;
}

function getBitrate({ config, bitrates }) {
  if (config.customBitrate != null) {
    // Custom bitrate provided, use it.
    return config.customBitrate;
  }

  if (
    config.codec !== 'prores' &&
    config.codec !== 'dnxhd' &&
    config.codec !== 'xdcam'
  ) {
    const bitrate = bitrates.find(b => b.bitrate === config.bitrate);

    // Custom bitrate not provided, and value set to automatic, use first tag.
    return {
      tag: bitrate ? bitrate.tags[0] : 'recommended',
      singularOption: false,
    };
  }

  return {
    tag: 'recommended',
    singularOption: true,
  };
}

function getOutputAspectRatio({ config }) {
  return {
    tag: config.outputAspectRatio.tag,
  };
}

function getResolution({ config }) {
  if (config.scaler === 'none') {
    return null;
  }

  if (config.customResolution != null) {
    // Custom resolution provided, use it.
    return config.customResolution;
  }

  // Custom resolution not provided, and value set to automatic, use tag.
  return {
    tag: config.resolution.tag,
  };
}

// Returns a ref which can be passed to an element to focus it on mount.
function useFocus() {
  const ref = React.useRef();
  React.useLayoutEffect(() => {
    if (ref.current) ref.current.focus();
  }, []);
  return ref;
}

// Returns a new object with only the provided keys.
function pick(obj, keys) {
  return keys.reduce((acc, item) => {
    const value = obj[item];
    if (typeof value != null) {
      acc[item] = value;
    }
    return acc;
  }, {});
}
