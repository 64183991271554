import { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';

import { Dialog } from '../dialog';
import { Alert, Field } from '../forms';
import { fetch } from '../requests';
import { addProject } from '../store/projects';
import { addToast } from '../store/toasts';

function _CreateProject({ teamId, addProject, addToast }) {
  const field = useRef(null);
  const [name, setName] = useState('');
  const [error, setError] = useState(null);

  useEffect(() => {
    field.current.focus();
  }, []);

  function onConfirm() {
    return fetch('/api/projects', {
      method: 'POST',
      body: { teamId, name },
    })
      .then(res => res.json())
      .then(result => {
        if (result.error) {
          return Promise.reject(result.error);
        }

        if (result && result.project) {
          addToast({ text: 'Project successfully created' });
          addProject(result.project);
        }
        return true;
      })
      .catch(error => {
        setError(error);
        return false;
      });
  }

  function onChange({ target }) {
    setName(target.value);
  }

  return (
    <Dialog
      heading="Create project"
      confirmText="Save"
      onConfirm={onConfirm}
      closeOnConfirm
      isOpen
    >
      {error && <Alert text={error} styles={{ marginBottom: 12 }} />}
      <Field
        label="Project name"
        placeholder="Choose a project name"
        name="name"
        type="text"
        innerRef={field}
        onChange={onChange}
      />
    </Dialog>
  );
}

export const CreateProject = connect(
  state => ({ teamId: state.currentTeamId }),
  { addProject, addToast },
)(_CreateProject);
