import { Component } from 'react';
import { connect } from 'react-redux';

import * as colors from '../colors';
import { rem } from '../typography';
import { Link } from '../link';
import { SquareButton } from '../button';
import { FormCard, FormTitle, Field, Alert } from '../forms';
import { fetch } from '../requests';
import { AuthLayout } from '../main-layout';
import { login } from '../analytics';

class _Authenticate extends Component {
  constructor(props) {
    super(props);
    const urlSearchParams = new URLSearchParams(window.location.search);
    const isVerified = urlSearchParams.get('v');
    const shouldFadeIn = !!urlSearchParams.get('f');
    const hasChangedPassword = !!urlSearchParams.get('c');
    this.state = {
      shouldFadeIn,
      hasChangedPassword,
      pleaseLogIn: false,
      error: false,
      fieldValues: this.defaultFieldValues,
      fieldValidation: this.defaultFieldValidation,
      isVerified: isVerified === 't' ? true : isVerified === 'f' ? false : null,
      hasJoinedTeam: urlSearchParams.get('t'),
      isButtonDisabled: false,
    };
  }

  defaultFieldValues = {
    email: '',
    password: '',
  };

  defaultFieldValidation = {
    email: false,
    password: false,
  };

  onChange = ({ target }) => {
    this.setState({
      fieldValues: {
        ...this.state.fieldValues,
        [target.name]: target.value,
      },
    });
  };

  validateForm = () => {
    const { email, password } = this.state.fieldValues;

    this.setState({
      error: false,
      fieldValidation: this.defaultFieldValidation,
    });

    if (email === '') {
      this.setState({
        fieldValidation: {
          ...this.defaultFieldValidation,
          email: 'Email address required',
        },
      });
      return false;
    }

    if (!email.includes('@')) {
      this.setState({
        fieldValidation: {
          ...this.defaultFieldValidation,
          email: 'Invalid email address',
        },
      });
      return false;
    }

    if (password === '') {
      this.setState({
        fieldValidation: {
          ...this.defaultFieldValidation,
          password: 'Password required',
        },
      });
      return false;
    }

    if (password.length < 10 || password.length > 64) {
      this.setState({
        fieldValidation: {
          ...this.defaultFieldValidation,
          password: 'Password must be between 10 and 64 characters',
        },
      });
      return false;
    }

    return true;
  };

  onSubmit = event => {
    event.preventDefault();

    if (!this.validateForm()) {
      return;
    }

    this.setState({ isButtonDisabled: true });

    const params = new URLSearchParams(window.location.search);
    const redirectTo = params.get('redirectedFrom');

    fetch('/api/login', {
      method: 'POST',
      body: {
        email: this.state.fieldValues.email,
        password: this.state.fieldValues.password,
      },
    })
      .then(res => res.json())
      .then(({ error, ...initialState }) => {
        this.setState({ isButtonDisabled: false });

        if (error) {
          this.setState({ error });
          return;
        }

        // Dispatch analytics event
        login(initialState.teams[0]);

        // Handle redirect.
        if (redirectTo) {
          window.location = redirectTo;
          return;
        }

        this.props.dispatch({ type: 'reset', payload: initialState });
        this.props.history.push('/', { replace: true });
      })
      .catch(() => {
        this.setState({
          isButtonDisabled: false,
          error: 'Unknown error; please try again',
        });
      });
  };

  componentDidMount() {
    const params = new URLSearchParams(window.location.search);
    const shouldFadeIn = params.get('f');
    const pleaseLogIn = params.get('p');
    this.setState({ shouldFadeIn, pleaseLogIn });
    if (shouldFadeIn) {
      setTimeout(() => {
        this.setState({ isTransitioning: true });
      }, 10);
    }
  }

  render() {
    return (
      <AuthLayout>
        <div id="register-recaptcha" css={{ display: 'none' }} />
        <FormCard
          css={{
            margin: '0 auto',
            transition: 'all .24s ease-in',
            opacity: this.state.shouldFadeIn
              ? this.state.isTransitioning
                ? 1
                : 0
              : 1,
          }}
        >
          <FormTitle css={{ alignSelf: 'center' }}>Log in</FormTitle>
          {this.state.error && <Alert text={this.state.error} />}
          {this.state.hasChangedPassword && (
            <p css={{ color: colors.grey3, fontSize: rem(14) }}>
              You have successfully changed your password; please log in.
            </p>
          )}
          {this.state.pleaseLogIn && (
            <p css={{ color: colors.grey3, fontSize: rem(14) }}>
              Please log in to continue.
            </p>
          )}
          {this.state.hasJoinedTeam &&
            (this.state.hasJoinedTeam === 't' ? (
              <p css={{ color: colors.grey3, fontSize: rem(14) }}>
                You have successfully joined the team; please log in to
                continue.
              </p>
            ) : (
              <Alert text="Could not join team, link expired. If you have already accepted an invitation to join the team, try logging in. Otherwise contact the team administrator." />
            ))}
          {this.state.isVerified === true ? (
            <p css={{ color: colors.grey3, fontSize: rem(14) }}>
              You have successfully verified your email address; please log in.
            </p>
          ) : (
            this.state.isVerified === false && (
              <Alert text="Email verification link invalid or expired; please reset your password" />
            )
          )}
          <Field
            type="email"
            label="Email"
            fieldName="email"
            placeholder="Your email address"
            autoComplete="username"
            value={this.state.fieldValues.email}
            onChange={this.onChange}
            validationMessage={this.state.fieldValidation.email}
            autoFocus
            required
          />
          <Field
            type="password"
            label="Password"
            fieldName="password"
            placeholder="Your password"
            autoComplete="current-password"
            value={this.state.fieldValues.password}
            onChange={this.onChange}
            validationMessage={this.state.fieldValidation.password}
            required
          />
          <SquareButton
            css={{ alignSelf: 'center', width: 130, marginTop: 10 }}
            onClick={this.onSubmit}
            type="submit"
            disabled={this.state.isButtonDisabled}
            isLoading={this.state.isButtonDisabled}
          >
            Log in
          </SquareButton>
          <Link
            to="/reset-password"
            css={{
              color: colors.grey3,
              fontSize: rem(12),
              alignSelf: 'center',
            }}
          >
            Forgot password?
          </Link>
          <span
            css={{
              color: colors.grey3,
              fontSize: rem(14),
              alignSelf: 'center',
              marginTop: 10,
              marginBottom: 5,
            }}
          >
            Don’t have an account?{' '}
            <Link
              to="/register"
              css={{ color: colors.orange0, textDecoration: 'none' }}
            >
              Sign up
            </Link>
          </span>
        </FormCard>
      </AuthLayout>
    );
  }
}

export const Authenticate = connect()(_Authenticate);
