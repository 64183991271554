import { forwardRef } from 'react';
import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';

import { rem } from './typography';
import * as colors from './colors';
import { Ellipsis } from './icons';

export const RoundedButton = styled.button(props => ({
  backgroundColor: props.onClick ? colors.white0 : colors.grey9,
  color:
    props.color && props.onClick
      ? props.color
      : props.onClick
      ? colors.orange1
      : colors.white0,
  fontSize: rem(13),
  height: 30,
  border: `1px solid ${props.onClick ? colors.white0 : colors.grey9}`,
  borderRadius: '30px',
  padding: '6px 16px 4px',
  boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.05)',
  transition: 'border .18s ease, box-shadow .18s ease',
  userSelect: 'none',
  outline: 'none',
  ':hover:not(:disabled)': {
    border: props.onClick
      ? `1px solid ${
          props.color && props.onClick
            ? props.color
            : props.onClick
            ? colors.orange1
            : colors.grey9
        }`
      : `1px solid ${colors.grey9}`,
  },
  ':active:not(:disabled)': {
    boxShadow: props.onClick
      ? `0 0 0 2px ${colors.hexToRgba(
          props.color ? props.color : colors.orange1,
          0.6,
        )}`
      : '0 0 10px 0 rgba(0, 0, 0, 0.05)',
  },
  ':disabled': {
    color: colors.grey5,
    cursor: 'not-allowed',
  },
  cursor: props.onClick ? 'pointer' : 'not-allowed',
}));

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
`;

export const Spinner = styled('div')({
  border: '2px solid #fff',
  borderTopColor: 'transparent',
  width: 20 - 3 * 2,
  height: 20 - 3 * 2,
  opacity: 1,
  borderRadius: '50%',
  animation: `${spin} 1s cubic-bezier(0.32, 0.9, 0.66, 0.29) infinite`,
});

const _SquareButton = styled('button')(
  props => {
    const mainColor =
      props.mainColor || (props.danger ? colors.red0 : colors.orange1);
    return {
      display: 'inline-flex',
      boxSizing: 'border-box',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '2px 16px 0px',
      height: 40,
      minWidth: 80,
      backgroundColor: mainColor,
      color: colors.white0,
      fontSize: rem(14),
      textShadow: '0 1px 2px rgba(0, 0, 0, 0.1)',
      border: 'none',
      borderRadius: 3,
      transition:
        'border .18s ease, box-shadow .18s ease, background-color .18s ease, filter .18s ease',
      userSelect: 'none',
      ':hover': {
        filter: 'brightness(105%)',
        backgroundColor: mainColor,
        textDecoration: 'none',
        cursor: 'pointer',
      },
      ':active:not(:disabled)': {
        color: colors.white0,
        filter: 'brightness(110%)',
        backgroundColor: mainColor,
        boxShadow: `0 0 0 2px ${colors.hexToRgba(mainColor, 0.6)}`,
      },
      ':focus:not(:disabled)': {
        outline: 'none',
        boxShadow: `0 0 0 2px ${colors.hexToRgba(mainColor, 0.6)}`,
      },
      '::-moz-focus-inner': {
        border: 0, // Remove pesky dotted outline in Firefox.
      },
      ':disabled': {
        backgroundColor: colors.grey9,
        cursor: 'not-allowed',
      },
    };
  },
  props =>
    props.secondary && {
      backgroundColor: colors.white0,
      color: colors.orange1,
      border: `1px solid ${colors.orange1}`,
      textShadow: 'none',
      ':hover': {
        color: colors.white0,
      },
    },
);

export function SquareButton(props) {
  const { isLoading, children, ...rest } = props;
  return (
    <_SquareButton disabled={props.disabled || isLoading} {...rest}>
      {isLoading ? <Spinner /> : children}
    </_SquareButton>
  );
}

export const ExpandButton = forwardRef(({ styles, ...rest }, ref) => (
  <div
    role="button"
    tabIndex="0"
    ref={ref}
    css={{
      ...styles,
      backgroundColor: colors.white2,
      border: `1px solid ${colors.white2}`,
      padding: '0 9px',
      borderRadius: 17,
      transition: 'border-color .18s ease',
      boxSizing: 'border-box',
      height: 17,
      width: 35,
      position: 'relative',
      ':hover:not(:focus)': {
        border: `1px solid ${colors.grey0}`,
      },
    }}
    {...rest}
  >
    <Ellipsis styles={{ position: 'absolute', top: 5, left: 8 }} />
  </div>
));
