import { createAction, createReducer } from './utils';

export const setUser = createAction('setUser');
export const updateUser = createAction('updateUser');

export const userReducer = createReducer(null, {
  [setUser]: (state, action) => action.payload,
  [updateUser]: (state, action) => {
    return { ...state, ...action.payload };
  },
});
