import { useState } from 'react';
import { connect } from 'react-redux';
import useReactRouter from 'use-react-router';

import { teamProjectsSelector, teamVideosSelector } from '../store/selectors';
import { updateVideo } from '../store/videos';
import * as colors from '../colors';
import { Text } from '../typography';
import { Alert } from '../forms';
import { Dialog } from '../dialog';
import {
  RoundedIconButton,
  ArrowLeft,
  Chevron,
  Film,
  Clapperboard,
  Folder,
} from '../icons';
import { fetch } from '../requests';
import { addToast } from '../store/toasts';

const projectContainerCss = selected => ({
  display: 'flex',
  alignItems: 'center',
  height: 40,
  padding: '0 10px',
  color: colors.orange1,
  borderRadius: 3,
  border: '1px solid transparent',
  cursor: 'pointer',
  ':hover': {
    backgroundColor: colors.white3,
    border: `1px solid ${colors.grey4}`,
  },
  ...(selected
    ? {
        backgroundColor: colors.white3,
        border: `1px solid ${colors.grey4}`,
      }
    : {}),
});

const Project = ({ name, onClick, selected }) => (
  <div onClick={onClick} css={projectContainerCss(selected)}>
    <Folder styles={{ marginRight: 10 }} />
    <span css={{ flex: 1 }}>{name}</span>
    <Chevron
      width={25}
      height={25}
      color={colors.grey2}
      styles={{ transform: 'rotate(-90deg)', marginRight: -10 }}
    />
  </div>
);

const ProjectVideo = ({ name, onClick, selected }) => (
  <div onClick={onClick} css={projectContainerCss(selected)}>
    <Film color={colors.black1} styles={{ marginRight: 10 }} />
    <span css={{ flex: 1 }}>{name}</span>
    <Chevron
      width={25}
      height={25}
      color={colors.grey2}
      styles={{ transform: 'rotate(-90deg)', marginRight: -10 }}
    />
  </div>
);

const ProcessedVideo = ({ name, clipId }) => (
  <div css={{ display: 'flex', alignItems: 'center', height: 35 }}>
    {!clipId && (
      <Film
        color={colors.black1}
        styles={{ marginLeft: 10, marginRight: 10 }}
      />
    )}
    {clipId && (
      <Clapperboard
        color={colors.black1}
        styles={{ marginLeft: 10, marginRight: 10 }}
      />
    )}
    <Text>{name}</Text>
  </div>
);

function _MoveVideo({
  projects,
  currentVideo,
  videos,
  initialProjectId,
  videoId,
  navigateOnSuccess,
  updateVideo,
  addToast,
}) {
  const { history } = useReactRouter();
  const [selectedProjectId, setSelectedProjectId] = useState(initialProjectId);
  const [selectedVideoId, setSelectedVideoId] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);

  function move({ targetProjectId, targetVideoId }) {
    // find all videos affected
    const affectedVideos = [currentVideo].concat(
      videos.filter(v =>
        !currentVideo.clip_id
          ? v.parent_id === videoId
          : currentVideo.clip_id === currentVideo.id &&
            v.clip_id === currentVideo.clip_id,
      ),
    );

    // create array of patch requests
    const patchRequests = affectedVideos.map(v => {
      let patchRequest = {
        id: v.id,
      };

      if (currentVideo.clip_id) {
        patchRequest = {
          ...patchRequest,
          clip_id: null,
          parent_id:
            v.clip_id === v.id || currentVideo.clip_id !== currentVideo.id
              ? null
              : currentVideo.clip_id,
        };
      } else {
        // always change parent for processed videos
        if (targetVideoId || currentVideo.parent_id) {
          patchRequest = { ...patchRequest, parent_id: targetVideoId };
        }
      }

      return patchRequest;
    });

    // patch all videos in one request
    return fetch(`/api/projects/${targetProjectId}/move-videos`, {
      method: 'PATCH',
      body: patchRequests,
    })
      .then(res => res.json())
      .then(response => {
        if (response.error) {
          return Promise.reject(response.error);
        }

        if (navigateOnSuccess) {
          history.push(
            `/projects/${targetProjectId}/videos/${targetVideoId || videoId}`,
          );
        }

        setTimeout(() => {
          response.videos.forEach(v => updateVideo(v));
        });

        const videoCount = response.videos.length;

        addToast({
          text: `${
            videoCount > 1 ? videoCount + ' videos' : 'Video'
          } successfully moved`,
        });
        return true;
      })
      .catch(error => {
        setErrorMessage(error);
        return false;
      });
  }

  const hasProjects = projects.length > 0;

  let selectedProject;
  let selectedVideo;
  let projectVideos = [];
  let processedVideos = [];
  let confirmText = 'Move to project';

  if (selectedProjectId) {
    selectedProject = projects.find(p => p.id === selectedProjectId);
    projectVideos = videos.filter(
      v => v.project_id === selectedProjectId && !v.parent_id,
    );

    if (selectedVideoId) {
      selectedVideo = videos.find(v => v.id === selectedVideoId);
      processedVideos = videos.filter(v => v.parent_id === selectedVideoId);
      confirmText = 'Move to video';
    }
  }

  let selectedItemName = selectedProject ? selectedProject.name : null;

  if (selectedVideo) {
    selectedItemName += ' / ' + selectedVideo.name;
  }

  return (
    <Dialog
      heading={selectedItemName ? selectedItemName : 'Move to'}
      onConfirm={() =>
        move({
          targetProjectId: selectedProjectId,
          targetVideoId: selectedVideoId,
        })
      }
      confirmText={confirmText}
      confirmProps={{
        disabled:
          !hasProjects ||
          !selectedProjectId ||
          (selectedProjectId === currentVideo.project_id &&
            !selectedVideoId &&
            !currentVideo.parent_id),
      }}
      containerStyles={{ position: 'relative' }}
      isOpen
      closeOnConfirm
    >
      {errorMessage && (
        <Alert styles={{ marginBottom: 12 }} text={errorMessage} />
      )}
      {selectedProjectId && (
        <RoundedIconButton
          icon={ArrowLeft}
          styles={{
            position: 'absolute',
            top: 16,
            left: 13,
          }}
          onClick={() => {
            if (selectedVideoId) {
              setSelectedVideoId(null);
            } else {
              setSelectedProjectId(null);
            }
          }}
          iconProps={{
            color: colors.orange1,
          }}
        />
      )}
      {hasProjects ? (
        selectedVideoId ? (
          processedVideos.length > 0 ? (
            processedVideos.map(video => (
              <ProcessedVideo
                key={video.id}
                clipId={video.clip_id}
                name={video.name}
              />
            ))
          ) : (
            <Text>This original has no processed videos.</Text>
          )
        ) : selectedProjectId ? (
          projectVideos.length > 0 ? (
            projectVideos.map(video => (
              <ProjectVideo
                key={video.id}
                name={video.name}
                onClick={() => setSelectedVideoId(video.id)}
                selected={selectedVideoId === video.id}
              />
            ))
          ) : (
            <Text>This project is empty.</Text>
          )
        ) : (
          projects.map(project => (
            <Project
              key={project.id}
              name={project.name}
              onClick={() => {
                setSelectedProjectId(project.id);
                setSelectedVideoId(null);
              }}
              selected={selectedProjectId === project.id}
            />
          ))
        )
      ) : (
        <Text>
          You must create a new project or upload a video before moving a video.
        </Text>
      )}
    </Dialog>
  );
}

export const MoveVideo = connect(
  (state, ownProps) => {
    const video = state.videos.find(video => video.id === ownProps.videoId);
    const videos = teamVideosSelector(state).filter(
      v => v.id != video.id && v.id != video.parent_id,
    );
    const currentProjectIsCandidate =
      videos.filter(v => v.project_id === video.project_id && !v.parent_id)
        .length > 0 || video.parent_id;
    const projects = teamProjectsSelector(state).filter(
      p => p.id != video.project_id || currentProjectIsCandidate,
    );
    const initialProjectId = currentProjectIsCandidate
      ? video.project_id
      : projects.length === 1
      ? projects[0].id
      : null;
    return {
      projects,
      currentVideo: video,
      videos,
      initialProjectId,
      navigateOnSuccess: ownProps.navigateOnSuccess,
    };
  },
  { updateVideo, addToast },
)(_MoveVideo);
