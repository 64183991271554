import React from 'react';

import { Dialog } from '../dialog';
import { Text } from '../typography';
import * as colors from '../colors';

export function ConfirmReupload({ reason, url, callback }) {
  return (
    <Dialog
      heading="Confirm reupload"
      confirmText="Confirm"
      onConfirm={callback}
      closeOnConfirm
      isOpen
    >
      <Text>
        The file you are attempting to upload{' '}
        {reason === 'uploading'
          ? 'is already being uploaded'
          : 'has already been uploaded'}
        {url ? (
          <>
            {' '}
            (
            {
              <a
                css={{
                  color: colors.orange1,
                  ':hover': { textDecoration: 'underline' },
                }}
                href={url}
                rel="noopener noreferrer"
                target="_blank"
              >
                see here
              </a>
            }
            ).{' '}
          </>
        ) : (
          '. '
        )}
        Are you sure you wish to upload it again?
      </Text>
    </Dialog>
  );
}
