/**
 * Processing Tool
 *
 * The Processing Tool is split into several modules:
 * - index.js            – This file, is the entry point and exports the actual modal.
 * - interface.js        – Contains all visual & interactive React components, including the
 *                         modal itself and all that it renders.
 * - parse-video.js      – Various utilities to parse a video's metadata in order to determine
 *                         both the default values and computing processing options based on
 *                         the video.
 * - generate-options.js – Generate options, both based on fixed defaults, presets, and the
 *                         video itself.
 */

import { connect } from 'react-redux';

import { closeDialog, openDialog } from '../../store/dialog';
import {
  parseVideo,
  computeAspectRatios,
  setReshapedResolution,
} from './parse-video';
import { ProcessingToolModal } from './interface';
import {
  generateOptions,
  getCodecProperties,
  isValidScaledResolution,
  findPresetResolutionForTag,
} from './generate-options';

export const ProcessingTool = connect(
  (state, ownProps) => {
    const video = state.videos.find(video => video.id === ownProps.videoId);
    const team = state.teams.find(team => team.id === video.team_id);

    const source = parseVideo(video);

    const derivedNum = !video.clip_id
      ? state.videos.filter(v => v.parent_id === video.id && !v.clip_id).length
      : state.videos.filter(
          v => v.clip_id === video.clip_id && v.id !== video.clip_id,
        ).length;

    function appraiseVideo({ presetId, presetName, ...config }) {
      return appraiseVideoClip({
        team,
        video,
        source,
        config,
        presetId,
        presetName,
      });
    }

    function processVideo({ targetId, ...config }) {
      return processVideoClip({
        targetId,
        team,
        video,
        config,
      });
    }

    const factoryAndTeamPresets = JSON.parse(
      JSON.stringify(
        state.presets.filter(preset => {
          return preset.builtin || preset.team_id === state.currentTeamId;
        }),
      ),
    );

    const presets = [];

    // Precompute the options and their default values for each preset.
    // TODO: REWRITE THIS - IT'S UNTENABLE TO MAINTAIN IN THE LONG RUN!
    const presetOptions = factoryAndTeamPresets.reduce((acc, preset) => {
      // compute absolute coordinates from normalized cropping coordinates stored in the preset
      let reshapedSourceResolution = source.resolution;

      const presetAdditions = {};

      // Compute crop rectangle in absolute coordinates and save it on the preset.
      if (
        typeof preset.options.reshaper === 'string' &&
        preset.options.reshaper !== 'none' &&
        typeof preset.options.normalizedCropCoordinates === 'object'
      ) {
        const normalizedCropCoordinates =
          preset.options.normalizedCropCoordinates;
        const { width, height } = reshapedSourceResolution;

        const cropCoordinates = [
          Math.round(normalizedCropCoordinates[0] * width),
          Math.round(normalizedCropCoordinates[1] * height),
          Math.round(normalizedCropCoordinates[2] * width),
          Math.round(normalizedCropCoordinates[3] * height),
        ];

        //preset.options.cropCoordinates = cropCoordinates;
        presetAdditions.cropCoordinates = cropCoordinates;

        reshapedSourceResolution = {
          width: cropCoordinates[2] - cropCoordinates[0],
          height: cropCoordinates[3] - cropCoordinates[1],
        };
      }

      // Add reshaped source resolution to preset.
      preset.reshapedSourceResolution = reshapedSourceResolution;

      // Compute mutated source based on the reshaped source resolution.
      const presetSource = JSON.parse(JSON.stringify(source));
      setReshapedResolution({
        source: presetSource,
        ...reshapedSourceResolution,
      });

      const opts = generateOptions(presetSource, preset.options, derivedNum);

      if (
        typeof opts.defaultValues.bitrate !== 'number' &&
        opts.defaultValues.bitrate.tag
      ) {
        const presetBitrate = opts.options.bitrates.find(b =>
          b.tags.includes(opts.defaultValues.bitrate.tag),
        ).bitrate;

        // replaces the bitrate field from an tag-object with the actual value
        presetAdditions.bitrate = presetBitrate;
      }

      if (
        typeof opts.defaultValues.resolution.width !== 'number' &&
        opts.defaultValues.resolution.tag
      ) {
        const presetResolution = opts.options.resolutions[
          opts.defaultValues.outputAspectRatio.tag
        ].find(r => r.resolution.tag === opts.defaultValues.resolution.tag)
          .resolution;

        // replaces the resolution field from an tag-object with the actual value
        presetAdditions.resolution = presetResolution;
      }

      if (
        typeof opts.defaultValues.outputAspectRatio.aspectRatio !== 'number' &&
        opts.defaultValues.outputAspectRatio.tag
      ) {
        const outputAspectRatio = opts.options.outputAspectRatios.find(
          ar => ar.tag === opts.defaultValues.outputAspectRatio.tag,
        );
        const presetOutputAspectRatio = {
          tag: outputAspectRatio.tag,
          aspectRatio: outputAspectRatio.aspectRatio,
        };

        // replaces the outputAspectRatio field from an tag-object with the actual value
        presetAdditions.outputAspectRatio = presetOutputAspectRatio;
      }

      // copy any injected preset options to defaultValues
      Object.keys(presetAdditions).forEach(key => {
        preset.options[key] = presetAdditions[key];
        opts.defaultValues[key] = presetAdditions[key];
      });

      presets.push(preset);

      acc[preset.id] = opts;
      return acc;
    }, {});

    // Add a scaleFactor and an isAllowed flag to all presets, which can be
    // used to determine whether a preset is allowed for the current video.
    // If other factors are relevant later besides the resolution, it can
    // be taken into account here.
    // TODO: REWRITE THIS - IT'S UNTENABLE TO MAINTAIN IN THE LONG RUN!
    factoryAndTeamPresets.forEach(preset => {
      const presetResolution =
        preset.options.customResolution || preset.options.resolution;
      presetOptions[preset.id].scaleFactor =
        presetResolution && presetResolution.height
          ? presetResolution.height / preset.reshapedSourceResolution.height
          : 1;
      presetOptions[preset.id].isAllowed = isValidScaledResolution(
        presetOptions[preset.id].defaultValues.scaler,
        preset.reshapedSourceResolution,
        presetResolution && presetResolution.height
          ? presetResolution
          : preset.reshapedSourceResolution,
      );
    });
    presets.forEach(preset => {
      preset.scaleFactor = presetOptions[preset.id].scaleFactor;
      preset.isAllowed = presetOptions[preset.id].isAllowed;

      // Update preset to include all options displayed (except title), not just the ones it stores.
      preset.rawOptions = preset.options;
      const { title, ...persistentDefaultValues } = presetOptions[
        preset.id
      ].defaultValues;
      preset.options = persistentDefaultValues;
    });

    // Select the first preset by default.
    const defaultPreset = presets[0];
    const { defaultValues } = presetOptions[defaultPreset.id];

    return {
      video,
      derivedNum,
      team,
      source,
      presets,
      appraiseVideo,
      processVideo,
      presetOptions,
      defaultPreset,
      defaultValues,
    };
  },
  { closeDialog, openDialog },
)(ProcessingToolModal);

function appraiseVideoClip({
  team,
  video,
  source,
  config,
  presetId,
  presetName,
}) {
  const nullIfEmpty = value =>
    value === 'none' || value == null ? null : value;

  const toScanning = scanningType =>
    scanningType === 'auto' || scanningType == null
      ? null
      : scanningType === 'progressive'
      ? {
          scanningType: 'progressive',
        }
      : {
          scanningType: 'interlaced',
          interlacedFieldOrder:
            scanningType === 'interlaced_tff' ? 'top_first' : 'bottom_first',
        };

  const crop =
    config.reshaper !== 'none'
      ? {
          x: config.cropCoordinates[0],
          y: config.cropCoordinates[1],
          width: config.cropCoordinates[2] - config.cropCoordinates[0],
          height: config.cropCoordinates[3] - config.cropCoordinates[1],
        }
      : null;

  const resolution = nullIfEmpty(config.scaler)
    ? config.customResolution || config.resolution
    : source.reshapedResolution;

  const fixedOutputResolution = nullIfEmpty(config.fixedOutputResolution)
    ? findPresetResolutionForTag(config.fixedOutputResolution)
    : null;

  // compute pixel aspect ratio (source vs target resolution) + aspect ratio adjusted resolution
  const { displayWidth, displayHeight, pixelAspectRatio } = computeAspectRatios(
    {
      frameWidth: source.reshapedResolution.width,
      frameHeight: source.reshapedResolution.height,
      displayAspectRatio: resolution.width / resolution.height,
    },
  );

  return fetch(`/api/appraise/${video.id}`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      // Resource ownership
      teamId: team.id,
      presetId,
      presetName,
      pixelAspectRatio,
      aspectRatioAdjustedResolution: {
        width: displayWidth,
        height: displayHeight,
      },

      // State based
      container: config.container,
      codec: config.codec,
      chromaSubsampling: config.chromaSubsampling,
      scanning: toScanning(config.scanningType),
      crop,
      resolution,
      bitrate:
        config.bitrate === -1
          ? config.customBitrate
          : config.codec !== 'dnxhd' && config.bitrate > 0
          ? config.bitrate
          : null,
      codecProperties: getCodecProperties(config),

      // Filters
      deinterlacer: nullIfEmpty(config.deinterlacer),
      interlacedFieldOrderMode: nullIfEmpty(config.deinterlacer)
        ? config.interlacedFieldOrderMode
        : null,
      denoiser: nullIfEmpty(config.denoiser),
      stabilizer: nullIfEmpty(config.stabilizer),
      augmenter: nullIfEmpty(config.augmenter),
      scaler: nullIfEmpty(config.scaler),
      clarityBoost: nullIfEmpty(config.clarityBoost),
      autoWhiteBalance: nullIfEmpty(config.autoWhiteBalance),
      colorBoost: nullIfEmpty(config.colorBoost),
      relightIntensity: nullIfEmpty(config.relightIntensity),
      backgroundBlur: nullIfEmpty(config.backgroundBlur),
      grainSize: nullIfEmpty(config.grainSize),
      grainStrength: nullIfEmpty(config.grainStrength),
      dvres2Variant: nullIfEmpty(config.dvres2Variant),
      frameRateConverter: nullIfEmpty(config.frameRateConverter),
      frameRate: nullIfEmpty(config.frameRateConverter)
        ? config.customFrameRate
          ? config.customFrameRate.fps
          : config.frameRate
        : null,
      debander: nullIfEmpty(config.debander),
      postProcessor: nullIfEmpty(config.postProcessor),
      fixedOutputResolution,
    }),
  }).then(res => res.json());
}

function processVideoClip({ targetId, team, video, config }) {
  return fetch(`/api/process/${video.id}`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      targetId,
      videoName: config.title,
      teamId: team.id,
      projectId: video.project_id,
    }),
  }).then(res => res.json());
}
