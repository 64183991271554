import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import { Switch, Route, matchPath } from 'react-router-dom';
import { Elements, PaymentElement, useElements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import countryList from 'react-select-country-list';

import * as colors from '../colors';
import { PoweredByStripe } from '../icons';
import { Alert, Field, FieldSet } from '../forms';
import { Select } from '../select';
import { SquareButton, RoundedButton } from '../button';
import { Link } from '../link';
import { Heading, Text, rem } from '../typography';
import { fetch } from '../requests';
import { updateTeam } from '../store/teams';
import { addToast } from '../store/toasts';
import { closeDialog, openDialog } from '../store/dialog';
import { ability } from '../ability';
import { triggerEvent } from '../events';
import { Invoices } from './invoices';
import { Processing, Utilities } from './transactions';
import { NotFound } from './not-found';
import {
  openBillingSetting,
  addBillingInfo,
  addPaymentMethod,
} from '../analytics';

const baseStyles = {
  lineHeight: '25px',
  paddingLeft: 20,
  margin: '2px 0',
  a: {
    color: colors.orange1,
  },
};

const activeStyles = {
  borderLeft: `3px solid ${colors.orange1}`,
  lineHeight: '25px',
  paddingLeft: 17,
  a: {
    color: colors.black1,
  },
  'a:hover': {
    textDecoration: 'none',
    cursor: 'default',
  },
};

const getItemStyles = active => ({
  ...baseStyles,
  ...(active ? activeStyles : {}),
});

const Separator = ({ styles = {} }) => (
  <hr
    css={{
      height: 1,
      width: '100%',
      border: 'none',
      backgroundColor: colors.hexToRgba(colors.grey3, 0.3),
      ...styles,
    }}
  />
);

function patchTeam(teamId, body) {
  return fetch(`/api/teams/${teamId}`, {
    method: 'PATCH',
    body,
  });
}

function daysUntilDeletionString(team) {
  const daysSinceTeamCreation =
    14 -
    Math.ceil(
      Math.abs(
        (new Date() - new Date(team.created_at)) / (24 * 60 * 60 * 1000),
      ),
    );

  return daysSinceTeamCreation > 1
    ? `in ${daysSinceTeamCreation} days`
    : 'within 24 hours';
}

const StripeFormContent = ({ stripe, currentTeam, setIsEditing, addToast }) => {
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const elements = useElements();

  function handleSubmit(event) {
    event.preventDefault();

    setIsSubmitting(true);

    stripe.then(val => {
      return val
        .confirmSetup({
          elements,
          confirmParams: {
            return_url: `${location.href}`,
          },
        })
        .then(function(result) {
          if (result.error) {
            addToast({ text: result.error.message });
            return;
          }

          setIsEditing(false);
        })
        .finally(() => setIsSubmitting(false));
    });
  }

  return (
    <>
      <form onSubmit={handleSubmit}>
        <PaymentElement
          options={{
            layout: {
              type: 'accordion',
              radios: false,
              spacedAccordionItems: true,
            },
            defaultValues: {
              billingDetails: {
                name: currentTeam.business_name,
                email: currentTeam.billing_email,
                address: {
                  line1: currentTeam.address1,
                  line2: currentTeam.address2,
                  city: currentTeam.city,
                  state: currentTeam.state,
                  country: currentTeam.country,
                  postal_code: currentTeam.postal_code,
                },
              },
            },
          }}
        />
        <SquareButton
          secondary
          css={{ marginTop: 24, marginRight: 12 }}
          disabled={isSubmitting}
          onClick={() => setIsEditing(false)}
        >
          Cancel
        </SquareButton>
        <SquareButton
          css={{ marginTop: 24 }}
          disabled={isSubmitting}
          onClick={handleSubmit}
        >
          Save
        </SquareButton>
      </form>
    </>
  );
};

const StripeForm = ({ stripe, currentTeam, setIsEditing, addToast }) => {
  const [clientSecret, setClientSecret] = React.useState(null);

  function fetchSetupIntent() {
    return fetch(`/api/customer/setup`)
      .then(res => res.text())
      .then(results => {
        if (results) {
          setClientSecret(JSON.parse(results).client_secret);
        }
      });
  }

  React.useEffect(() => {
    fetchSetupIntent();
  }, []);

  return (
    <>
      {clientSecret ? (
        <Elements
          stripe={stripe}
          options={{
            clientSecret,
            currency: 'usd',
            locale: 'en-US',
            fonts: [
              {
                cssSrc: 'https://fonts.googleapis.com/css?family=Hind:400',
              },
            ],
            appearance: {
              theme: 'stripe',
              rules: {
                '.Label': {
                  color: colors.grey3,
                  fontSize: '12px',
                  marginTop: '10px',
                },
                '.Input': {
                  color: colors.black1,
                  fontSize: '14px',
                  lineHeight: '23px',
                },
                '.Input:focus': {
                  border: `1px solid ${colors.hexToRgba(colors.orange1, 0.6)}`,
                  boxShadow: `0 0 0 1px ${colors.hexToRgba(
                    colors.orange1,
                    0.6,
                  )}`,
                },
                '.TermsText': {
                  color: colors.grey3,
                  fontSize: '14px',
                },
                '.AccordionItem': {
                  fontSize: '15px',
                },
                '.AccordionItem--selected': {
                  color: colors.black2,
                },
                '.TabIcon--selected': {
                  color: colors.orange1,
                },
              },
              variables: {
                spacingAccordionItem: '6px',
                fontFamily: 'Hind',
                colorPrimary: colors.orange1,
                colorTextPlaceholder: colors.grey3,
                colorBackground: colors.white4,
                fontSizeBase: '14px',
                borderRadius: '3px',
                fontSmooth: 'auto',
              },
            },
          }}
        >
          <StripeFormContent
            stripe={stripe}
            currentTeam={currentTeam}
            setIsEditing={setIsEditing}
            addToast={addToast}
          />
        </Elements>
      ) : (
        <Text>Please wait...</Text>
      )}
    </>
  );
};

function FormContent({
  stripePublicKey,
  stripe,
  currentTeam,
  updateTeam,
  openDialog,
  closeDialog,
  addToast,
}) {
  const [error, setError] = React.useState(null);
  const [isEditing, setIsEditing] = React.useState(false);

  function submitPaymentMethod(paymentMethodId) {
    return fetch('/api/customer', {
      method: 'POST',
      body: {
        teamId: currentTeam.id,
        paymentMethodId,
      },
    })
      .then(res => res.json())
      .then(response => {
        if (response.error) {
          setError(response.error);
          return;
        }

        setError(null);
        addPaymentMethod(currentTeam);
        paymentMethodChangeTeamUpdate({ updatedTeam: response.team });
        triggerEvent('openProcessingCredits', { amount: 0 });
      });
  }

  function updatePaymentMethod(paymentMethodId) {
    return fetch(`/api/customer/${currentTeam.stripe_customer_id}`)
      .then(res => res.text())
      .then(results => {
        return fetch(`/api/customer/${currentTeam.stripe_customer_id}`, {
          method: 'PUT',
          body: {
            previousPaymentMethodId: results
              ? JSON.parse(results).id
              : undefined,
            nextPaymentMethodId: paymentMethodId,
          },
        })
          .then(res => res.json())
          .then(response => {
            if (response.error) {
              setError(response.error);
              return;
            }

            setError(null);
            if (!methodOnFile) {
              addPaymentMethod(currentTeam);
            }
            paymentMethodChangeTeamUpdate({ updatedTeam: response.team });
            triggerEvent('openProcessingCredits', { amount: 0 });
          });
      });
  }

  const [methodOnFile, setMethodOnFile] = React.useState(null);

  function paymentMethodChangeTeamUpdate({ updatedTeam }) {
    updateTeam({
      id: updatedTeam.id,
      stripe_customer_id: updatedTeam.stripe_customer_id,
      plan_type: updatedTeam.plan_type,
      billing_frequency: updatedTeam.billing_frequency,
      billing_started_at: updatedTeam.billing_started_at,
      acls: updatedTeam.acls,
      bonus: updatedTeam.bonus,
    });
  }

  function fetchMethodOnFile() {
    if (currentTeam.stripe_customer_id) {
      return fetch(`/api/customer/${currentTeam.stripe_customer_id}`)
        .then(res => res.text())
        .then(results => {
          if (results) {
            setMethodOnFile(JSON.parse(results));

            // open the buy processing credits dialog if a payment method is on file
            // and the user is on /billing/buy-credits
            const matchBilling = matchPath(window.location.pathname, {
              path: '/billing/:subpage',
            });

            if (matchBilling && matchBilling.isExact && !methodOnFile) {
              const subpage = matchBilling.params.subpage;

              if (subpage === 'buy-credits') {
                triggerEvent('openProcessingCredits', { amount: 0 });
              }
            }
          } else {
            setMethodOnFile(null);
          }
        });
    }
  }

  function finalizeSetupIntent() {
    const params = new URLSearchParams(location.search);
    const redirectStatus = params.get('redirect_status');

    if (redirectStatus === 'succeeded') {
      const setupIntentId = params.get('setup_intent');
      const setupIntentClientSecret = params.get('setup_intent_client_secret');

      return stripe.then(val => {
        return val.retrieveSetupIntent(setupIntentClientSecret).then(result => {
          window.history.replaceState({}, '', `${location.pathname}`);

          const setupIntent = result.setupIntent;

          if (
            setupIntent &&
            setupIntent.id === setupIntentId &&
            setupIntent.payment_method
          ) {
            if (currentTeam.stripe_customer_id) {
              return updatePaymentMethod(setupIntent.payment_method);
            } else {
              return submitPaymentMethod(setupIntent.payment_method);
            }
          } else {
            addToast({
              text: 'Failed to update payment method, please try again',
            });
          }
        });
      });
    } else if (redirectStatus === 'failed') {
      window.history.replaceState({}, '', `${location.pathname}`);

      // toast
      addToast({ text: 'Authorization failed, please try again' });
    }

    return Promise.resolve();
  }

  React.useEffect(
    () => {
      fetchMethodOnFile();
    },
    [currentTeam],
  );

  React.useLayoutEffect(() => {
    finalizeSetupIntent();
  }, []);

  // display confirmation dialog which settles the account before closing
  function removeMethodOnFile() {
    const description = (
      <>
        Do you want to proceed with the removal of your payment method? Please
        note that your team will immediately be designated as a visitor and you
        will be unable to process or upload new videos. In addition, all video
        data will be deleted in our next daily cleanup and your account will be
        permanently deleted <b>{daysUntilDeletionString(currentTeam)}</b>.
      </>
    );

    openDialog({
      type: 'settleUtilities',
      heading: 'Confirm payment method removal',
      description,
      stripePublicKey,
      team: currentTeam,
      addToast,
      closeDialog,
      postConfirm: isConfirmed => {
        if (isConfirmed) {
          return fetch(
            `/api/customer/${methodOnFile.customer}/payment-method/${
              methodOnFile.id
            }`,
            {
              method: 'DELETE',
            },
          )
            .then(res => res.json())
            .then(response => {
              if (response.error) {
                setError(response.error);
                return;
              }

              setError(null);
              paymentMethodChangeTeamUpdate({
                updatedTeam: response.team,
              });
            });
        }
      },
    });
  }

  return isEditing ? (
    <>
      <p>Please enter your details below:</p>
      <StripeForm
        stripe={stripe}
        currentTeam={currentTeam}
        setIsEditing={setIsEditing}
        addToast={addToast}
      />
    </>
  ) : (
    <>
      {error && <Alert text={error} styles={{ marginBottom: 24 }} />}
      <div
        css={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        {methodOnFile ? (
          <div css={{ color: colors.grey10 }}>
            {methodOnFile.card
              ? 'Card: '
              : methodOnFile.paypal
              ? 'PayPal: '
              : methodOnFile.link
              ? 'Link: '
              : 'Unknown method'}
            <span css={{ color: colors.black1 }}>
              {methodOnFile.card
                ? `ends with ${methodOnFile.card.last4}`
                : methodOnFile.paypal
                ? methodOnFile.paypal.payer_email
                : methodOnFile.link
                ? methodOnFile.link.email
                : ''}
            </span>
          </div>
        ) : (
          <div css={{ color: colors.grey10 }}>No method on file</div>
        )}

        <div>
          <div css={{ float: 'left' }}>
            <RoundedButton
              css={{ marginLeft: 12 }}
              disabled={
                !methodOnFile || ability.cannot('manage', 'PaymentMethod')
              }
              onClick={() => removeMethodOnFile()}
            >
              Remove
            </RoundedButton>
          </div>
          <div css={{ float: 'right' }}>
            <RoundedButton
              css={{ marginLeft: 12 }}
              disabled={ability.cannot('manage', 'PaymentMethod')}
              onClick={() => setIsEditing(true)}
            >
              {methodOnFile ? 'Edit' : 'New method'}
            </RoundedButton>
          </div>
        </div>
      </div>
      {!currentTeam.country && (
        <Alert
          text={
            'Please enter a valid billing address before managing your payment method'
          }
          styles={{
            marginBottom: 24,
            color: colors.orange0,
            border: `1px solid ${colors.hexToRgba(colors.orange0, 0.7)}`,
          }}
        />
      )}
    </>
  );
}

function _PaymentInformation({
  stripePublicKey,
  currentTeam,
  updateTeam,
  addToast,
  openDialog,
  closeDialog,
}) {
  const defaultFieldValues = {
    businessName: currentTeam.business_name,
    billingEmail: currentTeam.billing_email,
    vatId: currentTeam.vat_id,
    address1: currentTeam.address1,
    address2: currentTeam.address2,
    city: currentTeam.city,
    postalCode: currentTeam.postal_code,
    state: currentTeam.state,
    country: currentTeam.country,
  };

  const defaultFieldValidation = {
    businessName: false,
    billingEmail: false,
    vatId: false,
    address1: false,
    address2: false,
    city: false,
    postalCode: false,
    state: false,
    country: false,
  };

  const [isBillingFormEditing, setIsBillingFormEditing] = React.useState(false);
  const [fieldValues, setFieldValues] = React.useState(defaultFieldValues);
  const [fieldValidation, setFieldValidation] = React.useState(
    defaultFieldValidation,
  );

  const stripePromiseRef = React.useRef(loadStripe(stripePublicKey));

  openBillingSetting(currentTeam);

  function onBillingFormFocus() {
    setIsBillingFormEditing(true);
  }

  const onChange = ({ target }) => {
    setFieldValues({
      ...fieldValues,
      [target.name]: target.value,
    });
  };

  function handleBillingFormCancel(event) {
    event.preventDefault();

    setFieldValues(defaultFieldValues);

    setIsBillingFormEditing(false);
  }

  function handleBillingFormSubmit(event) {
    event.preventDefault();

    // validate all required fields are set before submitting a request
    if (!fieldValues.businessName) {
      setFieldValidation({
        ...defaultFieldValidation,
        businessName: 'Business name or full name required',
      });
      return;
    }
    if (!fieldValues.billingEmail) {
      setFieldValidation({
        ...defaultFieldValidation,
        billingEmail: 'Billing email required',
      });
      return;
    }
    if (!fieldValues.billingEmail.includes('@')) {
      setFieldValidation({
        ...defaultFieldValidation,
        billingEmail: 'Invalid billing email address',
      });
      return;
    }
    if (!fieldValues.address1) {
      setFieldValidation({
        ...defaultFieldValidation,
        address1: 'Address line 1 required',
      });
      return;
    }
    if (!fieldValues.city) {
      setFieldValidation({
        ...defaultFieldValidation,
        city: 'City required',
      });
      return;
    }
    if (!fieldValues.postalCode) {
      setFieldValidation({
        ...defaultFieldValidation,
        postalCode: 'Postal code required',
      });
      return;
    }
    if (!fieldValues.country) {
      setFieldValidation({
        ...defaultFieldValidation,
        country: 'Country required',
      });
      return;
    }

    setFieldValidation(defaultFieldValidation);

    patchTeam(currentTeam.id, {
      ...fieldValues,
    })
      .then(res => res.json())
      .then(response => {
        if (response.error) {
          addToast({ text: response.error });
          return;
        }

        const { team } = response;

        updateTeam({
          id: team.id,
          business_name: team.business_name,
          billing_email: team.billing_email,
          vat_id: team.vat_id,
          address1: team.address1,
          address2: team.address2,
          city: team.city,
          postal_code: team.postal_code,
          state: team.state,
          country: team.country,
          acls: team.acls,
          bonus: team.bonus,
        });
        setFieldValues({
          businessName: team.business_name,
          billingEmail: team.billing_email,
          vatId: team.vat_id,
          address1: team.address1,
          address2: team.address2,
          city: team.city,
          postalCode: team.postal_code,
          state: team.state,
          country: team.country,
        });

        addToast({ text: 'Billing address updated' });

        addBillingInfo(currentTeam);

        setIsBillingFormEditing(false);
      })
      .catch(error => {
        addToast({ text: 'Could not update billing address' });
        throw error;
      });
  }

  const [processingBalance, setProcessingBalance] = React.useState('0.00');
  const [utilitiesBalance, setUtilitiesBalance] = React.useState('0.00');
  const [percentOffNextPurchase, setPercentOffNextPurchase] = React.useState(
    '0',
  );
  const [discountNames, setDiscountNames] = React.useState('0');
  const [lifetimeSpend, setLifetimeSpend] = React.useState();

  React.useEffect(
    () => {
      fetchBalances();
      fetchDiscounts();

      const timerId = setInterval(fetchBalances, 10000);

      return () => {
        clearInterval(timerId);
      };
    },
    [currentTeam],
  );

  function fetchBalances() {
    fetch(`/api/transactions/${currentTeam.id}/processing-balance`)
      .then(res => res.json())
      .then(response => {
        if (!response.error) {
          setProcessingBalance(
            (response.processing.millicents / 1e5).toFixed(2),
          );
          setLifetimeSpend(response.lifetimeSpend);
        }
      })
      .catch(() => {});
    fetch(`/api/transactions/${currentTeam.id}/utilities-balance`)
      .then(res => res.json())
      .then(response => {
        if (!response.error) {
          setUtilitiesBalance((response.utilities.millicents / 1e5).toFixed(2));
        }
      })
      .catch(() => {});
  }

  function fetchDiscounts() {
    fetch(`/api/discounts/${currentTeam.id}`)
      .then(res => res.json())
      .then(response => {
        if (!response.error) {
          setPercentOffNextPurchase(response.totalPercentOff);
          setDiscountNames(
            response.coupons.map(coupon => coupon.name).join(', '),
          );
        }
      });
  }

  function CountrySelector() {
    const countries = [
      ['', ''],
      ...countryList()
        .getData()
        .map(country => [country.value, country.label]),
    ];
    const options = useMemo(() => countries, []);

    const changeHandler = value => {
      setFieldValues({
        ...fieldValues,
        country: value,
      });
      setIsBillingFormEditing(true);
    };

    return (
      <FieldSet>
        <label htmlFor="country">Country *</label>
        <Select
          options={options}
          selectedValue={fieldValues.country}
          onSelect={changeHandler}
        />
        {fieldValidation.country && <span>{fieldValidation.country}</span>}
      </FieldSet>
    );
  }

  function LabelAndValue({ label, value, hoverInfo }) {
    return (
      <tr
        css={{
          float: 'left',
          width: '50%',
          a: {
            color: colors.orange1,
          },
        }}
      >
        <td>
          <Text css={{ color: colors.black1, marginBottom: 7 }}>{label}</Text>
          <Text
            css={{
              color: colors.grey3,
              marginBottom: 20,
              textTransform: 'capitalize',
            }}
          >
            {value}
          </Text>
        </td>
      </tr>
    );
  }

  return (
    <>
      <SectionHeading heading="Overview" />
      <div css={{ padding: 20 }}>
        <table css={{ display: 'block' }}>
          <LabelAndValue label="Plan" value={currentTeam.plan_type} />

          {currentTeam.plan_type === 'visitor' && (
            <LabelAndValue
              label="Time until deletion"
              value={daysUntilDeletionString(currentTeam)}
            />
          )}

          {currentTeam.billing_frequency !== 'never' && (
            <>
              <LabelAndValue
                label="Utilities billing check"
                value={currentTeam.billing_frequency}
              />
              <LabelAndValue
                label="Processing credits balance"
                value={`$${processingBalance}`}
              />
              <LabelAndValue
                label="Utilities balance"
                value={`$${utilitiesBalance}`}
              />
              {lifetimeSpend && (
                <>
                  <LabelAndValue
                    label="Lifetime spend, processing"
                    value={`$${lifetimeSpend.sum.toFixed(2)}`}
                  />
                  <LabelAndValue
                    label="Base volume discount, processing"
                    value={
                      <>
                        {lifetimeSpend.baseJobVolumeDiscount.toFixed(1)} /{' '}
                        {lifetimeSpend.maxVolumeDiscountAt} GP (
                        {(
                          (lifetimeSpend.baseJobVolumeDiscount /
                            lifetimeSpend.maxVolumeDiscountAt) *
                          100
                        ).toFixed(2)}
                        %) -{' '}
                        <a
                          target="_blank"
                          rel="nofollow"
                          href="https://www.pixop.com/pricing#filter-pricing"
                        >
                          More Info
                        </a>
                      </>
                    }
                  />
                </>
              )}
              {['pro', 'enterprise'].includes(currentTeam.plan_type) &&
                currentTeam.billing_started_at && (
                  <>
                    <LabelAndValue
                      label="Started on"
                      value={new Date(
                        currentTeam.billing_started_at,
                      ).toLocaleString()}
                    />
                  </>
                )}
              {percentOffNextPurchase > 0 && (
                <>
                  <LabelAndValue
                    label="Discount on next processing credits purchase"
                    value={`${parseFloat(
                      percentOffNextPurchase,
                    )}% (${discountNames})`}
                  />
                </>
              )}
            </>
          )}
        </table>
      </div>
      <SectionHeading heading="Billing address" />
      <form onSubmit={handleBillingFormSubmit}>
        <div css={{ padding: 5, '>div': { marginBottom: 20 } }}>
          <div css={{ display: 'flex' }}>
            <div css={{ flexBasis: '70%', paddingRight: 10 }}>
              <Field
                label="Business name *"
                fieldName="businessName"
                placeholder="Your business name or full customer name"
                id="edit-business-name"
                onFocus={onBillingFormFocus}
                value={fieldValues.businessName}
                onChange={onChange}
                validationMessage={fieldValidation.businessName}
                required
              />
            </div>
            <div css={{ flexBasis: '30%', paddingRight: 10 }}>
              <Field
                type="email"
                label="Billing email *"
                fieldName="billingEmail"
                placeholder="Billing email"
                id="edit-email"
                onFocus={onBillingFormFocus}
                value={fieldValues.billingEmail}
                onChange={onChange}
                validationMessage={fieldValidation.billingEmail}
                required
              />
            </div>
            <div css={{ flexBasis: '30%' }}>
              <Field
                label="VAT ID (optional)"
                fieldName="vatId"
                placeholder="VAT ID"
                id="edit-tax-id"
                onFocus={onBillingFormFocus}
                value={fieldValues.vatId}
                onChange={onChange}
                validationMessage={fieldValidation.vatId}
              />
            </div>
          </div>
          <div css={{ display: 'flex' }}>
            <div css={{ flexGrow: 1, paddingRight: 10 }}>
              <Field
                label="Address 1 *"
                fieldName="address1"
                placeholder="Address line 1"
                id="edit-address1"
                onFocus={onBillingFormFocus}
                value={fieldValues.address1}
                onChange={onChange}
                validationMessage={fieldValidation.address1}
                required
              />
            </div>
            <div css={{ flexGrow: 1 }}>
              <Field
                label="Address 2 (optional)"
                fieldName="address2"
                placeholder="Address line 2"
                id="edit-address2"
                onFocus={onBillingFormFocus}
                value={fieldValues.address2}
                onChange={onChange}
                validationMessage={fieldValidation.address2}
              />
            </div>
          </div>
          <div css={{ display: 'flex' }}>
            <div
              css={{
                paddingRight: 10,
              }}
            >
              <Field
                label="City *"
                fieldName="city"
                placeholder="City"
                id="edit-city"
                onFocus={onBillingFormFocus}
                value={fieldValues.city}
                onChange={onChange}
                validationMessage={fieldValidation.city}
                required
              />
            </div>
            <div
              css={{
                paddingRight: 10,
                width: '100px',
              }}
            >
              <Field
                label="Postal code *"
                fieldName="postalCode"
                placeholder="Postal code"
                id="edit-postal-code"
                onFocus={onBillingFormFocus}
                value={fieldValues.postalCode}
                onChange={onChange}
                validationMessage={fieldValidation.postalCode}
                required
              />
            </div>
            <div
              css={{
                paddingRight: 10,
              }}
            >
              <Field
                label="State (optional)"
                fieldName="state"
                placeholder="State or province"
                id="edit-state"
                onFocus={onBillingFormFocus}
                value={fieldValues.state}
                onChange={onChange}
                validationMessage={fieldValidation.state}
              />
            </div>
            <div css={{ width: '200px' }}>
              <CountrySelector />
            </div>
          </div>
          <label css={{ color: colors.grey3, fontSize: rem(12) }}>
            * Indicates required field
          </label>
        </div>
        {isBillingFormEditing && (
          <>
            <SquareButton
              secondary
              css={{ marginTop: 24, marginRight: 12 }}
              onClick={handleBillingFormCancel}
            >
              Cancel
            </SquareButton>
            <SquareButton
              css={{ marginTop: 24 }}
              onClick={handleBillingFormSubmit}
            >
              Save
            </SquareButton>
          </>
        )}
      </form>
      <SectionHeading heading="Payment method" />
      {currentTeam.plan_type === 'visitor' && (
        <Text css={{ color: colors.grey3 }}>
          Account restrictions are lifted after entering your payment
          information. You will be billed automatically for spent storage and
          download when the outstanding utilities balance is more than $9.99.
          Credits for processing must be prepaid via the{' '}
          <a
            href="/no-navigate"
            onClick={event => {
              event.preventDefault();
              triggerEvent('openProcessingCredits', { amount: 0 });
            }}
            css={{ color: colors.orange0 }}
          >
            Buy Processing Credits
          </a>{' '}
          dialog available in the top navigation bar.
        </Text>
      )}
      <FormContent
        stripePublicKey={stripePublicKey}
        stripe={stripePromiseRef.current}
        currentTeam={currentTeam}
        updateTeam={updateTeam}
        openDialog={openDialog}
        closeDialog={closeDialog}
        addToast={addToast}
      />
      <div
        css={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
        }}
      >
        <a href="https://stripe.com/" target="_blank" rel="noopener noreferrer">
          <PoweredByStripe styles={{ width: 130 }} />
        </a>
      </div>
    </>
  );
}

export const PaymentInformation = connect(
  state => {
    const { currentTeamId, env } = state;
    const currentTeam = state.teams.find(team => currentTeamId === team.id);
    return {
      currentTeam,
      stripePublicKey: env.STRIPE_PUBLIC_KEY,
    };
  },
  { updateTeam, addToast, openDialog, closeDialog },
)(_PaymentInformation);

function SectionHeading({ heading, value }) {
  return (
    <>
      <Heading as="h4">{heading}</Heading>
      {value && (
        <span>
          <font
            css={{
              color: colors.grey3,
            }}
          >
            {value}
          </font>
        </span>
      )}
      <Separator styles={{ marginBottom: 20 }} />
    </>
  );
}

export function Billing({ location }) {
  const { pathname } = location;
  return (
    <div css={{ display: 'flex', minHeight: 'calc(100vh - 170px)' }}>
      <ul
        css={{
          width: 215,
          backgroundColor: colors.white4,
          margin: 0,
          padding: '12px 0',
          fontSize: rem(13),
          lineHeight: '21px',
          listStyleType: 'none',
          borderRight: `1px solid ${colors.hexToRgba(colors.grey3, 0.15)}`,
        }}
      >
        <li
          css={getItemStyles(
            ['/billing', '/billing/buy-credits'].includes(pathname),
          )}
        >
          <Link to="/billing">Settings</Link>
        </li>
        <li
          css={getItemStyles(pathname === '/billing/processing-transactions')}
        >
          <Link to="/billing/processing-transactions">
            Processing transactions
          </Link>
        </li>
        <li css={getItemStyles(pathname === '/billing/utilities-transactions')}>
          <Link to="/billing/utilities-transactions">
            Utilities transactions
          </Link>
        </li>
        <li css={getItemStyles(pathname === '/billing/invoices')}>
          <Link to="/billing/invoices">Invoices</Link>
        </li>
      </ul>
      <div
        css={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: colors.white0,
          padding: '14px 20px 20px',
          '>*:not(:last-child)': {
            marginBottom: 12,
          },
        }}
      >
        <Switch>
          <Route exact path="/billing" component={PaymentInformation} />
          <Route
            exact
            path="/billing/buy-credits"
            component={PaymentInformation}
          />
          <Route
            exact
            path="/billing/processing-transactions"
            component={Processing}
          />
          <Route
            exact
            path="/billing/utilities-transactions"
            component={Utilities}
          />
          <Route exact path="/billing/invoices" component={Invoices} />

          <Route component={NotFound} />
        </Switch>
      </div>
    </div>
  );
}
