import Pagination from 'react-js-pagination';

import './pagination.css';

export const PaginationPanel = ({
  activePage,
  itemsCountPerPage,
  totalItemsCount,
  onChange,
}) => {
  return (
    <>
      <div
        css={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Pagination
          prevPageText="&lsaquo;"
          nextPageText="&rsaquo;"
          activePage={activePage}
          itemsCountPerPage={itemsCountPerPage}
          totalItemsCount={totalItemsCount}
          pageRangeDisplayed={5}
          onChange={onChange}
        />
      </div>
    </>
  );
};
