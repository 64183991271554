import React from 'react';
import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';

import { Alert, Input } from '../../forms';
import { Select } from '../../select';
import { SquareButton } from '../../button';
import { Modal } from '../../modal';
import { Close, Chevron, RoundedIconButton } from '../../icons';
import * as colors from '../../colors';
import { CustomResolution } from '../../custom-resolution';
import { CustomFrameRate } from '../../custom-framerate';
import { CustomBitrate } from '../../custom-bitrate';
import { ResolutionInfo } from '../../resolution-info';
import { Text, rem } from '../../typography';
import { VideoPlayer } from '../../pages/video';
import { QualityMetrics, QualityMetricsOverallScore } from '../../pages/video';
import {
  formatCodec,
  formatContainer,
  formatDetailedFrameRate,
  formatPixelFormatNameAndBitDepth,
  formatColorInfo,
} from './process-video-options';
import { bitsInClosestUnit, formatMsToHourMinutesSeconds } from '../../utils';
import { eventOn, eventOff } from '../../events';

import {
  generateBitrates,
  getChromaSubsamplingLabel,
  updateState as _updateState,
} from './generate-options';
import { ContextMenu } from '../../context-menu';
import {
  generateOptions,
  isValidScaledResolution,
  isFixedResolutionCodec,
} from './generate-options';
import {
  appraisedVideoProcessing,
  startedVideoProcessing,
} from '../../analytics';
import { setReshapedResolution } from './parse-video';

const SliderWithTooltip = Slider.createSliderWithTooltip(Slider);

const DialogContainer = ({ children }) => {
  return (
    <div
      css={{
        backgroundColor: colors.white0,
        borderRadius: 5,
        width: 1020,
        height: 890,
        maxHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {children}
    </div>
  );
};

const TitleBar = ({ closeDialog }) => (
  <div
    css={{
      position: 'relative',
      width: '100%',
      height: 56,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      color: colors.grey3,
      fontSize: rem(16),
      lineHeight: rem(32),
      borderBottom: '1px solid rgba(165,177,194,.3)',
    }}
  >
    Process
    <RoundedIconButton
      icon={Close}
      styles={{ position: 'absolute', top: 18, right: 14 }}
      iconProps={{ color: colors.grey2 }}
      onClick={closeDialog}
    />
  </div>
);

const delayedVisibility = keyframes`
  to {
    visibility: visible;
  }
`;

const getPresetStyles = ({ isAllowed, isActive }) =>
  isAllowed
    ? {
        color: isActive ? colors.orange0 : colors.black2,
        cursor: 'pointer',
        userSelect: 'none',
      }
    : {
        color: colors.grey2,
        cursor: 'default',
        userSelect: 'none',
      };

const SideNav = ({
  openDialog,
  presets,
  selectedPresetId,
  selectPreset,
  deletePreset,
  handleCreatePreset,
}) => {
  const factoryPresets = presets.filter(p => p.builtin);
  const teamPresets = presets.filter(p => !p.builtin);

  const [hoverIndex, setHoverIndex] = React.useState(null);
  const [selectedIndex, setSelectedIndex] = React.useState(null);

  const wrapContextOnClick = cb => () => {
    setHoverIndex(null);
    setSelectedIndex(null);
    cb();
  };

  function editPresetName(presetId) {
    openDialog({
      type: 'editPreset',
      payload: { id: presetId },
    });
  }

  function deletePresetConfirmationDialog(presetId) {
    openDialog({
      type: 'deletePreset',
      payload: { id: presetId, dispatcher: deletePreset },
    });
  }

  const [isFactoryVisible, setIsFactoryVisible] = React.useState(true);
  function toggleFactoryVisibility() {
    setIsFactoryVisible(state => !state);
  }

  const [isCustomVisible, setIsCustomVisible] = React.useState(true);
  function toggleCustomVisibility() {
    setIsCustomVisible(state => !state);
  }

  const PresetHoverBox = ({ text }) => (
    <div
      className="info"
      css={{
        position: 'absolute',
        zIndex: 3,
        bottom: 35,
        visibility: 'hidden',
        color: '#1e1e1e',
        backgroundColor: '#fff',
        padding: '8px 12px',
        border: `1px solid ${colors.grey3}`,
        lineHeight: '1.5',
        borderRadius: 3,
        boxShadow: '0 0 5px 1px #d4d4d4',
      }}
    >
      {text}
    </div>
  );

  return (
    <div
      css={{
        width: 240,
        borderRight: '1px solid rgba(165,177,194,0.2)',
        backgroundColor: 'rgb(250, 251, 255)',
      }}
    >
      <div
        css={{
          fontSize: 15,
          padding: 16,
          paddingBottom: 0,
          display: 'flex',
          alignItems: 'center',
          color: colors.grey10,
          cursor: 'pointer',
          userSelect: 'none',
        }}
        onClick={toggleFactoryVisibility}
      >
        <Chevron
          color={colors.grey10}
          styles={{
            marginLeft: -5,
            transform: `rotate(${isFactoryVisible ? '0' : '180'}deg)`,
          }}
        />
        <span>Factory Presets</span>
      </div>
      {isFactoryVisible && (
        <div css={{ padding: '8px 16px' }}>
          {factoryPresets.map(preset => (
            <div
              key={preset.id}
              onClick={preset.isAllowed ? () => selectPreset(preset.id) : null}
              css={{
                position: 'relative',
                padding: '4px 0',
                ...getPresetStyles({
                  isAllowed: preset.isAllowed,
                  isActive: selectedPresetId === preset.id,
                }),
                ':hover .info': {
                  animation: `0s linear .32s forwards ${delayedVisibility}`,
                },
              }}
            >
              {!preset.isAllowed && (
                <PresetHoverBox text="This preset is not available due to the video's resolution." />
              )}
              {preset.isAllowed && preset.description && (
                <PresetHoverBox text={preset.description} />
              )}
              {preset.name}
            </div>
          ))}
        </div>
      )}
      <div
        css={{
          fontSize: 15,
          padding: 16,
          paddingBottom: 0,
          display: 'flex',
          alignItems: 'center',
          color: colors.grey10,
          cursor: 'pointer',
          userSelect: 'none',
        }}
        onClick={toggleCustomVisibility}
      >
        <Chevron
          color={colors.grey10}
          styles={{
            marginLeft: -5,
            transform: `rotate(${isCustomVisible ? '0' : '180'}deg)`,
          }}
        />
        <span>Custom Presets</span>
      </div>
      <div css={{ padding: '8px 16px' }}>
        <div css={{ display: 'flex', flexDirection: 'column' }}>
          {isCustomVisible && (
            <div>
              {teamPresets.map((preset, index) => (
                <div
                  key={preset.id}
                  onClick={
                    preset.isAllowed ? () => selectPreset(preset.id) : null
                  }
                  onMouseOver={() => setHoverIndex(index)}
                  onMouseLeave={() => setHoverIndex(null)}
                  css={{
                    position: 'relative',
                    padding: '4px 0',
                    ...getPresetStyles({
                      isAllowed: preset.isAllowed,
                      isActive: selectedPresetId === preset.id,
                    }),
                    ':hover .info': {
                      animation: `0s linear .32s forwards ${delayedVisibility}`,
                    },
                  }}
                >
                  {!preset.isAllowed && (
                    <PresetHoverBox text="This preset is not available due to the video's resolution." />
                  )}
                  {preset.isAllowed && preset.description && (
                    <PresetHoverBox text={preset.description} />
                  )}
                  <span>{preset.name}</span>
                  <ContextMenu
                    onClick={() => setSelectedIndex(index)}
                    onBlur={() => setSelectedIndex(null)}
                    items={[
                      {
                        label: 'Edit',
                        onClick: wrapContextOnClick(() =>
                          editPresetName(preset.id),
                        ),
                      },
                      {
                        label: 'Delete',
                        onClick: wrapContextOnClick(() =>
                          deletePresetConfirmationDialog(preset.id),
                        ),
                        color: colors.red0,
                      },
                    ]}
                    styles={{
                      position: 'absolute',
                      opacity:
                        selectedIndex === index || hoverIndex === index ? 1 : 0,
                      top: 4,
                      right: 0,
                    }}
                  />
                </div>
              ))}
            </div>
          )}
          <>
            <SquareButton
              disabled={!!selectedPresetId}
              onClick={() => handleCreatePreset()}
              css={{
                height: 30,
                fontSize: 12,
                lineHeight: '30px',
                marginTop: 8,
                padding: 0,
              }}
            >
              Create Preset
            </SquareButton>
            {!!selectedPresetId && (
              <span css={{ color: colors.grey11, marginTop: 8 }}>
                You can create a preset after changing the processing
                configuration.
              </span>
            )}
          </>
        </div>
      </div>
    </div>
  );
};

const BottomBar = ({ children }) => (
  <div
    css={{
      display: 'flex',
      height: 56,
      borderTop: '1px solid rgba(165,177,194,0.2)',
    }}
  >
    {children}
  </div>
);

const DialogActions = ({
  team,
  closeDialog,
  handleProcessVideo,
  isLoading,
}) => (
  <div
    css={{
      flex: 1,
      display: 'flex',
      alignItems: 'center',
    }}
  >
    <SquareButton
      secondary
      css={{ marginLeft: 8, marginRight: 8 }}
      onClick={closeDialog}
    >
      Cancel
    </SquareButton>
    <div
      css={{
        flex: 1,
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
      }}
    >
      <SquareButton
        css={{
          marginRight: 8,
          '.info': {
            visibility: 'hidden',
          },
          ':hover .info': {
            visibility: 'visible',
          },
        }}
        isLoading={isLoading && isLoading === 'video'}
        disabled={isLoading}
        onClick={() => handleProcessVideo()}
      >
        Process
        <div
          className="info"
          css={{
            display: 'block',
            position: 'absolute',
            backgroundColor: colors.white0,
            borderRadius: 3,
            boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.05)',
            padding: '10px 16px',
            bottom: 45,
            width: 240,
            left: -190,
            textAlign: 'left',
            border: `1px solid ${colors.grey3}`,
            color: colors.black0,
          }}
        >
          Opens a confirmation dialog that states the exact cost of processing
          this video
        </div>
      </SquareButton>
    </div>
  </div>
);

const ContentTabs = ({ tabs, currentTab, setCurrentTab }) => (
  <div
    css={{
      display: 'flex',
      justifyContent: 'space-around',
      alignItems: 'center',
      padding: '0 24px',
      height: 50,
      borderBottom: '1px solid rgba(165,177,194,.3)',
    }}
  >
    {tabs.map(tab => (
      <div
        key={tab}
        css={{
          display: 'flex',
          alignItems: 'center',
          position: 'relative',
          height: 50,
          cursor: 'pointer',
          transition: 'color .18s ease',
          userSelect: 'none',
          color: tab === currentTab ? colors.black0 : colors.grey2,
          ':hover': {
            color: colors.black0,
          },
        }}
        onClick={() => setCurrentTab(tab)}
      >
        {tab}
        {tab === currentTab && (
          <div
            css={{
              position: 'absolute',
              bottom: -1,
              left: 0,
              right: 0,
              height: 3,
              backgroundColor: colors.orange0,
            }}
          />
        )}
      </div>
    ))}
  </div>
);

const Label = styled('label')({
  display: 'block',
  color: colors.grey10,
  fontSize: 12,
  lineHeight: '20px',
  select: {
    fontSize: 14,
  },
});

function ContainerCodecProfile({ config, source, options, updateConfig }) {
  // Return a custom label for the selected item. This is used to show both the
  // optgroup's label and the selected item's label.
  function getContainerCodecLabel(value) {
    const { container } = JSON.parse(value);
    return options.containers[container];
  }

  function getLabel(value) {
    if (!value) return;
    const { group } = JSON.parse(value);
    return group;
  }

  function validateCodec(codec) {
    // pass for now and rely on errors returned by the Lambda
  }

  const [error, setError] = React.useState(() => {
    try {
      validateCodec(config.codec);
      return null;
    } catch (err) {
      return err.message;
    }
  });

  return (
    <>
      <Label>
        Media Container &amp; Codec
        <Select
          selectedValue={{ container: config.container, codec: config.codec }}
          onSelect={value => {
            const val = JSON.parse(value);
            try {
              validateCodec(val.codec);
              setError(null);
            } catch (err) {
              setError(err.message);
            }
            updateConfig(val);
          }}
          getLabel={getContainerCodecLabel}
          options={options.containerCodecs}
        />
      </Label>
      {error && (
        <div css={{ marginTop: -10, marginBottom: 20, color: colors.red0 }}>
          {error}
        </div>
      )}
      {config.codec === 'prores' && (
        <>
          <Label>
            Apple ProRes Profile
            <Select
              selectedValue={config.proresProfile}
              onSelect={proresProfile => updateConfig({ proresProfile })}
              getLabel={getLabel}
              options={options.proresOptions}
            />
          </Label>
        </>
      )}
      {config.codec === 'dnxhd' && (
        <>
          <Label>
            DNxHD/HR Profile
            <Select
              selectedValue={config.dnxhdProfile}
              onSelect={dnxhdProfile => updateConfig({ dnxhdProfile })}
              getLabel={getLabel}
              options={options.dnxhdOptions}
            />
          </Label>
          <Text css={{ marginTop: -6, marginBottom: 12, fontSize: 12 }}>
            Depending on storage aspect ratio, <b>DNxHD</b> profiles might
            introduce a black border in the output video, since padding and
            cropping is used to produce perfect HD (1080p).
          </Text>
        </>
      )}
      {config.codec === 'xdcam' && (
        <>
          <Text css={{ marginTop: -6, marginBottom: 12, fontSize: 12 }}>
            Depending on storage aspect ratio, <b>XDCAM</b> might introduce a
            black border in the output video, since padding and cropping is used
            to produce perfect HD (1080 lines).
          </Text>
        </>
      )}
    </>
  );
}

function ChromaSubsampling({ config, options, updateConfig }) {
  return (
    <Label>
      Chroma Subsampling
      <Select
        selectedValue={config.chromaSubsampling}
        onSelect={chromaSubsampling => updateConfig({ chromaSubsampling })}
        getLabel={getChromaSubsamplingLabel}
        options={
          config.codec === 'prores'
            ? config.proresProfileChromaOptions
            : config.codec === 'dnxhd'
            ? config.dnxhdProfileChromaOptions
            : config.codec === 'xdcam'
            ? config.xdcamProfileChromaOptions
            : options.chromaSubsampling
        }
      />
    </Label>
  );
}

function Bitrate({ config, options, updateConfig }) {
  const bitrates = options.bitrates.map(({ bitrate, label }) => [
    bitrate,
    label,
  ]);
  return (
    <>
      <Label>
        Bitrate
        <Select
          onSelect={bitrate => {
            const result = JSON.parse(bitrate);
            updateConfig({
              bitrate: result,
              customBitrate: result !== -1 ? null : config.customBitrate,
            });
          }}
          selectedValue={config.bitrate.toString()}
          options={
            config.codec === 'prores'
              ? config.proresProfileBitrates
              : config.codec === 'dnxhd'
              ? config.dnxhdProfileBitrates
              : config.codec === 'xdcam'
              ? config.xdcamProfileBitrates
              : bitrates
          }
        />
      </Label>
      {!['prores', 'dnxhd', 'xdcam', 'ffv1'].includes(config.codec) && (
        <Text css={{ marginTop: -6, marginBottom: 12, fontSize: 12 }}>
          {config.bitrate === 0 && (
            <>
              The bitrate is continuously adjusted during encoding for the
              purpose of maintaining constant quality. The mode auto-adapts to
              the complexity of the content and motion. This option is
              recommended for most long-term video storage purposes.
            </>
          )}
          {config.bitrate > 0 && (
            <>
              The recommended target bitrate is proportional to the resolution
              (if scaled) and the source bitrate.
            </>
          )}
        </Text>
      )}
      {['ffv1'].includes(config.codec) && (
        <Text css={{ marginTop: -6, marginBottom: 12, fontSize: 12 }}>
          {config.bitrate > 0 && (
            <>
              A lossless encoder is selected; the target bitrate is effectively
              ignored as it does not affect the output quality or file size.
            </>
          )}
        </Text>
      )}
      {config.bitrate === -1 && (
        <>
          <Text css={{ marginTop: -6, marginBottom: 12, fontSize: 12 }}>
            Encoding is performed using the user-specified target bitrate.
          </Text>
          <Label css={{ marginBottom: '0 !important' }}>
            Custom bitrate
            <CustomBitrate
              sourceBitrate={config.customBitrate}
              onChange={({ bitrate }) =>
                updateConfig({
                  customBitrate: bitrate,
                })
              }
            />
          </Label>
        </>
      )}
    </>
  );
}

function Scanning({ config, options, updateConfig }) {
  return (
    <>
      <Label>
        Scanning
        <Select
          selectedValue={config.scanningType}
          onSelect={scanningType => updateConfig({ scanningType })}
          options={options.scanningTypes}
        />
      </Label>
      {config.scanningType === 'auto' && (
        <Text css={{ marginTop: -6, marginBottom: 12, fontSize: 12 }}>
          Automatically determines the appropriate scanning type based on the
          metadata of the source and any filters selected.
        </Text>
      )}
      {['interlaced_tff', 'interlaced_bff'].includes(config.scanningType) && (
        <Text css={{ marginTop: -6, marginBottom: 12, fontSize: 12 }}>
          Performs explicit interlacing of the processed video stream which has
          the effect of halving the stored frame rate.
        </Text>
      )}
    </>
  );
}

function FixedOutputResolution({ config, options, updateConfig }) {
  return (
    !isFixedResolutionCodec(config) && (
      <>
        <Label>
          Fixed output resolution
          <Select
            selectedValue={config.fixedOutputResolution}
            onSelect={fixedOutputResolution =>
              updateConfig({ fixedOutputResolution })
            }
            options={options.fixedOutputResolutions}
          />
        </Label>
        {config.fixedOutputResolution === 'none' && (
          <Text css={{ marginTop: -6, marginBottom: 12, fontSize: 12 }}>
            Retain the processed output resolution as is.
          </Text>
        )}
        {config.fixedOutputResolution !== 'none' && (
          <Text css={{ marginTop: -6, marginBottom: 12, fontSize: 12 }}>
            Enforce the selected resolution by cropping and/or padding the
            processed output frame as necessary. Useful for adapting
            non-widescreen aspect ratio video to standard 16:9 widescreen
            format. Black borders are inserted in padded areas.
          </Text>
        )}
      </>
    )
  );
}

function Deinterlacer({ config, options, source, updateConfig }) {
  return (
    <>
      <Label>
        Deinterlacer
        <Select
          onSelect={deinterlacer => updateConfig({ deinterlacer })}
          selectedValue={config.deinterlacer}
          options={options.deinterlacers}
        />
      </Label>
      <Text css={{ marginTop: -6, marginBottom: 12, fontSize: 12 }}>
        {config.deinterlacer === 'deint' ? "Pixop's " : ''}
        {config.deinterlacer === 'deint' && (
          <a
            href={'https://www.pixop.com/filters/deinterlacer'}
            target="_blank"
            rel="noopener noreferrer"
          >
            deep neural network based video deinterlacer
          </a>
        )}
        {['bwdif', 'yadif', 'weston3f'].includes(config.deinterlacer) && (
          <a
            href={'https://www.pixop.com/filters/classic'}
            target="_blank"
            rel="noopener noreferrer"
          >
            Classic deinterlacer
          </a>
        )}
        {config.deinterlacer === 'deint'
          ? ' which reduces aliasing artifacts such as interline twitter. Doubles the output frame rate.'
          : config.deinterlacer === 'bwdif'
          ? ' based on a motion adaptive approach that fuses the YADIF and Weston 3-Field methods. Doubles the output frame rate.'
          : config.deinterlacer === 'yadif'
          ? ' which checks the pixels of previous, current and next frames to re-create the missed field via edge-directed interpolation and applies a spatial check to prevent most artifacts.'
          : config.deinterlacer === 'weston3f'
          ? ' that considers the same position in the previous and next fields and two of its neighbors in both directions in the current field. Doubles the output frame rate.'
          : ''}
      </Text>
      <div css={{ marginLeft: 20 }}>
        {config.deinterlacer !== 'none' && (
          <>
            <Label css={{ position: 'relative' }}>
              Interlaced Field Order{' '}
              <Select
                onSelect={interlacedFieldOrderMode =>
                  updateConfig({ interlacedFieldOrderMode })
                }
                selectedValue={config.interlacedFieldOrderMode}
                options={options.interlacedFieldOrderModes}
              />
            </Label>
            {config.interlacedFieldOrderMode === 'auto_quick' && (
              <Text css={{ marginTop: -6, marginBottom: 12, fontSize: 12 }}>
                Quickly determine the interlaced field order based on the source
                file metadata.
              </Text>
            )}
            {config.interlacedFieldOrderMode === 'auto_analysis' && (
              <Text css={{ marginTop: -6, marginBottom: 12, fontSize: 12 }}>
                Auto determine the interlaced field order of the whole video
                based on a deep analysis of up to three one-minute segments.
              </Text>
            )}
          </>
        )}
      </div>
    </>
  );
}

function Crop({
  video,
  sourceResolution,
  cropCoordinates,
  onChange,
  openDialog,
}) {
  function sendUpdate(x1, y1, x2, y2) {
    const normalizedCropCoordinates = [
      x1 / sourceResolution.width,
      y1 / sourceResolution.height,
      x2 / sourceResolution.width,
      y2 / sourceResolution.height,
    ];

    onChange({
      cropCoordinates: [x1, y1, x2, y2],
      normalizedCropCoordinates,
    });
  }

  const reducer = (state, action) => {
    if (action.type === 'reset') {
      return action.value;
    }

    if (action.type === 'clamp') {
      const clamp = (value, min, max) => Math.max(Math.min(value, max), min);

      const x1 = clamp(state.topX, 0, sourceResolution.width - 16);
      const x2 = clamp(state.bottomX, x1 + 16, sourceResolution.width);
      const y1 = clamp(state.topY, 0, sourceResolution.height - 16);
      const y2 = clamp(state.bottomY, y1 + 16, sourceResolution.height);

      sendUpdate(x1, y1, x2, y2);

      return state;
    }

    const value = parseInt(action.value, 10);

    // Handle case where one of the inputs has been cleared completely.
    if (isNaN(value)) {
      return {
        ...state,
        [action.type]: action.value,
      };
    }

    switch (action.type) {
      case 'topX':
      case 'topY':
      case 'bottomX':
      case 'bottomY':
        return {
          ...state,
          [action.type]: value,
        };
    }

    return state;
  };
  const [state, dispatch] = React.useReducer(reducer, {
    topX: 8,
    topY: 8,
    bottomX: sourceResolution.width - 8,
    bottomY: sourceResolution.height - 8,
  });

  React.useEffect(
    () => {
      dispatch({
        type: 'reset',
        value: {
          topX: cropCoordinates ? cropCoordinates[0] : state.topX,
          topY: cropCoordinates ? cropCoordinates[1] : state.topY,
          bottomX: cropCoordinates ? cropCoordinates[2] : state.bottomX,
          bottomY: cropCoordinates ? cropCoordinates[3] : state.bottomY,
        },
      });

      if (!cropCoordinates) {
        sendUpdate(state.topX, state.topY, state.bottomX, state.bottomY);
      }
    },
    [cropCoordinates],
  );

  function editImageRectangle() {
    openDialog({
      type: 'editImageRectangle',
      payload: {
        video,
        inputRectangle: {
          x: state.topX,
          y: state.topY,
          width: state.bottomX - state.topX,
          height: state.bottomY - state.topY,
        },
        outputConsumer: outputRectangle => {
          dispatch({
            type: 'reset',
            value: {
              topX: Math.round(outputRectangle.x),
              topY: Math.round(outputRectangle.y),
              bottomX: Math.round(outputRectangle.x + outputRectangle.width),
              bottomY: Math.round(outputRectangle.y + outputRectangle.height),
            },
          });
          dispatch({ type: 'clamp' });
        },
      },
    });
  }

  return (
    <>
      <div
        css={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          gap: 8,
        }}
      >
        <div>
          <Label>
            Top X
            <Input
              type="number"
              value={state.topX}
              onChange={event =>
                dispatch({ type: 'topX', value: event.target.value })
              }
              onBlur={() => dispatch({ type: 'clamp' })}
              css={{ width: 120 }}
            />
          </Label>
        </div>
        <div>
          <Label>
            Top Y
            <Input
              type="number"
              value={state.topY}
              onChange={event =>
                dispatch({ type: 'topY', value: event.target.value })
              }
              onBlur={() => dispatch({ type: 'clamp' })}
              css={{ width: 120 }}
            />
          </Label>
        </div>
        <div>
          <Label>
            Bottom X
            <Input
              type="number"
              value={state.bottomX}
              onChange={event =>
                dispatch({ type: 'bottomX', value: event.target.value })
              }
              onBlur={() => dispatch({ type: 'clamp' })}
              css={{ width: 120 }}
            />
          </Label>
        </div>
        <div>
          <Label>
            Bottom Y
            <Input
              type="number"
              value={state.bottomY}
              onChange={event =>
                dispatch({ type: 'bottomY', value: event.target.value })
              }
              onBlur={() => dispatch({ type: 'clamp' })}
              css={{ width: 120 }}
            />
          </Label>
        </div>
        <div>
          <SquareButton onClick={editImageRectangle} css={{ width: 100 }}>
            Edit
          </SquareButton>
        </div>
      </div>
    </>
  );
}

function Reshaper({
  video,
  config,
  options,
  source,
  updateConfig,
  openDialog,
}) {
  return (
    <>
      <Label>
        Reshaper
        <Select
          onSelect={reshaper => updateConfig({ reshaper })}
          selectedValue={config.reshaper}
          options={options.reshapers}
        />
      </Label>
      <Text css={{ marginTop: -6, marginBottom: 12, fontSize: 12 }}>
        {config.reshaper === 'reshape'
          ? 'Crop the video either by manually changing the region of interest rectangle coordinates or interactively on video frames via the "Edit"-button.'
          : ''}
      </Text>
      <div css={{ marginLeft: 20 }}>
        {config.reshaper === 'reshape' && (
          <>
            <Crop
              video={video}
              sourceResolution={source.resolution}
              cropCoordinates={config.cropCoordinates}
              onChange={coordinates => updateConfig(coordinates)}
              openDialog={openDialog}
            />
          </>
        )}
      </div>
    </>
  );
}

function Augmenter({ config, options, source, updateConfig }) {
  return (
    <>
      <Label>
        Augmenter
        <Select
          onSelect={augmenter => updateConfig({ augmenter })}
          selectedValue={config.augmenter}
          options={options.augmenters}
        />
      </Label>
      <Text css={{ marginTop: -6, marginBottom: 12, fontSize: 12 }}>
        {config.augmenter === 'faceforward' ? "Pixop's " : ''}
        {config.augmenter === 'faceforward' && (
          <a
            href={'https://www.pixop.com/filters/faceforward'}
            target="_blank"
            rel="noopener noreferrer"
          >
            deep neural network based video face enhancer
          </a>
        )}
        {config.augmenter === 'faceforward'
          ? ' for recordings of human presenters. Makes lighting appear uniform across the entire face of the subject(s), blurs the background and performs color adjustments. To obtain the desired effect, the entire face must be clearly visible, partially front lit, fill at least 5% of image frame and remain relatively still.'
          : ''}
      </Text>

      <div css={{ marginLeft: 20 }}>
        {config.augmenter === 'faceforward' && (
          <>
            <Label css={{ position: 'relative', marginTop: 20 }}>
              Face relighting intensity
              <SliderWithTooltip
                min={0}
                max={200}
                marks={{
                  0: '0%',
                  50: '50%',
                  100: '100%',
                  150: '150%',
                }}
                step={10}
                included={false}
                tipFormatter={value => `${value}%`}
                value={config.relightIntensity}
                onChange={relightIntensity =>
                  updateConfig({ relightIntensity })
                }
              />
            </Label>
            <Label css={{ position: 'relative', marginTop: 30 }}>
              Color boost
              <SliderWithTooltip
                min={0}
                max={30}
                marks={{
                  0: '0%',
                  10: '10%',
                  20: '20%',
                }}
                step={1}
                included={false}
                tipFormatter={value => `${value}%`}
                value={config.colorBoost}
                onChange={colorBoost => updateConfig({ colorBoost })}
              />
            </Label>
            <Label css={{ position: 'relative', marginTop: 30 }}>
              Background blur
              <SliderWithTooltip
                min={0}
                max={20}
                marks={{
                  0: 'Off',
                  2: 'Subtle',
                  5: 'Weak',
                  10: 'Moderate',
                  15: 'Strong',
                }}
                step={1}
                included={false}
                tipFormatter={value => `${value}`}
                value={config.backgroundBlur}
                onChange={backgroundBlur => updateConfig({ backgroundBlur })}
              />
            </Label>
            <Label
              css={{
                position: 'relative',
                color: colors.grey10,
                fontSize: 12,
                marginTop: 30,
              }}
            >
              <Input
                type="checkbox"
                checked={config.autoWhiteBalance}
                onChange={checkbox =>
                  updateConfig({ autoWhiteBalance: checkbox.target.checked })
                }
                css={{ verticalAlign: 'middle' }}
              />{' '}
              Automatic white balance adjustment
            </Label>
          </>
        )}
      </div>
    </>
  );
}

function Scaler({ config, options, source, updateConfig }) {
  const resolutions = options.resolutions[config.outputAspectRatio.tag].map(
    ({ resolution, label }) => [
      JSON.stringify(resolution),
      label,
      !isValidScaledResolution(
        config.scaler,
        source.reshapedResolution,
        resolution,
      ),
    ],
  );
  resolutions.push([-1, 'Custom (enter below)']);

  const outputAspectRatios = options.outputAspectRatios.map(
    ({ aspectRatio, label, tag }) => [
      JSON.stringify({ tag, aspectRatio }),
      label,
    ],
  );

  return (
    <>
      <Label>
        Scaler
        <Select
          onSelect={scaler => updateConfig({ scaler })}
          selectedValue={config.scaler}
          options={options.scalers}
        />
      </Label>
      <Text css={{ marginTop: -6, marginBottom: 12, fontSize: 12 }}>
        {config.scaler === 'pabsr1' || config.scaler.startsWith('dvres')
          ? "Pixop's "
          : ''}
        {(config.scaler === 'pabsr1' || config.scaler.startsWith('dvres')) && (
          <a
            href={
              config.scaler === 'pabsr1'
                ? 'https://www.pixop.com/filters/pixsr'
                : 'https://www.pixop.com/filters/dvres'
            }
            target="_blank"
            rel="noopener noreferrer"
          >
            {config.scaler === 'pabsr1'
              ? 'machine-learned based scaler'
              : config.scaler === 'dvres'
              ? 'original deep neural network based video restoration algorithm'
              : 'improved deep neural network based video restoration algorithm'}
          </a>
        )}
        {config.scaler === 'scale' && (
          <a
            href={'https://www.pixop.com/filters/classic'}
            target="_blank"
            rel="noopener noreferrer"
          >
            Classic scaler
          </a>
        )}
        {config.scaler === 'pabsr1'
          ? '. Allows upscaling up to 4x of the original displayed frame size. Neither restoration nor AI detail injection is performed. Can optionally be applied without upscaling as final sweetening.'
          : config.scaler === 'dvres'
          ? '. Optionally scales, then deblurs, eliminates compression artifacts and injects details into degraded video (max HD resolution). For fair to good quality digital SD material.'
          : config.scaler === 'dvres2'
          ? '.'
          : config.scaler === 'scale'
          ? ' using the bicubic interpolation resampling method. Allows both upscaling and downscaling.'
          : ''}
      </Text>{' '}
      <div css={{ marginLeft: 20 }}>
        {config.scaler === 'pabsr1' && (
          <Label>
            Clarity boost
            <Select
              onSelect={clarityBoost => updateConfig({ clarityBoost })}
              selectedValue={config.clarityBoost}
              options={options.clarityBoostOptions}
            />
          </Label>
        )}
        {config.scaler === 'dvres2' && (
          <>
            <Label>
              Variant
              <Select
                onSelect={dvres2Variant => updateConfig({ dvres2Variant })}
                selectedValue={config.dvres2Variant}
                options={options.dvres2Variants}
              />
            </Label>
            {config.dvres2Variant === 'generic' && (
              <Text css={{ marginTop: -6, marginBottom: 12, fontSize: 12 }}>
                Denoises, deblurs and eliminates compression artifacts, then
                upscales while injecting details. For any quality digital camera
                recorded video.
              </Text>
            )}
            {config.dvres2Variant === 'finetune' && (
              <Text css={{ marginTop: -6, marginBottom: 12, fontSize: 12 }}>
                Eliminates compression artifacts, then upscales while injecting
                details. A relatively conservative, fine-tuning mode as neither
                denoising nor any deblurring is performed. Primarily intended
                for higher production quality video.
              </Text>
            )}
            {config.dvres2Variant === 'selfiestyle' && (
              <Text css={{ marginTop: -6, marginBottom: 12, fontSize: 12 }}>
                Denoises and eliminates compression artifacts, then upscales
                while injecting details. Intended for selfie-style recordings
                captured on smaller sensors such as web cameras or mobile
                phones.
              </Text>
            )}
          </>
        )}
        {config.scaler !== 'none' && (
          <>
            <Label css={{ position: 'relative' }}>
              Resolution{' '}
              <span
                css={{
                  color: colors.orange0,
                  '>div': {
                    visibility: 'hidden',
                    opacity: 0,
                    transition: 'opacity .18s ease',
                  },
                  ':hover>div': {
                    visibility: 'visible',
                    opacity: 1,
                  },
                }}
              >
                <span css={{ cursor: 'pointer', float: 'right' }}>Info</span>
                <ResolutionInfo
                  sourceResolution={source.reshapedResolution}
                  currentResolution={
                    config.customResolution || config.resolution
                  }
                />
              </span>
              <Select
                selectedValue={config.customResolution ? -1 : config.resolution}
                onSelect={value => {
                  const resolution = JSON.parse(value);
                  updateConfig({
                    resolution,
                    customResolution:
                      resolution === -1 ? source.reshapedResolution : null,
                  });
                }}
                options={resolutions}
              />
            </Label>
            {!config.customResolution && (
              <Label>
                Output aspect ratio
                <Select
                  selectedValue={config.outputAspectRatio}
                  onSelect={value => {
                    const outputAspectRatio = JSON.parse(value);
                    updateConfig({
                      outputAspectRatio,
                    });
                  }}
                  options={outputAspectRatios}
                />
              </Label>
            )}
            {config.customResolution && (
              <Label>
                Custom resolution
                <CustomResolution
                  sourceResolution={source.displayResolution}
                  customResolution={config.customResolution}
                  maxResolution={{
                    width:
                      config.scaler === 'pabsr1'
                        ? Math.min(7680, source.displayResolution.width * 4)
                        : config.scaler === 'dvres'
                        ? 1920
                        : config.scaler === 'dvres2'
                        ? Math.min(7680, source.displayResolution.width * 6)
                        : 7680,
                    height:
                      config.scaler === 'pabsr1'
                        ? Math.min(4320, source.displayResolution.height * 4)
                        : config.scaler === 'dvres'
                        ? 1080
                        : config.scaler === 'dvres2'
                        ? Math.min(4320, source.displayResolution.height * 6)
                        : 4320,
                  }}
                  onChange={customResolution =>
                    updateConfig({
                      customResolution: {
                        width: customResolution.width,
                        height: customResolution.height,
                      },
                    })
                  }
                />
              </Label>
            )}
          </>
        )}
      </div>
    </>
  );
}

function FrameRateConverter({ config, options, source, updateConfig }) {
  const frameRates = JSON.parse(JSON.stringify(options.frameRateOptions));
  frameRates.push([-1, 'Custom (enter below)']);

  const defaultOutputFrameRate = source.averageFramerate;

  return (
    <>
      <Label>
        Frame rate converter
        <Select
          onSelect={frameRateConverter => updateConfig({ frameRateConverter })}
          selectedValue={config.frameRateConverter}
          options={options.frameRateConverters}
        />
      </Label>
      <Text css={{ marginTop: -6, marginBottom: 12, fontSize: 12 }}>
        {['fps', 'fblend', 'mcinterpolate'].includes(
          config.frameRateConverter,
        ) && (
          <a
            href={'https://www.pixop.com/filters/classic'}
            target="_blank"
            rel="noopener noreferrer"
          >
            Classic frame rate converter
          </a>
        )}
        {config.frameRateConverter === 'fps'
          ? ' which converts the video to the specified constant frame rate by simply duplicating or dropping source frames as necessary.'
          : config.frameRateConverter === 'fblend'
          ? ' that changes the frame rate by blending new video output frames from the source frames.'
          : config.frameRateConverter === 'mcinterpolate'
          ? ' based on an advanced motion compensation interpolation algorithm.'
          : ''}
        {config.frameRateConverter === 'vfi'
          ? "Pixop's deep learning method for converting the frame rate accurately by estimating and interpolating the motion between two consecutive frames. Fast motion, line of sight being blocked by an intervening object and scene changes are handled."
          : ''}
      </Text>{' '}
      <div css={{ marginLeft: 20 }}>
        {config.frameRateConverter !== 'none' && (
          <>
            <Label css={{ position: 'relative' }}>
              Target frame rate{' '}
              <Select
                selectedValue={config.customFramerate ? -1 : config.frameRate}
                onSelect={value => {
                  const frameRate = JSON.parse(value);
                  updateConfig({
                    frameRate,
                    customFrameRate:
                      frameRate === -1 ? { fps: defaultOutputFrameRate } : null,
                  });
                }}
                options={frameRates}
              />
            </Label>

            {config.customFrameRate && (
              <Label>
                Custom target frame rate
                <CustomFrameRate
                  sourceFrameRate={defaultOutputFrameRate}
                  customFrameRate={config.customFrameRate}
                  onChange={customFrameRate =>
                    updateConfig({
                      customFrameRate,
                    })
                  }
                />
              </Label>
            )}
          </>
        )}
      </div>
    </>
  );
}

function PostProcessor({ config, options, source, updateConfig }) {
  return (
    <>
      <Label>
        Post processor
        <Select
          onSelect={postProcessor => updateConfig({ postProcessor })}
          selectedValue={config.postProcessor}
          options={options.postProcessors}
        />
      </Label>
      <Text css={{ marginTop: -6, marginBottom: 12, fontSize: 12 }}>
        {config.postProcessor === 'filmgrain'
          ? 'Adds a layer of digital film grain to the processed output using a physically-based grain model. By applying this filter, an element of materiality is injected, which makes denoised material look more attractive to some human observers, for example.'
          : ''}
      </Text>

      <div css={{ marginLeft: 20 }}>
        {config.postProcessor === 'filmgrain' && (
          <>
            <Label css={{ position: 'relative' }}>
              Grain size{' '}
              <Select
                onSelect={grainSize => updateConfig({ grainSize })}
                selectedValue={config.grainSize}
                options={options.grainSizeOptions}
              />
            </Label>
            <Label css={{ position: 'relative' }}>
              Grain strength
              <SliderWithTooltip
                min={0}
                max={50}
                marks={{
                  0: 'Invisible',
                  5: 'Subtle',
                  15: 'Weak',
                  25: 'Moderate',
                  40: 'Strong',
                }}
                step={1}
                included={false}
                tipFormatter={value => `${value}`}
                value={config.grainStrength}
                onChange={grainStrength => updateConfig({ grainStrength })}
              />
            </Label>
          </>
        )}
      </div>
    </>
  );
}

// Determine whether object 1 is contained by object 2, i.e. all values in object 1 are also
// in object 2 and are equivalent (object 2 may be a superset of object 1, as the reverse is
// not checked).
function objectIsSubset(opts1, opts2) {
  let equal = true;
  Object.entries(opts1).forEach(([key, val]) => {
    if (equal) {
      equal = JSON.stringify(val) === JSON.stringify(opts2[key]);
    }
  });
  return equal;
}

// Return a copy of an object without the specified keys.
function omit(obj, keys) {
  const shallowCopy = { ...obj };
  keys.forEach(key => delete shallowCopy[key]);
  return shallowCopy;
}

const tabs = ['Source', 'Format', 'Filters'];

export function ProcessingToolModal({
  isOpen,
  openDialog,
  closeDialog,
  team,
  video,
  source,
  presets,
  presetOptions,
  defaultPreset,
  defaultValues,
  derivedNum,
  appraiseVideo,
  processVideo,
}) {
  const processingReducer = React.useCallback(
    // TODO: REWRITE THIS - IT'S UNTENABLE TO MAINTAIN IN THE LONG RUN!
    function processingReducer(state, { type, payload }) {
      function updateReshapedResolution(nextConfig) {
        // sync the reshaped resolution of the source object
        function updateDimensions(cropCoordinates) {
          setReshapedResolution({
            source,
            width: cropCoordinates[2] - cropCoordinates[0],
            height: cropCoordinates[3] - cropCoordinates[1],
          });
        }

        const nextCropCoordinates =
          nextConfig.cropCoordinates || state.config.cropCoordinates;
        const nextReshaper = nextConfig.reshaper || state.config.reshaper;

        if (nextCropCoordinates && nextReshaper !== 'none') {
          updateDimensions(nextCropCoordinates);
        } else {
          setReshapedResolution({
            source,
            ...source.resolution,
          });
        }
      }

      switch (type) {
        case 'setTab': {
          return {
            ...state,
            currentTab: payload,
          };
        }
        case 'setThumbnails': {
          return {
            ...state,
            thumbnails: payload,
          };
        }
        case 'setPreset': {
          const currentTitle = state.config.title;

          const nextPreset = presets.find(p => p.id === payload);
          updateReshapedResolution(presetOptions[nextPreset.id].defaultValues);

          const nextConfig = _updateState({
            source,
            currentState: state.config,
            newState: presetOptions[nextPreset.id].defaultValues,
          });

          // Workaround for the title being reset.
          nextConfig.title = currentTitle;

          return {
            ...state,
            overrideBitrates: null, // Reset,
            preset: nextPreset,
            config: nextConfig,
            options: generateOptions(source, nextConfig, derivedNum).options,
          };
        }
        case 'deletePreset': {
          // switch to the default preset if the deleted preset was the currently selected one
          if (state.preset && state.preset.id === payload) {
            return processingReducer(state, {
              type: 'setPreset',
              payload: defaultPreset.id,
            });
          }

          return {
            ...state,
          };
        }
        case 'updateConfig': {
          /*** Start of code related to synchronizing bitrate and resolution ***/

          // When changing the resolution, also update the bitrate and bitrate options.
          const currentBitrates = generateBitrates(
            source,
            state.config.scaler === 'none'
              ? source.reshapedResolution
              : state.config.customResolution || state.config.resolution,
          );

          let bitrate = state.config.bitrate;
          let overrideBitrates = state.overrideBitrates;

          const prevLength = (overrideBitrates || currentBitrates).length;

          updateReshapedResolution(payload);

          let nextCfg = _updateState({
            source,
            currentState: state.config,
            newState: JSON.parse(JSON.stringify(payload)),
          });

          /*
          // ---- DEBUGGING CONFIG STATE ----

          function isObject(obj) {
            return obj !== null && typeof obj === 'object';
          }

          function diff(oldObj, newObj, deep = false) {
            const added = {};
            const updated = {};
            const removed = {};
            const unchanged = {};
            for (const oldProp in oldObj) {
              if (oldObj.hasOwnProperty(oldProp)) {
                const newPropValue = newObj[oldProp];
                const oldPropValue = oldObj[oldProp];
                if (newObj.hasOwnProperty(oldProp)) {
                  if (newPropValue === oldPropValue) {
                    unchanged[oldProp] = oldPropValue;
                  } else {
                    updated[oldProp] =
                      deep && isObject(oldPropValue) && isObject(newPropValue)
                        ? diff(oldPropValue, newPropValue, deep)
                        : { newValue: newPropValue };
                  }
                } else {
                  removed[oldProp] = oldPropValue;
                }
              }
            }
            for (const newProp in newObj) {
              if (newObj.hasOwnProperty(newProp)) {
                const oldPropValue = oldObj[newProp];
                const newPropValue = newObj[newProp];
                if (oldObj.hasOwnProperty(newProp)) {
                  if (oldPropValue !== newPropValue) {
                    if (!deep || !isObject(oldPropValue)) {
                      updated[newProp].oldValue = oldPropValue;
                    }
                  }
                } else {
                  added[newProp] = newPropValue;
                }
              }
            }
            return { added, updated, removed, unchanged };
          }

          console.log(diff(state.config, nextCfg, true));
          */

          // Update bitrates if a scaler is selected or deselected + when a new resolution is chosen.
          //
          // Note: Check for !customResolution due to resolution being provided with customResolution
          // when custom resolution is selected in the dropdown.
          if (
            bitrate > 0 &&
            ((payload.scaler &&
              payload.scaler !== 'none' &&
              state.config.scaler === 'none') ||
              payload.scaler === 'none' ||
              (payload.resolution && !payload.customResolution))
          ) {
            overrideBitrates = generateBitrates(source, nextCfg.resolution);
            const currentBitrateIndex = (state.overrideBitrates
              ? state.overrideBitrates
              : currentBitrates
            ).findIndex(b => b.bitrate === state.config.bitrate);

            if (currentBitrateIndex >= 0) {
              const diff = overrideBitrates.length - prevLength;
              const index = currentBitrateIndex + diff;

              if (index >= 0 && index < overrideBitrates.length) {
                bitrate = overrideBitrates[index].bitrate;
                nextCfg = { ...nextCfg, bitrate };
              }
            }
          } else if (bitrate > 0 && payload.customResolution) {
            // Custom resolution only works so-so here, since it's index-based and
            // the number of items my not be predictable. It may be easier to always
            // select the recommended bitrate, no matter what.
            overrideBitrates = generateBitrates(
              source,
              payload.customResolution,
            );
            const currentBitrateIndex = (state.overrideBitrates
              ? state.overrideBitrates
              : currentBitrates
            ).findIndex(b => b.bitrate === state.config.bitrate);

            if (currentBitrateIndex >= 0) {
              const diff = overrideBitrates.length - prevLength;
              const index = currentBitrateIndex + diff;

              if (index >= 0 && index < overrideBitrates.length) {
                bitrate = overrideBitrates[index].bitrate;
                nextCfg = { ...nextCfg, bitrate };
              }
            }
          }
          /*** End of code related to synchronizing bitrate and resolution ***/

          // Ignore start, end and normalizedCropCoordinates in comparison
          const omittedProperties = [
            'start',
            'end',
            'normalizedCropCoordinates',
          ];
          const strippedNextConfig = omit(nextCfg, omittedProperties);

          let nextPreset = state.preset;

          if (nextPreset) {
            // Check if, with the next options, the config no longer matches
            // the current preset, and if so, unselect it.
            for (let [key, val] of Object.entries(payload)) {
              // If the preset has this option set to a value, compare it.
              if (state.preset.options.hasOwnProperty(key)) {
                if (
                  !omittedProperties.includes(key) &&
                  JSON.stringify(state.preset.options[key]) !==
                    JSON.stringify(val)
                ) {
                  nextPreset = null;
                }
              }
            }

            // The current preset was unselected, check if we should select another.
            if (nextPreset === null) {
              presets.forEach(preset => {
                if (
                  objectIsSubset(
                    omit(preset.options, omittedProperties),
                    strippedNextConfig,
                  )
                ) {
                  nextPreset = preset;
                }
              });
            }
          } else {
            // Check if, with the next options, the config matches a preset,
            // and if so, select it.
            presets.forEach(preset => {
              // Ignore start/end in comparison
              if (
                objectIsSubset(
                  omit(preset.options, omittedProperties),
                  strippedNextConfig,
                )
              ) {
                nextPreset = preset;
              }
            });
          }

          return {
            ...state,
            bitrate,
            overrideBitrates,
            preset: nextPreset,
            config: nextCfg,
            options: generateOptions(source, nextCfg, derivedNum).options,
          };
        }
        default:
          throw new Error('Invalid action in processingReducer');
      }
    },
    [presets], // plus other outer dependencies used in the reducer, i.e. variables coming from props
  );

  const [
    { config, currentTab, preset, overrideBitrates, options },
    dispatch,
  ] = React.useReducer(processingReducer, {
    config: {
      ...defaultValues,
    },
    currentTab: tabs[0],
    thumbnails: [],
    preset: defaultPreset,
    overrideBitrates: null,
    options: presetOptions[defaultPreset.id].options,
  });

  function updateConfig(payload) {
    dispatch({ type: 'updateConfig', payload });
  }

  const [isProcessingVideo, setIsProcessingVideo] = React.useState(false);

  const [error, setError] = React.useState(null);

  const processingCreditsPaid = React.useCallback(event => {
    setError();
  }, []);

  React.useEffect(
    () =>
      window.Intercom &&
      window.Intercom('trackEvent', 'opened-processing-tool', {
        videoId: video.id,
        teamId: team.id,
      }),
    [],
  );

  React.useEffect(
    () => {
      eventOn('processingCreditsPaid', processingCreditsPaid);

      return () => {
        eventOff('processingCreditsPaid', processingCreditsPaid);
      };
    },
    [processingCreditsPaid],
  );

  function handleProcessVideo() {
    setIsProcessingVideo('video');

    // Built-in presets do not have UUID ids.
    const presetOpts =
      preset && preset.id.length >= 36
        ? {
            presetId: preset.id,
            presetName: preset.name,
          }
        : {};

    const processConfig = {
      ...presetOpts,
      ...config,
    };

    const startProcessing = targetId => {
      return processVideo({
        targetId,
        ...config,
      }).then(result => {
        if (result.error) {
          return Promise.reject(result.error);
        }

        startedVideoProcessing(team);

        closeDialog();

        // The video was created with a preset, do not suggest creating one.
        if (preset) return;

        setTimeout(
          () =>
            handleCreatePreset(
              (result && result.video && result.video.id) || null,
            ),
          200,
        );
      });
    };

    return appraiseVideo(processConfig)
      .then(response => {
        if (response && response.error) {
          return Promise.reject(response.error);
        }

        appraisedVideoProcessing(team);

        // close processing tool and open confirmation dialog
        closeDialog();

        openDialog({
          type: 'confirmAppraisal',
          payload: {
            showWarnings: true,
            sourceVideo: video,
            targetVideo: response.targetVideo,
            jobAppraisal: response.jobAppraisal,
            balances: response.balances,
            dispatcher: () => startProcessing(response.targetVideo.id),
          },
        });
      })
      .catch(error => {
        setIsProcessingVideo(false);
        setError(
          error || 'Unknown error processing video; please try again later.',
        );
      });
  }

  function handleCreatePreset(videoId) {
    openDialog({
      type: 'createPreset',
      config,
      videoId: videoId,
      bitrates:
        overrideBitrates ||
        generateBitrates(
          source,
          config.scaler === 'none'
            ? source.reshapedResolution
            : config.customResolution || config.resolution,
        ),
      resolutions: options.resolutions,
      outputAspectRatios: options.outputAspectRatios,
    });
  }

  return (
    <Modal isOpen={isOpen}>
      <DialogContainer>
        <TitleBar closeDialog={closeDialog} />
        <div css={{ display: 'flex', flex: 1, minHeight: 420 }}>
          <SideNav
            openDialog={openDialog}
            presets={presets}
            selectedPresetId={preset ? preset.id : null}
            selectPreset={id => dispatch({ type: 'setPreset', payload: id })}
            deletePreset={id => dispatch({ type: 'deletePreset', payload: id })}
            handleCreatePreset={handleCreatePreset}
          />
          <div css={{ flex: 1 }}>
            <ContentTabs
              tabs={tabs}
              currentTab={currentTab}
              setCurrentTab={tab => dispatch({ type: 'setTab', payload: tab })}
            />
            <div
              css={{
                boxSizing: 'border-box',
                overflowY: 'auto',
                height: 725,
                maxHeight: 'calc(100vh - 57px - 51px - 56px)',
                padding: '14px 20px',
                label: { marginBottom: 20 },
              }}
            >
              {team.plan_type === 'visitor' && !error && (
                <Alert
                  text={
                    'You cannot perform any processing until you have added your payment information on the [billing settings] page.'
                  }
                  styles={{
                    marginBottom: 12,
                    color: colors.orange0,
                    border: `1px solid ${colors.hexToRgba(
                      colors.orange0,
                      0.7,
                    )}`,
                  }}
                />
              )}
              {error && <Alert text={error} styles={{ marginBottom: 32 }} />}
              {currentTab === 'Source' ? (
                <div>
                  <div css={{ marginLeft: 0 }}>
                    <VideoPlayer
                      src={video.web_video_url}
                      poster={
                        video.thumbnail_urls && video.thumbnail_urls.large
                      }
                      width={640}
                      height={360}
                      styles={{
                        margin: '0 auto',
                        marginTop: 14,
                        marginBottom: 20,
                        flex: 1,
                      }}
                    />
                  </div>
                  <div
                    css={{
                      display: 'flex',
                      marginLeft: 0,
                      marginRight: 0,
                      borderTop: '1px solid rgba(128,128,128,.25)',
                      paddingTop: 20,
                    }}
                  >
                    <div
                      css={{
                        flex: '30%',
                        paddingLeft: 54,
                        paddingRight: 42,
                        label: { marginBottom: '16px !important' },
                      }}
                    >
                      <Label>
                        Format{' '}
                        <Text css={{ color: colors.grey11 }}>
                          {formatContainer(video)}
                        </Text>
                      </Label>
                      <Label>
                        Video track{' '}
                        <Text css={{ color: colors.grey11 }}>
                          {formatCodec(video)}, {formatDetailedFrameRate(video)}
                          , {bitsInClosestUnit(source.bitrate)}ps,{' '}
                          {formatPixelFormatNameAndBitDepth(
                            video.metadata.pixelFormatName,
                          )}
                          {video.metadata.colorSpaceName
                            ? ', ' + formatColorInfo(video)
                            : ''}
                        </Text>
                      </Label>
                      <Label>
                        Resolution{' '}
                        <Text css={{ color: colors.grey11 }}>
                          {video.metadata.frameWidth ===
                            source.originalDisplayResolution.width &&
                          video.metadata.frameHeight ===
                            source.originalDisplayResolution.height
                            ? `${source.resolution.width}×${
                                source.resolution.height
                              }`
                            : `${video.metadata.frameWidth}×${
                                video.metadata.frameHeight
                              } Storage, ${
                                source.originalDisplayResolution.width
                              }×${
                                source.originalDisplayResolution.height
                              } Display`}
                        </Text>
                      </Label>
                      <Label>
                        Duration{' '}
                        <Text css={{ color: colors.grey11 }}>
                          {formatMsToHourMinutesSeconds(
                            video.metadata.durationInMillis,
                          )}
                        </Text>
                      </Label>
                    </div>
                    <div
                      css={{ flex: '30%', paddingLeft: 24, paddingRight: 48 }}
                    >
                      <Label>
                        Overall score
                        <QualityMetricsOverallScore
                          video={video}
                          styles={{
                            marginTop: 6,
                            padding: 0,
                          }}
                        />
                      </Label>
                      <QualityMetrics
                        video={video}
                        styles={{
                          padding: 0,
                          marginTop: 6,
                          '*': { fontSize: '12px !important' },
                        }}
                      />
                    </div>
                  </div>
                </div>
              ) : currentTab === 'Format' ? (
                <>
                  <Label>
                    Title
                    <Input
                      name="title"
                      placeholder="Video title"
                      value={config.title}
                      css={{
                        display: 'block',
                        width: 'calc(100% - 26px)',
                        height: 25,
                        color: colors.black1,
                      }}
                      onChange={event =>
                        updateConfig({ title: event.target.value })
                      }
                    />
                  </Label>
                  {/* Container & Codec combined dropdown, dependent profile dropdown */}
                  <ContainerCodecProfile
                    config={config}
                    source={source}
                    options={options}
                    updateConfig={updateConfig}
                  />

                  {/* Chroma subsampling dropdown */}
                  <ChromaSubsampling
                    config={config}
                    options={options}
                    updateConfig={updateConfig}
                  />

                  {/* Bitrate dropdowns (including custom when selected) */}
                  <Bitrate
                    config={config}
                    options={
                      overrideBitrates
                        ? { bitrates: overrideBitrates }
                        : {
                            bitrates: generateBitrates(
                              source,
                              config.scaler === 'none'
                                ? source.reshapedResolution
                                : config.customResolution || config.resolution,
                            ),
                          }
                    }
                    updateConfig={updateConfig}
                  />

                  {/* Scanning dropdown */}
                  <Scanning
                    config={config}
                    options={options}
                    updateConfig={updateConfig}
                  />

                  {/* Fixed output resolution dropdown */}
                  <FixedOutputResolution
                    config={config}
                    options={options}
                    updateConfig={updateConfig}
                  />
                </>
              ) : currentTab === 'Filters' ? (
                <>
                  {/* Deinterlacer + related options */}
                  <Deinterlacer
                    config={config}
                    options={options}
                    source={source}
                    updateConfig={updateConfig}
                  />
                  {/* Reshaper */}
                  <Reshaper
                    video={video}
                    config={config}
                    options={options}
                    source={source}
                    updateConfig={updateConfig}
                    openDialog={openDialog}
                  />
                  {/* Denoiser */}
                  <Label>
                    Denoiser
                    <Select
                      onSelect={denoiser => updateConfig({ denoiser })}
                      selectedValue={config.denoiser}
                      options={options.denoisers}
                    />
                  </Label>
                  <Text css={{ marginTop: -6, marginBottom: 12, fontSize: 12 }}>
                    {config.denoiser === 'denoise' ? "Pixop's " : ''}
                    {config.denoiser === 'denoise' && (
                      <a
                        href={'https://www.pixop.com/filters/denoiser'}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        deep neural network based video denoiser
                      </a>
                    )}
                    {['hqdn3d'].includes(config.denoiser) && (
                      <a
                        href={'https://www.pixop.com/filters/classic'}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Classic denoiser
                      </a>
                    )}
                    {config.denoiser === 'denoise'
                      ? ' trained on a spatio-temporal gaussian noise model.'
                      : config.denoiser === 'hqdn3d'
                      ? ' based on a three-way low-pass filter, which can completely remove high-frequency noise while minimizing blending artifacts.'
                      : ''}
                  </Text>
                  {/* Stabilizer */}
                  <Label>
                    Stabilizer
                    <Select
                      onSelect={stabilizer => updateConfig({ stabilizer })}
                      selectedValue={config.stabilizer}
                      options={options.stabilizers}
                    />
                  </Label>
                  <Text css={{ marginTop: -6, marginBottom: 12, fontSize: 12 }}>
                    {config.stabilizer === 'dejit' ? "Pixop's " : ''}
                    {config.stabilizer === 'dejit' && (
                      <a
                        href={'https://www.pixop.com/filters/dejitter'}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        deep neural network based video dejitterer
                      </a>
                    )}
                    {config.stabilizer === 'dejit'
                      ? ' designed for stabilizing video that has been transferred to digital by re-aligning scanlines.'
                      : ''}
                  </Text>
                  {/* Augmenter */}
                  <Augmenter
                    config={config}
                    options={options}
                    source={source}
                    updateConfig={updateConfig}
                  />
                  {/* Scaler and scaler-related options */}
                  <Scaler
                    config={config}
                    options={options}
                    source={source}
                    updateConfig={updateConfig}
                  />
                  {/* Frame rate converter */}
                  <FrameRateConverter
                    config={config}
                    options={options}
                    source={source}
                    updateConfig={updateConfig}
                  />
                  {/* Debander */}
                  <Label>
                    Debander
                    <Select
                      onSelect={debander => updateConfig({ debander })}
                      selectedValue={config.debander}
                      options={options.debanders}
                    />
                  </Label>
                  <Text css={{ marginTop: -6, marginBottom: 12, fontSize: 12 }}>
                    {config.debander === 'gradfun' && (
                      <>
                        Fix the banding artifacts that are sometimes introduced
                        into nearly flat regions. Interpolate the gradients that
                        should go where the bands are, and dither them.
                      </>
                    )}
                  </Text>
                  {/* Post processor */}
                  <PostProcessor
                    config={config}
                    options={options}
                    source={source}
                    updateConfig={updateConfig}
                  />
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
        <BottomBar>
          <DialogActions
            team={team}
            closeDialog={closeDialog}
            handleProcessVideo={handleProcessVideo}
            isLoading={isProcessingVideo}
          />
        </BottomBar>
      </DialogContainer>
    </Modal>
  );
}
