import { createAction, createReducer } from './utils';

let idCounter = 0;
export const createNotification = createAction(
  'createNotification',
  payload => ({ id: ++idCounter, ...payload }),
);
export const removeNotification = createAction('removeNotification');

export const notificationsReducer = createReducer([], {
  [createNotification]: (state, action) => {
    return [action.payload, ...state];
  },
  [removeNotification]: (state, action) => {
    return state.filter(notification => notification.id !== action.payload.id);
  },
});
