import { createSelector } from 'reselect';

export const projectsSelector = state => state.projects;
export const videosSelector = state => state.videos;
export const currentTeamIdSelector = state => state.currentTeamId;
export const teamsSelector = state => state.teams;
export const membersSelector = state => state.members;
export const userSelector = state => state.user;
export const messagesSelector = state => state.messages;
export const notificationsSelector = state => state.notifications;
export const uploadsSelector = state => state.uploads;

export const teamProjectsSelector = createSelector(
  currentTeamIdSelector,
  projectsSelector,
  (currentTeamId, projects) =>
    projects.filter(project => project.team_id === currentTeamId),
);

export const teamVideosSelector = createSelector(
  currentTeamIdSelector,
  videosSelector,
  (currentTeamId, videos) =>
    videos.filter(video => video.team_id === currentTeamId),
);

export const teamNotificationsSelector = createSelector(
  currentTeamIdSelector,
  notificationsSelector,
  (currentTeamId, notifications) =>
    notifications.filter(
      notification => notification.team_id === currentTeamId,
    ),
);

export const teamUploadsSelector = createSelector(
  currentTeamIdSelector,
  uploadsSelector,
  (currentTeamId, uploads) =>
    uploads.filter(upload => upload.teamId === currentTeamId),
);

export const currentTeamSelector = createSelector(
  currentTeamIdSelector,
  teamsSelector,
  (currentTeamId, teams) => teams.find(team => team.id === currentTeamId),
);

export const otherTeamMembersSelector = createSelector(
  currentTeamIdSelector,
  membersSelector,
  userSelector,
  (currentTeamId, members, user) =>
    members.filter(
      member => member.team_id === currentTeamId && member.id !== user.id,
    ),
);

export const currentProjectSelector = createSelector(
  projectsSelector,
  (state, props) => props.projectId || props.match.params.projectId,
  (projects, projectId) => projects.find(project => project.id === projectId),
);

export const currentProjectVideosSelector = createSelector(
  currentProjectSelector,
  videosSelector,
  (currentProject, videos) =>
    currentProject
      ? videos.filter(video => video.project_id === currentProject.id)
      : [],
);

export const currentVideoSelector = createSelector(
  videosSelector,
  (state, props) => props.videoId || props.match.params.videoId,
  (videos, videoId) => videos.find(video => video.id === videoId),
);

export const derivedVideosSelector = createSelector(
  videosSelector,
  currentVideoSelector,
  (videos, video) =>
    video
      ? videos.filter(
          v =>
            v.parent_id === video.id ||
            (video.clip_id &&
              v.clip_id === video.clip_id &&
              v.id !== video.clip_id),
        )
      : [],
);
