import { Component, createRef } from 'react';
import { connect } from 'react-redux';

import { rem } from './typography';
import * as colors from './colors';
import * as constants from './constants';
import { projectsSelector } from './store/selectors';
import { addToast } from './store/toasts';

import { initUpload } from './store/uploads';

class _Dropzone extends Component {
  innerDropzone = createRef();
  inputRef = createRef();

  state = {
    isDragging: false,
  };

  dragging = isDragging => () => this.setState({ isDragging });

  onDropFiles = files => {
    const toUpload = files.filter(file => file.size > 1000);

    if (files.length > toUpload.length) {
      this.props.addToast({
        text: 'One or more files not uploaded due to small size',
      });
    }

    if (toUpload.length === 0) return;

    if (this.props.onDropFiles) {
      this.props.onDropFiles(toUpload);
    }

    this.props.initUpload({
      files: toUpload,
      projectId: this.props.projectId,
      noConfirm: this.props.noConfirm,
    });
  };

  componentDidMount() {
    // This library abstracts over some pretty awful browser APIs.
    this.innerDropzone.current.addEventListener('dragenter', this.onDragEnter);
    this.innerDropzone.current.addEventListener('dragleave', this.onDragLeave);
  }

  componentWillUnmount() {
    this.innerDropzone.current.removeEventListener(
      'dragenter',
      this.onDragEnter,
    );
    this.innerDropzone.current.removeEventListener(
      'dragleave',
      this.onDragLeave,
    );
  }

  onDragEnter = () => this.setState({ isDragging: true });
  onDragLeave = () => this.setState({ isDragging: true });

  onClick = () => {
    this.inputRef.current.value = null;
    this.inputRef.current.click();
  };

  onChangeFile = () => {
    this.onDropFiles([...this.inputRef.current.files]);
  };

  render() {
    return (
      <div
        id="dropzone"
        onClick={this.onClick}
        css={{
          display: 'flex',
          transition: 'background-color .36s ease',
          backgroundColor: this.state.isDragging ? colors.grey3 : colors.grey8,
          color: colors.white0,
          height: 110,
          width: '100%',
          borderRadius: 7,
          cursor: 'pointer',
          ':hover': {
            backgroundColor: colors.grey3,
          },
          ...(this.props.styles || {}),
        }}
      >
        <input
          ref={this.inputRef}
          type="file"
          multiple="multiple"
          css={{ display: 'none' }}
          onChange={this.onChangeFile}
        />
        <div
          ref={this.innerDropzone}
          css={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flex: 1,
            margin: 10,
            border: `1px dashed ${colors.white0}`,
            borderRadius: 3,
            fontSize: rem(14),
            color: colors.white0,
            userSelect: 'none',
            textShadow: constants.whiteTextTextShadow,
          }}
        >
          Drop video files here or click to choose files
        </div>
      </div>
    );
  }
}

export const Dropzone = connect(
  state => ({ projects: projectsSelector(state) }),
  { initUpload, addToast },
)(_Dropzone);
