import { bitsInClosestUnit } from '../../utils';

// Generated manually by cross checking FFmpeg DNxHD profiles with
//
// https://en.wikipedia.org/wiki/List_of_Avid_DNxHD_resolutions
// and
// http://resources.avid.com/SupportFiles/attach/MediaDirector%20SupportedVolumesAndFormats_v1_3.pdf
//
// NB: Only 1080p progressive resolutions included - interlaced resolutions have been filtered out.
const dnxhdProfiles = [
  {
    name: '36',
    profile: 'dnxhd',
    family: 'LB',
    width: 1920,
    height: 1080,
    fps: [23.976, 24, 25],
    bitrate: 36,
    pixelFormatName: 'yuv422p',
  },
  {
    name: '45',
    profile: 'dnxhd',
    family: 'LB',
    width: 1920,
    height: 1080,
    fps: [29.97],
    bitrate: 45,
    pixelFormatName: 'yuv422p',
  },
  {
    name: '75',
    profile: 'dnxhd',
    family: 'LB',
    width: 1920,
    height: 1080,
    fps: [50],
    bitrate: 75,
    pixelFormatName: 'yuv422p',
  },
  {
    name: '90',
    profile: 'dnxhd',
    family: 'LB',
    width: 1920,
    height: 1080,
    fps: [59.94, 60],
    bitrate: 90,
    pixelFormatName: 'yuv422p',
  },

  {
    name: '115',
    profile: 'dnxhd',
    family: 'SQ',
    width: 1920,
    height: 1080,
    fps: [23.976, 24],
    bitrate: 115,
    pixelFormatName: 'yuv422p',
  },
  {
    name: '120',
    profile: 'dnxhd',
    family: 'SQ',
    width: 1920,
    height: 1080,
    fps: [25],
    bitrate: 120,
    pixelFormatName: 'yuv422p',
  },
  {
    name: '145',
    profile: 'dnxhd',
    family: 'SQ',
    width: 1920,
    height: 1080,
    fps: [29.97],
    bitrate: 145,
    pixelFormatName: 'yuv422p',
  },
  {
    name: '240',
    profile: 'dnxhd',
    family: 'SQ',
    width: 1920,
    height: 1080,
    fps: [50],
    bitrate: 240,
    pixelFormatName: 'yuv422p',
  },
  {
    name: '290',
    profile: 'dnxhd',
    family: 'SQ',
    width: 1920,
    height: 1080,
    fps: [59.94, 60],
    bitrate: 290,
    pixelFormatName: 'yuv422p',
  },

  {
    name: '175',
    profile: 'dnxhd',
    family: 'HQ',
    width: 1920,
    height: 1080,
    fps: [23.976, 24],
    bitrate: 175,
    pixelFormatName: 'yuv422p',
  },
  {
    name: '185',
    profile: 'dnxhd',
    family: 'HQ',
    width: 1920,
    height: 1080,
    fps: [25],
    bitrate: 185,
    pixelFormatName: 'yuv422p',
  },
  {
    name: '220',
    profile: 'dnxhd',
    family: 'HQ',
    width: 1920,
    height: 1080,
    fps: [29.97],
    bitrate: 220,
    pixelFormatName: 'yuv422p',
  },
  {
    name: '365',
    profile: 'dnxhd',
    family: 'HQ',
    width: 1920,
    height: 1080,
    fps: [50],
    bitrate: 365,
    pixelFormatName: 'yuv422p',
  },
  {
    name: '440',
    profile: 'dnxhd',
    family: 'HQ',
    width: 1920,
    height: 1080,
    fps: [59.94, 60],
    bitrate: 440,
    pixelFormatName: 'yuv422p',
  },

  {
    name: '175x',
    profile: 'dnxhd',
    family: 'HQX',
    width: 1920,
    height: 1080,
    fps: [23.976, 24],
    bitrate: 175,
    pixelFormatName: 'yuv422p10le',
  },
  {
    name: '185x',
    profile: 'dnxhd',
    family: 'HQX',
    width: 1920,
    height: 1080,
    fps: [25],
    bitrate: 185,
    pixelFormatName: 'yuv422p10le',
  },
  {
    name: '220x',
    profile: 'dnxhd',
    family: 'HQX',
    width: 1920,
    height: 1080,
    fps: [29.97],
    bitrate: 220,
    pixelFormatName: 'yuv422p10le',
  },
  {
    name: '365x',
    profile: 'dnxhd',
    family: 'HQX',
    width: 1920,
    height: 1080,
    fps: [50],
    bitrate: 365,
    pixelFormatName: 'yuv422p10le',
  },
  {
    name: '440x',
    profile: 'dnxhd',
    family: 'HQX',
    width: 1920,
    height: 1080,
    fps: [59.94, 60],
    bitrate: 440,
    pixelFormatName: 'yuv422p10le',
  },

  {
    name: '350x',
    profile: 'dnxhd',
    family: '444',
    width: 1920,
    height: 1080,
    fps: [23.976, 24],
    bitrate: 350,
    pixelFormatName: 'yuv444p10le',
  },
  {
    name: '390x',
    profile: 'dnxhd',
    family: '444',
    width: 1920,
    height: 1080,
    fps: [25],
    bitrate: 390,
    pixelFormatName: 'yuv444p10le',
  },
  {
    name: '440x',
    profile: 'dnxhd',
    family: '444',
    width: 1920,
    height: 1080,
    fps: [29.97],
    bitrate: 440,
    pixelFormatName: 'yuv444p10le',
  },
  {
    name: '735x',
    profile: 'dnxhd',
    family: '444',
    width: 1920,
    height: 1080,
    fps: [50],
    bitrate: 730,
    pixelFormatName: 'yuv444p10le',
  },
  {
    name: '880x',
    profile: 'dnxhd',
    family: '444',
    width: 1920,
    height: 1080,
    fps: [59.94, 60],
    bitrate: 880,
    pixelFormatName: 'yuv444p10le',
  },
];

const dnxhdFamilyNameMap = {
  LB: 'Low Bandwidth',
  SQ: 'Standard Quality',
  HQ: 'High Quality (8 bit)',
  HQX: 'High Quality (10 bit)',
  '444': '4:4:4',
};

const dnxhrProfiles = [
  {
    bpp: 16,
    compressionFactor: 22,
    profile: 'dnxhr_lb',
    family: 'LB',
    pixelFormatName: 'yuv422p',
  },
  {
    bpp: 16,
    compressionFactor: 7,
    profile: 'dnxhr_sq',
    family: 'SQ',
    pixelFormatName: 'yuv422p',
  },
  {
    bpp: 16,
    compressionFactor: 4.5,
    profile: 'dnxhr_hq',
    family: 'HQ',
    pixelFormatName: 'yuv422p',
  },
  {
    bpp: 24,
    compressionFactor: 5.5,
    profile: 'dnxhr_hqx',
    family: 'HQX',
    pixelFormatName: 'yuv422p12le',
  },
  {
    bpp: 36,
    compressionFactor: 4.5,
    profile: 'dnxhr_444',
    family: '444',
    pixelFormatName: 'yuv444p12le',
  },
];

const dnxhrFamilyNameMap = {
  LB: 'Low Bandwidth',
  SQ: 'Standard Quality',
  HQ: 'High Quality (8 bit)',
  HQX: 'High Quality (12 bit)',
  '444': '4:4:4',
};

function isFramerateEquivalent(n, m) {
  var delta = Math.abs(n - m);
  return delta < 0.001;
}

export function getValidDNxHDOptions(targetFramerate) {
  const validHDProfiles = [];

  // include HD 1080p profiles which match the target FPS
  // for max compatibility
  dnxhdProfiles.forEach(profile => {
    profile.fps.forEach(fps => {
      if (isFramerateEquivalent(fps, targetFramerate)) {
        validHDProfiles.push(profile);
      }
    });
  });

  const dnxhdOptions = {};

  if (validHDProfiles.length > 0) {
    dnxhdOptions.DNxHD = validHDProfiles.map(profile => [
      JSON.stringify({
        ...profile,
        group: 'DNxHD',
      }),
      `${profile.name} / ${dnxhdFamilyNameMap[profile.family]}`,
    ]);
  }

  // include all DNxHR profiles
  dnxhdOptions.DNxHR = dnxhrProfiles.map(profile => [
    JSON.stringify({
      ...profile,
      group: 'DNxHR',
    }),
    `${dnxhrFamilyNameMap[profile.family]} [1:${profile.compressionFactor}]`,
  ]);

  return dnxhdOptions;
}

export function getDNxHDCodecProperties(profile) {
  const codecProperties = {};
  // pick the required runtime DNxHD/HR profile for FFmpeg
  codecProperties.profile = profile.profile;
  codecProperties.subprofile = '_' + profile.family.toLowerCase();

  return codecProperties;
}

export function getDNxHDBitrateAndLabel(profile, targetResolution, fps) {
  if (profile.profile === 'dnxhd') {
    // convert fixed DNxHD profile bitrate to Mbps
    const profileBitrate = profile.bitrate * 1000000;

    return {
      bitrate: profileBitrate,
      label: `${bitsInClosestUnit(profileBitrate)}ps`,
    };
  } else {
    // calculate DNxHR profile bitrate estimate
    const bitrateEstimate =
      (targetResolution.width * targetResolution.height * profile.bpp * fps) /
      profile.compressionFactor;

    return {
      bitrate: bitrateEstimate,
      label: `${bitsInClosestUnit(bitrateEstimate)}ps (estimate)`,
    };
  }
}
