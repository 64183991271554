const actionType = 'COPY_CLIPBOARD';

export const copyToClipboard = payload => ({ type: actionType, payload });

export const clipboardMiddleware = () => next => action => {
  if (action.type === actionType) {
    const focusEl = document.activeElement;
    const el = document.createElement('textarea');
    el.value = action.payload;
    document.body.appendChild(el);
    el.focus();
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    focusEl.focus();
  }
  return next(action);
};
