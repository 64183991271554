import { Dialog } from '../dialog';
import * as colors from '../colors';

function capitalize(str) {
  return str[0].toUpperCase() + str.slice(1).toLowerCase();
}

export function ViewJobDetails({
  sourceVideoId,
  targetVideoId,
  billableItems,
  discountPercentage,
}) {
  return (
    <Dialog heading="Job details" isOpen containerStyles={{ width: 550 }}>
      <table
        css={{
          width: '100%',
          color: colors.grey3,
          borderSpacing: '0 3px',
          'td + td + td': { textAlign: 'right' },
          'td + td + td + td': { color: colors.black1 },
          'thead td': {
            color: colors.grey3,
            fontWeight: 'bold',
          },
          tr: {
            height: 30,
          },
          'tr:nth-of-type(even)': {
            background: colors.hexToRgba(colors.grey4, 0.6),
          },
          'tr>td:first-of-type': {
            paddingLeft: 6,
          },
        }}
      >
        <thead>
          <tr>
            <td />
            <td>Category</td>
            <td>Processing units [GP]</td>
            <td>Charge</td>
          </tr>
        </thead>
        <tbody>
          {billableItems.map(item => (
            <tr>
              <td>{item.productName}</td>
              <td>{capitalize(item.category)}</td>
              <td>{item.amount}</td>
              <td>${item.costUSD.toFixed(2)}</td>
              <td />
            </tr>
          ))}
        </tbody>
      </table>
      <div css={{ marginTop: 12 }}>
        <table css={{ td: { color: colors.grey3, fontSize: 12 } }}>
          <tr>
            <td>Source video id:</td>
            <td>{sourceVideoId}</td>
          </tr>
          <tr>
            <td>Target video id:</td>
            <td>{targetVideoId}</td>
          </tr>
          {!!discountPercentage && (
            <tr>
              <td>Discount:</td>
              <td>{discountPercentage}%</td>
            </tr>
          )}
        </table>
      </div>
    </Dialog>
  );
}
