import { Link as RouterLink } from 'react-router-dom';

export const Link = ({ css, ...rest }) => (
  <RouterLink
    css={{
      textDecoration: 'none',
      ':hover': { textDecoration: 'underline' },
      ...css,
    }}
    {...rest}
  />
);
