import { Chevron } from './icons';
import * as colors from './colors';

export function Select({
  options,
  onSelect,
  getLabel,
  selectedValue = '',
  styles = {},
}) {
  const value =
    typeof selectedValue === 'string'
      ? selectedValue
      : JSON.stringify(selectedValue);

  function onChange(event) {
    const { value } = event.target;
    onSelect(value);
  }

  const label = getLabel ? getLabel(value) : null;

  return (
    <div css={{ position: 'relative' }}>
      <select
        value={value}
        onChange={onChange}
        css={{
          WebkitAppearance: 'none',
          borderRadius: 3,
          border: `1px solid ${colors.grey4}`,
          backgroundColor: colors.white3,
          padding: '10px 16px 7px',
          lineHeight: '23px',
          color: colors.black1,
          position: 'relative',
          width: '100%',
          paddingRight: 30,
          ...(label
            ? {
                paddingTop: 21,
                paddingBottom: 5,
                lineHeight: '14px',
              }
            : {}),
          ...styles,
        }}
      >
        {Array.isArray(options)
          ? options.map(([value, label, disabled = false]) => (
              <option key={value} value={value} disabled={!!disabled}>
                {label}
              </option>
            ))
          : options &&
            Object.entries(options).map(([group, items]) => (
              <optgroup key={group} label={group}>
                {items.map(([value, label, disabled = false]) => (
                  <option key={value} value={value} disabled={!!disabled}>
                    {label}
                  </option>
                ))}
              </optgroup>
            ))}
      </select>
      {label && (
        <div
          css={{
            position: 'absolute',
            top: 8,
            right: 32,
            left: 17,
            lineHeight: '12px',
            color: colors.grey11,
            paddingRight: 30,
            backgroundColor: colors.white3,
            fontSize: 10,
            display: 'flex',
            alignItems: 'center',
            pointerEvents: 'none',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
          }}
        >
          {label}
        </div>
      )}
      <Chevron
        color={colors.grey10}
        styles={{
          position: 'absolute',
          top: 12,
          right: 12,
          pointerEvents: 'none',
        }}
      />
    </div>
  );
}
