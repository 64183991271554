import { createAction, createReducer } from './utils';

export const addPreset = createAction('addPreset');
export const updatePreset = createAction('updatePreset');
export const removePreset = createAction('removePreset');

export const presetsReducer = createReducer([], {
  [addPreset]: (state, action) => {
    return [...state, action.payload];
  },
  [updatePreset]: (state, action) => {
    return state.map(preset => {
      if (preset.id === action.payload.id) {
        return {
          ...preset,
          ...action.payload,
        };
      }
      return preset;
    });
  },
  [removePreset]: (state, action) => {
    return state.filter(preset => preset.id !== action.payload.id);
  },
});
