/**
 * Trim Tool
 *
 * The Trim Tool is split into several modules:
 * - index.js            – This file, is the entry point and exports the actual modal.
 * - interface.js        – Contains all visual & interactive React components, including the
 *                         modal itself and all that it renders.
 */

import { connect } from 'react-redux';

import { closeDialog, openDialog } from '../../store/dialog';
import { TrimToolModal } from './interface';
import { mapToOutputPixelFormat } from '../processing-tool/parse-video';

export const TrimTool = connect(
  (state, ownProps) => {
    const video = state.videos.find(video => video.id === ownProps.videoId);
    const team = state.teams.find(team => team.id === video.team_id);

    const derivedNum = new Set(
      state.videos
        .filter(v => v.parent_id === video.id && v.clip_id)
        .map(v => v.clip_id),
    ).size;

    function appraiseVideo({ ...config }) {
      return appraiseVideoClip({
        team,
        video,
        config,
      });
    }

    function processVideo({ targetId, ...config }) {
      return processVideoClip({
        targetId,
        derivedNum,
        team,
        video,
        config,
      });
    }

    return {
      video,
      team,
      appraiseVideo,
      processVideo,
    };
  },
  { closeDialog, openDialog },
)(TrimToolModal);

function appraiseVideoClip({ team, video, config }) {
  const startPositionMillis = config.start;
  let endPositionMillis = config.end;

  // As the seconds are rounded up, we must take into account the actual
  // total duration of the video, if the value is lower than what is reported
  // in the UI. The difference here should always be less than one second.
  endPositionMillis =
    video.metadata.durationInMillis < endPositionMillis
      ? video.metadata.durationInMillis
      : endPositionMillis;

  return fetch(`/api/appraise/${video.id}`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      // Resource ownership
      teamId: team.id,

      // State based
      container: 'mov',
      containerProperties: {},
      codec: 'h264',
      codecProperties: {},
      chromaSubsampling: mapToOutputPixelFormat(video.metadata.pixelFormatName),
      startPositionMillis,
      endPositionMillis,
      makeClip: true,
    }),
  }).then(res => res.json());
}

function processVideoClip({ targetId, derivedNum, team, video, config }) {
  return fetch(`/api/process/${video.id}`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      targetId,
      videoName: `${video.name} - Clip ${derivedNum + 1}`,
      teamId: team.id,
      projectId: video.project_id,
    }),
  }).then(res => res.json());
}
