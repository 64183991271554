import { createNotification, removeNotification } from './notifications';
import { addToast } from './toasts';
import { createAction, createReducer } from './utils';
import { ability } from '../ability';

export const removeVideo = createAction('removeVideo');
export const updateVideo = createAction('updateVideo');
export const addIfNotPresent = createAction('addIfNotPresent');

export const videosMiddleware = store => next => action => {
  const state = store.getState();

  if (action.type === addIfNotPresent.type) {
    const isOriginalUpload = action.payload.parent_id === null;
    const isUploader = state.user.id === action.payload.uploader_user_id;
    if (isOriginalUpload && !isUploader) {
      // Create a notification when we receive a message about a new video.
      store.dispatch(
        createNotification({
          type: 'uploadVideo',
          team_id: action.payload.team_id,
          project_id: action.payload.project_id,
          video_id: action.payload.id,
          created_at: action.payload.created_at,
        }),
      );
    }

    const target = isUploader ? 'mine' : 'others';

    if (
      !isOriginalUpload &&
      action.payload &&
      action.payload.clip_id &&
      action.payload.clip_id === action.payload.id &&
      action.payload.processing_state &&
      action.payload.processing_state.processingStatus === 'ENQUEUED'
    ) {
      // A new clip is enqueued
      if (
        state.user.messaging_preferences['clipProcessingEnqueued'][target][
          'toast'
        ]
      ) {
        store.dispatch(
          addToast({
            text: `Transcoding of "${action.payload.name}" is now triggered`,
          }),
        );
      }
    } else if (
      !isOriginalUpload &&
      action.payload &&
      action.payload.processing_state &&
      action.payload.processing_state.processingStatus === 'ENQUEUED'
    ) {
      // A processing job is enqueued
      if (
        state.user.messaging_preferences['processingEnqueued'][target]['toast']
      ) {
        store.dispatch(
          addToast({
            text: `Processing of "${action.payload.name}" is now triggered`,
          }),
        );
      }
    }
  }
  if (action.type === removeVideo.type) {
    const notification = state.notifications.find(
      notification => notification.video_id === action.payload.id,
    );

    if (notification) {
      store.dispatch(
        removeNotification({
          id: notification.id,
        }),
      );
    }
  }
  if (action.type === updateVideo.type) {
    const video = state.videos.find(video => video.id === action.payload.id);
    // Processing error
    if (
      video &&
      video.processing_state &&
      video.processing_state.processingStatus !== 'ERROR' &&
      action.payload.processing_state &&
      action.payload.processing_state.processingStatus === 'ERROR'
    ) {
      store.dispatch(
        addToast({ text: 'There was an error during processing' }),
      );
    }

    // A new clip is fully ingested
    // check that this user has the ability to process videos
    if (
      ability.can('process', 'Video') &&
      video &&
      video.parent_id &&
      video.clip_id &&
      video.clip_id === video.id &&
      video.ingestion_state &&
      video.ingestion_state.ingestionStatus !== 'DONE' &&
      action.payload.ingestion_state &&
      action.payload.ingestion_state.ingestionStatus === 'DONE'
    ) {
      const target =
        state.user.id === video.uploader_user_id ? 'mine' : 'others';

      if (
        state.user.messaging_preferences['clipIngestionDone'][target]['toast']
      ) {
        store.dispatch(
          addToast({
            text: `"${
              video.name
            }" is now ingested and available for processing from the [clip tab /projects/${
              video.project_id
            }/videos/${video.parent_id}?clip_id=${
              video.clip_id
            }]. We recommend that any experimentation is done on clips.`,
            timeout: false,
          }),
        );
      }
    }
  }

  return next(action);
};

export const videosReducer = createReducer([], {
  [removeVideo]: (state, action) => {
    let videos = state.filter(
      video =>
        video.id !== action.payload.id && video.parent_id !== action.payload.id,
    );

    if (action.payload.clipId && action.payload.id === action.payload.clipId) {
      videos = videos.filter(
        video =>
          video.parent_id !== action.payload.id &&
          video.clip_id !== action.payload.id,
      );
    }

    return videos;
  },
  [updateVideo]: (state, action) => {
    return state.map(video => {
      if (video.id === action.payload.id) {
        return {
          ...video,
          ...action.payload,
          // Hack to prevent new thumbnail urls causing a flash in the UI.
          // Required due to thumbnail URLs being created for every video
          // request.
          thumbnail_urls: video.thumbnail_urls || action.payload.thumbnail_urls,
        };
      }
      return video;
    });
  },
  [addIfNotPresent]: (state, action) => {
    const value = action.payload;
    const existingVideo = state.find(video => video.id === action.payload.id);
    if (existingVideo) {
      if (existingVideo.upload_state.progress < value.upload_state.progress) {
        return state.map(v => (v.id === value.id ? value : v));
      } else {
        return state;
      }
    } else {
      return [value, ...state];
    }
  },
});
