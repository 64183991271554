import React from 'react';
import { connect } from 'react-redux';
import { keyframes } from '@emotion/react';
import useReactRouter from 'use-react-router';

import { fetch } from '../requests';
import * as colors from '../colors';
import * as constants from '../constants';
import {
  Download,
  Eye,
  ExclamationCircle,
  Move,
  Process,
  Play,
  Share,
  Trash,
  Scissors,
  Tag,
  Film,
  Clapperboard,
} from '../icons';
import { RoundedButton } from '../button';
import { Heading, Text } from '../typography';
import { ContextMenu } from '../context-menu';
import { Modal } from '../modal';
import { VideoComparison } from '../video-comparison';
import { aggregateVideos } from '../video-status';
import { openDialog } from '../store/dialog';
import { addToast } from '../store/toasts';
import { copyToClipboard } from '../store/clipboard-middleware';
import {
  currentVideoSelector,
  derivedVideosSelector,
  currentTeamSelector,
} from '../store/selectors';
import { ProcessingVideo } from './project';
import {
  formatMsToHourMinutesSeconds,
  bitsInClosestUnit,
  bytesInClosestUnit,
} from '../utils';
import { NotFound } from './not-found';
import { videoStates, getVideoState } from '../video-status';
import { displayVideoInfo } from '../video-comparison';
import {
  formatCodec,
  formatContainer,
  formatDetailedFrameRate,
  calculateBitrate,
  formatPixelFormatNameAndBitDepth,
  formatColorInfo,
} from '../modals/processing-tool/process-video-options';
import { Progress } from '../progress';

// Container element for a section with a title and separator.
function Section({ title, children, styles = {} }) {
  return (
    <div
      css={{ ...styles, padding: '20px 30px', backgroundColor: colors.white0 }}
    >
      <div css={{ display: 'flex', alignItems: 'center', marginBottom: 12 }}>
        <Heading
          as="h3"
          css={{ fontSize: 20, lineHeight: '32px', marginRight: 24 }}
        >
          {title}
        </Heading>
        <div
          css={{
            flex: 1,
            height: 1,
            backgroundColor: colors.grey3,
            border: 'none',
            opacity: 0.3,
            margin: 0,
          }}
        />
      </div>
      {children}
    </div>
  );
}

const versionDateFormatter = new Intl.DateTimeFormat(
  navigator.language || 'en-US',
  {
    day: 'numeric',
    year: 'numeric',
    month: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  },
);
const formatVersionDate = date => versionDateFormatter.format(date);

function _VideoVersion({
  video,
  original,
  contextMenuItems,
  contextMenuIsOpen,
  selectVersion,
  isSelected,
  isDoneUploadingOrProcessing,
  isComparable,
}) {
  const videoState = getVideoState(video);
  let errorMessage;
  if (videoState === videoStates.error) {
    errorMessage =
      video.parent_id === null
        ? 'There is a problem with this upload; the video might be corrupt. Please verify the integrity and/or repair the file by transcoding it before uploading it again.'
        : 'There is a problem with this derived video; please try reprocessing the original video.';
  }

  const info = [videoStates.finished, videoStates.low_quality].includes(
    videoState,
  )
    ? displayVideoInfo(video)
    : null;

  const [errorLeftPos, setErrorLeftPos] = React.useState(-80);
  const ref = React.useRef();
  React.useLayoutEffect(() => {
    const { x } = ref.current.getBoundingClientRect();
    if (x <= 80) {
      setErrorLeftPos(0);
    }
  }, []);

  return (
    <div
      ref={ref}
      css={{
        display: 'flex',
        flexDirection: 'column',
        border: `1px solid ${original ? colors.orange1 : '#e4e7ed'}`,
        borderRadius: 3,
        padding: 11,
      }}
    >
      <div css={{ display: 'flex' }}>
        {isDoneUploadingOrProcessing ? (
          <div
            css={{
              position: 'relative',
              '.info': {
                visibility: 'hidden',
              },
              ':hover .info': {
                visibility: 'visible',
              },
            }}
          >
            <img
              src={video.thumbnail_urls && video.thumbnail_urls.large}
              css={{
                width: 100,
                height: 60,
                borderRadius: 2,
                backgroundColor: colors.grey3,
              }}
            />
            {info && !errorMessage && (
              <div
                className="info"
                css={{
                  display: 'block',
                  position: 'absolute',
                  backgroundColor: colors.white0,
                  borderRadius: 3,
                  boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.05)',
                  padding: '10px 16px',
                  bottom: 60,
                  width: 240,
                  left: errorLeftPos === 0 ? 0 : -80,
                  border: `1px solid ${colors.grey3}`,
                }}
              >
                {info}
              </div>
            )}
            {errorMessage && (
              <div
                css={{
                  width: 22,
                  height: 22,
                  backgroundColor: colors.red0,
                  position: 'absolute',
                  left: 10,
                  top: 27,
                  zIndex: constants.zIndices.base + 1,
                  borderRadius: '50%',
                  justifyContent: 'center',
                  alignItems: 'center',
                  '> div': {
                    visibility: 'hidden',
                  },
                  ':hover > div': {
                    visibility: 'visible',
                  },
                }}
              >
                <ExclamationCircle
                  styles={{ position: 'absolute', top: 5, left: 5 }}
                  width={12}
                  height={12}
                />
                <div
                  css={{
                    position: 'absolute',
                    backgroundColor: colors.white0,
                    borderRadius: 3,
                    boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.05)',
                    padding: '10px 16px',
                    bottom: 36,
                    left: errorLeftPos,
                    width: 159,
                    border: `1px solid ${colors.grey3}`,
                  }}
                >
                  <div css={{ color: colors.black1 }}>Problem</div>
                  <div
                    css={{
                      color: colors.grey2,
                      lineHeight: '20px',
                      fontWeight: 400,
                      marginTop: 6,
                    }}
                  >
                    {errorMessage}
                  </div>
                </div>
              </div>
            )}
          </div>
        ) : (
          <div
            css={{
              width: 100,
              height: 82,
              display: 'flex',
              flexDirection: 'column',
              borderRadius: 2,
              overflow: 'hidden',
              '@media (max-width: 999px)': { height: '55vw' },
            }}
          >
            <ProcessingVideo
              video={video}
              aggregatedVideoData={aggregateVideos([video])}
              contentStyles={{
                fontSize: 12,
                svg: {
                  width: 12,
                  height: 12,
                },
              }}
              statusStyles={{
                '>div, >div>div': {
                  margin: 0,
                  borderRadius: 0,
                },
                height: 14,
                margin: 0,
                '*': {
                  margin: 0,
                  borderRadius: 0,
                },
              }}
            />
          </div>
        )}
        <div
          css={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            marginLeft: 12,
            flex: 1,
            '.info': {
              visibility: 'hidden',
            },
            ':hover .info': {
              visibility: 'visible',
            },
            position: 'relative',
          }}
        >
          <Text
            css={{
              fontSize: 13,
              fontWeight: 600,
              color: colors.black1,
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              maxWidth: 150,
            }}
          >
            {video.name}
            <div
              className="info"
              css={{
                display: 'block',
                position: 'absolute',
                backgroundColor: colors.white0,
                borderRadius: 3,
                boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.05)',
                padding: '4px 4px',
                bottom: isDoneUploadingOrProcessing ? 65 : 82,
                border: `1px solid ${colors.grey3}`,
              }}
            >
              {video.name}
            </div>
          </Text>
          <Text
            css={{
              fontSize: 13,
              color: original ? colors.orange1 : colors.grey2,
            }}
          >
            {original ? 'Original' : video.is_preview ? 'Preview' : 'Processed'}
          </Text>
          <Text css={{ fontSize: 13, color: colors.grey3 }}>
            {formatVersionDate(new Date(video.created_at))}
          </Text>
          {!isDoneUploadingOrProcessing && (
            <ContextMenu
              id={video.id}
              styles={{
                width: 35,
                height: 17,
                marginTop: -3,
                alignSelf: 'flex-end',
              }}
              items={contextMenuItems}
              isOpen={contextMenuIsOpen}
            />
          )}
        </div>
      </div>
      {isDoneUploadingOrProcessing && (
        <div
          css={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginTop: 8,
            marginBottom: -3,
          }}
        >
          <label
            css={{
              '>*': { cursor: isComparable ? 'default' : 'not-allowed' },
            }}
          >
            <input
              type="checkbox"
              checked={isSelected}
              disabled={!isComparable}
              onChange={() => selectVersion(video.id)}
            />
            <Text
              css={{
                fontSize: 13,
                display: 'inline',
                color: isSelected ? colors.orange1 : colors.grey3,
                marginLeft: 8,
                userSelect: 'none',
              }}
            >
              Compare
            </Text>
          </label>
          <ContextMenu
            id={video.id}
            styles={{ width: 35, height: 17, marginTop: -3 }}
            items={contextMenuItems}
            isOpen={contextMenuIsOpen}
          />
        </div>
      )}
    </div>
  );
}

const VideoVersion = connect(
  (state, ownProps) => {
    const { video } = ownProps;
    const { derivedVideos } = ownProps;

    let isComparable = false;

    if (
      video.parent_id === null &&
      video.ingestion_state.ingestionStatus === 'DONE'
    ) {
      // If it's an original video and it's been ingested, it's comparable
      // if it can be compared with something!
      isComparable =
        derivedVideos.filter(d => d.ingestion_state.ingestionStatus === 'DONE')
          .length > 0;
    } else if (
      video.parent_id !== null &&
      video.ingestion_state.ingestionStatus === 'DONE'
    ) {
      // If we have a derived video, we definitely have a working original. So
      // it's down to whether this one is ingested, which it is.
      isComparable = true;
    }

    const isUploaded = video.upload_state.uploadStatus === 'DONE';
    const isIngested = video.ingestion_state.ingestionStatus === 'DONE';
    return {
      isComparable,
      isDoneUploadingOrProcessing:
        (isUploaded && isIngested) || (video.parent_id && isIngested),
    };
  },
  (dispatch, ownProps) => {
    const video = ownProps.video;
    const videoId = video.id;

    const processVideo =
      video.ingestion_state.ingestionStatus === 'DONE'
        ? () => dispatch(openDialog({ type: 'processVideo', videoId }))
        : null;

    const makeClip =
      video.ingestion_state.ingestionStatus === 'DONE'
        ? () => dispatch(openDialog({ type: 'makeClip', videoId }))
        : null;

    const editVideo = () =>
      dispatch(openDialog({ type: 'editVideo', videoId }));

    const downloadVideo =
      video.ingestion_state.ingestionStatus === 'DONE'
        ? () =>
            dispatch(
              openDialog({
                type: 'downloadVideo',
                videoId: video.id,
                onlyThis: true,
              }),
            )
        : null;

    const moveVideo = navigateOnSuccess => () =>
      dispatch(openDialog({ type: 'moveVideo', videoId, navigateOnSuccess }));

    const viewInfo =
      video.parent_id && Object.keys(video.processing_parameters).length > 0
        ? () => dispatch(openDialog({ type: 'viewInfo', videoId: video.id }))
        : null;

    const deleteVideo =
      video.parent_id && video.clip_id !== video.id
        ? () => dispatch(openDialog({ type: 'deleteVideo', videoId: video.id }))
        : () =>
            dispatch(
              openDialog({
                type: 'deleteVideo',
                videoId: video.id,
                navigateTo:
                  video.clip_id && video.parent_id
                    ? `/projects/${video.project_id}/videos/${video.parent_id}`
                    : `/projects/${video.project_id}`,
              }),
            );

    const playVideo =
      video.ingestion_state.ingestionStatus === 'DONE'
        ? () => dispatch(openDialog({ type: 'videoPlayer', videoId: video.id }))
        : null;

    const contextMenuItems = [
      {
        label: 'Play video',
        onClick: playVideo,
      },
      {
        label: 'Process',
        onClick: processVideo,
      },
      {
        label: 'Make clip',
        onClick: makeClip,
      },
      {
        label: 'Edit metadata',
        onClick: editVideo,
      },
      {
        label: 'Download',
        onClick: downloadVideo,
      },
      {
        label: 'Move',
        onClick: moveVideo(!video.parent_id),
      },
      {
        label: 'Processing info',
        onClick: viewInfo,
      },
      {
        label: 'Delete',
        onClick: deleteVideo,
        color: colors.red0,
      },
    ];

    return { contextMenuItems };
  },
)(_VideoVersion);

function _Versions({
  video,
  derivedVideos,
  team,
  addToast,
  copyToClipboard,
  versionState,
}) {
  const {
    selectedVersions,
    setSelectedVersion,
    openContextMenuVideoId,
  } = versionState;

  const [isComparisonViewOpen, openComparisonView] = React.useState(false);

  function selectVersion(id) {
    const nextVersions = selectedVersions.includes(id)
      ? selectedVersions.filter(version => version !== id)
      : selectedVersions.length > 0
      ? [id, selectedVersions[0]]
      : [id];
    setSelectedVersion(nextVersions);
  }

  const [comparison, setComparison] = React.useState({});
  function getComparison() {
    const [videoIdOne, videoIdTwo] = selectedVersions;
    const key = videoIdOne + videoIdTwo;
    if (comparison[key]) return;

    return fetch('/api/compare', {
      method: 'POST',
      body: { videoIdOne, videoIdTwo },
    })
      .then(res => res.json())
      .then(response => {
        if (response.error) {
          return;
        }

        let scheme = window.location.href.slice(0, 5);
        scheme = scheme === 'http' || scheme === 'https' ? scheme : 'http';
        setComparison({
          [key]: scheme + '://' + window.location.host + response.url,
        });
      });
  }

  // Note: due to the way the Clipboard API works, copying must be done in a
  // user event, like a click handler. If we make a request for the comparison
  // URL here, it won't work on some browsers (Safari...). For this reason, we
  // make the request on hover and store it, and attempt to copy here
  // immediately.
  function shareComparison() {
    const [videoIdOne, videoIdTwo] = selectedVersions;
    const key = videoIdOne + videoIdTwo;
    if (!comparison[key]) {
      addToast({ text: 'Error creating shareable comparison URL' });
      return;
    }

    copyToClipboard(comparison[key]);
    addToast({ text: 'URL copied to clipboard' });
  }

  const comparisonVideos = [video, ...derivedVideos]
    .filter(video => selectedVersions.includes(video.id))
    // Ensure that the videos are in order of creation (ascending).
    .sort((a, b) => (a.created_at <= b.created_at ? -1 : 1))
    // Do a second pass to ensure that any original videos are always first no
    // matter what.
    .sort(a => (a.parent_id === null ? 1 : -1));

  const { history } = useReactRouter();

  return (
    <Section title="Versions">
      {isComparisonViewOpen && (
        <Modal close={() => openComparisonView(false)}>
          <VideoComparison
            imageModeTitle="Drag slider to compare"
            videoModeTitle="Drag slider to compare; click to pause and unpause"
            comparisonVideos={comparisonVideos}
            team={team}
            close={() => openComparisonView(false)}
            billing={() => history.push('/billing')}
          />
        </Modal>
      )}
      <div
        css={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: 20,
        }}
      >
        <Text>
          {derivedVideos.length === 0
            ? 'Click “Process” and start enhancing your footage.'
            : 'Select two versions to compare.'}
        </Text>
        <div css={{ display: 'flex', justifyContent: 'center' }}>
          {comparisonVideos.length === 2 && (
            <RoundedButton
              onClick={shareComparison}
              onMouseEnter={getComparison}
              css={{
                display: 'inline-flex',
                justifyContent: 'center',
                marginRight: 10,
              }}
            >
              <Share color={colors.orange1} styles={{ marginTop: -1 }} />
              <span css={{ marginLeft: 7, color: colors.black0 }}>
                Share comparison
              </span>
            </RoundedButton>
          )}
          <RoundedButton
            onClick={
              comparisonVideos.length === 2
                ? () => openComparisonView(true)
                : null
            }
          >
            <Eye
              color={
                comparisonVideos.length === 2 ? colors.orange1 : colors.white0
              }
            />
            <span
              css={{
                marginLeft: 9,
                color:
                  comparisonVideos.length === 2 ? colors.black0 : colors.white0,
              }}
            >
              Compare
            </span>
          </RoundedButton>
        </div>
      </div>
      <div
        css={{
          display: 'grid',
          gridColumnGap: 20,
          gridRowGap: 20,
          gridTemplateColumns: '1fr 1fr 1fr',
          // On smaller screens, we ensure we either have 3 columns, if space
          // allows, or otherwise as many columns as fit where each column is
          // at least 235px.
          '@media (max-width: 900px)': {
            gridTemplateColumns: 'repeat(auto-fit, minmax(235px, 1fr))',
          },
        }}
      >
        <VideoVersion
          video={video}
          derivedVideos={derivedVideos}
          selectVersion={selectVersion}
          isSelected={selectedVersions.includes(video.id)}
          contextMenuIsOpen={video.id === openContextMenuVideoId}
          original
        />
        {derivedVideos.map(derived => (
          <VideoVersion
            key={derived.id}
            selectVersion={selectVersion}
            isSelected={selectedVersions.includes(derived.id)}
            contextMenuIsOpen={derived.id === openContextMenuVideoId}
            video={derived}
          />
        ))}
      </div>
    </Section>
  );
}

const Versions = connect(
  null,
  { addToast, copyToClipboard },
)(_Versions);

function VideoAttribute({ attr, title, value, onClick }) {
  return (
    <div
      role="button"
      data-attr={attr}
      css={{
        // Prevent affecting the grid gaps with the padding by offsetting it
        // with an equivalent negative margin. Note: the padding is only there
        // for the box shadow on click.
        padding: '6px 8px',
        margin: '-6px -8px',
        cursor: 'pointer',
        borderRadius: 3,
        transition: 'box-shadow .18s ease',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        ':focus,:active': {
          boxShadow: `0 0 0 2px ${colors.hexToRgba(colors.orange1, 0.6)}`,
        },
      }}
      onClick={onClick}
      onKeyDown={event => event.keyCode === 13 && onClick()}
      tabIndex="0"
    >
      <Text css={{ color: colors.black1 }}>{title}</Text>
      <Text
        css={{
          color: colors.grey3,
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
        }}
      >
        {value ? value : `Add ${title}`}
      </Text>
    </div>
  );
}

function _Metadata({ video, editAttribute }) {
  // Determines which video attributes are displayed, and in what order.
  const attributeKeys = !video.clip_id
    ? [
        'director',
        'producer',
        'writer',
        'cast',
        'genres',
        'country',
        'language',
        'notes',
      ]
    : ['notes'];

  const attributes = attributeKeys.map(key => ({
    key,
    title: `${key[0].toUpperCase()}${key.slice(1)}`,
    value: video.attributes[key] || null,
  }));

  return (
    <Section title="Metadata">
      <div
        css={{
          display: 'grid',
          gridTemplateColumns: '1fr 1fr',
          gridColumnGap: 24,
          gridRowGap: 24,
        }}
      >
        <VideoAttribute
          title="Title"
          value={video.name}
          attr="title"
          onClick={editAttribute}
        />
        <VideoAttribute
          title="Description"
          attr="description"
          value={video.description}
          onClick={editAttribute}
        />
        {attributes.map(({ key, title, value }) => (
          <VideoAttribute
            key={key}
            attr={key}
            title={title}
            value={value}
            onClick={editAttribute}
          />
        ))}
      </div>
    </Section>
  );
}

const Metadata = connect(
  null,
  (dispatch, ownProps) => {
    return {
      editAttribute: event => {
        dispatch(
          openDialog({
            type: 'editVideo',
            videoId: ownProps.video.id,
            attr: event.currentTarget.dataset
              ? event.currentTarget.dataset.attr
              : null,
          }),
        );
      },
    };
  },
)(_Metadata);

function _ActionMenu({
  editVideo,
  makeClip,
  downloadVideo,
  moveVideo,
  processVideo,
  playVideo,
  deleteVideo,
  isUploaded,
  isIngested,
}) {
  return (
    <div
      css={{
        minHeight: 91,
        boxSizing: 'border-box',
        backgroundColor: colors.white4,
        display: 'flex',
        padding: '20px 0',
      }}
    >
      <div
        css={{
          margin: '0 auto',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <div
          css={{
            display: 'flex',
            flexWrap: 'wrap',
            alignItems: 'center',
            margin: '-6px 6px -6px 6px',

            '>*': {
              margin: 6,
            },

            '>*:not(:last-of-type)': {
              margin: 6,
            },
          }}
        >
          <RoundedButton
            css={{ position: 'relative' }}
            onClick={isIngested ? processVideo : null}
          >
            <Process
              color={isIngested ? colors.orange1 : colors.white0}
              style={{
                position: 'absolute',
                top: 9,
                left: 13,
              }}
            />{' '}
            <span
              css={{
                marginLeft: 18,
                color: isIngested ? colors.black0 : colors.white0,
              }}
            >
              Process
            </span>
          </RoundedButton>
          <RoundedButton
            css={{ position: 'relative' }}
            onClick={isIngested ? makeClip : null}
          >
            <Scissors
              color={isIngested ? colors.orange1 : colors.white0}
              style={{ position: 'absolute', top: 8, left: 13 }}
            />{' '}
            <span
              css={{
                marginLeft: 18,
                color: isIngested ? colors.black0 : colors.white0,
              }}
            >
              Make clip
            </span>
          </RoundedButton>
          <RoundedButton css={{ position: 'relative' }} onClick={editVideo}>
            <Tag
              color={colors.orange1}
              style={{ position: 'absolute', top: 8, left: 13 }}
            />{' '}
            <span
              css={{
                marginLeft: 18,
                color: colors.black0,
              }}
            >
              Edit metadata
            </span>
          </RoundedButton>
          <RoundedButton
            css={{ position: 'relative' }}
            onClick={isUploaded ? downloadVideo : null}
          >
            <Download
              color={isUploaded ? colors.orange1 : colors.white0}
              style={{ position: 'absolute', top: 8, left: 13 }}
            />{' '}
            <span css={{ marginLeft: 18, color: colors.black0 }}>Download</span>
          </RoundedButton>
          <RoundedButton css={{ position: 'relative' }} onClick={moveVideo}>
            <Move
              color={colors.orange1}
              style={{ position: 'absolute', top: 9, left: 13 }}
            />{' '}
            <span css={{ marginLeft: 18, color: colors.black0 }}>Move</span>
          </RoundedButton>
          <RoundedButton
            color={colors.red0}
            css={{ position: 'relative' }}
            onClick={deleteVideo}
          >
            <Trash
              color={colors.red0}
              style={{ position: 'absolute', top: 8, left: 13 }}
            />{' '}
            <span css={{ marginLeft: 18, color: colors.black0 }}>Delete</span>
          </RoundedButton>
        </div>
      </div>
    </div>
  );
}

const ActionMenu = connect(
  (state, ownProps) => {
    const { video } = ownProps;
    const isUploaded = video.upload_state.uploadStatus === 'DONE';
    const isIngested = video.ingestion_state.ingestionStatus === 'DONE';
    return { isUploaded, isIngested };
  },
  (dispatch, ownProps) => {
    const { video } = ownProps;
    return {
      deleteVideo: () =>
        dispatch(
          openDialog({
            type: 'deleteVideo',
            videoId: video.id,
            navigateTo:
              video.clip_id && video.parent_id
                ? `/projects/${video.project_id}/videos/${video.parent_id}`
                : `/projects/${video.project_id}`,
          }),
        ),
      processVideo: () =>
        dispatch(
          openDialog({
            type: 'processVideo',
            videoId: video.id,
          }),
        ),
      moveVideo: () =>
        dispatch(
          openDialog({
            type: 'moveVideo',
            videoId: video.id,
            navigateOnSuccess: true,
          }),
        ),
      makeClip: () =>
        dispatch(
          openDialog({
            type: 'makeClip',
            videoId: video.id,
          }),
        ),
      editVideo: () =>
        dispatch(
          openDialog({
            type: 'editVideo',
            videoId: video.id,
          }),
        ),
      downloadVideo: () =>
        dispatch(
          openDialog({
            type: 'downloadVideo',
            videoId: video.id,
          }),
        ),
      playVideo: () =>
        dispatch(
          openDialog({
            type: 'videoPlayer',
            videoId: video.id,
          }),
        ),
    };
  },
)(_ActionMenu);

export function VideoPlayer({
  src,
  poster,
  height,
  width,
  label,
  playIconSize = 60,
  styles = {},
}) {
  const videoEl = React.useRef();
  const sourceEl = React.useRef();
  const [isPlaying, setPlaying] = React.useState(false);

  // Useful to determine if the player has been started before, at which point
  // we won't show the background thumbnail image again if the person pauses,
  // just the paused video.
  const [hasPlayed, setHasPlayed] = React.useState(false);

  if (sourceEl.current && src !== sourceEl.current.src) {
    sourceEl.current.src = src;
    videoEl.current.load();

    setTimeout(() => {
      setPlaying(false);
      setHasPlayed(false);
    });
  }

  function onClickVideo(event) {
    event.stopPropagation();
    if (!isPlaying) {
      setPlaying(true);
      setHasPlayed(true);
      videoEl.current.play();
    } else {
      setPlaying(false);
      videoEl.current.pause();
    }
  }

  return (
    <>
      <div
        css={{
          height,
          width,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          position: 'relative',
          cursor: 'pointer',
          backgroundColor: colors.black0,
          backgroundSize: 'cover',
          backgroundPosition: 'center center',
          backgroundImage: isPlaying || hasPlayed ? 'none' : `url(${poster})`,
          overflow: 'hidden',
          svg: {
            transition: 'opacity .18s ease',
            opacity: 0.75,
          },
          ':hover svg': {
            opacity: 1,
          },
          ...styles,
        }}
        onClick={onClickVideo}
      >
        <video
          css={{
            width,
            objectFit: 'contain',
            opacity: hasPlayed ? 1 : 0,
            height,
          }}
          ref={videoEl}
          controls
        >
          <source src={src} type="video/mp4" ref={sourceEl} />
        </video>
        {!isPlaying && (
          <>
            <Play
              width={playIconSize}
              height={playIconSize}
              style={{
                position: 'absolute',
                filter: 'drop-shadow(0px 0px 20px rgba(0,0,0,0.5))',
              }}
            />
          </>
        )}
      </div>

      {label && !isPlaying && (
        <div
          css={{
            position: 'absolute',
            color: colors.white0,
            backgroundColor: colors.orange0,
            cursor: 'pointer',
            borderRadius: 5,
            margin: 4,
          }}
          onClick={onClickVideo}
        >
          {!isPlaying && (
            <>
              <div
                css={{
                  padding: 4,
                  fontSize: 24,
                }}
              >
                {label}
              </div>
            </>
          )}
        </div>
      )}
    </>
  );
}

const mapSubjectiveScoreToColor = subjectiveScore => {
  // return sane colors for OOB input
  if (subjectiveScore < 0) {
    return colors.red0;
  }
  if (subjectiveScore > 1) {
    return colors.green0;
  }

  // LERP red->yellow and yellow->red
  const threshold1 = 0.65;
  const invThreshold1 = 1.0 / threshold1;
  const invThreshold2 = 1.0 / (1.0 - threshold1);
  const hexColor1 = subjectiveScore < threshold1 ? colors.red0 : colors.yellow0;
  const hexColor2 =
    subjectiveScore < threshold1 ? colors.yellow0 : colors.green0;
  const invLerp =
    subjectiveScore < threshold1
      ? subjectiveScore * invThreshold1
      : (subjectiveScore - threshold1) * invThreshold2;
  const lerp = 1 - invLerp;

  const red = Math.round(
    parseInt(hexColor1.slice(1, 3), 16) * lerp +
      parseInt(hexColor2.slice(1, 3), 16) * invLerp,
  );
  const green = Math.round(
    parseInt(hexColor1.slice(3, 5), 16) * lerp +
      parseInt(hexColor2.slice(3, 5), 16) * invLerp,
  );
  const blue = Math.round(
    parseInt(hexColor1.slice(5, 7), 16) * lerp +
      parseInt(hexColor2.slice(5, 7), 16) * invLerp,
  );

  return `rgba(${red},${green},${blue},1)`;
};

export function QualityMetrics({ video, styles = {} }) {
  return video.quality_assessment && video.quality_assessment.metrics ? (
    <div css={{ flexDirection: 'column', padding: '16px 18px', ...styles }}>
      {video.quality_assessment.metrics.map((metric, i) => (
        <div key={i}>
          <div
            css={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Text css={{ color: colors.grey11 }}>
              {metric.name[0].toUpperCase()}
              {metric.name.slice(1)}
            </Text>
            <Text css={{ color: colors.black1 }}>
              {metric.subjectiveScore.toFixed(1)}
            </Text>
          </div>
          <div
            css={{
              marginTop: 7,
              marginBottom: 15,
              height: 10,
              width: '100%',
              display: 'flex',
              backgroundColor: colors.white2,
              borderRadius: 15,
              overflow: 'hidden',
            }}
          >
            <div
              css={{
                width: `${Math.floor((metric.subjectiveScore / 5) * 100)}%`,
                backgroundColor: `${mapSubjectiveScoreToColor(
                  metric.subjectiveScore / 5,
                )}`,
                borderRadius: 15,
              }}
            />
          </div>
        </div>
      ))}
    </div>
  ) : null;
}

export function QualityMetricsDescription({ video, styles = {} }) {
  return video.quality_assessment && video.quality_assessment.metrics ? (
    <div css={{ padding: '8px 16px 8px 18px', marginTop: -20, ...styles }}>
      <Text
        css={{
          fontSize: 12,
        }}
      >
        Each metric rates an aspect of the ingested video quality. Higher scores
        generally yield better processing results.
      </Text>
    </div>
  ) : null;
}

export function QualityMetricsOverallScore({ video, styles = {} }) {
  return video.quality_assessment && video.quality_assessment.metrics ? (
    <div css={{ padding: '8px 16px 8px 18px', ...styles }}>
      {video.quality_assessment.overallSubjectiveScore && (
        <div
          css={{
            display: 'flex',
            height: 60,
            backgroundColor: colors.white1,
            borderRadius: 7,
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Text css={{ fontSize: 16, color: '#656565' }}>
            Score is{' '}
            <font color={colors.black0}>
              <span css={{ fontWeight: 700 }}>
                {video.quality_assessment.overallSubjectiveScore.toFixed(1)}
              </span>{' '}
            </font>
            out of{' '}
            <span css={{ color: colors.black0, fontWeight: 700 }}>5</span>
          </Text>
        </div>
      )}
    </div>
  ) : null;
}

function VideoInfo({ video, isIngested, isUploaded }) {
  const bitrate = calculateBitrate(video);
  const isReady = (isUploaded && isIngested) || (video.parent_id && isIngested);
  return (
    <div
      css={{
        padding: 18,
        span: {
          color: colors.grey3,
        },
        '>div': {
          marginBottom: 12,
        },
        '>div>span:first-of-type': {
          color: colors.black1,
        },
      }}
    >
      <div>
        <Text>Title</Text>
        <Text>{video.name}</Text>
      </div>
      <div>
        <Text>Size</Text>
        <Text>
          {video.file_size_bytes
            ? bytesInClosestUnit(video.file_size_bytes)
            : video.metadata.size
            ? bytesInClosestUnit(video.metadata.size)
            : 'Pending upload/ingestion'}
        </Text>
      </div>
      <div>
        <Text>Video track</Text>
        <Text css={{ color: colors.grey11 }}>
          {isReady ? (
            <>
              {formatCodec(video)}, {formatDetailedFrameRate(video)},{' '}
              {bitsInClosestUnit(bitrate)}ps,{' '}
              {formatPixelFormatNameAndBitDepth(video.metadata.pixelFormatName)}
              {video.metadata.colorSpaceName
                ? ', ' + formatColorInfo(video)
                : ''}
            </>
          ) : (
            'Pending ingestion'
          )}
        </Text>
      </div>
      <div>
        <Text>Format</Text>
        <Text>{isReady ? formatContainer(video) : 'Pending ingestion'}</Text>
      </div>
      <div>
        <Text>Resolution</Text>
        <Text>
          {isReady ? (
            <>
              {video.metadata.frameWidth}×{video.metadata.frameHeight}
            </>
          ) : (
            'Pending ingestion'
          )}
        </Text>
      </div>
      <div>
        <Text>Duration</Text>
        <Text>
          {isReady
            ? formatMsToHourMinutesSeconds(video.metadata.durationInMillis)
            : 'Pending ingestion'}
        </Text>
      </div>
    </div>
  );
}

function ClipTab({
  isMaster,
  label,
  onClick,
  isActive,
  aggregatedVideoData,
  info,
}) {
  const progressAnimation = inactiveBackgroundColor => keyframes`
        0% {
            background-color: ${
              isActive ? colors.white0 : inactiveBackgroundColor
            };
        }

        50% {
            background-color: ${colors.hexToRgba(colors.orange0, 0.3)};
        }

        100% {
            background-color: ${
              isActive ? colors.white0 : inactiveBackgroundColor
            };
        }
      `;

  return (
    <>
      <div
        tabIndex="0"
        role="button"
        onClick={onClick}
        onKeyDown={event => event.keyCode === 13 && onClick()}
        key={label}
        css={{
          flex: 1,
          flexWrap: 'wrap',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          position: 'relative',
          height: 50,
          minWidth: aggregatedVideoData.numTotal > 0 ? 150 : 100,
          marginLeft: 2,
          marginRight: 2,
          cursor: 'pointer',
          transition: 'background-color .3s ease',
          userSelect: 'none',
          boxShadow: isActive ? '0px -5px 12px rgba(0, 0, 0, 0.1)' : null,
          borderRadius: '15px 15px 2px 2px',
          borderStyle: isActive ? 'solid' : 'dotted',
          borderColor:
            aggregatedVideoData.numTotal > 0 || isActive
              ? 'transparent'
              : colors.grey3,
          borderWidth: isActive ? 2 : 1,
          backgroundColor: isActive ? colors.white0 : colors.white2,
          animation:
            aggregatedVideoData.numTotal === 0
              ? 'none'
              : `${progressAnimation(colors.white1)} 2s linear infinite`,
          ':hover': {
            backgroundColor: isActive ? colors.white0 : colors.white4,
            animation:
              aggregatedVideoData.numTotal === 0
                ? 'none'
                : `${progressAnimation(colors.white4)} 2s linear infinite`,
            borderStyle: 'solid',
            borderColor: 'transparent',
            borderWidth: 2,
          },
          ':hover .info': {
            visibility: 'visible',
          },
        }}
      >
        <div
          css={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
          }}
        >
          <span
            css={{
              flex: 1,
              display: 'flex',
              flexWrap: 'nowrap',
              justifyContent: 'center',
            }}
          >
            {isMaster && (
              <Film
                color={colors.orange1}
                width={21.33}
                height={16}
                styles={{
                  marginRight: 7,
                  marginTop: 2,
                }}
              />
            )}
            {!isMaster && (
              <Clapperboard
                color={colors.orange1}
                width={16}
                height={16}
                styles={{
                  marginRight: 7,
                  marginTop: 2,
                }}
              />
            )}
            <Text
              css={{
                color: colors.black1,
                userSelect: 'none',
                fontWeight:
                  aggregatedVideoData.numTotal === 0 ? 'normal' : 'bold',
                fontSize: aggregatedVideoData.numTotal === 0 ? 17 : 18,
                alignItems: 'center',
              }}
            >
              {label}
            </Text>
          </span>
          {aggregatedVideoData.numTotal > 0 && (
            <div
              css={{
                justifyContent: 'center',
                alignItems: 'center',
                display: 'flex',
              }}
            >
              <Progress
                value={aggregatedVideoData.value}
                color={aggregatedVideoData.color}
                isActive={aggregatedVideoData.isActive}
                styles={{ flex: 1, margin: '0 10px' }}
                onClick={onClick}
              />
            </div>
          )}
        </div>
        {info && (
          <div
            className="info"
            css={{
              display: 'block',
              position: 'absolute',
              visibility: 'hidden',
              backgroundColor: colors.white0,
              borderRadius: 3,
              boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.05)',
              padding: '4px 4px',
              bottom: 45,
              border: `1px solid ${colors.grey3}`,
              whiteSpace: 'nowrap',
              color: colors.black0,
              zIndex: 1000,
            }}
          >
            {info}
          </div>
        )}
      </div>
    </>
  );
}

function Tab({ label, onClick, isActive, disabled }) {
  return (
    <div
      tabIndex="0"
      role="button"
      onClick={disabled ? null : onClick}
      onKeyDown={event => event.keyCode === 13 && onClick()}
      css={{
        flex: 1,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        transition: 'border-color .18s ease',
        borderBottom: `2px solid ${isActive ? colors.orange1 : 'transparent'}`,
        cursor: isActive || disabled ? 'default' : 'pointer',
        ':hover >span': {
          color: disabled ? colors.grey3 : colors.black1,
        },
      }}
    >
      <Text
        css={{
          transition: 'color .18s ease',
          color: isActive ? colors.black1 : colors.grey3,
          userSelect: 'none',
        }}
      >
        {label}
      </Text>
    </div>
  );
}

function Sidebar({ video }) {
  const [activeTab, setActiveTab] = React.useState('video');
  const isUploaded = video.upload_state.uploadStatus === 'DONE';
  const isIngested = video.ingestion_state.ingestionStatus === 'DONE';
  return (
    <div
      css={{
        display: 'flex',
        flexDirection: 'column',
        width: 334,
        backgroundColor: colors.white4,
      }}
    >
      <div
        css={{
          display: 'flex',
          height: 48,
          borderBottom: `1px solid ${colors.hexToRgba(colors.grey3, 0.3)}`,
          '>div>span': {
            marginTop: 3,
          },
        }}
      >
        <Tab
          label="Video info"
          onClick={() => setActiveTab('video')}
          isActive={activeTab === 'video'}
        />
        <Tab
          label="Quality analysis"
          onClick={() => setActiveTab('quality')}
          isActive={activeTab === 'quality'}
          disabled={!isIngested}
        />
      </div>
      <div css={{ flex: 1, overflow: 'auto', backgroundColor: colors.white0 }}>
        {activeTab === 'quality' ? (
          <div>
            <QualityMetrics video={video} />
            <QualityMetricsDescription video={video} />
            <QualityMetricsOverallScore
              video={video}
              height={80}
              textFontSize={25}
            />
          </div>
        ) : (
          activeTab === 'video' && (
            <VideoInfo
              video={video}
              isIngested={isIngested}
              isUploaded={isUploaded}
            />
          )
        )}
      </div>
    </div>
  );
}

const TabSelector = ({
  activeTab,
  video,
  masterDerivedVideos,
  originalClipVideos,
  derivedClipVideos,
  params,
  setActiveTab,
}) => {
  let clipNumber = 1;

  const { history } = useReactRouter();

  React.useEffect(
    () => {
      params = new URLSearchParams(location.search);
      setActiveTab(params.get('clip_id') || 'master');
    },
    [params],
  );

  return (
    <div
      css={{
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        borderBottom: `20px solid ${colors.hexToRgba(colors.white0)}`,
      }}
    >
      <ClipTab
        key={video.id}
        isMaster={true}
        label="Master"
        onClick={() => {
          params.delete('clip_id');
          history.push(`${location.pathname}`);

          setActiveTab('master');
        }}
        isActive={activeTab === 'master'}
        aggregatedVideoData={aggregateVideos([video, ...masterDerivedVideos])}
        info={video.name}
      />
      {originalClipVideos.map(clip => (
        <ClipTab
          key={clip.id}
          isMaster={false}
          label={`Clip ${clipNumber++}`}
          onClick={() => {
            params.set('clip_id', clip.id);
            history.push(`${location.pathname}?${params}`);

            setActiveTab(`${clip.id}`);
          }}
          isActive={activeTab === `${clip.id}`}
          aggregatedVideoData={aggregateVideos([
            clip,
            ...derivedClipVideos(clip),
          ])}
          info={clip.name}
        />
      ))}
    </div>
  );
};

const _Video = ({ video, derivedVideos, team, ...rest }) => {
  if (!video) {
    return <NotFound {...rest} />;
  }

  const [selectedVersions, setSelectedVersion] = React.useState({});

  let params = new URLSearchParams(location.search);
  const clipId = params.get('clip_id');

  const [activeTab, setActiveTab] = React.useState();

  React.useEffect(
    () => {
      setActiveTab(clipId || 'master');
    },
    [derivedVideos],
  );

  // group all videos by clip id
  const allVideosGroupedByClipId = [video, ...derivedVideos].reduce(
    (grouping, video) => (
      grouping[video.clip_id]
        ? grouping[video.clip_id].push(video)
        : (grouping[video.clip_id] = [video]),
      grouping
    ),
    {},
  );

  // find all original clip videos
  const originalClipVideos = Object.keys(allVideosGroupedByClipId)
    .filter(clipId => clipId !== 'null')
    .map(videoId =>
      allVideosGroupedByClipId[videoId].find(
        video => video.clip_id === video.id,
      ),
    )
    .filter(video => video)
    .sort((a, b) => (a.created_at <= b.created_at ? -1 : 1));

  // function for finding all derived videos for either a clip or the master
  const derivedClipVideos = originalClip =>
    allVideosGroupedByClipId[originalClip.clip_id].filter(video =>
      video.clip_id ? video.id !== originalClip.clip_id : !!video.parent_id,
    );

  const masterDerivedVideos = derivedClipVideos(video);

  const parentClipVideo = originalClipVideos.find(v => v.id === clipId);

  const tabVideo = activeTab === 'master' ? video : parentClipVideo;
  const tabDerivedVideos =
    activeTab === 'master'
      ? masterDerivedVideos
      : parentClipVideo
      ? derivedClipVideos(parentClipVideo)
      : [];

  const isUploaded = tabVideo
    ? tabVideo.upload_state.uploadStatus === 'DONE'
    : false;
  const isIngested = tabVideo
    ? tabVideo.ingestion_state.ingestionStatus === 'DONE'
    : false;
  const isDoneUploadingOrProcessing =
    (isUploaded && isIngested) ||
    (tabVideo && tabVideo.parent_id && isIngested);

  return (
    <div css={{ display: 'flex', flexDirection: 'column', maxWidth: 960 }}>
      <TabSelector
        activeTab={activeTab}
        video={video}
        masterDerivedVideos={masterDerivedVideos}
        originalClipVideos={originalClipVideos}
        derivedClipVideos={derivedClipVideos}
        params={params}
        setActiveTab={setActiveTab}
      />
      {tabVideo && (
        <>
          <div
            css={{
              display: 'flex',
              height: 400,
            }}
          >
            {isDoneUploadingOrProcessing ? (
              <VideoPlayer
                src={tabVideo.web_video_url}
                poster={
                  tabVideo.thumbnail_urls && tabVideo.thumbnail_urls.large
                }
                width={626}
                height={400}
                label={tabVideo.clip_id && 'CLIP'}
              />
            ) : (
              <div
                css={{ display: 'flex', flexDirection: 'column', width: 626 }}
              >
                <ProcessingVideo
                  video={tabVideo}
                  aggregatedVideoData={aggregateVideos([tabVideo])}
                  contentStyles={{
                    fontSize: 20,
                    svg: {
                      width: 48,
                      height: 48,
                    },
                  }}
                />
              </div>
            )}
            <Sidebar video={tabVideo} />
          </div>
          <div
            css={{
              display: 'flex',
              flexDirection: 'column',
              backgroundColor: colors.white0,
            }}
          >
            <div css={{ flex: 1 }}>
              <ActionMenu video={tabVideo} />
              <Versions
                video={tabVideo}
                derivedVideos={tabDerivedVideos}
                team={team}
                versionState={{
                  selectedVersions: selectedVersions[tabVideo.id] || [],
                  setSelectedVersion: newVersions =>
                    setSelectedVersion(selectedVersions => ({
                      ...selectedVersions,
                      [tabVideo.id]: newVersions,
                    })),
                }}
              />
              <Metadata video={tabVideo} />
            </div>
          </div>{' '}
        </>
      )}
    </div>
  );
};

export const Video = connect((state, ownProps) => {
  const video = currentVideoSelector(state, ownProps);
  const team = currentTeamSelector(state, ownProps);

  return {
    video,
    team,
    derivedVideos: derivedVideosSelector(state, ownProps),
  };
})(_Video);
