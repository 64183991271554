function convertFirstBillingTag(text) {
  return text.replace(
    /\[billing settings\]/i,
    '<a onclick="event.stopPropagation()" href="/billing">billing settings</a>',
  );
}

function convertFirstBuyProcessingCreditsTag(text) {
  const buyCreditsRegExp = /\[buy (?:(\d+(\.\d+)?)) processing credits\]/i;
  const match = buyCreditsRegExp.exec(text);

  if (match) {
    return text.replace(
      match[0],
      `<a href='/no-navigate' onClick="document.dispatchEvent(new CustomEvent('openProcessingCredits', { detail: { amount: ${
        match[1]
      } }})); return false;">Click here to buy processing credits</a>`,
    );
  }

  return text;
}

function convertClipLinkTag(text) {
  const clipLinkRegExp = /\[clip tab (?:(\/(?:[-a-zA-Z0-9()@:%_\+.~#?&//=]*)))\]/i;
  const match = clipLinkRegExp.exec(text);

  if (match) {
    return text.replace(
      match[0],
      `<a onclick="event.stopPropagation()" href=${match[1]}>Clip tab</a>`,
    );
  }

  return text;
}

export function replaceTemplateTagsWithHtml(text) {
  if (typeof text === 'string') {
    let result = convertFirstBillingTag(text);
    result = convertFirstBuyProcessingCreditsTag(result);
    result = convertClipLinkTag(result);

    return result;
  }
}
