import React from 'react';

import { Alert } from '../../forms';
import { SquareButton } from '../../button';
import { Modal } from '../../modal';
import { Close, RoundedIconButton } from '../../icons';
import * as colors from '../../colors';
import { rem } from '../../typography';
import { VideoPlayer } from '../../pages/video';

import { ClipSelection } from './clip-selection';

const DialogContainer = ({ children }) => {
  return (
    <div
      css={{
        backgroundColor: colors.white0,
        borderRadius: 5,
        width: 920,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {children}
    </div>
  );
};

const TitleBar = ({ closeDialog }) => (
  <div
    css={{
      position: 'relative',
      width: '100%',
      height: 56,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      color: colors.grey3,
      fontSize: rem(16),
      lineHeight: rem(32),
      borderBottom: '1px solid rgba(165,177,194,.3)',
    }}
  >
    Make Clip
    <RoundedIconButton
      icon={Close}
      styles={{ position: 'absolute', top: 18, right: 14 }}
      iconProps={{ color: colors.grey2 }}
      onClick={closeDialog}
    />
  </div>
);

const BottomBar = ({ children }) => (
  <div
    css={{
      display: 'flex',
      height: 56,
      borderTop: '1px solid rgba(165,177,194,0.2)',
    }}
  >
    {children}
  </div>
);

const DialogActions = ({ team, closeDialog, handleMakeClip, isLoading }) => (
  <div
    css={{
      flex: 1,
      display: 'flex',
      justyfiContent: 'space-between',
      alignItems: 'center',
    }}
  >
    <SquareButton
      secondary
      css={{ marginLeft: 8, marginRight: 8 }}
      onClick={closeDialog}
    >
      Cancel
    </SquareButton>
    <div
      css={{
        flex: 1,
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
      }}
    >
      <SquareButton
        css={{
          marginRight: 8,
          '.info': {
            visibility: 'hidden',
          },
          ':hover .info': {
            visibility: 'visible',
          },
        }}
        isLoading={isLoading}
        disabled={isLoading}
        onClick={() => handleMakeClip()}
      >
        Process
        <div
          className="info"
          css={{
            display: 'block',
            position: 'absolute',
            backgroundColor: colors.white0,
            borderRadius: 3,
            boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.05)',
            padding: '10px 16px',
            bottom: 45,
            width: 240,
            left: -190,
            textAlign: 'left',
            border: `1px solid ${colors.grey3}`,
            color: colors.black0,
          }}
        >
          Opens a confirmation dialog that states the exact cost of transcoding
          this clip
        </div>
      </SquareButton>
    </div>
  </div>
);

export function TrimToolModal({
  isOpen,
  openDialog,
  closeDialog,
  team,
  video,
  appraiseVideo,
  processVideo,
}) {
  const trimReducer = React.useCallback(
    function trimReducer(state, { type, payload }) {
      switch (type) {
        case 'setThumbnails': {
          return {
            ...state,
            thumbnails: payload,
          };
        }
        case 'updateConfig': {
          return {
            ...state,
            config: {
              ...state.config,
              ...payload,
            },
          };
        }
        default:
          throw new Error('Invalid action in trimReducer: ' + type);
      }
    },
    [], // plus other outer dependencies used in the reducer, i.e. variables coming from props
  );

  const defaultClipDuration = 10000;
  const midpointStart =
    (video.metadata.durationInMillis - defaultClipDuration) / 2;
  const midpointEnd = midpointStart + defaultClipDuration;

  const [{ config, thumbnails }, dispatch] = React.useReducer(trimReducer, {
    config: {
      start: Math.ceil(Math.max(midpointStart, 0) / 1000) * 1000,
      end:
        Math.ceil(
          Math.min(video.metadata.durationInMillis, midpointEnd) / 1000,
        ) * 1000,
    },
    thumbnails: [],
  });

  function updateConfig(payload) {
    dispatch({ type: 'updateConfig', payload });
  }

  const [isProcessingVideo, setIsProcessingVideo] = React.useState(false);

  const [error, setError] = React.useState(null);

  React.useEffect(
    () =>
      window.Intercom &&
      window.Intercom('trackEvent', 'opened-trim-tool', {
        videoId: video.id,
        teamId: team.id,
      }),
    [],
  );

  function handleMakeClip() {
    setIsProcessingVideo('clip');

    const startProcessing = targetId => {
      return processVideo({
        targetId,
        ...config,
      }).then(result => {
        if (result.error) {
          return Promise.reject(result.error);
        }

        closeDialog();
      });
    };

    return appraiseVideo(config)
      .then(response => {
        if (response && response.error) {
          return Promise.reject(response.error);
        }

        // close processing tool and open confirmation dialog
        closeDialog();

        openDialog({
          type: 'confirmAppraisal',
          payload: {
            showWarnings: false,
            sourceVideo: video,
            targetVideo: response.targetVideo,
            jobAppraisal: response.jobAppraisal,
            balances: response.balances,
            dispatcher: () => startProcessing(response.targetVideo.id),
          },
        });
      })
      .catch(error => {
        setIsProcessingVideo(false);
        setError(
          error || 'Unknown error processing video; please try again later.',
        );
      });
  }

  // Fetch frames for trim selection thumbnails.
  React.useEffect(() => {
    fetch(`/api/uploads/frame-urls?sourceId=${video.id}`)
      .then(res => res.json())
      .then(frames => {
        dispatch({ type: 'setThumbnails', payload: frames.thumbs });
      });
  }, []);

  return (
    <Modal isOpen={isOpen}>
      <DialogContainer>
        <TitleBar closeDialog={closeDialog} />
        <div css={{ display: 'flex', flex: 1, minHeight: 420 }}>
          <div css={{ flex: 1 }}>
            <div
              css={{
                boxSizing: 'border-box',
                overflowY: 'auto',
                padding: '14px 20px',
                label: { marginBottom: 20 },
              }}
            >
              {
                <div
                  css={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: 'calc(100% + 28px)',
                    margin: '-14px -20px',
                  }}
                >
                  <div css={{ flex: 1 }}>
                    <div
                      css={{
                        width: 760,
                        margin: '0 auto',
                        marginTop: 14,
                        marginBottom: 14,
                        flex: 1,
                      }}
                    >
                      {team.plan_type === 'visitor' && !error && (
                        <Alert
                          text={
                            'You cannot make any clips until you have added your payment information on the [billing settings] page.'
                          }
                          styles={{
                            marginBottom: 12,
                            color: colors.orange0,
                            border: `1px solid ${colors.hexToRgba(
                              colors.orange0,
                              0.7,
                            )}`,
                          }}
                        />
                      )}
                      {error && (
                        <Alert text={error} styles={{ marginBottom: 12 }} />
                      )}
                    </div>

                    <VideoPlayer
                      src={video.web_video_url}
                      poster={
                        video.thumbnail_urls && video.thumbnail_urls.large
                      }
                      width={600}
                      height={340}
                      styles={{
                        margin: '0 auto',
                        marginTop: 14,
                        marginBottom: 20,
                        flex: 1,
                      }}
                    />
                  </div>
                  <ClipSelection
                    maxDuration={null}
                    duration={Math.ceil(video.metadata.durationInMillis / 1000)}
                    start={Math.floor(config.start / 1000)}
                    end={Math.ceil(config.end / 1000)}
                    width={879}
                    frames={thumbnails}
                    onChangeClipDuration={duration =>
                      updateConfig({
                        start: duration.start * 1000,
                        end: duration.end * 1000,
                      })
                    }
                  />
                </div>
              }
            </div>
          </div>
        </div>
        <BottomBar>
          <DialogActions
            team={team}
            closeDialog={closeDialog}
            handleMakeClip={handleMakeClip}
            isLoading={isProcessingVideo}
          />
        </BottomBar>
      </DialogContainer>
    </Modal>
  );
}
