import { Heading, Text } from './typography';

export const CookiePolicy = () => (
  <>
    <Heading as="h1">Cookie policy</Heading>
    <Text>
      This cookie policy (“Policy”) describes what cookies are and how Pixop ApS
      (“Pixop ApS”, “we”, “us” or “our”) uses them on the pixop.com website and
      any of its products or services (collectively, “Website” or “Services”).
    </Text>
    <Text>
      You should read this Policy so you can understand what type of cookies we
      use, the information we collect using cookies and how that information is
      used. It also describes the choices available to you regarding accepting
      or declining the use of cookies. For further information on how we use,
      store and keep your personal data secure, see our Privacy Policy.
    </Text>
    <Heading as="h2">What are cookies?</Heading>
    <Text>
      Cookies are small pieces of data stored in text files that are saved on
      your computer or other devices when websites are loaded in a browser. They
      are widely used to remember you and your preferences, either for a single
      visit (through a “session cookie”) or for multiple repeat visits (using a
      “persistent cookie”).
    </Text>
    <Text>
      Session cookies are temporary cookies that are used during the course of
      your visit to the Website, and they expire when you close the web browser.
    </Text>
    <Text>
      Persistent cookies are used to remember your preferences within our
      Website and remain on your desktop or mobile device even after you close
      your browser or restart your computer. They ensure a consistent and
      efficient experience for you while visiting our Website or using our
      Services.
    </Text>
    <Text>
      Cookies may be set by the Website (“first-party cookies”), or by third
      parties, such as those who serve content or provide advertising or
      analytics services on the website (“third party cookies”). These third
      parties can recognize you when you visit our website and also when you
      visit certain other websites.
    </Text>
    <Heading as="h2">What type of cookies do we use?</Heading>
    <Heading as="h3">Necessary cookies</Heading>
    <Text>
      Necessary cookies allow us to offer you the best possible experience when
      accessing and navigating through our Website and using its features. For
      example, these cookies let us recognize that you have created an account
      and have logged into that account to access the content.
    </Text>
    <Heading as="h3">Functionality cookies</Heading>
    <Text>
      Functionality cookies let us operate the Website and our Services in
      accordance with the choices you make. For example, we will recognize your
      username and remember how you customized the Website and Services during
      future visits.
    </Text>
    <Heading as="h2">Do we use web beacons or tracking pixels?</Heading>
    <Text>
      Our emails may contain a “web beacon” (or “tracking pixel”) to tell us
      whether our emails are opened and verify any clicks through to links or
      advertisements within the email.
    </Text>
    <Text>
      We may use this information for purposes including determining which of
      our emails are more interesting to users and to query whether users who do
      not open our emails wish to continue receiving them.
    </Text>
    <Text>
      The pixel will be deleted when you delete the email. If you do not wish
      the pixel to be downloaded to your device, you should read the email in
      plain text view or with images disabled.
    </Text>
    <Heading as="h2">What are your cookie options?</Heading>
    <Text>
      If you don’t like the idea of cookies or certain types of cookies, you can
      change your browser’s settings to delete cookies that have already been
      set and to not accept new cookies. To learn more about how to do this,
      visit the help pages of your browser.
    </Text>
    <Text>
      Please note, however, that if you delete cookies or do not accept them,
      you might not be able to use all of the features our Website and Services
      offer.
    </Text>
    <Heading as="h2">Changes and amendments</Heading>
    <Text>
      We reserve the right to modify this Policy relating to the Website or
      Services at any time, effective upon posting of an updated version of this
      Policy on the Website. When we do we will post a notification on the main
      page of our Website. Continued use of the Website after any such changes
      shall constitute your consent to such changes.
    </Text>
    <Heading as="h2">Acceptance of this policy</Heading>
    <Text>
      You acknowledge that you have read this Policy and agree to all its terms
      and conditions. By using the Website or its Services you agree to be bound
      by this Policy. If you do not agree to abide by the terms of this Policy,
      you are not authorized to use or access the Website and its Services.
    </Text>
    <Heading as="h2">Contacting us</Heading>
    <Text>
      If you have any questions about this Policy or our use of cookies, please
      contact us.
    </Text>
    <Text>This document was last updated on January 15, 2019</Text>
  </>
);

