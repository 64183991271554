import { useState, useRef, useLayoutEffect } from 'react';

import * as colors from './colors';
import * as constants from './constants';
import { rem } from './typography';
import { ExpandButton } from './button';
import { triggerEvent } from './events';

const itemHeight = 23;
const paddingTopBot = 10;
const marginBelow = 4;
const itemsContainerCss = {
  position: 'absolute',
  right: 0,
  paddingTop: rem(paddingTopBot),
  paddingBottom: rem(paddingTopBot),
  minWidth: 182,
  lineHeight: rem(itemHeight),
  backgroundColor: colors.white0,
  color: colors.grey3,
  boxShadow: `0 0 20px ${colors.hexToRgba(colors.black0, 0.16)}`,
  borderRadius: 5,
  userSelect: 'none',
  cursor: 'default',
  ':hover': {
    cursor: 'pointer',
  },
  '> div': {
    paddingLeft: rem(15),
    paddingRight: rem(15),
  },
  '> div:not(:last-child)': {
    marginBottom: rem(marginBelow),
  },
};

export function ContextMenu({
  id,
  items,
  onClick: outerOnClick,
  onBlur: outerOnBlur,
  isOpen = false,
  styles = {},
}) {
  const ref = useRef();
  const expandRef = useRef();
  const [state, setState] = useState({
    isOpen,
    isFocused: false,
  });
  const [topPos, setTopPos] = useState(30);
  const [leftPos, setLeftPos] = useState('auto');

  useLayoutEffect(() => {
    if (state.isOpen) {
      expandRef.current.focus();
    }
  }, []);

  function toggleOpen() {
    const isOpen = !state.isOpen;

    setState(state => ({ ...state, isOpen }));

    triggerEvent('contextMenuVisibilityChanged', {
      id,
      isOpen,
    });
  }

  function onKeyDown(event) {
    event.stopPropagation();
    if (event.keycode === 13) {
      toggleOpen();
    }
  }

  function onClick(event) {
    if (outerOnClick) {
      outerOnClick(event);
    }

    const { top, left } = ref.current.getBoundingClientRect();
    if ((window.innerHeight - top) / (items.length || 1) < 40) {
      // Negative positioning in the event that there is no space below the
      // button.
      const offset = 5;
      setTopPos(
        -1 *
          (marginBelow * items.length +
            itemHeight * items.length +
            paddingTopBot * 2 +
            offset),
      );
    }

    // Position the element where the button itself is, in the event that there
    // may not be enough space for the dropdown element.
    if (left < 200) {
      setLeftPos(0);
    }

    event.stopPropagation();
    toggleOpen();
  }

  function onFocus() {
    setState(state => ({ ...state, isFocused: true }));
  }

  function onBlur() {
    if (outerOnBlur) {
      outerOnBlur(event);
    }

    setState(state => ({ ...state, isFocused: false, isOpen: false }));

    triggerEvent('contextMenuVisibilityChanged', {
      id,
      isOpen: false,
    });
  }

  return (
    <div
      ref={ref}
      css={{
        position: 'relative',
        ...styles,
      }}
    >
      <div
        css={{
          position: 'absolute',
          top: 0,
          right: 0,
        }}
      >
        <ExpandButton
          onKeyDown={onKeyDown}
          onClick={onClick}
          onFocus={onFocus}
          onBlur={onBlur}
          ref={expandRef}
          styles={{
            ...(state.isFocused
              ? {
                  boxShadow: `0 0 0 1.5px ${colors.hexToRgba(
                    colors.orange1,
                    0.6,
                  )}`,
                }
              : {}),
          }}
        />
      </div>
      {state.isOpen && (
        <div
          css={{
            ...itemsContainerCss,
            top: topPos,
            left: leftPos,
            zIndex: constants.zIndices.base + 2,
          }}
        >
          {items.map(item => (
            <div
              key={item.label}
              onMouseDown={item.onClick ? item.onClick : undefined}
              css={{
                color: item.color,
                transition: 'color .18s ease',
                ':hover': {
                  color: item.color || colors.black0,
                },
                ...(item.onClick
                  ? {
                      color: item.color,
                    }
                  : {
                      cursor: 'default',
                      color: colors.grey0,
                      ':hover': {
                        color: colors.grey0,
                      },
                    }),
              }}
            >
              {item.label}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
