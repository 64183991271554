import React from 'react';
import { connect } from 'react-redux';

import * as colors from '../colors';
import { Heading, Text } from '../typography';
import { fetch } from '../requests';
import { currentTeamSelector } from '../store/selectors';
import { PaginationPanel } from '../pagination-panel';

const PAGE_SIZE = 20;

const Separator = ({ styles = {} }) => (
  <hr
    css={{
      height: 1,
      width: '100%',
      border: 'none',
      backgroundColor: colors.hexToRgba(colors.grey3, 0.3),
      ...styles,
    }}
  />
);

function SectionHeading({ heading, value }) {
  return (
    <>
      <Heading as="h4">{heading}</Heading>
      {value && (
        <span>
          <font
            css={{
              color: colors.grey3,
            }}
          >
            {value}
          </font>
        </span>
      )}
      <Separator styles={{ marginBottom: 20 }} />
    </>
  );
}

function InvoicesSection({ invoices }) {
  const [activePage, setActivePage] = React.useState(1);

  return (
    <>
      {(!invoices || invoices.length === 0) && (
        <>
          <span css={{ color: colors.grey3 }}>No invoices created, yet.</span>
        </>
      )}
      {invoices && invoices.length > 0 && (
        <>
          <table
            css={{
              width: '100%',
              color: colors.grey3,
              borderSpacing: '0 3px',
              'td + td + td + td': { textAlign: 'right' },
              'tbody td + td + td + td': { color: colors.black1 },
              tr: {
                height: 26,
              },
              'thead td': {
                color: colors.grey3,
                fontWeight: 'bold',
              },
              td: { padding: 0 },
              'tr:nth-of-type(even)': {
                background: colors.hexToRgba(colors.grey4, 0.6),
              },
              'tr>td:first-of-type': {
                paddingLeft: 6,
              },
              'tr>td:last-of-type': {
                paddingRight: 6,
              },
            }}
          >
            <thead>
              <tr>
                <td>Date</td>
                <td>Invoice number</td>
                <td>Status</td>
                <td>Amount</td>
                <td />
                <td />
              </tr>
            </thead>
            <tbody>
              {invoices
                .slice((activePage - 1) * PAGE_SIZE, activePage * PAGE_SIZE)
                .map(_row => {
                  return (
                    <tr>
                      <td>{new Date(_row.date * 1000).toLocaleString()}</td>
                      <td>{_row.number}</td>
                      <td
                        css={{
                          textTransform: 'capitalize',
                          color:
                            _row.status === 'paid'
                              ? colors.green0
                              : colors.grey3,
                        }}
                      >
                        {_row.status}
                      </td>
                      <td>{_row.amount} USD</td>
                      <td>
                        <a
                          href={_row.url}
                          css={{
                            color: colors.orange1,
                            cursor: 'pointer',
                            ':hover': { textDecoration: 'underline' },
                          }}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {_row.status === 'open' ? 'Pay' : 'View'}
                        </a>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
          <PaginationPanel
            activePage={activePage}
            itemsCountPerPage={PAGE_SIZE}
            totalItemsCount={invoices.length}
            onChange={pageNumber => setActivePage(pageNumber)}
          />
        </>
      )}
    </>
  );
}

const InvoiceList = connect(state => ({
  currentTeam: currentTeamSelector(state),
}))(_InvoiceList);

function _InvoiceList({ currentTeam }) {
  const [invoices, setInvoices] = React.useState();

  React.useEffect(() => {
    fetch(`/api/customer/${currentTeam.stripe_customer_id}/invoices`)
      .then(res => res.json())
      .then(invoices => setInvoices(invoices));
  }, []);

  return (
    <>
      <>
        <p>
          <SectionHeading heading={'Invoices'} />
          {invoices && <InvoicesSection invoices={invoices.invoices} />}
          {!invoices && <Text>Please wait while fetching invoice list...</Text>}
        </p>
      </>
    </>
  );
}

export function Invoices() {
  return (
    <div
      css={{
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: colors.white0,
        padding: '14px 20px 20px',
        '>*:not(:last-child)': {
          marginBottom: 12,
        },
      }}
    >
      <InvoiceList />
    </div>
  );
}
