import { createAction, createReducer } from './utils';

export const removeMember = createAction('removeMember');
export const addMember = createAction('addMember');

export const membersReducer = createReducer([], {
  [addMember]: (state, action) => [action.payload, ...state],
  [removeMember]: (state, action) =>
    state.filter(member => member.id !== action.payload.id),
});
