import { connect } from 'react-redux';
import { Component, createRef, useCallback } from 'react';
import {
  GoogleReCaptchaProvider,
  useGoogleReCaptcha,
} from 'react-google-recaptcha-v3';
import useReactRouter from 'use-react-router';

import { Anchor, rem } from '../typography';
import { SquareButton } from '../button';
import { Link } from '../link';
import { FormCard, FormTitle, Field, DropDown, Alert } from '../forms';
import * as colors from '../colors';
import { ArrowLeft } from '../icons';
import { fetch } from '../requests';
import { AuthLayout } from '../main-layout';
import { PolicyModal } from '../policy-modal';
import { completedSignup, newsletterSignup } from '../analytics';

class RegisterComponent extends Component {
  defaultFieldValues = {
    name: '',
    email: '',
    team: '',
    password: '',
    passwordConfirmation: '',
    industry: '',
    newsletter: false,
  };

  defaultFieldValidation = {
    name: false,
    email: false,
    team: false,
    password: false,
    passwordConfirmation: false,
    industry: false,
  };

  state = {
    error: false,
    hasRegistered: false,
    isButtonDisabled: false,
    isEmailDisabled: false,
    fieldValues: this.defaultFieldValues,
    fieldValidation: this.defaultFieldValidation,
  };

  onChange = ({ target }) => {
    this.setState({
      fieldValues: {
        ...this.state.fieldValues,
        [target.name]:
          target.type === 'checkbox' ? target.checked : target.value,
      },
    });
  };

  validateForm = () => {
    const { password, passwordConfirmation } = this.state.fieldValues;

    this.setState({
      error: false,
      fieldValidation: this.defaultFieldValidation,
    });

    const presence = field => field === '';
    const containsAtSymbol = field => !field.includes('@');
    const checks = [
      ['name', presence, 'Name required'],
      ['email', presence, 'Email required'],
      ['email', containsAtSymbol, 'Invalid email address'],
      ['password', presence, 'Password required'],
      [
        'password',
        field => field.length < 10 || field.length > 64,
        'Password must be between 10 and 64 characters',
      ],
      ['passwordConfirmation', presence, 'Password confirmation required'],
      ['industry', presence, 'Industry required'],
    ];

    let isValid = true;
    checks.forEach(([field, check, errorMessage]) => {
      if (!isValid) {
        return;
      }

      const value = this.state.fieldValues[field];
      if (check(value)) {
        isValid = false;

        this.setState({
          fieldValidation: {
            ...this.defaultFieldValidation,
            [field]: errorMessage,
          },
        });
      }
    });

    // Manual check for the sake of simplicity, since we compare two fields.
    // Only check if the form is already valid, in order to only show one error
    // at a time.
    if (isValid && password !== passwordConfirmation) {
      this.setState({
        fieldValidation: {
          ...this.defaultFieldValidation,
          password: 'Passwords do not match',
          passwordConfirmation: 'Passwords do not match',
        },
      });

      return false;
    }

    return isValid;
  };

  signUpFormRef = createRef(null);

  submit = reCaptchaToken => {
    if (!this.validateForm()) {
      return;
    }

    const params = new URLSearchParams(window.location.search);
    const campaignId = params.get('campaignId');

    // The & character is escaped.
    const invitationId =
      params.get('invitationId') || params.get('amp;invitationId');
    this.setState({ isButtonDisabled: true });
    fetch('/api/register', {
      method: 'POST',
      body: {
        invitationId,
        name: this.state.fieldValues.name,
        email: this.state.fieldValues.email,
        team: this.state.fieldValues.team,
        password: this.state.fieldValues.password,
        industry: this.state.fieldValues.industry,
        newsletter: this.state.fieldValues.newsletter,
        rewardfulReferralId: this.signUpFormRef.current.referral
          ? this.signUpFormRef.current.referral.value
          : undefined,
        stripeCustomerCouponId: this.signUpFormRef.current.coupon
          ? this.signUpFormRef.current.coupon.value
          : undefined,
        campaignId,
        reCaptchaToken,
      },
    })
      .then(res => res.json())
      .then(({ error }) => {
        this.setState({ isButtonDisabled: false });

        if (error) {
          this.setState({
            error,
          });
          return;
        }

        if (this.signUpFormRef.current.referral) {
          // remove Rewardful referral cookie and referral id query parameter to prevent duplicate sign ups
          const cookieDomain =
            location.hostname === 'app.pixop.com'
              ? 'pixop.com'
              : location.hostname;
          document.cookie =
            'rewardful.referral=; expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/; domain=' +
            cookieDomain;

          const url = new URL(document.location.href);
          const params = new URLSearchParams(url.search);
          params.delete('referral');

          const paramsStr = params.toString();

          window.history.replaceState(
            {},
            '',
            url.origin + url.pathname + (paramsStr ? '?' + paramsStr : ''),
          );
        }

        if (invitationId) {
          // Redirect to auth page, as the invited user has a confirmed email.
          this.props.history.push('/login?p=t');
        } else {
          this.setState({ hasRegistered: true });
        }

        completedSignup();
        if (this.state.fieldValues.newsletter) {
          newsletterSignup();
        }
      })
      .catch(() => {
        this.setState({
          isButtonDisabled: false,
          error: 'Unknown error; please try again',
        });
      });
  };

  backToAuthenticate = event => {
    event.preventDefault();
    this.setState({ isTransitioning: true });
    setTimeout(() => {
      this.setState({ isTransitioning: false });
      this.props.history.push('/login?f=t');
    }, 240);
  };

  componentDidMount() {
    const params = new URLSearchParams(window.location.search);
    const email = params.get('email');
    if (email) {
      this.setState(state => ({
        fieldValues: { ...state.fieldValues, email },
        isEmailDisabled: true,
      }));
    }
  }

  render() {
    const SignUpButton = () => {
      const { executeRecaptcha } = useGoogleReCaptcha();

      const handleReCaptchaVerifyAndSubmit = useCallback(
        async () => {
          if (!executeRecaptcha) {
            this.setState({
              error: 'reCAPTCHA error; please try again',
            });
            return;
          }

          const reCaptchaToken = await executeRecaptcha('register');
          this.submit(reCaptchaToken);
        },
        [executeRecaptcha],
      );

      const onClick = event => {
        event.preventDefault();

        handleReCaptchaVerifyAndSubmit();
      };

      return (
        <SquareButton
          css={{ alignSelf: 'center', width: 130 }}
          onClick={onClick}
          type="submit"
          disabled={
            this.state.isButtonDisabled || !this.state.fieldValues.terms
          }
          isLoading={this.state.isButtonDisabled}
        >
          Sign up
        </SquareButton>
      );
    };

    return (
      <AuthLayout>
        <div id={this.props.reCaptchaElementId} />
        <FormCard
          css={{
            margin: '0 auto',
            position: 'relative',
            transition: 'all .24s ease-out',
            opacity: this.state.isTransitioning ? 0 : 1,
            transform: this.state.isTransitioning
              ? 'translate(-20px, 0px)'
              : 'translate(0px, 0px)',
          }}
          data-rewardful="true"
          ref={this.signUpFormRef}
        >
          <Link
            to="/login"
            onClick={this.backToAuthenticate}
            css={{
              position: 'absolute',
              top: 28,
              left: 31,
            }}
          >
            <ArrowLeft
              color={colors.orange1}
              title="Back to log in page"
              styles={{
                ':hover': { color: colors.orange0 },
              }}
            />
          </Link>
          <FormTitle css={{ alignSelf: 'center' }}>Sign up</FormTitle>
          {this.state.error && <Alert text={this.state.error} />}
          {this.state.hasRegistered ? (
            <p
              css={{
                margin: 0,
                fontSize: rem(14),
                lineHeight: 1.6,
                color: colors.grey2,
                paddingTop: 6,
              }}
            >
              Congratulations, you have created a Pixop account! Please check
              your email in order to verify your email address and log in.
            </p>
          ) : (
            <>
              <Field
                type="name"
                label="Name *"
                fieldName="name"
                placeholder="Your name"
                value={this.state.fieldValues.name}
                onChange={this.onChange}
                validationMessage={this.state.fieldValidation.name}
                autoFocus
                required
              />
              <Field
                type="email"
                label="Email *"
                fieldName="email"
                placeholder="Your email address"
                value={this.state.fieldValues.email}
                onChange={this.onChange}
                validationMessage={this.state.fieldValidation.email}
                disabled={this.state.isEmailDisabled ? 'disabled' : false}
                required
              />
              {!this.state.isEmailDisabled && (
                <Field
                  type="text"
                  label="Organization"
                  fieldName="team"
                  placeholder="Choose a name for your team"
                  value={this.state.fieldValues.team}
                  onChange={this.onChange}
                  validationMessage={this.state.fieldValidation.team}
                />
              )}
              <Field
                type="password"
                label="Password *"
                fieldName="password"
                placeholder="Choose a password"
                value={this.state.fieldValues.password}
                onChange={this.onChange}
                validationMessage={this.state.fieldValidation.password}
                autoComplete="off"
              />
              <Field
                type="password"
                label="Repeat password *"
                fieldName="passwordConfirmation"
                placeholder="Repeat your password"
                value={this.state.fieldValues.passwordConfirmation}
                onChange={this.onChange}
                validationMessage={
                  this.state.fieldValidation.passwordConfirmation
                }
                autoComplete="off"
              />
              <DropDown
                label="Industry *"
                selectedValue={this.state.fieldValues.industry}
                options={[
                  '',
                  'Marketing',
                  'Post Production',
                  'Social Media Content Creation',
                  'Filmmaking',
                  'Journalism',
                  'Broadcasting and Media Production',
                  'Other',
                ].map(v => [v, v])}
                onSelect={value => {
                  this.setState({
                    fieldValues: {
                      ...this.state.fieldValues,
                      industry: value,
                    },
                  });
                }}
                validationMessage={this.state.fieldValidation.industry}
              />
              <label css={{ color: colors.grey3 }}>
                <input
                  type="checkbox"
                  name="newsletter"
                  value={this.state.fieldValues.newsletter}
                  onChange={this.onChange}
                />{' '}
                Sign up for our newsletter
              </label>
              <div>
                <label css={{ color: colors.grey3 }}>
                  <input
                    type="checkbox"
                    name="terms"
                    value={this.state.fieldValues.terms}
                    onChange={this.onChange}
                  />{' '}
                  {'Accept our '}
                </label>
                <PolicyModal policy="terms">
                  {openPolicyModal => (
                    <Anchor
                      css={{ color: colors.orange0, cursor: 'pointer' }}
                      onClick={openPolicyModal}
                    >
                      terms of service
                    </Anchor>
                  )}
                </PolicyModal>
              </div>
              <SignUpButton />
            </>
          )}
        </FormCard>
      </AuthLayout>
    );
  }
}

function _Register({ googleReCaptchaSiteKey }) {
  const reCaptchaElementId = 'register-recaptcha';
  const { history } = useReactRouter();

  return (
    <>
      <GoogleReCaptchaProvider
        reCaptchaKey={googleReCaptchaSiteKey}
        useRecaptchaNet="true"
        useEnterprise="true"
        scriptProps={{ async: true, defer: true, appendTo: 'body' }}
        container={{
          element: reCaptchaElementId,
          parameters: {
            badge: 'bottomleft',
          },
        }}
      >
        <RegisterComponent
          reCaptchaElementId={reCaptchaElementId}
          history={history}
        />
      </GoogleReCaptchaProvider>
    </>
  );
}

export const Register = connect(state => {
  const { env } = state;
  return {
    googleReCaptchaSiteKey: env.GOOGLE_RECAPTCHA_SITE_KEY,
  };
})(_Register);
