const {
  calculateBitrate,
  containers,
  codecs,
  containerCodecs,
  defaults,
} = preval.require('./process-video-options');

export function parseVideo(video) {
  const { container, codec } = getContainerAndCodec(video.metadata);
  const source = {
    codec,
    container,
    name: video.name,
    durationInMillis: video.metadata.durationInMillis,
    displayAspectRatio: video.metadata.displayAspectRatio,
    averageFramerate: video.metadata.averageFramerate,
    resolution: {
      width: video.metadata.frameWidth,
      height: video.metadata.frameHeight,
    },
    bitrate: calculateBitrate(video),
    chromaSubsampling: mapToOutputPixelFormat(video.metadata.pixelFormatName),
    scanning: video.metadata.scanning,
  };

  // set initial reshaped resolution, pixel aspect ratio and display resolution
  setReshapedResolution({ source, ...source.resolution });

  // save original pixel aspect ratio and display resolution
  source.originalPixelAspectRatio = source.pixelAspectRatio;
  source.originalDisplayResolution = source.displayResolution;

  return source;
}

export function computeAspectRatios({
  frameWidth,
  frameHeight,
  displayAspectRatio,
}) {
  const pixelAspectRatio = displayAspectRatio
    ? displayAspectRatio / (frameWidth / frameHeight)
    : 1;
  let displayWidth = frameWidth;
  let displayHeight = frameHeight;
  if (pixelAspectRatio > 1) {
    displayWidth = Math.round(frameWidth * pixelAspectRatio);
  } else {
    displayHeight = Math.round(frameHeight / pixelAspectRatio);
  }
  return { displayWidth, displayHeight, pixelAspectRatio };
}

function updatePixelAspectRatioAndDisplayResolution(source) {
  const { displayWidth, displayHeight, pixelAspectRatio } = computeAspectRatios(
    {
      frameWidth: source.reshapedResolution.width,
      frameHeight: source.reshapedResolution.height,
      displayAspectRatio: source.displayAspectRatio,
    },
  );

  source.pixelAspectRatio = pixelAspectRatio;
  source.displayResolution = { width: displayWidth, height: displayHeight };
}

export function setReshapedResolution({ source, width, height }) {
  source.reshapedResolution = { width, height };

  // update pixel aspect ratio and display resolution
  updatePixelAspectRatioAndDisplayResolution(source);
}

/**
 * Return the source video's container and codec (in short form).
 *
 * - If the source container and codec match available options, they are returned.
 * - If the source container and codec combination do not match available
 *   target containers and/or codecs, defaults are returned for either or both
 *   values.
 * - When a value is missing, the first available value is returned. For
 *   example:
 *   - If a container is set, but no codec, the first listed codec of the
 *     container is used.
 *   - If a codec is set, but no container, the first container listed that has
 *     the codec is used.
 */
const validContainers = Object.keys(containers);
const validCodecs = Object.keys(codecs);
export function getContainerAndCodec({ codecName, containerName }) {
  let container;

  for (let _container of validContainers) {
    // substring search due to some formats sharing the same decoder
    if (containerName.search(_container) >= 0) {
      container = _container;
      break;
    }
  }

  let codec;
  if (validCodecs.includes(codecName)) {
    codec = codecName;
  }

  // Default to... the defaults, when neither is set in the resource.
  const defs = { codec: defaults.codec, container: defaults.container };
  if (!codec && !container) {
    return defs;
  }

  // One of the two is not set (container or codec) - find a matching
  /* eslint-disable-next-line no-unused-vars */
  for (let [_, item] of Object.entries(containerCodecs)) {
    const vals = item.map(v => JSON.parse(v[0]));
    const _container = vals[0].container;
    const containerMatches = container === _container;
    const _codecs = vals.map(v => v.codec);

    for (let _codec of _codecs) {
      // found matching encoder (find 'prores_ks' from 'prores') and either container missing or found matching container -> return container/codec pair
      if (_codec.startsWith(codec) && (!container || containerMatches)) {
        return { codec, container: _container };
      }
    }

    // found matching container -> return first codec for that container
    if (containerMatches) {
      return { container, codec: _codecs[0] };
    }
  }

  return defs;
}

// map YUVJ/UYVY/YUYV/YUVA/RGB/etc. -> YUV
export function mapToOutputPixelFormat(pixelFormatName) {
  console.log('Mapping pixel format: ' + pixelFormatName);

  if (RegExp('yuv\\d\\d\\dp').test(pixelFormatName)) {
    return pixelFormatName;
  } else if (
    RegExp('yuvj\\d\\d\\dp').test(pixelFormatName) ||
    RegExp('yuva\\d\\d\\dp').test(pixelFormatName) ||
    RegExp('uyvy\\d\\d\\d').test(pixelFormatName) ||
    RegExp('yuyv\\d\\d\\d').test(pixelFormatName)
  ) {
    return 'yuv' + pixelFormatName.slice(4, 7) + 'p';
  } else if (RegExp('uyyvyy\\d\\d\\d').test(pixelFormatName)) {
    return 'yuv' + pixelFormatName.slice(6, 9) + 'p';
  } else if (
    pixelFormatName.includes('rgb') ||
    pixelFormatName.includes('bgr')
  ) {
    return pixelFormatName.includes('48') || pixelFormatName.includes('64')
      ? 'yuv444p16le'
      : 'yuv444p';
  }

  // map everything else to YUV 4:4:4 (8-bit)
  return 'yuv444p';
}
