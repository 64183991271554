import React from 'react';
import { connect } from 'react-redux';
import { loadStripe } from '@stripe/stripe-js';

import * as colors from '../colors';
import { Dialog } from '../dialog';
import { openDialog, closeDialog } from '../store/dialog';
import { SquareButton } from '../button';
import { Heading } from '../typography';
import { fetch } from '../requests';
import { Alert, Field } from '../forms';
import { currentTeamSelector } from '../store/selectors';
import { updateTeam } from '../store/teams';
import * as analytics from '../analytics';
import { triggerEvent } from '../events';

const Separator = ({ styles = {} }) => (
  <hr
    css={{
      height: 1,
      width: '100%',
      border: 'none',
      backgroundColor: colors.hexToRgba(colors.grey3, 0.3),
      ...styles,
    }}
  />
);

function SectionHeading({ heading, value }) {
  return (
    <>
      <Heading as="h4">{heading}</Heading>
      {value && (
        <span>
          <font
            css={{
              color: colors.grey3,
            }}
          >
            {value}
          </font>
        </span>
      )}
      <Separator styles={{ marginBottom: 20 }} />
    </>
  );
}

function _ProcessingCredits({
  stripePublicKey,
  updateTeam,
  openDialog,
  closeDialog,
  team,
  amount,
}) {
  const [currentBalance, setCurrentBalance] = React.useState();
  const [lifetimeSpend, setLifetimeSpend] = React.useState();
  const [promoCode, setPromoCode] = React.useState();
  const [
    promoCodeValidationError,
    setPromoCodeValidationError,
  ] = React.useState();
  const [promoCodeMessage, setPromoCodeMessage] = React.useState();
  const [
    promoCodeMessageTimerId,
    setPromoCodeMessageTimerId,
  ] = React.useState();
  const [isProcessingPromoCode, setIsProcessingPromoCode] = React.useState(
    false,
  );
  const [percentOffNextPurchase, setPercentOffNextPurchase] = React.useState(0);
  const [conversionMultiplier, setConversionMultiplier] = React.useState(0);
  const [isBuying, setIsBuying] = React.useState(false);
  const [hasPaid, setHasPaid] = React.useState(false);

  const standardMinimumAmount = 10;

  const [minimumPurchase, setMinimumPurchase] = React.useState(
    amount > 0 ? Math.ceil(amount) : standardMinimumAmount,
  );
  const [creditsAmount, setCreditsAmount] = React.useState(
    Math.min(minimumPurchase, 9999),
  );

  const [
    buyCreditsValidationError,
    setBuyCreditsValidationError,
  ] = React.useState();
  const [buyCreditsMessage, setBuyCreditsMessage] = React.useState();
  const [
    buyCreditsMessageTimerId,
    setBuyCreditsMessageTimerId,
  ] = React.useState();
  const [threeDSecureIFrame, setThreeDSecureIFrame] = React.useState();

  const mounted = React.useRef(false);

  const stripePromiseRef = React.useRef(loadStripe(stripePublicKey));

  function fetchBalance() {
    fetch(`/api/transactions/${team.id}/processing-balance`)
      .then(res => res.json())
      .then(response => {
        if (!response.error) {
          setCurrentBalance((response.processing.millicents / 1e5).toFixed(2));
          setLifetimeSpend(response.lifetimeSpend.sum.toFixed(2));

          // if the user has never bought credits and the amount to be paid is not the default,
          // then increase the minimum to $10
          if (response.lifetimeSpend.sum === 0 && amount > 0) {
            setMinimumPurchase(standardMinimumAmount);

            // also bump the current amount if it is currently less than $10
            if (creditsAmount < standardMinimumAmount) {
              setCreditsAmount(standardMinimumAmount);
            }
          }
        }
      })
      .catch(() => {});
  }

  function fetchDiscounts() {
    fetch(`/api/discounts/${team.id}`)
      .then(res => res.json())
      .then(response => {
        if (!response.error) {
          const totalPercentOff = parseFloat(response.totalPercentOff);

          setPercentOffNextPurchase(totalPercentOff);
          setConversionMultiplier(1 - totalPercentOff / 100);
        }
      })
      .catch(() => {});
  }

  React.useEffect(() => {
    mounted.current = true;

    fetchDiscounts();
    fetchBalance();

    const intervalDiscountTimerId = setInterval(fetchDiscounts, 10000);
    const intervalBalanceTimerId = setInterval(fetchBalance, 10000);

    return () => {
      mounted.current = false;

      clearInterval(intervalDiscountTimerId);
      clearInterval(intervalBalanceTimerId);
    };
  }, []);

  function closeProcessingCredits() {
    // only if the dialog is still mounted
    if (mounted.current) {
      clearTimeout(promoCodeMessageTimerId);
      clearTimeout(buyCreditsMessageTimerId);

      closeDialog();
    }
  }

  const onBuyCreditsChange = ({ target }) => {
    if (target.value.match(/^[A-Za-z]+$/)) {
      return;
    }

    let newValue = parseInt(target.value);

    if (!isNaN(newValue)) {
      if (newValue > 9999) {
        newValue = 9999;
      }
      setCreditsAmount(newValue);
    } else {
      setCreditsAmount('');
    }
  };

  const onBuyCreditsBlur = ({ target }) => {
    let newValue = parseInt(target.value);

    if (newValue < minimumPurchase || isNaN(newValue)) {
      setCreditsAmount(minimumPurchase);
    } else if (newValue > 9999) {
      setCreditsAmount(9999);
    } else {
      setCreditsAmount(newValue);
    }
  };

  function handlePromotionCodeSubmit(event) {
    event.preventDefault();

    setPromoCodeValidationError();
    setIsProcessingPromoCode(true);

    return fetch(`/api/discounts/${team.id}`, {
      method: 'POST',
      body: { promotionCode: promoCode },
    })
      .then(res => res.json())
      .then(response => {
        if (response.error) {
          setPromoCode('');
          setPromoCodeValidationError(response.error);
          return;
        }

        setPromoCode(response.code);

        setPromoCodeMessage(`Promotion code applied!`);
        setPromoCodeMessageTimerId(
          setTimeout(() => {
            setPromoCodeMessageTimerId(null);

            setPromoCodeMessage();
          }, 5000),
        );

        updateTeam({
          id: team.id,
          stripe_credits_promo_code: response.code,
        });
      })
      .catch(error => {
        setPromoCodeValidationError('Could not apply promotion code');
      })
      .finally(() => setIsProcessingPromoCode(false));
  }

  function handleBuyCreditsFormSubmit(event) {
    event.preventDefault();

    if (!team.stripe_customer_id) {
      setBuyCreditsValidationError(
        'Your billing address and payment info is required to buy processing credits. Please add your payment information on the [billing settings] page and try again.',
      );
      return;
    }

    setBuyCreditsValidationError();

    fetch(`/api/customer/${team.stripe_customer_id}/appraise-credits`, {
      method: 'POST',
      body: {
        amount: creditsAmount,
      },
    })
      .then(res => res.json())
      .then(response => {
        if (response.error) {
          setBuyCreditsValidationError(response.error);

          return;
        }

        const outputAmount = response.outputAmount;

        function paymentSuccessful(purchaseResponse) {
          if (purchaseResponse.invoiceId) {
            analytics.purchaseCredits({ ...purchaseResponse, team });

            triggerEvent('processingCreditsPaid', {
              amount: purchaseResponse.amount,
            });
          }

          // no need to bother if the dialog has been closed
          if (mounted.current) {
            setHasPaid(true);
            setCreditsAmount(minimumPurchase);

            setBuyCreditsMessage(
              `Thanks, $${outputAmount} will be available in a few seconds; this window will close automatically.`,
            );
            setBuyCreditsMessageTimerId(
              setTimeout(() => {
                setBuyCreditsMessageTimerId(null);

                setBuyCreditsMessage();
                closeProcessingCredits();
              }, 6000),
            );
          }
        }

        let isConfirmed = false;

        function close() {
          closeDialog();

          if (isConfirmed) {
            setIsBuying(true);

            return fetch(
              `/api/customer/${team.stripe_customer_id}/processing-credits`,
              {
                method: 'POST',
                body: {
                  amount: creditsAmount,
                },
              },
            )
              .then(res => res.json())
              .then(response => {
                if (response.error) {
                  setBuyCreditsValidationError(response.error);

                  setIsBuying(false);
                  return;
                } else if (response.returnUrl) {
                  setThreeDSecureIFrame(
                    `<iframe width="500" height="600" frameborder="no" title="3DS Authentication" src="${
                      response.returnUrl
                    }"</iframe>`,
                  );

                  const threeDSListener = function(event) {
                    if (event.data === '3DS-authentication-complete') {
                      window.removeEventListener('message', threeDSListener);

                      setThreeDSecureIFrame(null);
                      setIsBuying(false);

                      // Check the PaymentIntent
                      stripePromiseRef.current.then(val => {
                        return val
                          .retrievePaymentIntent(response.client_secret)
                          .then(function(result) {
                            if (result.error) {
                              // PaymentIntent client secret was invalid
                              setBuyCreditsValidationError(
                                'Failed to create payment, please try again.',
                              );
                            } else {
                              if (result.paymentIntent.status === 'succeeded') {
                                paymentSuccessful(response);
                              } else if (
                                result.paymentIntent.status ===
                                'requires_payment_method'
                              ) {
                                // Authentication failed, prompt the customer to enter another payment method
                                setBuyCreditsValidationError(
                                  'Authentication failed, please try again or enter another payment method on the [billing settings] page.',
                                );
                              }
                            }
                          });
                      });
                    }
                  };

                  window.addEventListener('message', threeDSListener);

                  return;
                } else {
                  setIsBuying(false);
                  paymentSuccessful(response);
                }
              })
              .catch(error => {
                setBuyCreditsValidationError(
                  'A critical error occurred, please try again later.',
                );

                setIsBuying(false);
              });
          }
        }

        openDialog({
          type: 'confirmPurchaseCredits',
          isTeamVATExempt: response.isTeamVATExempt,
          inputWithTaxAmount: response.inputWithTaxAmount,
          outputAmount: response.outputAmount,
          close,
          onConfirm: () => (isConfirmed = true),
        });
      });
  }

  return (
    <Dialog
      isOpen
      close={closeProcessingCredits}
      heading="Processing credits"
      containerStyles={{ width: 540 }}
    >
      {currentBalance && (
        <>
          <div css={{ marginBottom: '30px' }}>
            <div css={{ float: 'left' }}>
              <span css={{ color: colors.grey3 }}>Current balance: </span>
              <span css={{ fontSize: 16 }}>${currentBalance}</span>
            </div>
            <div css={{ float: 'right' }}>
              <span css={{ color: colors.grey3 }}>Lifetime spend: </span>
              <span css={{ fontSize: 16 }}>${lifetimeSpend}</span>
            </div>
          </div>
        </>
      )}
      {!currentBalance && (
        <div css={{ marginBottom: '20px' }}>
          <span css={{ color: colors.grey3 }}>Please wait...</span>
        </div>
      )}
      {['pro', 'enterprise'].includes(team.plan_type) && (
        <>
          <div css={{ marginBottom: '10px' }}>
            <SectionHeading heading="Promotional discount" />

            <form onSubmit={handlePromotionCodeSubmit}>
              <div css={{ display: 'flex' }}>
                <div css={{ flexBasis: '30%', paddingRight: 10 }}>
                  <Field
                    type="text"
                    label="Promo code"
                    placeholder="Enter a promotion code"
                    fieldName="promoCode"
                    id="edit-promo-code"
                    value={promoCode}
                    onChange={({ target }) => setPromoCode(target.value)}
                    disabled={isProcessingPromoCode || isBuying || hasPaid}
                  />
                </div>
                <div
                  css={{ flexBasis: '15%', paddingRight: 10, marginTop: 18 }}
                >
                  <SquareButton
                    isLoading={isProcessingPromoCode}
                    disabled={isProcessingPromoCode || isBuying || hasPaid}
                    onClick={handlePromotionCodeSubmit}
                    mainColor={colors.green0}
                  >
                    Apply
                  </SquareButton>
                </div>
                <div
                  css={{
                    flexBasis: '55%',
                    color: colors.grey3,
                    fontSize: 12,
                    marginTop: 32,
                  }}
                >
                  {promoCodeMessage && <span>{promoCodeMessage}</span>}
                </div>
              </div>
            </form>
          </div>

          {promoCodeValidationError && (
            <Alert text={promoCodeValidationError} />
          )}
        </>
      )}
      <div css={{ marginTop: '10px', marginBottom: '10px' }}>
        <SectionHeading heading="Buy processing credits" />

        {percentOffNextPurchase > 0 && (
          <div css={{ marginBottom: 20 }}>
            <table
              css={{
                width: '80%',
                border: '1px solid',
                color: colors.grey3,
                borderSpacing: '0',
                td: {
                  textAlign: 'center',
                  padding: 0,
                },
                'thead td': {
                  color: colors.grey3,
                  fontWeight: 'bold',
                },
                tr: {
                  height: 28,
                },
                'thead tr': {
                  background: colors.hexToRgba(colors.grey4, 0.6),
                },
                'tbody tr:nth-child(2n)': {
                  background: colors.hexToRgba(colors.grey4, 0.6),
                },
              }}
            >
              <thead>
                <tr>
                  <td>Credits received</td>
                  <td>Discount</td>
                  <td>Paid (ex. VAT)</td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>${minimumPurchase} - $9999</td>
                  <td css={{ color: colors.black0 }}>
                    {percentOffNextPurchase}%
                  </td>
                  <td>
                    ${(minimumPurchase * conversionMultiplier).toFixed(2)} - $
                    {(9999 * conversionMultiplier).toFixed(2)}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        )}

        <form onSubmit={handleBuyCreditsFormSubmit}>
          <div css={{ display: 'flex' }}>
            <div css={{ flexBasis: '30%', paddingRight: 10 }}>
              <Field
                type="number"
                label="Credits amount (USD)"
                fieldName="creditsAmount"
                id="edit-credits-amount"
                value={creditsAmount}
                onChange={onBuyCreditsChange}
                onBlur={onBuyCreditsBlur}
                disabled={isBuying || hasPaid}
                min={minimumPurchase.toString()}
                max="9999"
                required
              />
            </div>
            <div css={{ flexBasis: '15%', paddingRight: 10, marginTop: 18 }}>
              <SquareButton
                isLoading={isBuying}
                disabled={isBuying || hasPaid}
                onClick={handleBuyCreditsFormSubmit}
              >
                Buy
              </SquareButton>
            </div>
            <div
              css={{
                flexBasis: '55%',
                color: colors.grey3,
                fontSize: 12,
                marginTop: 24,
              }}
            >
              {buyCreditsMessage && <span>{buyCreditsMessage}</span>}
            </div>
          </div>
        </form>
      </div>

      {team.plan_type === 'visitor' && !buyCreditsValidationError && (
        <Alert
          text={
            'You cannot purchase credits until you have added your payment information on the [billing settings] page.'
          }
          styles={{
            marginBottom: 24,
            color: colors.orange0,
            border: `1px solid ${colors.hexToRgba(colors.orange0, 0.7)}`,
          }}
        />
      )}
      {team.plan_type === 'test' && !buyCreditsValidationError && (
        <Alert
          text={
            'Your team is assigned to the test plan; please reach out to us if you need additional processing credits.'
          }
          styles={{
            marginBottom: 24,
            color: colors.orange0,
            border: `1px solid ${colors.hexToRgba(colors.orange0, 0.7)}`,
          }}
        />
      )}

      {threeDSecureIFrame && (
        <p>
          <div
            dangerouslySetInnerHTML={{
              __html: threeDSecureIFrame,
            }}
          />
        </p>
      )}
      {buyCreditsValidationError && <Alert text={buyCreditsValidationError} />}

      <Separator styles={{ marginTop: 20, marginBottom: 10 }} />
      <div css={{ color: colors.grey3, fontSize: 12 }}>
        Press <b css={{ color: colors.black0 }}>Buy</b> to open a confirmation
        dialog that states the exact amount to be paid (including VAT, if
        applicable) and the processing credits you will receive. Processing
        credits will be transferred to your account immediately after you click{' '}
        <b css={{ color: colors.black0 }}>Confirm</b>. Our Terms of Service can
        be found{' '}
        <a
          href="https://www.pixop.com/terms-of-service"
          target="_blank"
          rel="nofollow"
          css={{ color: colors.orange0 }}
        >
          here
        </a>
        .
      </div>
    </Dialog>
  );
}

export const ProcessingCredits = connect(
  (state, ownProps) => {
    const { env } = state;
    return {
      team: currentTeamSelector(state),
      stripePublicKey: env.STRIPE_PUBLIC_KEY,
      amount: ownProps.payload.amount,
    };
  },
  { updateTeam, openDialog, closeDialog },
)(_ProcessingCredits);
