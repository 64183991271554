import { connect } from 'react-redux';
import { NavLink as RouterNavLink, matchPath } from 'react-router-dom';
import useReactRouter from 'use-react-router';
import React from 'react';

import * as colors from './colors';
import * as constants from './constants';
import {
  teamProjectsSelector,
  teamVideosSelector,
  teamNotificationsSelector,
  userSelector,
  currentTeamSelector,
} from './store/selectors';
import { Profile } from './profile';
import { rem } from './typography';
import { Link } from './link';
import { Text } from './typography';
import { openDialog } from './store/dialog';
import { NotificationsBadge } from './notifications-badge';
import { SearchBox } from './search-box';
import { ArrowLeft } from './icons';
import { PixopLogo } from './pixop-logo';
import { SquareButton } from './button';
import { eventOn, eventOff, triggerEvent } from './events';

function NavLink({ styles = {}, ...rest }) {
  return (
    <RouterNavLink
      exact
      activeStyle={{ color: colors.black1 }}
      css={{ fontSize: rem(14), cursor: 'pointer', ...styles }}
      {...rest}
    />
  );
}

export const mainNavHeight = 80;
export const _MainNav = ({ user, notifications, openDialog }) => {
  const openProcessingCredits = React.useCallback(
    event => openDialog({ type: 'processingCredits', payload: event.detail }),
    [openDialog],
  );

  React.useEffect(
    () => {
      eventOn('openProcessingCredits', openProcessingCredits);

      return () => {
        eventOff('openProcessingCredits', openProcessingCredits);
      };
    },
    [openProcessingCredits],
  );

  return (
    <nav
      css={{
        boxSizing: 'border-box',
        position: 'fixed',
        top: 0,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        height: mainNavHeight,
        backgroundColor: colors.white0,
        padding: '0 20px',
        boxShadow: `0 3px 15px 0 ${colors.hexToRgba(colors.black0, 0.03)}`,
        zIndex: constants.zIndices.nav + 1,
      }}
    >
      <a href="https://www.pixop.com/" aria-label="Home">
        <PixopLogo height={27} width={133.5} />
      </a>
      {user ? (
        <div
          css={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            'a, >span': {
              textDecoration: 'none',
              marginRight: 24,
              color: colors.grey2,
              transition: 'color .36s ease',
            },
            'a:last-of-type': {
              marginRight: 30,
            },
            'a:hover, span:hover': {
              color: colors.black0,
            },
          }}
        >
          <SearchBox styles={{ minWidth: 174, height: 30, marginRight: 30 }} />
          <NavLink to="/">Projects</NavLink>
          <NavLink to="/stats">Stats</NavLink>
          <NavLink
            to="/billing/buy-credits"
            onClick={event => {
              event.preventDefault();
              triggerEvent('openProcessingCredits', { amount: 0 });
            }}
            styles={{
              color: `${colors.orange1} !important`,
              ':hover': {
                color: `${colors.orange2} !important`,
              },
            }}
          >
            Buy Processing Credits
          </NavLink>
          <NavLink
            to="/no-navigate"
            onClick={event => {
              event.preventDefault();
              openDialog({ type: 'upload' });
            }}
          >
            Upload
          </NavLink>
          <NotificationsBadge
            number={notifications.length}
            styles={{ width: 26, height: 36, marginRight: 20 }}
          />
          <Profile color={colors.grey0} />
        </div>
      ) : (
        <div
          css={{
            '@media (max-width: 440px)': {
              '.sign-up': {
                display: 'none',
              },
            },
          }}
        >
          <Link
            to="/register"
            css={{ marginRight: 20, color: colors.orange1 }}
            className="sign-up"
          >
            Sign up
          </Link>
          <Link
            to="/login"
            css={{
              color: colors.orange1,
              ':hover': { textDecoration: 'none' },
            }}
          >
            <SquareButton styles={{ marginRight: 20 }}>Log in</SquareButton>
          </Link>
        </div>
      )}
    </nav>
  );
};

export const MainNav = connect(
  state => ({
    user: userSelector(state),
    notifications: teamNotificationsSelector(state),
  }),
  { openDialog },
)(_MainNav);

export const subNavHeight = 50;

function findProject(projects, projectId) {
  return projects.find(project => project.id === projectId);
}

function findVideo(videos, videoId) {
  return videos.find(video => video.id === videoId);
}

const nameCapitalized = name =>
  name.charAt(0).toUpperCase() + name.slice(1).replace(/-/g, ' ');

const Page = ({ name, href }) => (
  <Link
    to={href}
    css={{ color: colors.orange1, ':hover': { color: colors.orange0 } }}
  >
    {name}
  </Link>
);

const _Breadcrumbs = ({ projects, videos, team }) => {
  useReactRouter(); // Subscribe to route changes

  const matchVideo = matchPath(window.location.pathname, {
    path: '/projects/:projectId/videos/:videoId',
  });
  const matchProject = matchPath(window.location.pathname, {
    path: '/projects/:projectId',
  });
  const matchAccount = matchPath(window.location.pathname, {
    path: '/account/:subpage',
  });
  const matchBilling = matchPath(window.location.pathname, {
    path: '/billing/:subpage',
  });
  const matchAny = matchPath(window.location.pathname, {
    path: '/*',
  });

  const CurrentTeam = () => (
    <>
      <Text
        css={{ color: colors.grey10, paddingRight: '20px', fontWeight: 'bold' }}
      >
        {team && nameCapitalized(team.name)}
      </Text>
    </>
  );

  return (
    <div
      css={{
        display: 'flex',
        alignItems: 'center',
        position: 'fixed',
        boxSizing: 'border-box',
        top: mainNavHeight,
        width: '100%',
        height: subNavHeight,
        padding: '3px 20px 0',
        backgroundColor: colors.white1,
        color: colors.grey3,
        borderBottom: `1px solid ${colors.hexToRgba(colors.grey3, 0.16)}`,
        fontSize: rem(14),
        zIndex: constants.zIndices.nav,
        whiteSpace: 'nowrap',
        'a:not(:last-child)::after': {
          display: 'inline-block',
          content: '"/"',
          margin: '0 5px',
          color: colors.grey3,
        },
        'a:last-child': {
          color: colors.grey10,
          textDecoration: 'none',
          cursor: 'default',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          overflow: 'hidden',
        },
      }}
    >
      <CurrentTeam />
      {matchAny && (
        <>
          {matchAny.url.startsWith('/account') ? (
            <>
              <Page href="/account" name="Account" />
            </>
          ) : matchAny.url.startsWith('/billing') ? (
            <>
              <Page href="/billing" name="Billing" />
            </>
          ) : matchAny.url === '/stats' ? (
            <>
              <Page href={window.location.pathname} name="Stats" />
            </>
          ) : matchAny.url !== '/' ? (
            <>
              <span
                css={{
                  display: 'inline-flex',
                  marginRight: 12,
                  color: colors.orange1,
                  cursor: 'pointer',
                  ':hover': {
                    color: colors.orange0,
                    textDecoration: 'underline',
                  },
                  ':hover svg path': {
                    fill: colors.orange0,
                  },
                }}
                onClick={() => window.history.back()}
              >
                <ArrowLeft
                  width={10}
                  color={colors.orange1}
                  style={{ marginRight: 6 }}
                />
                Back
              </span>
              <Page href="/" name="Projects" />
            </>
          ) : (
            <>
              <Page href="/" name="Projects" />
            </>
          )}
        </>
      )}
      {matchProject &&
        matchProject.isExact &&
        (() => {
          const project = findProject(projects, matchProject.params.projectId);
          return project ? (
            <Page href={`/projects/${project.id}`} name={project.name} />
          ) : null;
        })()}
      {matchVideo &&
        matchVideo.isExact &&
        (() => {
          const project = findProject(projects, matchVideo.params.projectId);
          const video = findVideo(videos, matchVideo.params.videoId);

          const params = new URLSearchParams(location.search);
          const clipId = params.get('clip_id');
          const clip = findVideo(videos, clipId);

          return project && video ? (
            <>
              <Page href={`/projects/${project.id}`} name={project.name} />
              <Page
                href={`/projects/${project.id}/videos/${video.id}`}
                name={video.name}
              />
              {clip && (
                <Page
                  href={`/projects/${project.id}/videos/${
                    video.id
                  }?clip_id=${clipId}`}
                  name={clip.name}
                />
              )}
            </>
          ) : null;
        })()}

      {matchAccount &&
        matchAccount.isExact &&
        (() => {
          const subpage = matchAccount.params.subpage;
          return subpage ? (
            <Page
              href={`/account/${subpage}`}
              name={nameCapitalized(subpage)}
            />
          ) : null;
        })()}
      {matchBilling &&
        matchBilling.isExact &&
        (() => {
          const subpage = matchBilling.params.subpage;
          return subpage ? (
            <Page
              href={`/billing/${subpage}`}
              name={nameCapitalized(subpage)}
            />
          ) : null;
        })()}
    </div>
  );
};

export const Breadcrumbs = connect(state => ({
  projects: teamProjectsSelector(state),
  videos: teamVideosSelector(state),
  team: currentTeamSelector(state),
}))(_Breadcrumbs);

export const totalNavHeight = mainNavHeight + subNavHeight;
