import { createAction, createReducer } from './utils';
import { ability } from '../ability';
import { addToast } from './toasts';

export const setCurrentTeam = createAction('setCurrentTeam');
export const removeTeam = createAction('removeTeam');
export const updateTeam = createAction('updateTeam');

export const teamsMiddleware = store => next => action => {
  const state = store.getState();

  // update ACLs if an update is received for the currently active team
  if (
    action.type === updateTeam.type &&
    state.currentTeamId === action.payload.id
  ) {
    const currentTeam = state.teams.find(
      team => team.id === state.currentTeamId,
    );

    if (currentTeam && action.payload.acls) {
      ability.update(action.payload.acls);
    }
    if (currentTeam && action.payload.bonus) {
      store.dispatch(
        addToast({
          text: `You have successfully redeemed your $${
            action.payload.bonus
          } processing credits bonus`,
        }),
      );
    }
  }

  return next(action);
};

export const currentTeamIdReducer = createReducer(null, {
  [setCurrentTeam]: (state, action) => action.payload.id,
});

export const teamsReducer = createReducer([], {
  [removeTeam]: (state, action) =>
    state.filter(team => team.id !== action.payload.id),
  [updateTeam]: (state, action) => {
    return state.map(team => {
      if (team.id === action.payload.id) {
        return {
          ...team,
          ...action.payload,
        };
      }
      return team;
    });
  },
});
