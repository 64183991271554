import { bitsInClosestUnit } from '../../utils';

const pr422profiles = [
  {
    bpp: 20,
    compressionFactor: 26,
    profile: 'proxy',
    name: 'Proxy',
    pixelFormatName: 'yuv422p10le',
  },
  {
    bpp: 20,
    compressionFactor: 11.6,
    profile: 'lt',
    name: 'LT',
    pixelFormatName: 'yuv422p10le',
  },
  {
    bpp: 20,
    compressionFactor: 8,
    profile: 'standard',
    name: 'Standard',
    pixelFormatName: 'yuv422p10le',
  },
  {
    bpp: 20,
    compressionFactor: 5.4,
    profile: 'hq',
    name: 'HQ',
    pixelFormatName: 'yuv422p10le',
  },
];

const pr4444profiles = [
  {
    bpp: 36,
    compressionFactor: 8.0,
    profile: '4444',
    name: 'Standard',
    pixelFormatName: 'yuv444p12le',
  },
  {
    bpp: 36,
    compressionFactor: 5.9,
    profile: '4444xq',
    name: 'XQ',
    pixelFormatName: 'yuv444p12le',
  },
];

export function getProResOptions() {
  const proresOptions = {};

  proresOptions['422'] = pr422profiles.map(profile => [
    JSON.stringify({
      ...profile,
      group: '422',
    }),
    `${profile.name}`,
  ]);
  proresOptions['4444 (without alpha)'] = pr4444profiles.map(profile => [
    JSON.stringify({
      ...profile,
      group: '4444 (without alpha)',
    }),
    `${profile.name}`,
  ]);

  return proresOptions;
}

export function getProResCodecProperties(profile) {
  const codecProperties = {};
  // pick the required runtime ProRes profile for FFmpeg
  codecProperties.profile = '_' + profile.profile;

  return codecProperties;
}

export function getProResBitrateAndLabel(profile, targetResolution, fps) {
  // calculate ProRes profile bitrate estimate
  const bitrateEstimate =
    (targetResolution.width * targetResolution.height * profile.bpp * fps) /
    profile.compressionFactor;

  return {
    bitrate: bitrateEstimate,
    label: `${bitsInClosestUnit(bitrateEstimate)}ps (estimate)`,
  };
}
