import * as colors from '../colors';
import { Dialog } from '../dialog';
import { Text } from '../typography';

export function ConfirmPurchaseCredits({
  isTeamVATExempt,
  inputWithTaxAmount,
  outputAmount,
  onConfirm,
  close,
}) {
  return (
    <Dialog
      heading="Confirm purchase"
      confirmText="Confirm"
      onConfirm={onConfirm}
      close={close}
      closeOnConfirm
      isOpen
    >
      <Text
        css={{
          marginBottom: 6,
        }}
      >
        Do you want to proceed with the buying{' '}
        <span css={{ color: colors.black0, fontSize: 16 }}>
          ${outputAmount.toFixed(2)}
        </span>{' '}
        processing credits?{' '}
        <span css={{ color: colors.black0, fontSize: 16 }}>
          ${inputWithTaxAmount.toFixed(2)}
        </span>
        {!isTeamVATExempt && ', including taxes,'} will be charged from your
        payment method on file when you confirm the purchase.
      </Text>
    </Dialog>
  );
}
