/**
 * These Utilities inspired by the redux-starter-kit utilitites.
 */

export function createReducer(initialState = null, actionsMap) {
  return function(state = initialState, action) {
    const reducer = actionsMap[action.type];
    return reducer ? reducer(state, action) : state;
  };
}

export function createAction(type, payloadCreator) {
  function actionCreator(payload) {
    if (payloadCreator) {
      return { type, payload: payloadCreator(payload) };
    }
    return { type, payload };
  }
  actionCreator.toString = () => `${type}`;
  actionCreator.type = type;
  return actionCreator;
}
