import React from 'react';
import { connect } from 'react-redux';
import useReactRouter from 'use-react-router';

import {
  formatFrameRateWithScanningAndFieldOrder,
  formatPixelFormatNameAndBitDepth,
  generateWarnings,
} from './process-video-options';
import { ExclamationCircle } from '../../icons';
import { formatMsToHourMinutesSeconds, inClosestUnit } from '../../utils';
import * as colors from '../../colors';
import { Dialog } from '../../dialog';
import { Alert } from '../../forms';
import { ability } from '../../ability';
import { eventOn, eventOff } from '../../events';

export const bytesInClosestUnit = inClosestUnit('bytes', 1);

function _ConfirmAppraisal({
  teamId,
  showWarnings,
  sourceVideo,
  targetVideo,
  jobAppraisal,
  balances,
  dispatcher,
}) {
  const { history } = useReactRouter();

  const [
    currentProcessingCreditsBalance,
    setProcessingCreditsCurrentBalance,
  ] = React.useState(balances.processing.millicents);
  const [error, setError] = React.useState(null);

  const processingCreditsPaid = React.useCallback(() => {
    setError();
  }, []);

  React.useEffect(
    () => {
      eventOn('processingCreditsPaid', processingCreditsPaid);

      return () => {
        eventOff('processingCreditsPaid', processingCreditsPaid);
      };
    },
    [processingCreditsPaid],
  );

  function fetchBalance() {
    fetch(`/api/transactions/${teamId}/processing-balance`)
      .then(res => res.json())
      .then(response => {
        if (!response.error) {
          setProcessingCreditsCurrentBalance(response.processing.millicents);
        }
      })
      .catch(() => {});
  }

  React.useEffect(() => {
    fetchBalance();

    const intervalBalanceTimerId = setInterval(fetchBalance, 10000);

    return () => clearInterval(intervalBalanceTimerId);
  }, []);

  function onConfirm() {
    event.preventDefault();
    event.stopPropagation();

    return dispatcher().catch(error => {
      setError(error);
      return false;
    });
  }

  function onUpgrade() {
    event.preventDefault();
    event.stopPropagation();

    history.push('/billing', { replace: true });
  }

  function capitalize(str) {
    return str[0].toUpperCase() + str.slice(1).toLowerCase();
  }

  return (
    <Dialog
      heading="Confirm processing"
      confirmText={ability.can('process', 'Video') ? 'Confirm' : 'Upgrade'}
      onConfirm={ability.can('process', 'Video') ? onConfirm : onUpgrade}
      closeOnConfirm
      isOpen
      containerStyles={{ width: 620 }}
    >
      <p css={{ color: colors.grey3 }}>
        Here is a calculation for the processing cost of this job, and estimates
        for monthly storage and download costs. The processing cost will be
        withdrawn from your processing account if the job is successfully
        rendered.
      </p>
      <table
        css={{
          width: '100%',
          color: colors.grey3,
          borderSpacing: '0 3px',
          'td + td + td': {
            textAlign: 'right',
          },
          'tbody td + td + td': {
            textAlign: 'right',
          },
          'tbody td + td + td + td': {
            color: colors.black1,
            textAlign: 'right',
          },
          'thead td': {
            color: colors.grey3,
            fontWeight: 'bold',
          },
          tr: {
            height: 26,
          },
          'tbody tr:nth-last-child(-n + 1)': {
            background: colors.hexToRgba(colors.grey4, 0.6),
          },
          td: { padding: 0 },
          'tr>td:first-of-type': {
            paddingLeft: 6,
          },
          'tr>td:last-of-type': {
            paddingRight: 6,
          },
        }}
      >
        <thead>
          <tr>
            <td />
            <td>Category</td>
            <td>Processing units [GP]</td>
            <td>Charge</td>
          </tr>
        </thead>
        <tbody>
          {jobAppraisal.jobItems.map(jobItem => (
            <>
              <tr>
                <td>{jobItem.productName}</td>
                <td>{capitalize(jobItem.category)}</td>
                <td>{jobItem.amountGP}</td>
                <td>${jobItem.normalCostUSD.toFixed(2)}</td>
              </tr>
            </>
          ))}
          {jobAppraisal.totalProcessingDiscountUSD > 0 && (
            <tr>
              <td>
                Volume discount ({jobAppraisal.discountPercentage.toFixed(1)}
                %)
              </td>
              <td />
              <td />
              <td>
                <span css={{ color: colors.orange0 }}>
                  -${jobAppraisal.totalProcessingDiscountUSD.toFixed(2)}
                </span>
              </td>
            </tr>
          )}
          <tr>
            <td>
              <span css={{ fontSize: 16 }}>Total processing</span>
            </td>
            <td />
            <td />
            <td>
              <span css={{ fontSize: 16 }}>
                ${jobAppraisal.totalProcessingCostUSD.toFixed(2)}
              </span>
            </td>
          </tr>
        </tbody>
      </table>
      <p>
        <table
          css={{
            color: colors.grey3,
            td: {
              paddingRight: 10,
              color: colors.grey3,
            },
            'td + td': {
              color: colors.black1,
              fontSize: 16,
            },
            tr: {
              height: 24,
            },
          }}
        >
          <tbody>
            {targetVideo.preset.name && (
              <tr>
                <td>Preset:</td>
                <td>{targetVideo.preset.name}</td>
              </tr>
            )}
            <tr>
              <td>Output specifications:</td>
              <td>
                {targetVideo.frameWidth}×{targetVideo.frameHeight},{' '}
                {formatFrameRateWithScanningAndFieldOrder(
                  targetVideo.scanning,
                  targetVideo.averageFramerate,
                )}
                ,{' '}
                {formatPixelFormatNameAndBitDepth(targetVideo.pixelFormat.name)}
              </td>
            </tr>
            <tr>
              <td>Output duration:</td>
              <td>
                {formatMsToHourMinutesSeconds(
                  jobAppraisal.expectedIngestedDurationMs,
                )}
              </td>
            </tr>
            <tr>
              <td>Estimated rendering time:</td>
              <td>
                {formatMsToHourMinutesSeconds(
                  jobAppraisal.estimatedTotalComputeTime * 1000,
                )}
              </td>
            </tr>
            <tr>
              <td>Estimated file size:</td>
              <td>{bytesInClosestUnit(jobAppraisal.estimatedFileSize)}</td>
            </tr>
          </tbody>
        </table>
      </p>
      <p>
        <table
          css={{
            color: colors.grey3,
            td: {
              paddingRight: 10,
              color: colors.grey3,
            },
            'td+td': {
              textAlign: 'right',
            },
            'thead td': {
              color: colors.grey3,
              fontWeight: 'bold',
            },
            'tbody td + td + td': {
              color: colors.black0,
            },
            'tbody td + td + td + td': {
              color: colors.grey3,
              textAlign: 'left',
            },
            tr: {
              height: 24,
            },
          }}
        >
          <thead>
            <tr>
              <td />
              <td>{ability.can('process', 'Video') && <>Current balance</>}</td>
              <td>Charge</td>
              <td />
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Processing</td>
              <td>
                {ability.can('process', 'Video') && (
                  <>${(currentProcessingCreditsBalance / 1e5).toFixed(2)}</>
                )}
              </td>
              <td>
                <span css={{ fontSize: 16 }}>
                  ${jobAppraisal.totalProcessingCostUSD.toFixed(2)}
                </span>
              </td>
            </tr>
            <tr>
              <td>Storage</td>
              <td>
                {ability.can('process', 'Video') && (
                  <>${(balances.storage.millicents / 1e5).toFixed(2)}</>
                )}
              </td>
              <td>
                <span css={{ fontSize: 16 }}>
                  $
                  {jobAppraisal.estimatedStorageCostUSD < 0.01
                    ? jobAppraisal.estimatedStorageCostUSD.toFixed(3)
                    : jobAppraisal.estimatedStorageCostUSD.toFixed(2)}
                </span>
              </td>
              <td>per month (est.)</td>
            </tr>
            <tr>
              <td>Download</td>
              <td>
                {ability.can('process', 'Video') && (
                  <>
                    $
                    {(
                      (balances.downloadCF.millicents +
                        balances.downloadS3.millicents) /
                      1e5
                    ).toFixed(2)}
                  </>
                )}
              </td>
              <td>
                <span css={{ fontSize: 16 }}>
                  $
                  {jobAppraisal.estimatedDownloadCostUSD < 0.01
                    ? jobAppraisal.estimatedDownloadCostUSD.toFixed(3)
                    : jobAppraisal.estimatedDownloadCostUSD.toFixed(2)}
                </span>
              </td>
              <td>per download (est.)</td>
            </tr>
          </tbody>
        </table>
      </p>
      {showWarnings && (
        <p>
          <table
            css={{
              color: colors.grey3,
              td: {
                paddingRight: 10,
              },
            }}
          >
            {generateWarnings({ sourceVideo, targetVideo }).map(warning => (
              <tr>
                <td>
                  <ExclamationCircle
                    color={colors.orange0}
                    width={12}
                    height={12}
                  />
                </td>
                <td>{warning}</td>
              </tr>
            ))}
          </table>
        </p>
      )}

      {ability.cannot('process', 'Video') && !error && (
        <Alert
          text="Payment info must be added before any processing can be performed; click <b>Upgrade</b> to continue to the [billing settings] page!"
          styles={{
            marginBottom: 12,
            color: colors.orange0,
            border: `1px solid ${colors.hexToRgba(colors.orange0, 0.7)}`,
          }}
        />
      )}
      {error && <Alert text={error} styles={{ marginBottom: 12 }} />}
    </Dialog>
  );
}

export const ConfirmAppraisal = connect((state, ownProps) => ({
  teamId: state.currentTeamId,
  showWarnings: ownProps.payload.showWarnings,
  sourceVideo: ownProps.payload.sourceVideo,
  targetVideo: ownProps.payload.targetVideo,
  jobAppraisal: ownProps.payload.jobAppraisal,
  balances: ownProps.payload.balances,
  dispatcher: ownProps.payload.dispatcher,
}))(_ConfirmAppraisal);
