import React from 'react';
import { connect } from 'react-redux';

import * as colors from './colors';
import * as constants from './constants';
import { removeToast } from './store/toasts';
import { replaceTemplateTagsWithHtml } from './template-tags';

const Toast = ({ id, text, timeout = 6000, removeToast }) => {
  const [isOpen, setOpen] = React.useState(false);

  let closeTimer;
  function onClose() {
    setOpen(false);
    closeTimer = setTimeout(() => {
      removeToast({ id });
    }, 500);
  }

  React.useEffect(() => {
    // Fade the video in.
    setOpen(true);

    if (timeout === false) {
      return () => {};
    }

    // Hide the toast after the specified timeout.
    const hideTimer = setTimeout(() => setOpen(false), timeout);

    // Remove the toast after hiding it.
    const removeTimer = setTimeout(() => removeToast({ id }), timeout + 500);

    return () => {
      clearTimeout(closeTimer);
      clearTimeout(hideTimer);
      clearTimeout(removeTimer);
    };
  }, []);

  return (
    <div
      onClick={onClose}
      css={{ display: 'flex', justifyContent: 'center', width: '100%' }}
    >
      <div
        css={{
          display: 'inline-block',
          padding: '14px 20px 13px',
          backgroundColor: colors.black1,
          color: colors.white0,
          transition: 'opacity .18s ease-in',
          opacity: isOpen ? 0.9 : 0,
          borderRadius: 3,
          boxShadow: '0 0 10px rgba(0, 0, 0, 0.3)',
          marginTop: 12,
          textAlign: 'center',
          cursor: 'pointer',
          maxWidth: '40vw',
          a: {
            color: colors.orange0,
          },
        }}
      >
        <div
          css={{ lineHeight: '23px' }}
          dangerouslySetInnerHTML={{
            __html: replaceTemplateTagsWithHtml(text),
          }}
        />
      </div>
    </div>
  );
};

function _ToastsManager({ toasts, removeToast }) {
  return (
    <div
      css={{
        position: 'fixed',
        zIndex: constants.zIndices.base + 3,
        right: 0,
        bottom: 20,
        left: 0,
        margin: '0 auto',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      {toasts &&
        toasts.length > 0 &&
        toasts.map(toast => (
          <Toast
            text={toast.text}
            key={toast.id}
            timeout={toast.timeout}
            removeToast={removeToast(toast.id)}
          />
        ))}
    </div>
  );
}

export const ToastsManager = connect(
  state => ({ toasts: state.toasts }),
  dispatch => ({ removeToast: id => () => dispatch(removeToast({ id })) }),
)(_ToastsManager);
