import { useState } from 'react';
import { connect } from 'react-redux';

import * as colors from './colors';
import * as constants from './constants';
import { Close, RoundedIconButton } from './icons';
import { Heading, rem } from './typography';
import { Modal } from './modal';
import { SquareButton } from './button';
import { closeDialog } from './store/dialog';

function _Dialog({
  isOpen,
  heading,
  children,
  confirmText,
  cancelText,
  close,
  closeDialog: closeDialogAction,
  closeOnConfirm,
  closeOnOutsideContainerClick,
  onConfirm,
  danger,
  confirmProps = {},
  containerStyles = {},
  childrenParentStyles = {},
}) {
  if (!isOpen) return null;
  const closeDialog = close || (() => closeDialogAction());
  const [isLoading, setLoading] = useState(false);
  return (
    <Modal close={closeDialog} closeOnOutsideContainerClick>
      <div
        css={{
          boxSizing: 'border-box',
          display: 'flex',
          flexDirection: 'column',
          width: 450,
          maxHeight: '100%',
          backgroundColor: colors.white0,
          borderRadius: 5,
          boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.05)',
          margin: 20,
          zIndex: constants.zIndices.modal,
          '@media (max-width: 610px)': {
            width: '100%',
          },
          ...containerStyles,
        }}
      >
        <div
          css={{
            position: 'relative',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: 57,
            borderBottom: `1px solid ${colors.hexToRgba(colors.grey3, 0.2)}`,
          }}
        >
          <Heading
            css={{
              alignSelf: 'center',
              fontSize: rem(16),
              lineHeight: rem(32),
              color: danger ? colors.red0 : colors.grey3,
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              margin: '40px',
            }}
          >
            {heading}
          </Heading>
          <RoundedIconButton
            icon={Close}
            styles={{ position: 'absolute', top: 16, right: 13 }}
            iconProps={{ color: colors.grey2 }}
            onClick={closeDialog}
          />
        </div>
        <div
          css={{
            padding: 20,
            overflow: 'auto',
            maxHeight: '80vh',
            ...childrenParentStyles,
          }}
        >
          {children}
        </div>
        {confirmText && (
          <div
            css={{
              display: 'flex',
              flexDirection: 'row-reverse',
              alignItems: 'center',
              height: 57,
              borderTop: `1px solid ${colors.hexToRgba(colors.grey3, 0.2)}`,
              padding: '0 10px',
            }}
          >
            <SquareButton
              isLoading={isLoading}
              danger={danger}
              onClick={event => {
                let potentialPromise;
                if (onConfirm) {
                  potentialPromise = onConfirm(event);
                  setLoading(true);
                }
                if (closeOnConfirm) {
                  if (potentialPromise && potentialPromise.then) {
                    potentialPromise
                      .then(wasSuccessful => {
                        setLoading(false);
                        if (wasSuccessful === true) {
                          closeDialog(event);
                        }
                      })
                      .catch(() => {
                        setLoading(false);
                      });
                  } else {
                    closeDialog(event);
                  }
                }
              }}
              {...confirmProps}
            >
              {confirmText}
            </SquareButton>
            <SquareButton
              secondary
              onClick={closeDialog}
              css={{ marginRight: 10 }}
            >
              {cancelText ? cancelText : 'Cancel'}
            </SquareButton>
          </div>
        )}
      </div>
    </Modal>
  );
}

export const Dialog = connect(
  null,
  { closeDialog },
)(_Dialog);
