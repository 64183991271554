import { Component } from 'react';

import * as colors from '../colors';
import { rem } from '../typography';
import { ArrowLeft } from '../icons';
import { SquareButton } from '../button';
import { Link } from '../link';
import { FormCard, FormTitle, Field, Alert } from '../forms';
import { fetch } from '../requests';
import { AuthLayout } from '../main-layout';

export class ResetPassword extends Component {
  state = {
    error: false,
    isButtonDisabled: false,
    hasRequestedPassword: false,
    email: '',
    emailValidation: false,
  };

  onChange = ({ target }) => {
    this.setState({ [target.name]: target.value });
  };

  validateForm = () => {
    const { email } = this.state;

    this.setState({
      error: false,
      emailValidation: false,
    });

    if (email === '') {
      this.setState({ emailValidation: 'Email address required' });
      return false;
    }

    if (!email.includes('@')) {
      this.setState({ emailValidation: 'Invalid email address' });
      return false;
    }

    return true;
  };

  onSubmit = event => {
    event.preventDefault();

    if (!this.validateForm()) {
      return;
    }

    this.setState({ isButtonDisabled: true });
    fetch('/api/reset-password', {
      method: 'POST',
      body: { email: this.state.email },
    })
      .then(res => res.json())
      .then(({ error }) => {
        this.setState({ isButtonDisabled: false });

        if (error) {
          this.setState({ error });
          return;
        }

        this.setState({
          hasRequestedPassword: true,
          email: '',
          emailValidation: false,
        });
      })
      .catch(() => {
        this.setState({
          isButtonDisabled: false,
          error: 'Unknown error; please try again',
        });
      });
  };

  backToAuthenticate = event => {
    event.preventDefault();
    this.setState({ isTransitioning: true });
    setTimeout(() => {
      this.setState({ isTransitioning: false });
      this.props.history.push('/login?f=t');
    }, 240);
  };

  render() {
    return (
      <AuthLayout>
        <FormCard
          css={{
            margin: '0 auto',
            minHeight: 379,
            position: 'relative',
            transition: 'all .24s ease-out',
            opacity: this.state.isTransitioning ? 0 : 1,
            transform: this.state.isTransitioning
              ? 'translate(-20px, 0px)'
              : 'translate(0px, 0px)',
          }}
        >
          <Link
            to="/login"
            onClick={this.backToAuthenticate}
            css={{
              position: 'absolute',
              top: 28,
              left: 31,
            }}
          >
            <ArrowLeft
              color={colors.orange1}
              title="Back to log in page"
              styles={{
                ':hover': { color: colors.orange0 },
              }}
            />
          </Link>
          <FormTitle css={{ alignSelf: 'center' }}>Forgot password</FormTitle>
          {this.state.error && <Alert text={this.state.error} />}
          <p
            css={{
              color: colors.grey2,
              fontSize: rem(14),
              textAlign: 'center',
              marginBottom: '45px !important',
            }}
          >
            {this.state.hasRequestedPassword
              ? 'Password reset; please check your email.'
              : 'Please insert your email address.'}
          </p>
          <Field
            type="email"
            label="Email"
            fieldName="email"
            placeholder="Your email address"
            autoComplete="username"
            value={this.state.email}
            onChange={this.onChange}
            validationMessage={this.state.emailValidation}
            autoFocus
            required
          />
          <SquareButton
            css={{
              alignSelf: 'center',
              width: 130,
              marginTop: 'auto',
              marginBottom: '0',
            }}
            onClick={this.onSubmit}
            type="submit"
            disabled={this.state.isButtonDisabled}
            isLoading={this.state.isButtonDisabled}
          >
            Reset password
          </SquareButton>
        </FormCard>
      </AuthLayout>
    );
  }
}
