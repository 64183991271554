import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import styled from '@emotion/styled';

import { Dialog } from '../../dialog';
import * as colors from '../../colors';
import { RoundedIconButton, ArrowLeft } from '../../icons';

const Label = styled('label')({
  display: 'block',
  color: colors.grey10,
  fontSize: 12,
  lineHeight: '20px',
  select: {
    fontSize: 14,
  },
});

const CANVAS_MAX_WIDTH = Math.round(853 * 1.3);
const CANVAS_MAX_HEIGHT = Math.round(480 * 1.3);
const CANVAS_ASPECT_RATIO = CANVAS_MAX_WIDTH / CANVAS_MAX_HEIGHT;

const InteractiveImage = ({ rectangleState, imageUrl }) => {
  const [rect, setRect] = rectangleState;

  const [draggingHandle, setDraggingHandle] = useState(null);
  const [draggingRect, setDraggingRect] = useState(false);
  const [dragOffset, setDragOffset] = useState({ x: 0, y: 0 });

  const [image, setImage] = useState();
  const [canvasSize, setCanvasSize] = useState({ width: 0, height: 0 });
  const [scale, setScale] = useState(1);
  const [imageLoaded, setImageLoaded] = useState(false);

  const canvasRef = useRef(null);

  const handleSize = 8;
  const halfHandleSize = handleSize / 2;

  React.useEffect(
    () => {
      setImageLoaded(false);

      const img = new Image();
      img.src = imageUrl;
      img.onload = () => {
        // compare aspect ratio of image with
        const imageAspectRatio = img.width / img.height;

        let canvasSize;

        if (imageAspectRatio > CANVAS_ASPECT_RATIO) {
          // full width
          canvasSize = {
            width: CANVAS_MAX_WIDTH,
            height: CANVAS_MAX_WIDTH / imageAspectRatio,
          };
          setScale(CANVAS_MAX_WIDTH / img.width);
        } else {
          // full height
          canvasSize = {
            width: CANVAS_MAX_HEIGHT * imageAspectRatio,
            height: CANVAS_MAX_HEIGHT,
          };
          setScale(CANVAS_MAX_HEIGHT / img.height);
        }

        setCanvasSize(canvasSize);
        setImageLoaded(true);
      };
      setImage(img);
    },
    [imageUrl],
  );

  // Draw the rectangle and its handles
  const draw = () => {
    if (!imageLoaded) {
      return;
    }

    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');

    ctx.scale(scale, scale);

    // clear everything
    ctx.fillStyle = colors.black0;
    ctx.rect(0, 0, canvas.width / scale, canvas.height / scale);
    ctx.fill();

    // draw visible area
    ctx.drawImage(
      image,
      rect.x,
      rect.y,
      rect.width,
      rect.height,
      rect.x,
      rect.y,
      rect.width,
      rect.height,
    );

    ctx.globalAlpha = 0.5;

    // draw invisible areas with half alpha
    ctx.drawImage(
      image,
      0,
      0,
      image.width,
      rect.y,
      0,
      0,
      canvas.width / scale,
      rect.y,
    );

    ctx.drawImage(
      image,
      0,
      rect.y,
      rect.x,
      image.height,
      0,
      rect.y,
      rect.x,
      canvas.height / scale,
    );

    ctx.drawImage(
      image,
      rect.x,
      rect.y + rect.height,
      image.width,
      image.height,
      rect.x,
      rect.y + rect.height,
      canvas.width / scale,
      canvas.height / scale,
    );

    ctx.drawImage(
      image,
      rect.x + rect.width,
      rect.y,
      image.width,
      rect.height,
      rect.x + rect.width,
      rect.y,
      canvas.width / scale,
      rect.height,
    );

    ctx.globalAlpha = 1.0;

    // highlight cropped area
    ctx.beginPath();
    ctx.lineWidth = 2 / scale;
    ctx.fillStyle = colors.hexToRgba(colors.orange2, 0.2);
    ctx.strokeStyle = colors.orange2;
    ctx.rect(rect.x, rect.y, rect.width, rect.height);
    ctx.fill();
    ctx.stroke();

    // draw handles
    const handlePositions = [
      { x: rect.x, y: rect.y },
      { x: rect.x + rect.width, y: rect.y },
      { x: rect.x, y: rect.y + rect.height },
      { x: rect.x + rect.width, y: rect.y + rect.height },
    ];

    handlePositions.forEach((pos, index) => {
      ctx.fillStyle = draggingHandle === index ? colors.white0 : colors.orange0;
      ctx.beginPath();
      ctx.arc(pos.x, pos.y, halfHandleSize / scale, 0, 2 * Math.PI);
      ctx.fill();
    });

    ctx.setTransform(1, 0, 0, 1, 0, 0);
  };

  const handleMouseDown = e => {
    if (!imageLoaded) {
      return;
    }

    const x = e.nativeEvent.offsetX / scale;
    const y = e.nativeEvent.offsetY / scale;

    const handlePositions = [
      { x: rect.x, y: rect.y },
      { x: rect.x + rect.width, y: rect.y },
      { x: rect.x, y: rect.y + rect.height },
      { x: rect.x + rect.width, y: rect.y + rect.height },
    ];

    handlePositions.forEach((handle, index) => {
      if (
        x >= handle.x - halfHandleSize / scale &&
        x <= handle.x + halfHandleSize / scale &&
        y >= handle.y - halfHandleSize / scale &&
        y <= handle.y + halfHandleSize / scale
      ) {
        setDraggingHandle(index);
      }
    });

    // Check if click is inside the rectangle
    if (
      !draggingHandle &&
      x >= rect.x &&
      x <= rect.x + rect.width &&
      y >= rect.y &&
      y <= rect.y + rect.height
    ) {
      setDraggingRect(true);
      setDragOffset({
        x: e.clientX / scale - rect.x,
        y: e.clientY / scale - rect.y,
      });
    }
  };

  const handleMouseMove = e => {
    if (!imageLoaded) {
      return;
    }

    if (draggingHandle !== null) {
      const x = e.nativeEvent.offsetX / scale;
      const y = e.nativeEvent.offsetY / scale;

      let newRect = { ...rect };
      switch (draggingHandle) {
        case 0:
          newRect = {
            ...rect,
            x,
            y,
            width: rect.width + (rect.x - x),
            height: rect.height + (rect.y - y),
          };
          break;
        case 1:
          newRect = {
            ...rect,
            x: rect.x,
            y,
            width: x - rect.x,
            height: rect.height + (rect.y - y),
          };
          break;
        case 2:
          newRect = {
            ...rect,
            x,
            y: rect.y,
            width: rect.width + (rect.x - x),
            height: y - rect.y,
          };
          break;
        case 3:
          newRect = { ...rect, width: x - rect.x, height: y - rect.y };
          break;
        default:
          break;
      }

      // clamp rectangle position and size
      newRect.width = Math.max(16, newRect.width);
      newRect.height = Math.max(16, newRect.height);
      newRect.x = Math.min(newRect.x, canvasSize.width / scale - newRect.width);
      newRect.y = Math.min(
        newRect.y,
        canvasSize.height / scale - newRect.height,
      );

      setRect(newRect);
    } else if (draggingRect) {
      // Logic to move the entire rectangle
      const newX = Math.min(
        Math.max(0, e.clientX / scale - dragOffset.x),
        canvasSize.width / scale - rect.width,
      );
      const newY = Math.min(
        Math.max(0, e.clientY / scale - dragOffset.y),
        canvasSize.height / scale - rect.height,
      );

      setRect({ ...rect, x: newX, y: newY });
    }
  };

  const handleMouseUp = () => {
    if (!imageLoaded) {
      return;
    }

    setDraggingHandle(null);
    setDraggingRect(false); // Reset the dragging flag on mouse up
  };

  useEffect(
    () => {
      draw();
    },
    [imageLoaded, rect, draggingHandle],
  );

  return (
    <canvas
      ref={canvasRef}
      width={canvasSize.width}
      height={canvasSize.height}
      onMouseDown={handleMouseDown}
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
    />
  );
};

function _EditImageRectangle({
  video,
  displayAspectRatio,
  inputRectangle,
  outputConsumer,
}) {
  const [comparisonImages, setComparisonImages] = React.useState();
  const [imageComparisonIndex, setImageComparisonIndex] = React.useState(0);
  const [showRight, setShowRight] = React.useState(false);

  const rectangleState = useState(inputRectangle);
  const [rect] = rectangleState;

  React.useEffect(() => {
    function fetchVideoFrames() {
      return fetch(`/api/uploads/frame-urls?sourceId=${video.id}`).then(res =>
        res.json(),
      );
    }

    fetchVideoFrames().then(results => setComparisonImages(results));
  }, []);

  function onConfirm() {
    event.preventDefault();
    event.stopPropagation();

    outputConsumer(rect);
  }

  return (
    <Dialog
      heading="Region of interest"
      confirmText={'Confirm'}
      onConfirm={onConfirm}
      closeOnConfirm
      isOpen
      containerStyles={{
        width: CANVAS_MAX_WIDTH + 40,
        height: CANVAS_MAX_HEIGHT + 180,
      }}
    >
      <div css={{ textAlign: 'center' }}>
        {comparisonImages && (
          <InteractiveImage
            rectangleState={rectangleState}
            imageUrl={comparisonImages.source[imageComparisonIndex]}
            displayAspectRatio={video.metadata.displayAspectRatio}
          />
        )}
      </div>
      <div
        css={{
          position: 'absolute',
          zIndex: 10000,
          bottom: 20,
          left: 20,
          right: 20,
          height: 148,
          backgroundColor: 'rgba(0,0,0,.5)',
          maxWidth: 'calc(100vw - 40px)',
          overflow: 'hidden',
          borderBottomLeftRadius: 5,
          borderBottomRightRadius: 5,
        }}
      >
        <div css={{ display: 'flex', justifyContent: 'space-between' }}>
          <div css={{ color: '#fff', marginTop: 16, marginLeft: 20 }}>
            Select frame
          </div>
          <div css={{ display: 'flex', marginRight: 20, marginTop: 10 }}>
            <RoundedIconButton
              icon={ArrowLeft}
              onClick={() => setShowRight(false)}
              iconProps={{
                color: showRight ? colors.orange0 : 'rgba(245,171,53,0.3)',
                disabled: showRight,
              }}
              styles={{
                cursor: showRight ? 'pointer' : 'default',
                backgroundColor: '#fff',
                marginRight: 8,
                width: 30,
                height: 30,
                ':hover': {
                  backgroundColor: '#fff',
                },
              }}
            />
            <RoundedIconButton
              icon={ArrowLeft}
              onClick={() => setShowRight(true)}
              iconProps={{
                color: showRight ? 'rgba(245,171,53,0.3)' : colors.orange0,
                disabled: !showRight,
                styles: {
                  transform: 'rotate(180deg)',
                },
              }}
              styles={{
                cursor: showRight ? 'default' : 'pointer',
                backgroundColor: '#fff',
                width: 30,
                height: 30,
                ':hover': {
                  backgroundColor: '#fff',
                },
              }}
            />
          </div>
        </div>

        <div
          css={{
            marginTop: 14,
            marginLeft: 20,
            width: 3000,
            transition: 'transform .36s ease',
            transform: showRight ? 'translateX(-1320px)' : 'translateX(0)',
          }}
        >
          {comparisonImages &&
            Array.isArray(comparisonImages.thumbs) &&
            comparisonImages.thumbs.map((frame, index) => (
              <img
                key={index.toString()}
                onClick={() => setImageComparisonIndex(index)}
                src={frame}
                css={{
                  boxSizing: 'border-box',
                  height: 75,
                  width: 112,
                  border: 'none',
                  marginRight: 20,
                  borderRadius: 3,
                  border:
                    index === imageComparisonIndex
                      ? `2px solid ${colors.orange0}`
                      : 'none',
                  cursor: 'pointer',
                  transition: 'filter .36s ease, border .18s ease',
                  filter: 'brightness(90%)',
                  ':hover': {
                    filter: 'brightness(110%)',
                  },
                }}
              />
            ))}
        </div>
      </div>
    </Dialog>
  );
}

export const EditImageRectangle = connect((state, ownProps) => ({
  ...ownProps.payload,
}))(_EditImageRectangle);
