import React from 'react';
import { connect } from 'react-redux';

import { Heading } from './typography';
import { Progress } from './progress';
import { RoundedIconButton, Chevron } from './icons';
import { openDialog } from './store/dialog';
import {
  teamVideosSelector,
  teamUploadsSelector,
  userSelector,
} from './store/selectors';
import * as colors from './colors';
import * as constants from './constants';

const _GlobalUploadState = ({ videos, openDialog }) => {
  const [isHidden, setHidden] = React.useState(false);
  const isVisible = videos.length > 0;
  return (
    <div
      css={{
        position: 'fixed',
        zIndex: constants.zIndices.base + 3,
        right: 100, // Allow space for the Intercom widget
        bottom: -100,
        backgroundColor: colors.white0,
        padding: 12,
        minWidth: 440,
        borderTopLeftRadius: 3,
        borderTopRightRadius: 3,
        boxShadow: '0 0 10px rgba(0, 0, 0, .1)',
        transition: 'bottom .36s ease',
        ...(isVisible && !isHidden
          ? { bottom: 0 }
          : isVisible && isHidden
          ? { bottom: 0 }
          : { bottom: -100 }),
      }}
    >
      <Heading as="h5" css={{ color: colors.grey3 }}>
        Active uploads
      </Heading>
      <RoundedIconButton
        icon={Chevron}
        iconProps={
          isHidden
            ? {
                color: colors.grey7,
                width: 24,
                height: 24,
                styles: { transform: 'rotateZ(180deg)' },
              }
            : { color: colors.grey7, width: 24, height: 24 }
        }
        styles={{
          position: 'absolute',
          top: 12,
          right: 12,
          width: 24,
          height: 24,
        }}
        onClick={() => setHidden(!isHidden)}
      />
      {isVisible && !isHidden
        ? videos.map(video => (
            <div key={video.id} css={{ margin: '12px 0' }}>
              <div
                css={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginBottom: 6,
                }}
              >
                <div>
                  <span>{video.name}</span>
                  {video.unfinishedUpload && (
                    <span
                      css={{
                        marginLeft: 12,
                        color: colors.grey1,
                        cursor: 'pointer',
                        ':hover': { color: colors.orange1 },
                      }}
                      onClick={
                        video.unfinishedUpload
                          ? () =>
                              openDialog({
                                type: 'upload',
                                projectId: video.project_id,
                                noConfirm: true,
                              })
                          : null
                      }
                    >
                      Resume upload
                    </span>
                  )}
                </div>
                <div css={{ display: 'flex' }}>
                  <div
                    css={{
                      color: colors.grey2,
                      marginLeft: 12,
                      marginRight: 12,
                    }}
                  >
                    {video.upload ? video.upload.averageSpeed : '0 bytes'}/s
                  </div>
                  <div
                    tabIndex="0"
                    role="button"
                    css={{ color: colors.red0, cursor: 'pointer' }}
                    onClick={() => {
                      openDialog({
                        type: 'deleteUpload',
                        videoId: video.id,
                        projectId: video.project_id,
                      });
                    }}
                  >
                    Cancel
                  </div>
                </div>
              </div>
              <Progress
                value={
                  video.upload_state.progress
                    ? video.upload_state.progress
                    : 'In queue'
                }
                color={
                  video.upload_state.progress && !video.unfinishedUpload
                    ? colors.orange0
                    : colors.grey1
                }
                styles={{ width: '100%' }}
                isActive={
                  video.upload_state.progress && !video.unfinishedUpload
                }
              />
            </div>
          ))
        : null}
    </div>
  );
};

export const GlobalUploadState = connect(
  state => {
    const videos = teamVideosSelector(state);
    const uploads = teamUploadsSelector(state);
    const user = userSelector(state);

    const uploadVideos = uploads.map(upload =>
      videos.find(video => video.id === upload.video.id),
    );
    const unfinishedUploads = videos
      .filter(
        video =>
          video.upload_state.uploadStatus !== 'DONE' &&
          (user && video.uploader_user_id === user.id) &&
          !video.upload &&
          !video.parent_id,
      )
      .map(video => ({ ...video, unfinishedUpload: true }));
    for (let video of unfinishedUploads) {
      if (!uploadVideos.find(v => v.id === video.id)) {
        uploadVideos.push(video);
      }
    }
    return {
      uploads,
      videos: uploadVideos,
    };
  },
  { openDialog },
)(_GlobalUploadState);
