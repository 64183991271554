const {
  presetResolutions,
  fixedOutputResolutions,
  chromaSubsamplingOptions,
  scanningTypes,
  clarityBoostOptions,
  grainSizeOptions,
  dvres2Variants,
  codecs,
  containers,
  containerCodecs,
  defaults,
  deinterlacers,
  interlacedFieldOrderModes,
  reshapers,
  denoisers,
  stabilizers,
  augmenters,
  scalers,
  frameRateConverters,
  frameRateOptions,
  debanders,
  postProcessors,
} = preval.require('./process-video-options');

import { formatPixelFormatName } from './process-video-options';

import {
  getProResOptions,
  getProResCodecProperties,
  getProResBitrateAndLabel,
} from './prores-options';
import {
  getValidDNxHDOptions,
  getDNxHDCodecProperties,
  getDNxHDBitrateAndLabel,
} from './dnxhd-options';
import { xdcamHD422Bitrate, xdcamHD422PixelFormatName } from './xdcam-options';
import { bitsInClosestUnit } from '../../utils';

function createProResNextState(
  source,
  proresProfileJson,
  targetResolution,
  state,
) {
  const proresProfile =
    typeof proresProfileJson === 'string'
      ? JSON.parse(proresProfileJson)
      : proresProfileJson;
  const pixelFormatName = proresProfile.pixelFormatName;

  // find chroma subsampling option
  const proresProfileChromaOptions = {};
  const chromaLabel = getChromaSubsamplingLabel(pixelFormatName);
  proresProfileChromaOptions[chromaLabel] = [
    chromaSubsamplingOptions[chromaLabel].find(c => c[0] === pixelFormatName),
  ];

  // set chroma subsampling to the one and only option for the selected
  // ProRes profile
  state.chromaSubsampling = pixelFormatName;
  state.proresProfileChromaOptions = proresProfileChromaOptions;

  // calculate and update ProRes profile bitrate + label to the one and only
  // for the selected ProRes profile
  const { bitrate, label } = getProResBitrateAndLabel(
    proresProfile,
    targetResolution,
    source.averageFramerate,
  );

  state.bitrate = bitrate;
  state.proresProfileBitrates = [[bitrate, label]];

  return state;
}

function createDNxHDNextState(
  source,
  dnxhdProfileJson,
  targetResolution,
  state,
) {
  const dnxhdProfile = JSON.parse(dnxhdProfileJson);
  const pixelFormatName = dnxhdProfile.pixelFormatName;

  // find chroma subsampling option
  const dnxhdProfileChromaOptions = {};
  const chromaLabel = getChromaSubsamplingLabel(pixelFormatName);
  dnxhdProfileChromaOptions[chromaLabel] = [
    chromaSubsamplingOptions[chromaLabel].find(c => c[0] === pixelFormatName),
  ];

  // set chroma subsampling to the one and only option for the selected
  // DNxHD/HR profile
  state.chromaSubsampling = pixelFormatName;
  state.dnxhdProfileChromaOptions = dnxhdProfileChromaOptions;

  // calculate and update DNxHD/HR profile bitrate + label to the one and only
  // for the selected DNxHD/HR profile
  const { bitrate, label } = getDNxHDBitrateAndLabel(
    dnxhdProfile,
    targetResolution,
    source.averageFramerate,
  );
  state.bitrate = bitrate;
  state.dnxhdProfileBitrates = [[bitrate, label]];

  return state;
}

// TODO: REWRITE THIS - IT'S UNTENABLE TO MAINTAIN IN THE LONG RUN!
export function updateState({ source, currentState, newState }) {
  const { options } = generateOptions(source, currentState);

  const bitrates = generateBitrates(
    source,
    currentState.scaler === 'none'
      ? source.reshapedResolution
      : currentState.customResolution || currentState.resolution,
  );

  if (newState.codec) {
    if (newState.codec === 'prores') {
      // Apple ProRes codec selected -> update bitrates and chroma subsamplings
      newState = createProResNextState(
        source,
        // Presets may provide this value, otherwise fall back on the current value.
        newState.proresProfile
          ? newState.proresProfile
          : currentState.proresProfile,
        newState.resolution && newState.resolution.width
          ? newState.resolution
          : currentState.resolution && currentState.resolution.width
          ? currentState.resolution
          : source.reshapedResolution,
        newState,
      );
      // Remove custom bitrate
      delete currentState.customBitrate;
    } else if (newState.codec === 'dnxhd') {
      // DNxHD/HR codec selected -> update bitrates and chroma subsamplings
      newState = createDNxHDNextState(
        source,
        // Presets may provide this value, otherwise fall back on the current value.
        newState.dnxhdProfile
          ? newState.dnxhdProfile
          : currentState.dnxhdProfile,
        newState.resolution && newState.resolution.width
          ? newState.resolution
          : currentState.resolution && currentState.resolution.width
          ? currentState.resolution
          : source.reshapedResolution,
        newState,
      );
      // Remove custom bitrate
      delete currentState.customBitrate;
    } else if (newState.codec === 'xdcam') {
      // XDCAM HD422 codec selected -> update bitrates and chroma subsamplings
      newState.bitrate = xdcamHD422Bitrate;
      newState.chromaSubsampling = xdcamHD422PixelFormatName;
      newState.xdcamProfileChromaOptions = [
        chromaSubsamplingOptions[
          getChromaSubsamplingLabel(xdcamHD422PixelFormatName)
        ].find(c => c[0] === xdcamHD422PixelFormatName),
      ];
      newState.xdcamProfileBitrates = [
        [xdcamHD422Bitrate, `${bitsInClosestUnit(xdcamHD422Bitrate)}ps`],
      ];
      // Remove custom bitrate
      delete currentState.customBitrate;
    } else if (
      !newState.bitrate &&
      ['prores', 'dnxhd', 'xdcam'].includes(currentState.codec)
    ) {
      // Select a recommended bitrate if the new state does not include a bitrate
      // TODO May not be required - we always will have one?
      const recommendedBitrate = bitrates.find(bitrate => bitrate.recommended);
      newState.bitrate = recommendedBitrate
        ? recommendedBitrate.bitrate
        : bitrates[0].bitrate;
    }
  } else if (newState.proresProfile) {
    // New Apple ProRes profile selected -> update bitrates and chroma subsamplings
    newState = createProResNextState(
      source,
      newState.proresProfile,
      currentState.resolution,
      newState,
    );
  } else if (newState.dnxhdProfile) {
    // New DNxHD/HR profile selected -> update bitrates and chroma subsamplings
    newState = createDNxHDNextState(
      source,
      newState.dnxhdProfile,
      currentState.resolution,
      newState,
    );
  }

  // Select resolution if
  const nextScaler = newState.scaler || currentState.scaler;
  const nextResolution = newState.resolution || currentState.resolution;
  const nextOutputAspectRatio =
    newState.outputAspectRatio || currentState.outputAspectRatio;
  const nextCustomResolution =
    newState.customResolution || currentState.customResolution;

  const firstValidResolution = findValidResolution(
    getResolutions(
      getOutputAspectRatios(source),
      source.reshapedResolution,
      nextScaler,
    ),
    nextOutputAspectRatio,
  );

  if (!nextCustomResolution) {
    if (nextScaler === 'none') {
      // reset resolution
      newState.resolution = source.reshapedResolution;
    } else {
      if (!firstValidResolution) {
        // deselect scaler, there is no valid resolution to be selected
        newState.resolution = source.reshapedResolution;
        newState.scaler = 'none';
      } else {
        // check if current resolution is valid, use it if so, otherwise go with the the first valid resolution
        newState.resolution =
          nextOutputAspectRatio.tag &&
          nextResolution.tag &&
          isValidScaledResolution(
            nextScaler,
            source.reshapedResolution,
            nextResolution,
          )
            ? options.resolutions[nextOutputAspectRatio.tag].find(
                r => r.resolution.tag === nextResolution.tag,
              ).resolution
            : firstValidResolution.resolution;
      }
    }

    // sync output aspect ratio with any changes to the resolution
    const newOutputAspectRatio = options.outputAspectRatios.find(
      ar => ar.tag === nextOutputAspectRatio.tag,
    );
    newState.outputAspectRatio = {
      tag: newOutputAspectRatio.tag,
      aspectRatio: newOutputAspectRatio.aspectRatio,
    };
  }

  if (newState.bitrate === -1) {
    // Custom bitrate is selected, but no value has been defined.
    // Use the source bitrate.
    if (!newState.customBitrate) {
      newState.customBitrate = source.bitrate;
    }
  } else {
    // We have a custom bitrate defined, set bitrate to -1 to enforce it.
    if (newState.customBitrate) {
      newState.bitrate = -1;
    }
  }

  // A non-custom resolution is selected, ensure custom resolution gets removed
  if (newState.resolution && !newState.customResolution) {
    delete currentState.customResolution;
  }

  // A non-custom frame rate is selected, ensure custom frame rate gets removed
  if (newState.frameRate && !newState.customFrameRate) {
    delete currentState.customFrameRate;
  }

  return {
    ...currentState,
    ...newState,
  };
}

function findValidResolution(resolutions, outputAspectRatio) {
  return resolutions[outputAspectRatio.tag].find(
    resolution => resolution.isValid,
  );
}

export function generateOptions(source, preset, derivedNum = 0) {
  const outputAspectRatios = getOutputAspectRatios(source);

  // display aspect ratio is the default
  let outputAspectRatio = outputAspectRatios[0];

  if (typeof preset.outputAspectRatio === 'object') {
    outputAspectRatio =
      'tag' in preset.outputAspectRatio
        ? outputAspectRatios.find(ar => ar.tag === preset.outputAspectRatio.tag)
        : preset.outputAspectRatio;
  }

  const resolutions = getResolutions(
    outputAspectRatios,
    source.reshapedResolution,
    preset.scaler,
  );
  const firstValidResolution = findValidResolution(
    resolutions,
    outputAspectRatio,
  );

  let resolution = source.reshapedResolution;

  // Use the selected target resolution if a scaler is selected
  if (preset.scaler) {
    if (firstValidResolution) {
      resolution = firstValidResolution.resolution;
    }

    if (preset.customResolution) {
      resolution = preset.customResolution;
    } else if (typeof preset.resolution === 'object') {
      resolution =
        'tag' in preset.resolution
          ? resolutions[outputAspectRatio.tag].find(
              r => r.resolution.tag === preset.resolution.tag,
            ).resolution
          : preset.resolution;
    }
  }

  const { scaler, scalers } = getScalers(resolutions[outputAspectRatio.tag]);
  const bitrates = generateBitrates(source, resolution);

  let bitrate;
  // Preset.bitrate is an object initially (as saved), but is always a value
  // after the initial default values have been computed.
  if (typeof preset.bitrate === 'number') {
    bitrate = preset.bitrate;
  } else {
    bitrate = preset.bitrate
      ? 'tag' in preset.bitrate
        ? bitrates.find(b => b.tags.includes(preset.bitrate.tag))
        : bitrates.find(bitrate => bitrate.recommended).bitrate
      : bitrates.find(bitrate => bitrate.recommended).bitrate;
  }

  // TODO if codec is prores / dnxhd, select based on which it is
  const chromaSubsamplingOpts = chromaSubsamplingOptions;
  const chromaSubsampling = source.chromaSubsampling;

  const { codec, container } = getSourceContainerCodec(source);

  const proresOptions = getProResOptions();
  const dnxhdOptions = getValidDNxHDOptions(source.averageFramerate);

  const defaultProResProfile = proresOptions['422'][2][0];
  const initialProResState = createProResNextState(
    source,
    defaultProResProfile,
    resolution,
    {},
  );

  const defaultDNxHDProfile = dnxhdOptions['DNxHR'][1][0];
  const initialDNxHDState = createDNxHDNextState(
    source,
    defaultDNxHDProfile,
    resolution,
    {},
  );

  return {
    options: {
      codecs,
      fixedOutputResolutions,
      containers,
      containerCodecs,
      resolutions,
      outputAspectRatios,
      bitrates,
      chromaSubsampling: chromaSubsamplingOpts,
      scanningTypes,
      deinterlacers,
      interlacedFieldOrderModes,
      reshapers,
      denoisers,
      stabilizers,
      augmenters,
      scalers,
      clarityBoostOptions,
      grainSizeOptions,
      dvres2Variants,
      frameRateConverters,
      frameRateOptions,
      debanders,
      postProcessors,
      proresOptions,
      dnxhdOptions,

      proresProfileChromaOptions: initialProResState.proresProfileChromaOptions,
      proresProfileBitrates: initialProResState.proresProfileBitrates,
      dnxhdProfileChromaOptions: initialDNxHDState.dnxhdProfileChromaOptions,
      dnxhdProfileBitrates: initialDNxHDState.dnxhdProfileBitrates,

      xdcamProfileChromaOptions: [
        chromaSubsamplingOptions[
          getChromaSubsamplingLabel(xdcamHD422PixelFormatName)
        ].find(c => c[0] === xdcamHD422PixelFormatName),
      ],
      xdcamProfileBitrates: [
        [xdcamHD422Bitrate, `${bitsInClosestUnit(xdcamHD422Bitrate)}ps`],
      ],
    },
    defaultValues: {
      title: `${source.name} #${derivedNum + 1}`,
      container,
      codec,
      resolution,
      bitrate,
      chromaSubsampling,
      fixedOutputResolution: defaults.fixedOutputResolution,
      scanningType: defaults.scanningType,
      interlacedFieldOrderMode: defaults.interlacedFieldOrderMode,
      autoWhiteBalance: defaults.autoWhiteBalance,
      colorBoost: defaults.colorBoost,
      relightIntensity: defaults.relightIntensity,
      backgroundBlur: defaults.backgroundBlur,
      clarityBoost: defaults.clarityBoost,
      grainSize: defaults.grainSize,
      grainStrength: defaults.grainStrength,
      dvres2Variant: defaults.dvres2Variant,
      frameRate: defaults.frameRate,
      outputAspectRatio,

      dnxhdProfile: defaultDNxHDProfile,
      proresProfile: defaultProResProfile,

      // Reset other options
      deinterlacer: preset.deinterlacer || 'none',
      reshaper: preset.reshaper || 'none',
      denoiser: preset.denoiser || 'none',
      stabilizer: preset.stabilizer || 'none',
      augmenter: preset.augmenter || 'none',
      scaler: scaler || 'none',
      frameRateConverter: preset.frameRateConverter || 'none',
      debander: preset.debander || 'none',
      postProcessor: preset.postProcessor || 'none',

      // Note, the below is not safe, as any default value can be overridden.
      // Also, not verified to be a valid option. Needs to be done.
      ...preset, // Override any of the above defaults with the selected preset.
    },
  };
}

// Simply return the source material's codec and container for now.
function getSourceContainerCodec(source) {
  const { codec, container } = source;
  return { codec, container };
}

// Map of output aspect ratio tags to the corresponding list of resolutions.
function getResolutions(outputAspectRatios, sourceResolution, scaler) {
  /**
   * The available resolutions consist of:
   * - Default resolutions, along with their scale factor
   * - Scaled resolutions, i.e. set multiples of the existing resolution
   * - A custom resolution, which is not set here.
   * Depending on the video's resolution, some of the defaults may or may not be allowed, and thus omitted.
   */
  function computeResolutions(resolution) {
    const resolutions = [
      ...presetResolutions.map(([value, label]) => {
        const scaled = getScaledResolution(resolution, value);
        const factor =
          Math.round((scaled.width / resolution.width) * 100) / 100;
        return {
          resolution: scaled,
          factor,
          label: `${label} - ${scaled.width}×${scaled.height} – ${factor}x`,
          genericLabel: `${label}`,
          isValid: isValidScaledResolution(scaler, resolution, scaled),
        };
      }),
    ];

    // Add multipliers of the source resolution, excluding those
    // already listed as presets.
    const scaleFactors = [1, 2, 3, 4];
    for (const factor of scaleFactors) {
      const width = Math.round(factor * resolution.width);
      const height = factor * resolution.height;
      const scaledResolution = { width, height, tag: `${factor}x` };
      resolutions.push({
        resolution: scaledResolution,
        factor,
        label: `${width}×${height} - ${factor}x`,
        genericLabel: `${factor}x`,
        isValid: isValidScaledResolution(scaler, resolution, scaledResolution),
      });
    }

    return resolutions;
  }

  // compute map of output aspect ratio tags to the corresponding list of resolutions
  const resolutionsMap = outputAspectRatios.reduce(
    (acc, outputAspectRatio) => ({
      ...acc,
      [outputAspectRatio.tag]: computeResolutions({
        width: Math.round(
          outputAspectRatio.aspectRatio * sourceResolution.height,
        ),
        height: sourceResolution.height,
      }),
    }),
    {},
  );

  return resolutionsMap;
}

/**
 * The scalers that are available depend on the available resolutions.
 */
function getScalers(resolutions) {
  if (!resolutions.some(res => res.isValid)) {
    // None of the target resolutions are valid, downscaling is available.
    return {
      scaler: 'none',
      scalers: [['none', 'None'], ['scale', 'Bicubic Interpolation']],
    };
  }

  return { scaler: defaults.scaler, scalers };
}

/**
 * Given a source resolution and a target resolution, return the source
 * resolution scaled to the target resolution, within its width and height
 * bounds. Neither width or height must ever be larger than the target's, and
 * the aspect ratio must be maintained.

 * Examples (target resolution is always 1920x1080):
 * 1). Source and target aspect ratios equal:
 *     source: {width: 640, height: 360}
 *     result: {width: 1920, height: 1080}
 * 2). Source width is higher than target's ratio (and the limiting factor):
 *     source: {width: 700, height: 360}
 *     result: {width: 1920, height: 987}
 * 3). Source height is higher than the target's ratio (and the limiting factor):
 *     source: {width: 640, height: 400}
 *     result: {width: 1728, height: 1080}
 */
function getScaledResolution(source, target) {
  const sourceRatio = source.width / source.height;
  const targetRatio = target.width / target.height;

  if (Math.round(sourceRatio) === targetRatio) {
    return target;
  }

  return {
    width:
      sourceRatio < targetRatio
        ? Math.round((target.height / source.height) * source.width)
        : target.width,
    height:
      sourceRatio > targetRatio
        ? Math.round((target.width / source.width) * source.height)
        : target.height,
    tag: target.tag,
  };
}

export function isValidScaledResolution(
  scaler,
  sourceResolution,
  scaledResolution,
) {
  if (!scaler || scaler === 'none') {
    return (
      scaledResolution &&
      scaledResolution.width == sourceResolution.width &&
      scaledResolution.height == sourceResolution.height
    );
  }

  // Enforce a minimum of 16 pixels (width+height) and no scaled resolutions higher UHD 8K dimensions.
  let result =
    scaledResolution.width >= 16 &&
    scaledResolution.height >= 16 &&
    scaledResolution.width <= 7680 &&
    scaledResolution.height <= 4320;

  if (scaler === 'pabsr1') {
    // >=1x scale and <=4x
    result =
      result &&
      (scaledResolution.width <= sourceResolution.width * 4 &&
        scaledResolution.width >= sourceResolution.width &&
        scaledResolution.height <= sourceResolution.height * 4 &&
        scaledResolution.height >= sourceResolution.height);
  } else if (scaler === 'dvres') {
    // < HD
    result =
      result &&
      (scaledResolution.width <= 1920 && scaledResolution.height <= 1080);
  } else if (scaler === 'dvres2') {
    // <= 6x
    result =
      result &&
      (scaledResolution.width <= sourceResolution.width * 6 &&
        scaledResolution.height <= sourceResolution.height * 6);
  }

  return result;
}

function calcBitrate({ factor, source, targetResolution }) {
  const sourcePixels = source.resolution.width * source.resolution.height;
  const targetPixels = targetResolution.width * targetResolution.height;
  return Math.round((factor * source.bitrate * targetPixels) / sourcePixels);
}

// Generate a list of bitrates, based on the source material
// and the target resolution.
export function generateBitrates(source, targetResolution) {
  const bitrateByFactor = factor =>
    calcBitrate({ factor, source, targetResolution });

  const recommendedBitrate = bitrateByFactor(1);

  const availableBitrates = [];

  availableBitrates.push({
    bitrate: 0,
    label: 'Adaptive',
    genericLabel: 'Adaptive',
    recommended: true,
    tags: ['adaptive'],
  });

  if (source.bitrate != recommendedBitrate) {
    availableBitrates.push({
      bitrate: source.bitrate,
      label: `${bitsInClosestUnit(source.bitrate)}ps (source)`,
      genericLabel: 'Source',
      tags: ['source'],
    });
  }

  availableBitrates.push({
    bitrate: bitrateByFactor(0.5),
    label: `${bitsInClosestUnit(bitrateByFactor(0.5))}ps`,
    genericLabel: '50% of recommended',
    tags: ['50p_recommended'],
  });
  availableBitrates.push({
    bitrate: bitrateByFactor(0.75),
    label: `${bitsInClosestUnit(bitrateByFactor(0.75))}ps`,
    genericLabel: '75% of recommended',
    tags: ['75p_recommended'],
  });

  if (source.bitrate != recommendedBitrate) {
    availableBitrates.push({
      bitrate: recommendedBitrate,
      label: `${bitsInClosestUnit(recommendedBitrate)}ps (recommended)`,
      genericLabel: 'Recommended',
      tags: ['recommended'],
    });
  } else {
    availableBitrates.push({
      bitrate: source.bitrate,
      label: `${bitsInClosestUnit(source.bitrate)}ps (recommended/source)`,
      genericLabel: 'Recommended / Source',
      tags: ['recommended', 'source'],
    });
  }

  availableBitrates.push({
    bitrate: bitrateByFactor(1.5),
    label: `${bitsInClosestUnit(bitrateByFactor(1.5))}ps`,
    genericLabel: '150% of recommended',
    tags: ['150p_recommended'],
  });
  availableBitrates.push({
    bitrate: bitrateByFactor(2),
    label: `${bitsInClosestUnit(bitrateByFactor(2))}ps`,
    genericLabel: '200% of recommended',
    tags: ['200p_recommended'],
  });
  availableBitrates.push({ bitrate: -1, label: `Custom (enter below)` });

  return availableBitrates;
}

export function getOutputAspectRatios(source) {
  const outputAspectRatios = [];

  const displayAspectRatio =
    source.displayResolution.width / source.displayResolution.height;
  const storageAspectRatio =
    source.reshapedResolution.width / source.reshapedResolution.height;
  const originalPixelAspectRatio =
    source.resolution.width /
    source.resolution.height /
    (source.originalDisplayResolution.width /
      source.originalDisplayResolution.height);
  const pixelAspectRatioPreserved =
    storageAspectRatio / originalPixelAspectRatio;

  outputAspectRatios.push({
    aspectRatio: displayAspectRatio,
    label: `Display aspect ratio - ${displayAspectRatio.toFixed(3)}`,
    genericLabel: 'Display aspect ratio',
    tag: 'display',
  });

  outputAspectRatios.push({
    aspectRatio: storageAspectRatio,
    label: `Storage aspect ratio - ${storageAspectRatio.toFixed(3)}`,
    genericLabel: 'Storage aspect ratio',
    tag: 'storage',
  });

  outputAspectRatios.push({
    aspectRatio: pixelAspectRatioPreserved,
    label: `Pixel aspect ratio preserved - ${pixelAspectRatioPreserved.toFixed(
      3,
    )}`,
    genericLabel: 'Pixel aspect ratio preserved',
    tag: 'par_preserved',
  });

  outputAspectRatios.push({
    aspectRatio: 16 / 9,
    label: '16:9 (HDTV) - 1.777',
    genericLabel: '16:9 (HDTV)',
    tag: '16_9',
  });

  outputAspectRatios.push({
    aspectRatio: 4 / 3,
    label: '4:3 (SD) - 1.333',
    genericLabel: '4:3 (SD)',
    tag: '4_3',
  });

  return outputAspectRatios;
}

export function getChromaSubsamplingLabel(pixelFormatName) {
  return formatPixelFormatName(pixelFormatName);
}

export function getCodecProperties(state) {
  if (state.codec === 'prores') {
    return getProResCodecProperties(JSON.parse(state.proresProfile));
  } else if (state.codec === 'dnxhd') {
    return getDNxHDCodecProperties(JSON.parse(state.dnxhdProfile));
  }

  return {};
}

export function findPresetResolutionForTag(tag) {
  const matchingResolutions = presetResolutions.filter(
    ([value, label]) => value.tag === tag,
  );

  return matchingResolutions && matchingResolutions[0][0];
}

export function isFixedResolutionCodec(state) {
  if (state.codec === 'xdcam') {
    return true;
  } else if (state.codec === 'dnxhd') {
    return (
      getDNxHDCodecProperties(JSON.parse(state.dnxhdProfile)).profile ===
      'dnxhd'
    );
  }

  return false;
}
