import { createAction, createReducer } from './utils';

export const addMessage = createAction('addMessage');
export const updateMessage = createAction('updateMessage');
export const removeMessage = createAction('removeMessage');

export const messagesReducer = createReducer([], {
  [addMessage]: (state, action) => {
    return [...state, action.payload];
  },
  [updateMessage]: (state, action) => {
    return state.map(message => {
      if (message.id === action.payload.id) {
        return {
          ...message,
          ...action.payload,
        };
      }
      return message;
    });
  },
  [removeMessage]: (state, action) => {
    return state.filter(message => message.id !== action.payload.id);
  },
});
