import { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import useReactRouter from 'use-react-router';

import { teamProjectsSelector, teamVideosSelector } from './store/selectors';
import { debounce } from './utils';
import { Heading, Text } from './typography';
import * as colors from './colors';
import { rem } from './typography';
import { Close, RoundedIconButton, Search } from './icons';
import { MultiThumbnail } from './multi-thumbnail';
import { aggregateVideos } from './video-status';

const inputCss = {
  position: 'absolute',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  border: 'none',
  height: 30,
  lineHeight: 'normal',
  width: '100%',
  padding: '0 25px 0 17px',
  outline: 'none',
  color: colors.grey2,
  backgroundColor: colors.white2,
  fontSize: rem(13),
  borderRadius: 30,
  transition: 'color .18s ease, background-color .18s ease',
  '::placeholder': {
    color: colors.grey2,
  },
  ':focus': {
    backgroundColor: colors.grey3,
    color: colors.white0,
    boxShadow: `0 0 0 2px ${colors.hexToRgba(colors.orange1, 0.6)}`,
  },
  ':focus::placeholder': {
    color: colors.white0,
  },
  '::-webkit-search-cancel-button': {
    WebkitAppearance: 'none',
  },
};

function _SearchBox({ projects, videos, styles = {} }) {
  // Remove the query and hide results if the user clicks outside the element.
  const containerRef = useRef();
  useOnClickOutside(containerRef, closeSearchBox);

  const [isIconVisible, showIcon] = useState(true);

  // In order to ensure that updates to the input value are shown immediately,
  // but filtered results are only applied as determined by the debounce
  // function arguments, we use different state values to contain the input
  // value and the current search query.
  const [value, setValue] = useState('');
  const [searchString, setSearchString] = useState('');

  const query = searchString.toLowerCase();
  const isEmpty = query === '';

  const projectResults = projects.filter(project => {
    if (
      !isEmpty &&
      project &&
      typeof project.name === 'string' &&
      project.name.toLowerCase().includes(query)
    ) {
      const projectVideos = videos.filter(
        video => video.project_id === project.id,
      );
      project.numVideos = projectVideos.length;
      project.aggregatedVideos = aggregateVideos(projectVideos);
      return project;
    }
    return false;
  });

  const numVideos = videos.length;
  const videoResults = videos.filter(video => {
    if (
      !isEmpty &&
      video &&
      typeof video.name === 'string' &&
      video.name.toLowerCase().includes(query)
    ) {
      video.project = projects.find(project => project.id === video.project_id);
      return video;
    }

    // Descriptions can be longer than other fields, so this is a potential
    // bottleneck.
    if (
      !isEmpty &&
      typeof video.description === 'string' &&
      video.description.toLowerCase().includes(query)
    ) {
      video.project = projects.find(project => project.id === video.project_id);
      return video;
    }

    // Comparing the text values of n attribute fields in m videos can
    // potentially be quite slow. Only do the comparison if there are fewer
    // than 100 videos for now.
    if (numVideos < 100 && !isEmpty && video.attributes) {
      for (const value of Object.values(video.attributes)) {
        if (
          value &&
          typeof value === 'string' &&
          value.toLowerCase().includes(query)
        ) {
          video.project = projects.find(
            project => project.id === video.project_id,
          );
          return video;
        }
      }
    }

    return false;
  });

  // Update the search query only when 160ms have passed since the previous
  // keystroke.
  const debouncedUpdate = debounce(setSearchString, 160);
  function onChange(event) {
    const { value } = event.target;
    setValue(value);
    debouncedUpdate(value);
  }

  function closeSearchBox() {
    setValue('');
    setSearchString('');
    showIcon(true);
  }

  return (
    <div
      ref={containerRef}
      css={{
        ...styles,
        position: 'relative',
      }}
    >
      <input
        type="search"
        placeholder="Search here"
        value={value}
        onChange={onChange}
        onFocus={() => showIcon(false)}
        css={inputCss}
      />
      {isIconVisible ? (
        <Search
          width={12}
          height={12}
          styles={{ position: 'absolute', top: 9, right: 10 }}
        />
      ) : (
        <RoundedIconButton
          icon={Close}
          iconProps={{
            width: 12,
            height: 12,
            color: colors.grey2,
          }}
          styles={{
            position: 'absolute',
            top: 8,
            right: 8,
            height: 14,
            width: 14,
            padding: 0,
            backgroundColor: colors.white0,
          }}
          onClick={closeSearchBox}
        />
      )}
      {!isEmpty && (
        <SearchResults
          projects={projectResults}
          videos={videoResults}
          searchString={value}
          closeSearchBox={closeSearchBox}
          styles={{
            position: 'absolute',
            top: 45,
          }}
        />
      )}
    </div>
  );
}

export function Thumbnail({ src, styles = {} }) {
  return (
    <div
      css={{
        borderRadius: 3,
        backgroundColor: colors.grey0,
        backgroundSize: 'cover',
        backgroundImage: src && `url(${src})`,
        ...styles,
      }}
    />
  );
}

function SearchResultRow({ children, openResult }) {
  return (
    <div
      tabIndex="0"
      css={{
        display: 'flex',
        cursor: 'pointer',
        borderRadius: 3,
      }}
      onClick={openResult}
      onKeyDown={event => {
        if (event.keyCode === 13) {
          openResult(event);
        }
      }}
    >
      {children}
    </div>
  );
}

export function useOnClickOutside(ref, handler) {
  useEffect(() => {
    function listener(event) {
      if (!ref.current || ref.current.contains(event.target)) return;
      handler(event);
    }

    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);

    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, []);
}

function SearchResults({
  projects,
  videos,
  searchString,
  closeSearchBox,
  styles = {},
}) {
  const { history } = useReactRouter();
  return (
    <div
      css={{
        backgroundColor: colors.white0,
        width: 324,
        padding: 20,
        boxShadow: '0 0 20px rgba(0, 0, 0, .16)',
        borderRadius: 5,
        maxHeight: '70vh',
        overflow: 'auto',
        '>div:not(:last-child)': {
          marginBottom: 26,
        },
        ...styles,
      }}
    >
      {projects.length === 0 && videos.length === 0 && (
        <Heading as="h6" css={{ fontSize: 14 }}>
          No results found for “{searchString}”.
        </Heading>
      )}
      {projects.length > 0 && (
        <div>
          <div css={{ display: 'flex', justifyContent: 'space-between' }}>
            <Heading as="h6" css={{ fontSize: 14, marginBottom: 12 }}>
              Projects
            </Heading>
            <Text css={{ color: colors.grey3 }}>{projects.length}</Text>
          </div>
          <div
            css={{
              '>div:not(:last-child)': { marginBottom: 15 },
            }}
          >
            {projects.map(project => (
              <SearchResultRow
                key={project.id}
                openResult={() => {
                  closeSearchBox();
                  history.push(`/projects/${project.id}`);
                }}
              >
                <MultiThumbnail
                  thumbnails={project.aggregatedVideos.thumbnails
                    .slice(0, 4)
                    .map(thumb => thumb.small)}
                  styles={{ height: 40, width: 61, marginRight: 10 }}
                />
                <div
                  css={{
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                  }}
                >
                  <Text
                    css={{
                      color: colors.black1,
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                      width: 255,
                    }}
                  >
                    {project.name}
                  </Text>
                  <Text>
                    {project.numVideos} video
                    {project.numVideos === 1 ? '' : 's'} -{' '}
                    {project.aggregatedVideos.size}
                  </Text>
                </div>
              </SearchResultRow>
            ))}
          </div>
        </div>
      )}
      {videos.length > 0 && (
        <div>
          <div css={{ display: 'flex', justifyContent: 'space-between' }}>
            <Heading as="h6" css={{ fontSize: 14, marginBottom: 12 }}>
              Videos
            </Heading>
            <Text css={{ color: colors.grey3 }}>{videos.length}</Text>
          </div>
          <div css={{ '>div:not(:last-child)': { marginBottom: 15 } }}>
            {videos.map(video => (
              <SearchResultRow
                key={video.id}
                openResult={() => {
                  closeSearchBox();
                  history.push(
                    video.clip_id
                      ? `/projects/${video.project.id}/videos/${
                          video.parent_id
                        }?clip_id=${video.clip_id}`
                      : video.parent_id
                      ? `/projects/${video.project.id}/videos/${
                          video.parent_id
                        }`
                      : `/projects/${video.project.id}/videos/${video.id}`,
                  );
                }}
              >
                <Thumbnail
                  src={video.thumbnail_urls && video.thumbnail_urls.small}
                  styles={{
                    width: 61,
                    height: 40,
                    marginRight: 10,
                  }}
                />
                <div
                  css={{
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <Text
                    css={{
                      color: colors.black1,
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                      width: 255,
                    }}
                  >
                    {video.name}
                  </Text>
                  <Text>In {video.project.name}</Text>
                </div>
              </SearchResultRow>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

export const SearchBox = connect(state => ({
  projects: teamProjectsSelector(state),
  videos: teamVideosSelector(state),
}))(_SearchBox);
