import React from 'react';
import { connect } from 'react-redux';

import * as colors from '../../colors';
import { Text, rem } from '../../typography';
import { Dialog } from '../../dialog';
import { Alert } from '../../forms';
import { fetch } from '../../requests';
import { removePreset } from '../../store/presets';
import { addToast } from '../../store/toasts';

function _DeletePreset({
  preset,
  removePreset,
  teamId,
  dispatcher,
  addToast,
  resetPreset,
}) {
  const [error, setError] = React.useState(null);

  function onConfirm() {
    event.preventDefault();
    event.stopPropagation();

    return fetch(`/api/presets/${preset.id}`, {
      method: 'DELETE',
      body: { teamId },
    })
      .then(res => res.json())
      .then(response => {
        if (response.error) {
          return Promise.reject(response.error);
        }

        removePreset({ id: preset.id });
        dispatcher(preset.id);
        addToast({ text: 'Preset successfully deleted' });
        return true;
      })
      .catch(error => {
        setError(error);
        return false;
      });
  }

  return (
    <Dialog
      danger
      heading="Confirm preset deletion"
      confirmText="Yes, delete preset"
      onConfirm={onConfirm}
      closeOnConfirm
      isOpen
    >
      {error && <Alert text={error} styles={{ marginBottom: 12 }} />}
      <Text css={{ fontSize: rem(14), color: colors.grey2 }}>
        Are you sure you wish to delete this preset ({preset && preset.name})?
      </Text>
    </Dialog>
  );
}

export const DeletePreset = connect(
  (state, ownProps) => ({
    teamId: state.currentTeamId,
    preset: state.presets.find(preset => preset.id === ownProps.payload.id),
    dispatcher: ownProps.payload.dispatcher,
  }),
  { removePreset, addToast },
)(_DeletePreset);
