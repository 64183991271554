import React from 'react';
import { connect } from 'react-redux';

import { Dialog } from '../dialog';
import { currentVideoSelector } from '../store/selectors';
import * as colors from '../colors';
import {
  formatMsToHourMinutesSeconds,
  bitsInClosestUnit,
  bytesInClosestUnit,
} from '../utils';
import {
  formatCodec,
  formatCodecName,
  formatContainer,
  formatContainerName,
  formatPixelFormatNameAndBitDepth,
  formatDetailedFrameRate,
  formatFrameRateWithScanningAndFieldOrder,
  formatColorInfo,
  formatBoolean,
  calculateBitrate,
  interlacedFieldOrderModes,
  clarityBoostOptions,
  grainSizeOptions,
  dvres2Variants,
  deinterlacers,
  frameGeometryAdjusters,
  denoisers,
  debanders,
  stabilizers,
  augmenters,
  scalers,
  frameRateConverters,
  postProcessors,
  defaults,
} from '../modals/processing-tool/process-video-options';

function findOption(options, value) {
  return options.find(option => option[0] === value)[1];
}

function getInterlacedFieldOrder(value) {
  return findOption(interlacedFieldOrderModes, value);
}

function getClarityBoost(value) {
  return findOption(clarityBoostOptions, value);
}

function getGrainSize(value) {
  return findOption(grainSizeOptions, value);
}

function getDVRES2Variant(value) {
  return findOption(dvres2Variants, value);
}

function getFilterName(value) {
  const filter = [
    ...deinterlacers,
    ...frameGeometryAdjusters,
    ...denoisers,
    ...stabilizers,
    ...augmenters,
    ...scalers,
    ...frameRateConverters,
    ...debanders,
    ...postProcessors,
  ].find(filter => filter[0] === value);
  return filter ? filter[1] : value;
}

const NotApplicable = () => (
  <td css={{ color: `${colors.grey2} !important` }}>N/A</td>
);

function _ViewInfo({ video, preset, source }) {
  const isDone = video.ingestion_state.ingestionStatus === 'DONE';

  function getProcessed(video) {
    const params = video.processing_parameters;
    if (Object.keys(params).length === 0) {
      // This is not supposed to happen, but let's at least prevent the page
      // from showing an error.
      return {};
    }

    const startPosMillis = params.sourceVideo.startPositionMillis || 0;
    const startPos = formatMsToHourMinutesSeconds(startPosMillis);
    const endPosMillis =
      params.sourceVideo.endPositionMillis || source.metadata.durationInMillis;
    const endPos = formatMsToHourMinutesSeconds(endPosMillis);
    const codec =
      formatCodecName(params.targetVideo.codec.name) ||
      params.targetVideo.codec.name;
    const container =
      formatContainerName(params.targetVideo.container.name) ||
      params.targetVideo.container.name;
    const { frameWidth, frameHeight } = isDone
      ? video.metadata
      : params.targetVideo;
    const resolution = `${frameWidth}×${frameHeight}`;
    const frameRate = isDone
      ? formatDetailedFrameRate(video)
      : `${parseFloat(
          params.targetVideo.averageFramerate.toFixed(3),
        )} FPS (${formatFrameRateWithScanningAndFieldOrder(
          params.targetVideo.scanning,
          params.targetVideo.averageFramerate,
        )})`;
    const pixelFormat = formatPixelFormatNameAndBitDepth(
      isDone
        ? video.metadata.pixelFormatName
        : params.targetVideo.pixelFormat.name,
    );
    const colorInfo = isDone ? formatColorInfo(video) : 'Pending ingestion';
    const bitrate = `${bitsInClosestUnit(
      isDone ? calculateBitrate(video) : params.targetVideo.bitrate,
    )}ps`;
    const size = isDone
      ? bytesInClosestUnit(video.metadata.size)
      : 'Pending ingestion';
    const duration = formatMsToHourMinutesSeconds(
      endPosMillis - startPosMillis,
    );
    return {
      videoFilters: params.targetVideo.videoFilters,
      startPos,
      endPos,
      codec,
      container,
      resolution,
      frameRate,
      pixelFormat,
      colorInfo,
      bitrate,
      size,
      duration,
    };
  }

  const processed = getProcessed(video);
  const sourceBitrate = bitsInClosestUnit(calculateBitrate(source)) + 'ps';

  function Filter({ filter }) {
    return (
      <tr>
        <td>Filter</td>
        <NotApplicable />
        <td>{getFilterName(filter.name)}</td>
      </tr>
    );
  }

  return (
    <Dialog heading="Processing info" isOpen containerStyles={{ width: 800 }}>
      {preset && (
        <div css={{ marginBottom: 12 }}>
          This video was processed with the "{preset.name}" preset.
        </div>
      )}
      <table
        css={{
          width: '100%',
          color: colors.grey3,
          borderSpacing: '0 3px',
          'td + td': { color: colors.black1, textAlign: 'right' },
          'thead td': {
            color: colors.grey3,
          },
          tr: {
            height: 30,
          },
          'tr:nth-of-type(even)': {
            background: colors.hexToRgba(colors.grey4, 0.6),
          },
          td: { padding: 0 },
          'tr>td:first-of-type': {
            paddingLeft: 6,
          },
          'tr>td:last-of-type': {
            paddingRight: 6,
          },
        }}
      >
        <thead>
          <tr>
            <td />
            <td>Original</td>
            <td>Processed</td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Resolution</td>
            <td>
              {source.metadata.frameWidth}×{source.metadata.frameHeight}
            </td>
            <td>{processed.resolution}</td>
          </tr>
          <tr>
            <td>Frame rate</td>
            <td>{formatDetailedFrameRate(source)}</td>
            <td>{processed.frameRate}</td>
          </tr>
          <tr>
            <td>Container</td>
            <td>{formatContainer(source)}</td>
            <td>{processed.container}</td>
          </tr>
          <tr>
            <td>Codec</td>
            <td>{formatCodec(source)}</td>
            <td>{processed.codec}</td>
          </tr>
          <tr>
            <td>Pixel format</td>
            <td>
              {formatPixelFormatNameAndBitDepth(
                source.metadata.pixelFormatName,
              )}
            </td>
            <td>{processed.pixelFormat}</td>
          </tr>
          <tr>
            <td>Color info</td>
            <td>{formatColorInfo(source)}</td>
            <td>{processed.colorInfo}</td>
          </tr>
          <tr>
            <td>Bitrate</td>
            <td>{sourceBitrate}</td>
            <td>{processed.bitrate}</td>
          </tr>
          <tr>
            <td>Size</td>
            <td>{bytesInClosestUnit(source.metadata.size)}</td>
            <td>{processed.size}</td>
          </tr>
          <tr>
            <td>Start position (ms)</td>
            <NotApplicable />
            <td>{processed.startPos}</td>
          </tr>
          <tr>
            <td>End position (ms)</td>
            <NotApplicable />
            <td>{processed.endPos}</td>
          </tr>
          <tr>
            <td>Duration</td>
            <td>
              {formatMsToHourMinutesSeconds(source.metadata.durationInMillis)}
            </td>
            <td>{processed.duration}</td>
          </tr>
          {processed.videoFilters &&
            processed.videoFilters.map(filter =>
              ['deint', 'yadif', 'bwdif', 'weston3f'].includes(filter.name) ? (
                <>
                  <Filter filter={filter} />
                  <tr>
                    <td>Interlaced Field Order</td>
                    <NotApplicable />
                    <td>
                      {getInterlacedFieldOrder(
                        filter.properties.interlacedFieldOrder ||
                          defaults.interlacedFieldOrderMode,
                      )}
                    </td>
                  </tr>
                </>
              ) : filter.name === 'crop' ? (
                <>
                  <Filter filter={filter} />
                  <tr>
                    <td>Cropping rectangle</td>
                    <NotApplicable />
                    <td>
                      ({parseInt(filter.properties.x)},
                      {parseInt(filter.properties.y)})-(
                      {parseInt(filter.properties.x) +
                        parseInt(filter.properties.width)}
                      ,
                      {parseInt(filter.properties.y) +
                        parseInt(filter.properties.height)}
                      )
                    </td>
                  </tr>
                </>
              ) : filter.name === 'faceforward' ? (
                <>
                  <Filter filter={filter} />
                  <tr>
                    <td>Face relighting intensity</td>
                    <NotApplicable />
                    <td>
                      {filter.properties.relightIntensity ||
                        defaults.relightIntensity}
                      %
                    </td>
                  </tr>
                  <tr>
                    <td>Auto white balance</td>
                    <NotApplicable />
                    <td>
                      {formatBoolean(
                        filter.properties.autoWhiteBalance ||
                          defaults.autoWhiteBalance,
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>Color boost</td>
                    <NotApplicable />
                    <td>
                      {filter.properties.colorBoost || defaults.colorBoost}%
                    </td>
                  </tr>
                  <tr>
                    <td>Background blur</td>
                    <NotApplicable />
                    <td>
                      {filter.properties.backgroundBlur ||
                        defaults.backgroundBlur}{' '}
                      / 20
                    </td>
                  </tr>
                </>
              ) : filter.name === 'pabsr1' ? (
                <>
                  <Filter filter={filter} />
                  <tr>
                    <td>Clarity boost</td>
                    <NotApplicable />
                    <td>{getClarityBoost(filter.properties.clarityBoost)}</td>
                  </tr>
                </>
              ) : filter.name === 'dvres2' ? (
                <>
                  <Filter filter={filter} />
                  <tr>
                    <td>Pixop Deep Restoration 2 variant</td>
                    <NotApplicable />
                    <td>
                      {getDVRES2Variant(
                        filter.properties.variant || defaults.dvres2Variant,
                      )}
                    </td>
                  </tr>
                </>
              ) : filter.name === 'filmgrain' ? (
                <>
                  <Filter filter={filter} />
                  <tr>
                    <td>Grain size</td>
                    <NotApplicable />
                    <td>
                      {getGrainSize(
                        filter.properties.grainSize || defaults.grainSize,
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>Grain strength</td>
                    <NotApplicable />
                    <td>
                      {filter.properties.lumaAmplification ||
                        defaults.grainStrength}{' '}
                      / 50
                    </td>
                  </tr>
                </>
              ) : ['fps', 'fblend', 'mcinterpolate', 'vfi'].includes(
                  filter.name,
                ) ? (
                <>
                  <Filter filter={filter} />
                  <tr>
                    <td>Target frame rate</td>
                    <NotApplicable />
                    <td>{filter.properties.fps} FPS</td>
                  </tr>
                </>
              ) : (
                <>
                  <Filter filter={filter} />
                </>
              ),
            )}
        </tbody>
      </table>
    </Dialog>
  );
}

export const ViewInfo = connect((state, ownProps) => {
  const video = currentVideoSelector(state, ownProps);
  const { presets } = state;
  const preset = video.preset_id
    ? presets.find(preset => preset.id === video.preset_id)
    : null;
  const source =
    !video.clip_id || video.clip_id === video.id
      ? state.videos.find(v => v.id === video.parent_id)
      : state.videos.find(v => v.id === video.clip_id);
  return { video, preset, source };
})(_ViewInfo);
