import { useState } from 'react';
import { connect } from 'react-redux';

import * as colors from '../colors';
import { Text, rem } from '../typography';
import { Dialog } from '../dialog';
import { Alert } from '../forms';
import { fetch } from '../requests';
import { cancelUpload } from '../store/uploads';
import { removeProject } from '../store/projects';
import { addToast } from '../store/toasts';

function _DeleteProject({
  project,
  teamId,
  videos,
  removeProject,
  cancelUpload,
  addToast,
}) {
  if (!project) {
    return null;
  }

  const [error, setError] = useState(null);

  const projectId = project.id;

  function onConfirm() {
    event.preventDefault();
    event.stopPropagation();

    return fetch(`/api/projects/${projectId}`, {
      method: 'DELETE',
      body: { teamId },
    })
      .then(res => res.json())
      .then(response => {
        if (response.error) {
          return Promise.reject(response.error);
        }

        cancelUpload({ projectId });
        removeProject({ id: projectId });
        addToast({ text: 'Project successfully deleted' });
        return true;
      })
      .catch(error => {
        setError(error);
        return false;
      });
  }

  const fileOrFiles = files => (files === 1 ? 'file' : 'files');

  return (
    <Dialog
      danger
      heading="Confirm project deletion"
      confirmText="Yes, delete project"
      onConfirm={onConfirm}
      closeOnConfirm
      isOpen
      containerStyles={{ maxHeight: 800 }}
    >
      {error && <Alert text={error} styles={{ marginBottom: 12 }} />}
      {videos.length > 0 ? (
        <>
          <Text css={{ fontSize: rem(14), color: colors.grey2 }}>
            If you delete the{' '}
            <span css={{ fontSize: rem(15), color: colors.red0 }}>
              {project.name}
            </span>{' '}
            project, you will also{' '}
            <b>
              <span css={{ fontSize: rem(15), color: colors.black1 }}>
                permanently lose all of its uploaded and processed video
                material
              </span>
            </b>
            . Are you sure you wish to continue by deleting this project and{' '}
            <b>
              <span css={{ fontSize: rem(15), color: colors.black1 }}>
                {videos.length}
              </span>
            </b>{' '}
            {fileOrFiles(videos.length)}?
          </Text>
          <p>
            <div
              style={{
                color: colors.red0,
              }}
            >
              <ol>
                {videos
                  .filter(video => !video.parent_id)
                  .map(video => {
                    const noVideos =
                      videos.filter(v => v.parent_id == video.id).length + 1;

                    return (
                      <li>
                        {video.name}{' '}
                        <span css={{ color: colors.grey2 }}>
                          ({noVideos} {fileOrFiles(noVideos)})
                        </span>
                      </li>
                    );
                  })}
              </ol>
            </div>
          </p>
        </>
      ) : (
        <>
          <Text css={{ fontSize: rem(14), color: colors.grey2 }}>
            Do you want to delete the{' '}
            <span css={{ fontSize: rem(15), color: colors.red0 }}>
              {project.name}
            </span>{' '}
            project?
          </Text>
        </>
      )}
    </Dialog>
  );
}

export const DeleteProject = connect(
  (state, ownProps) => ({
    project: state.projects.find(project => project.id === ownProps.projectId),
    teamId: state.currentTeamId,
    videos: state.videos.filter(
      video => video.project_id === ownProps.projectId,
    ),
  }),
  { cancelUpload, removeProject, addToast },
)(_DeleteProject);
