import React from 'react';
import { connect } from 'react-redux';

import { VideoPlayer } from '../pages/video';
import { Close, RoundedIconButton } from '../icons';
import { Modal } from '../modal';
import { Heading, rem } from '../typography';
import * as colors from '../colors';
import * as constants from '../constants';
import { closeDialog } from '../store/dialog';

const _VideoPlayerModal = ({ video, closeDialog }) => {
  const aspectRatio =
    video.metadata.webVideoWidth / video.metadata.webVideoHeight;

  let playerWidth = 960;
  const minUnadjustedWidthAspectRatio = 1.15;

  if (aspectRatio < minUnadjustedWidthAspectRatio) {
    playerWidth *= aspectRatio / minUnadjustedWidthAspectRatio;
  }

  return (
    <Modal isOpen>
      <div css={{ padding: 20 }}>
        <div
          css={{
            boxSizing: 'border-box',
            display: 'flex',
            flexDirection: 'column',
            width: playerWidth,
            maxHeight: '100%',
            backgroundColor: colors.white0,
            borderRadius: 5,
            boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.05)',
            zIndex: constants.zIndices.modal,
            '@media (max-width: 960px)': {
              width: '100%',
            },
          }}
        >
          <div
            css={{
              position: 'relative',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: 57,
              borderBottom: `1px solid ${colors.hexToRgba(colors.grey3, 0.2)}`,
            }}
          >
            <Heading
              css={{
                alignSelf: 'center',
                fontSize: rem(16),
                lineHeight: rem(32),
                color: colors.grey3,
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                margin: '40px',
              }}
            >
              {video.name || 'Video'}
            </Heading>
            <RoundedIconButton
              icon={Close}
              styles={{ position: 'absolute', top: 16, right: 13 }}
              iconProps={{ color: colors.grey2 }}
              onClick={closeDialog}
            />
          </div>
          <VideoPlayer
            src={video.web_video_url}
            poster={video.thumbnail_urls && video.thumbnail_urls.large}
            width={playerWidth}
            height="auto"
          />
        </div>
      </div>
    </Modal>
  );
};

export const VideoPlayerModal = connect(
  (state, ownProps) => ({
    video: state.videos.find(video => video.id === ownProps.videoId),
  }),
  { closeDialog },
)(_VideoPlayerModal);
