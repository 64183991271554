import { connect } from 'react-redux';

import * as colors from './colors';
import { Thumbnail } from './profile';
import { RoundedButton } from './button';
import { openDialog } from './store/dialog';
import { currentTeamSelector } from './store/selectors';

function _TeamMembers({ user, team, teamMembers, addTeamMember }) {
  return (
    <div
      css={{
        display: 'flex',
        alignItems: 'center',
        '>*:not(:last-child)': { marginRight: 6 },
      }}
    >
      {team && team.name && (
        <>
          <span css={{ color: colors.grey3 }}>Team</span>
          <span css={{ color: colors.orange1 }}>{team.name}</span>
        </>
      )}
      <Thumbnail user={user} color={colors.grey0} withInfo round />
      {teamMembers.map(user => (
        <Thumbnail
          key={user.id}
          user={user}
          color={colors.grey0}
          withInfo
          round
        />
      ))}
      <RoundedButton onClick={addTeamMember}>Add member</RoundedButton>
    </div>
  );
}

export const TeamMembers = connect(
  state => {
    const team = currentTeamSelector(state);
    const teamMembers = state.members.filter(
      member => member.team_id === team.id && member.id !== state.user.id,
    );
    return {
      team,
      teamMembers,
      user: state.user,
    };
  },
  dispatch => {
    return {
      _addTeamMember: team => () =>
        dispatch(
          openDialog({
            type: 'addTeamMember',
            teamId: team.id,
            teamName: team.name,
          }),
        ),
    };
  },
  (stateProps, dispatchProps, ownProps) => {
    const { user, team, teamMembers } = stateProps;
    const addTeamMember = dispatchProps._addTeamMember(team);
    return {
      user,
      team,
      teamMembers,
      addTeamMember,
      ...ownProps,
    };
  },
)(_TeamMembers);
