window.dataLayer = window.dataLayer || [];

const emitGA4 = data => {
  window.dataLayer.push(data);
};

const emitPlausible = data => {
  if (window.plausible) {
    window.plausible(data);
  }
};

export const completedSignup = () => {
  emitGA4({ event: 'completed_signup' });
  emitPlausible('AppSignup');
};

export const newsletterSignup = () => {
  emitGA4({ event: 'newsletter_signup' });
  emitPlausible('NewsletterSignup');
};

const emitOnceForPlanType = (team, planType, eventId) => {
  // only emit once for visitors
  if (
    team &&
    team.plan_type === planType &&
    'localStorage' in window &&
    window['localStorage'] !== null
  ) {
    const key = `plausible.one-offs`;
    const state = JSON.parse(localStorage.getItem(key) || '{}');

    if (state[eventId] !== true) {
      state[eventId] = true;
      emitPlausible(eventId);
    }

    localStorage.setItem(key, JSON.stringify(state));
  }
};

export const login = team => {
  emitOnceForPlanType(team, 'visitor', 'PF_01_Login');
};

export const openSampleComparison = team => {
  emitOnceForPlanType(team, 'visitor', 'PF_02_OpenSampleComparison');
};

export const openBillingSetting = team => {
  emitOnceForPlanType(team, 'visitor', 'PF_03_OpenBillingSettings');
};

export const addBillingInfo = team => {
  emitOnceForPlanType(team, 'visitor', 'PF_04_AddBillingInfo');
};

export const addPaymentMethod = team => {
  emitOnceForPlanType(team, 'visitor', 'PF_05_AddPaymentMethod');
};

export const purchaseCredits = ({
  team,
  invoiceId,
  amount,
  paidAmount,
  discount,
  tax,
}) => {
  emitGA4({
    event: 'purchase',
    ecommerce: {
      transaction_id: invoiceId,
      affiliation: 'Pixop App',
      value: paidAmount,
      tax,
      currency: 'USD',
      items: [
        {
          item_id: 'CREDITS',
          item_name: 'Pixop - Processing credits',
          price: amount,
          discount,
          item_brand: 'Pixop',
          item_category: 'Processing credits',
        },
      ],
    },
  });
  emitPlausible('Purchase');
  emitOnceForPlanType(team, 'pro', 'PF_06_Purchase');
};

export const startUpload = team => {
  emitOnceForPlanType(team, 'pro', 'PF_07_StartUpload');
};

export const completeUpload = team => {
  emitGA4({ event: 'video_uploaded' });
  emitPlausible('CompleteUpload');
  emitOnceForPlanType(team, 'pro', 'PF_08_CompleteUpload');
};

export const appraisedVideoProcessing = team => {
  emitGA4({ event: 'appraised_video_processing' });
  emitOnceForPlanType(team, 'pro', 'PF_09_AppraiseVideoProcessing');
};

export const startedVideoProcessing = team => {
  emitGA4({ event: 'started_video_processing' });
  emitOnceForPlanType(team, 'pro', 'PF_10_StartVideoProcessing');
};

export const openOwnComparison = team => {
  emitOnceForPlanType(team, 'pro', 'PF_11_OpenOwnComparison');
};
