import { createAction, createReducer } from './utils';

export const addProject = createAction('addProject');
export const updateProject = createAction('updateProject');
export const removeProject = createAction('removeProject');

export const projectsReducer = createReducer(null, {
  [addProject]: (state, action) => {
    const project = action.payload;
    const foundProject = state.find(({ id }) => id === project.id);
    if (!foundProject) {
      return [project, ...state];
    }
    return state;
  },
  [updateProject]: (state, action) => {
    const project = action.payload;
    return state.map(current => {
      if (current.id === project.id) {
        return project;
      }
      return current;
    });
  },
  [removeProject]: (state, action) => {
    return state.filter(project => project.id !== action.payload.id);
  },
});
