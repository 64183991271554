import { keyframes } from '@emotion/react';

import * as colors from './colors';
import * as constants from './constants';
import { rem } from './typography';

const progressAnimation = keyframes`
  0% {
    background-position: 0 0;
  }

  100% {
    background-position: 14px 0;
  }
`;

function progressToPercentage(progress = 0) {
  if (progress === 0) {
    return '0';
  } else if (progress === 1) {
    return '100';
  }
  return (Math.round(progress * 10000) / 100).toFixed(2);
}

export const Progress = ({
  value,
  color,
  isActive,
  styles,
  onClick,
  progressValue,
}) => {
  let label;
  let percentage;
  if (typeof value === 'string') {
    label = value;
    percentage = progressValue || 100;
  } else {
    percentage = progressToPercentage(value);
  }
  return (
    <div
      css={{
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: 14,
        width: 150,
        borderRadius: 14,
        backgroundColor: colors.grey0,
        overflow: 'hidden',
        ...styles,
        cursor: onClick ? 'pointer' : 'default',
      }}
      onClick={onClick}
    >
      <div
        css={{
          position: 'absolute',
          left: 0,
          height: 14,
          width: `${percentage}%`,
          borderRadius: 14,
          backgroundColor: color || colors.orange0,
          backgroundImage: isActive
            ? 'linear-gradient(45deg,rgba(255,255,255,.15) 25%,transparent 25%,transparent 50%,rgba(255,255,255,.15) 50%,rgba(255,255,255,.15) 75%,transparent 75%,transparent)'
            : 'none',
          backgroundSize: '1rem 1rem',
          animation: isActive
            ? `${progressAnimation} 1s linear infinite`
            : 'none',
        }}
      />
      <span
        css={{
          position: 'absolute',
          top: 0,
          bottom: 0,
          lineHeight: rem(14),
          fontSize: rem(10),
          color: percentage > 40 ? colors.white0 : colors.black2,
          textShadow: percentage > 40 ? constants.whiteTextTextShadow : 'none',
          textTransform: 'uppercase',
          letterSpacing: '0.03em',
        }}
      >
        {label ? label : `${percentage}%`}
      </span>
    </div>
  );
};
