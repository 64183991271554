import React from 'react';
import { loadStripe } from '@stripe/stripe-js';

import * as colors from '../colors';
import { Dialog } from '../dialog';
import { Text } from '../typography';
import { addToast } from '../store/toasts';
import { fetch } from '../requests';

export function SettleUtilities({
  heading,
  description,
  stripePublicKey,
  team,
  postConfirm,
  closeDialog,
  addToast,
}) {
  const [
    hasDebtThatMustBeSettled,
    setHasDebtThatMustBeSettled,
  ] = React.useState();
  const [
    utilitiesWithTaxBalance,
    setUtilitiesWithTaxBalance,
  ] = React.useState();
  const [isTeamVATExempt, setIsTeamVATExempt] = React.useState();
  const [threeDSecureIFrame, setThreeDSecureIFrame] = React.useState();

  const stripePromiseRef = React.useRef(loadStripe(stripePublicKey));

  function fetchAppraisal() {
    fetch(`/api/customer/${team.stripe_customer_id}/appraise-utilities`, {
      method: 'POST',
    })
      .then(res => res.json())
      .then(response => {
        setHasDebtThatMustBeSettled(response.mustSettle);
        setUtilitiesWithTaxBalance(response.amountWithTax);
        setIsTeamVATExempt(response.isTeamVATExempt);
      });
  }

  function isDebtSettled() {
    return fetch(
      `/api/customer/${team.stripe_customer_id}/appraise-utilities`,
      {
        method: 'POST',
      },
    )
      .then(res => res.json())
      .then(response => {
        return !response.mustSettle;
      });
  }

  function isBillableTeam() {
    return team.stripe_customer_id && team.billing_frequency !== 'never';
  }

  React.useEffect(() => {
    if (isBillableTeam()) {
      fetchAppraisal();
    } else {
      setHasDebtThatMustBeSettled(false);
      setUtilitiesWithTaxBalance('0.00');
    }
  }, []);

  let isConfirmed = false;

  function handleClose() {
    if (isBillableTeam() && isConfirmed) {
      function handleError(message) {
        closeDialog();
        postConfirm(false);

        addToast({ text: message });
      }

      return fetch(
        `/api/customer/${team.stripe_customer_id}/settle-utilities`,
        {
          method: 'POST',
        },
      )
        .then(res => res.json())
        .then(response => {
          function closeDialogWhenDebtIsSettled() {
            isDebtSettled().then(isSettled => {
              if (isSettled) {
                closeDialog();
                postConfirm(true);
              } else {
                // wait 2 s., then re-check - TODO: give up after 5-10 attempts and show an error
                setTimeout(closeDialogWhenDebtIsSettled, 2000);
              }
            });
          }

          if (response.error) {
            handleError(response.error);

            return;
          } else if (response.returnUrl) {
            setThreeDSecureIFrame(
              `<iframe width="500" height="600" frameborder="no" title="3DS Authentication" src="${
                response.returnUrl
              }"</iframe>`,
            );

            const threeDSListener = function(event) {
              if (event.data === '3DS-authentication-complete') {
                window.removeEventListener('message', threeDSListener);

                setThreeDSecureIFrame(null);

                // Check the PaymentIntent
                stripePromiseRef.current.then(val => {
                  return val
                    .retrievePaymentIntent(response.client_secret)
                    .then(function(result) {
                      if (result.error) {
                        // PaymentIntent client secret was invalid
                        handleError(
                          'Failed to create payment, please try again.',
                        );
                      } else {
                        if (result.paymentIntent.status === 'succeeded') {
                          closeDialogWhenDebtIsSettled();
                        } else if (
                          result.paymentIntent.status ===
                          'requires_payment_method'
                        ) {
                          // Authentication failed, prompt the customer to enter another payment method
                          handleError(
                            'Authentication failed, please try again or enter another payment method.',
                          );
                        }
                      }
                    });
                });
              }
            };

            window.addEventListener('message', threeDSListener);

            return;
          } else {
            closeDialogWhenDebtIsSettled();
          }
        })
        .catch(error => {
          handleError('A critical error occurred, please try again later.');
        });
    } else {
      closeDialog();
      postConfirm(isConfirmed);
    }
  }

  return (
    <Dialog
      heading={heading}
      confirmText="Confirm"
      containerStyles={{ width: 540 }}
      onConfirm={() => {
        if (utilitiesWithTaxBalance) {
          isConfirmed = true;
        } else {
          addToast({ text: 'Please try again in a couple of seconds' });
          return Promise.resolve(false);
        }
      }}
      close={handleClose}
      closeOnConfirm
      isOpen
    >
      <Text
        css={{
          marginBottom: 6,
        }}
      >
        {utilitiesWithTaxBalance && (
          <>
            {description}
            {hasDebtThatMustBeSettled && (
              <>
                {' '}
                Also, your outstanding utilities balance of{' '}
                <span css={{ color: colors.black0, fontSize: 16 }}>
                  ${utilitiesWithTaxBalance}
                </span>
                {!isTeamVATExempt && ', including taxes,'} will be settled via
                payment from your payment method when you press{' '}
                <b css={{ color: colors.black0 }}>Confirm</b>.
              </>
            )}
            {threeDSecureIFrame && (
              <p>
                <div
                  dangerouslySetInnerHTML={{
                    __html: threeDSecureIFrame,
                  }}
                />
              </p>
            )}
          </>
        )}
        {!utilitiesWithTaxBalance && <Text>Please wait...</Text>}
      </Text>
    </Dialog>
  );
}
