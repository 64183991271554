import * as colors from './colors';

export const whiteTextTextShadow = `0 0 1px ${colors.grey2}`;

export const transitionShort = '.18s';
export const transitionLong = '.36s';

export const baseFontSize = 14;

// This scale provides a way to set various z-index values throughout the app,
// as well as within local components, in slightly more predictable manner.
// Thus, any base UI elements will only need to use the base + modifier, and
// they will not affect other z-index values. Navigation elements will always
// be under modals, etc.
export const zIndices = {
  base: 0,
  nav: 10,
  subModal: 100,
  modal: 1000,
};
