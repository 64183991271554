import { createAction, createReducer } from './utils';

let toastId = 0;
export const addToast = createAction('addToast', payload => ({
  id: ++toastId,
  ...payload,
}));

export const removeToast = createAction('removeToast');

export const toastsReducer = createReducer([], {
  [addToast]: (state, action) => [action.payload, ...state],
  [removeToast]: (state, action) =>
    state.filter(toast => toast.id !== action.payload.id),
});
