import React from 'react';
import { connect } from 'react-redux';
import styled from '@emotion/styled';

import * as colors from '../../colors';
import { Dialog } from '../../dialog';
import { Alert, Input } from '../../forms';
import { fetch } from '../../requests';
import { updatePreset } from '../../store/presets';
import { addToast } from '../../store/toasts';

function _EditPreset({ preset, updatePreset, teamId, addToast }) {
  const [error, setError] = React.useState(null);

  const [presetName, setPresetName] = React.useState(preset.name);
  const [presetDescription, setPresetDescription] = React.useState(
    preset.description,
  );

  function onConfirm() {
    event.preventDefault();
    event.stopPropagation();

    return fetch(`/api/presets/${preset.id}`, {
      method: 'PATCH',
      body: { teamId, name: presetName, description: presetDescription },
    })
      .then(res => res.json())
      .then(response => {
        if (response.error) {
          return Promise.reject(response.error);
        }

        updatePreset({
          id: preset.id,
          name: presetName,
          description: presetDescription,
        });
        return true;
      })
      .catch(error => {
        setError(error);
        return false;
      });
  }

  return (
    <Dialog
      heading="Edit preset name and description"
      confirmText="Confirm"
      onConfirm={onConfirm}
      closeOnConfirm
      isOpen
    >
      {error && <Alert text={error} styles={{ marginBottom: 12 }} />}
      <div css={{ marginBottom: 20 }}>
        <Label>
          Preset name
          <Input
            type="text"
            placeolder="Preset name"
            value={presetName}
            onChange={event => setPresetName(event.target.value)}
            css={{ width: 'calc(100% - 24px)' }}
          />
        </Label>
      </div>
      <Label>
        Preset description
        <Input
          type="text"
          placeolder="Preset description"
          value={presetDescription}
          onChange={event => setPresetDescription(event.target.value)}
          css={{ width: 'calc(100% - 24px)' }}
        />
      </Label>
    </Dialog>
  );
}

const Label = styled('label')({
  display: 'block',
  color: colors.grey3,
  fontSize: 12,
  lineHeight: '20px',
  select: {
    fontSize: 14,
  },
});

export const EditPreset = connect(
  (state, ownProps) => ({
    teamId: state.currentTeamId,
    preset: state.presets.find(preset => preset.id === ownProps.payload.id),
  }),
  { updatePreset, addToast },
)(_EditPreset);
