import { useState } from 'react';
import useReactRouter from 'use-react-router';

import * as colors from './colors';
import * as constants from './constants';
import { rem } from './typography';
import { Chevron } from './icons';

const filters = {
  newest: 'Newest',
  oldest: 'Oldest',
  active: 'Active videos',
  inactive: 'Inactive videos',
  error: 'With errors',
  'a-z': 'Sort A-Z',
  'z-a': 'Sort Z-A',
};

const getFilter = filter => filters[filter] || 'Newest';

const containerCss = {
  position: 'relative',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  height: 30,
  color: colors.grey3,
  fontSize: rem(13),
  userSelect: 'none',
  transition: 'color .18s ease',
  zIndex: constants.zIndices.base + 3,
  paddingLeft: 12,
  paddingRight: 5,
  borderRadius: 30,
  ':focus': {
    color: colors.black0,
    boxShadow: `0 0 0 2px ${colors.hexToRgba(colors.orange1, 0.6)}`,
  },
  ':hover': {
    cursor: 'pointer',
    color: colors.black0,
  },
};

const optionsCss = {
  position: 'absolute',
  top: 43,
  right: 0,
  fontSize: rem(14),
  color: colors.grey3,
  borderRadius: 5,
  boxShadow: `0 0 20px ${colors.hexToRgba(colors.black0, 0.16)}`,
  backgroundColor: colors.white0,
  minWidth: 182,
  paddingTop: rem(10),
  paddingBottom: rem(10),
  '> div': {
    paddingLeft: rem(15),
    paddingRight: rem(15),
    lineHeight: rem(23),
    transition: 'color .18s ease',
  },
  '> div:not(:last-child)': {
    paddingBottom: rem(4),
  },
  '> div:hover': {
    color: colors.black0,
  },
};

export const Filter = ({ basePath, currentFilter }) => {
  const { history } = useReactRouter();
  const [isOpen, show] = useState(false);
  const setFilter = newFilter =>
    history.push(`${basePath}?filter=${newFilter}`);
  const onKeyDown = ({ keyCode }) => {
    if (keyCode === 13) {
      show(!isOpen);
    } else if (keyCode === 27) {
      show(false);
    }
  };
  return (
    <div
      tabIndex="0"
      role="button"
      onClick={() => show(!isOpen)}
      onKeyDown={onKeyDown}
      onBlur={() => show(false)}
      css={containerCss}
    >
      <span css={{ lineHeight: rem(13) }}>{getFilter(currentFilter)}</span>
      <Chevron css={{ marginLeft: 3, marginTop: -3 }} />
      {isOpen && (
        <div css={optionsCss}>
          {Object.entries(filters).map(([filter, label]) => (
            <div
              key={filter}
              onClick={() => setFilter(filter)}
              css={{ color: currentFilter === filter && colors.black0 }}
            >
              {label}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
