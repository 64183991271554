import React from 'react';
import { render } from 'react-dom';

import { App } from './app';
import { setDangerousHtml } from './utils';

// Enable HMR in development.
if (process.env.NODE_ENV !== 'production' && module.hot) {
  module.hot.accept();
}

const root = document.getElementById('root');
const state = JSON.parse(root.getAttribute('data-state'));
if (process.env.NODE_ENV === 'production') {
  root.removeAttribute('data-state');
}

// Set Sentry up on Errors only. This allows us to only import the
// (quite large) Sentry module when there is an error to report.
function captureError(error) {
  if (process.env.NODE_ENV === 'production') {
    import(/* webpackChunkName: "sentry" */ '@sentry/browser').then(Sentry => {
      Sentry.init({
        dsn: state.env.SENTRY_WEB_DSN,
        integrations: integrations =>
          integrations.filter(int => int.name !== 'GlobalHandlers'),
      });

      Sentry.captureException(error);
    });
  } else {
    console.error(error); // eslint-disable-line no-console
  }
}
window.onerror = (message, url, line, column, error) => captureError(error);
window.onunhandledrejection = event => captureError(event.reason);

// listen to 403 errors by hooking the global fetch function
// based on https://stackoverflow.com/questions/44440532/fetch-and-addeventlistener
window.fetch = new Proxy(window.fetch, {
  apply(fetch, that, args) {
    // Forward function call to the original fetch
    const result = fetch.apply(that, args);

    // Reload if any 401 is received
    result.then(response => {
      if (response.status === 401) {
        console.log('Unauthorized, refreshing state...');
        window.location.reload();
      }
    });

    return result;
  },
});

if (state.isSafari) {
  const polyfills = document.createElement('script');
  polyfills.src = state.polyfills;
  setDangerousHtml(document.body, polyfills);
}

render(<App initialState={state} />, root);
