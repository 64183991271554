import { useState } from 'react';
import { connect } from 'react-redux';
import useReactRouter from 'use-react-router';

import * as colors from '../colors';
import { Text, rem } from '../typography';
import { Dialog } from '../dialog';
import { Alert } from '../forms';
import { fetch } from '../requests';
import { removeVideo } from '../store/videos';
import { addToast } from '../store/toasts';

function _DeleteVideo({
  video,
  derivedVideos,
  navigateTo,
  removeVideo,
  addToast,
}) {
  const { history } = useReactRouter();
  const [error, setError] = useState(null);

  function onConfirm(event) {
    event.preventDefault();
    event.stopPropagation();

    return fetch(`/api/uploads/${video.id}`, { method: 'DELETE' }).then(res => {
      if (res.ok) {
        if (navigateTo) {
          history.push(navigateTo);
        }

        setTimeout(() => {
          // Ensure that we navigate away before the video is deleted from the
          // store.
          removeVideo({ id: video.id, clipId: video.clip_id });
        });

        addToast({ text: 'Video successfully deleted' });
        return true;
      } else {
        return res.json().then(response => {
          setError(response.error);
          return false;
        });
      }
    });
  }

  if (!video) {
    /* eslint-disable-next-line no-console */
    console.log('_DeleteVideo called with undefined video!');
    return null;
  }

  const toBeDeleted =
    !video.parent_id && !video.clip_id
      ? 'all uploaded and processed material'
      : video.clip_id && video.clip_id === video.id
      ? 'all material related to this clip'
      : 'the processed material';

  return (
    <Dialog
      heading="Confirm video deletion"
      confirmText="Yes, delete video"
      onConfirm={onConfirm}
      isOpen
      closeOnConfirm
      danger
      containerStyles={{ maxHeight: 800 }}
    >
      {error && <Alert text={error} styles={{ marginBottom: 12 }} />}
      <Text css={{ fontSize: rem(14), color: colors.grey2 }}>
        If you delete this video,{' '}
        <b>
          <span css={{ fontSize: rem(15), color: colors.black1 }}>
            {toBeDeleted} will be permanently lost
          </span>
        </b>
        . Are you sure you wish to continue and delete{' '}
        {derivedVideos.length > 0 ? (
          <>
            these{' '}
            <b>
              <span css={{ fontSize: rem(15), color: colors.black1 }}>
                {derivedVideos.length + 1}
              </span>
            </b>{' '}
            files?
          </>
        ) : (
          <>this file?</>
        )}
      </Text>
      <p>
        <div
          style={{
            color: colors.red0,
          }}
        >
          {derivedVideos.length > 0 ? (
            <ol>
              <li>
                <b>{video.name} (Original)</b>
              </li>
              {derivedVideos.map(video => {
                return (
                  <li>
                    {video.name} ({video.is_preview ? 'Preview' : 'Processed'})
                  </li>
                );
              })}
            </ol>
          ) : (
            <ul style={{ paddingLeft: 30, listStyleType: 'disc' }}>
              <li>
                <b>
                  {video.name} (
                  {!video.parent_id
                    ? 'Original'
                    : video.is_preview
                    ? 'Preview'
                    : 'Processed'}
                  )
                </b>
              </li>
            </ul>
          )}
        </div>
      </p>
    </Dialog>
  );
}

export const DeleteVideo = connect(
  (state, ownProps) => ({
    video: state.videos.find(video => video.id === ownProps.videoId),
    derivedVideos: state.videos.filter(
      video =>
        video.parent_id === ownProps.videoId ||
        (video.clip_id === ownProps.videoId && video.id !== ownProps.videoId),
    ),
  }),
  { removeVideo, addToast },
)(_DeleteVideo);
