import React from 'react';

import { Input } from './forms';

function createReducer({ sourceResolution, maxResolution, onChange }) {
  return function reducer(state, action) {
    if (action.type === 'reset') {
      return action.value;
    }

    const value = parseInt(action.value, 10);

    // Handle case where one of the inputs has been cleared completely.
    if (isNaN(value)) {
      return {
        width: action.type === 'width' ? action.value : sourceResolution.width,
        height:
          action.type === 'height' ? action.value : sourceResolution.height,
      };
    }

    if (action.type === 'width') {
      const returnValue = {
        width: Math.min(maxResolution.width, value),
        height: state.height,
      };
      onChange(returnValue);
      return returnValue;
    }

    if (action.type === 'height') {
      const returnValue = {
        width: state.width,
        height: Math.min(maxResolution.height, value),
      };
      onChange(returnValue);
      return returnValue;
    }

    return state;
  };
}

export function CustomResolution({
  sourceResolution,
  customResolution,
  maxResolution,
  onChange,
}) {
  const reducer = createReducer({ sourceResolution, maxResolution, onChange });
  const [state, dispatch] = React.useReducer(
    reducer,
    customResolution || {
      width: sourceResolution.width,
      height: sourceResolution.height,
    },
  );

  React.useEffect(
    () => {
      dispatch({
        type: 'reset',
        value: customResolution,
      });
    },
    [customResolution],
  );

  return (
    <div
      css={{
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <Input
        type="number"
        value={state.width}
        onChange={event =>
          dispatch({ type: 'width', value: event.target.value })
        }
      />
      <span css={{ padding: 6, fontSize: 16, marginTop: 5 }}>×</span>
      <Input
        type="number"
        value={state.height}
        onChange={event =>
          dispatch({ type: 'height', value: event.target.value })
        }
      />
    </div>
  );
}
